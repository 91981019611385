import React from "react";
import PoweredBy from "@/assets/images/PacksizeLogoWhite.png";
import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_DarkBG.svg";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Grid } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	fullHeight: { height: "100%" },
    leftMargin: {  marginLeft: "32px", padding: "0px !important" },
    bottomLeftMargin: {  marginLeft: "32px", marginBottom: "8px !important", padding: "0px !important" },
    rowPadding: { padding: "0px !important" }
}));

const LoginLeftPanel = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const poweredByAltText = intl.formatMessage({ id: "Powered By" });

	return (
		<Grid className={classes.fullHeight}>
			<Grid.Row className={classes.rowPadding}></Grid.Row>
			<Grid.Row className={classes.rowPadding}>
				<Grid.Column verticalAlign="middle" className={classes.leftMargin}>
					<img data-cy="packnet" src={CloudPlatformLogo} style={{ height: "30px" }} alt="PackNet" />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className={classes.rowPadding}>
				<Grid.Column verticalAlign="bottom" className={classes.bottomLeftMargin}>
					<img data-cy="powered-by" src={PoweredBy} style={{ width: "178px"}} alt={poweredByAltText} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default LoginLeftPanel;
