export const sortObjectsDescending = (items, key) => {
	if (!items.length) return items;

	return items?.sort((a, b) => {
		var nameA = a[key]?.toString().toUpperCase(); // ignore upper and lowercase
		var nameB = b[key]?.toString().toUpperCase(); // ignore upper and lowercase
		if (nameA > nameB) {
			return -1;
		}
		if (nameA < nameB) {
			return 1;
		}
		// names must be equal
		return 0;
	});
};

export const sortObjectsAscending = (items, key) => {
	if (!items.length) return items;

	return items?.sort((a, b) => {
		var nameA = a[key]?.toString().toUpperCase(); // ignore upper and lowercase
		var nameB = b[key]?.toString().toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		// names must be equal
		return 0;
	});
};
