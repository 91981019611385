import React, {useState, useEffect, useContext, useRef} from "react";
import {Form, FormField, Grid, GridRow, Button} from "semantic-ui-react";
import LoginSplashFrame from "../login-splash-frame";
import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_LightBG.svg";
import {FormattedMessage, useIntl} from "react-intl";
import {createUseStyles, useTheme} from "react-jss";
import {ReactComponent as Error} from "@/assets/images/error.svg";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as identityApi from "../../../api/identity-api";
import {setSsoUser, UserContext} from "@/components/user-context";
import LoadingBar from "@/components/loadingBar";
import { enqueueSnackbar } from "notistack";
import isStrongPassword from "validator/lib/isStrongPassword";

const useStyles = createUseStyles((theme) => ({
    loginGridContainer: {
        flex: "1",
        width: "40%",
        minWidth: "360px",
        maxWidth: "600px",
    },
    fullWidth: {
        width: "100%",
    },
    form: {
        width: "100%",
    },
    gridRow: {
        paddingBottom: "2rem",
    },
    resetPasswordButton: {
        textTransform: "none !important",
        width: "100%",
    },
    errorState: {
        color: "#FF3743",
    },
    loader: {
        marginTop: "2rem !important",
    },
    field: {
        "& .pointing.above.prompt.label": {
            border: "none !important",
            "margin-top": 0,
        },
        "& .label": {
            "&:before": {
                display: "none",
            },
        },
    },
}));

const PasswordReset = () => {
        const theme = useTheme();
        const classes = useStyles({theme});
        const intl = useIntl();
        let {setCurrentUser} = useContext(UserContext);
        const [errors, setErrors] = useState(false);
        const [password, setPassword] = useState("");
        const [loading, setLoading] = useState(true);
        const [searchParams] = useSearchParams();
        let loadCount = useRef(null);

        const navigate = useNavigate();

        const passwordStrengthRules = {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
        };

        const validateOtp = (token) => {
            identityApi.validatePasswordResetOtp({token}).then((validationResponse) => {
                if (validationResponse.success === true && validationResponse.responseData) {
                    let authToken = validationResponse.responseData.access_token;
                    setSsoUser(authToken, setCurrentUser).then((_) => {
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            });
        }

        useEffect(() => {
            if (loadCount.current == null) loadCount.current = 0;

            let token = searchParams.get("token");

            if (loadCount.current < 1) {
                if (token !== undefined && token != null) {
                    validateOtp(token);
                }
                setLoading(false);
            } else {
                setTimeout(() => {
                    setLoading(false);
                }, 4000)
            }

            loadCount.current += 1;
        }, []);

        const handleResetPasswordSubmit = async () => {
            if (!isStrongPassword(password, passwordStrengthRules)) {
                setErrors(intl.formatMessage({id: "Password not strong enough"}));
                return;
            }


            await identityApi.forgotPasswordReset({Password: password}).then((validationResponse) => {
                if (validationResponse.success === true) {
                    enqueueSnackbar(
                        intl.formatMessage({
                            id: "unknown",
                            defaultMessage: "Password reset successfully",
                        }),
                        {variant: "customSuccess", autoHideDuration: 3000}
                    );
                    navigate("/");
                } else {
                    enqueueSnackbar(intl.formatMessage({id: "Failed to reset password, please try again."}), {
                        variant: "customError",
                        autoHideDuration: 3000,
                    });
                    navigate("/login");
                }
            });
        };

        return (
            <LoginSplashFrame>
                {loading ? (
                    <div data-testid="loader">
                        <div className={classes.loaderStack}>
                            <img data-cy="loader" src={CloudPlatformLogo} style={{height: "60px"}} alt="PackNet"/>
                            <div className={classes.loader}>
                                <LoadingBar></LoadingBar>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid className={classes.loginGridContainer}>
                        <div className={classes.fullWidth}>
                            <GridRow className={classes.gridRow}>
                                <div>
                                    <img data-cy="packnet" src={CloudPlatformLogo} style={{height: "30px"}} alt="PackNet"/>
                                </div>
                            </GridRow>
                            <Form className={classes.form}>
                                <FormField className={classes.field}>
                                    <GridRow className={classes.fullWidth}>
									<span className={errors ? classes.errorState : ""}>
										<FormattedMessage id="Password" className={errors ? classes.errorState : ""}/>
									</span>
                                        <Form.Input
                                            placeholder={intl.formatMessage({id: "Enter User Password"})}
                                            type="password"
                                            required
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setErrors(false);
                                            }}
                                            icon
                                            error={errors ? {content: errors} : null}
                                        >
                                            <input/>
                                            {errors.password && (
                                                <i class="icon">
                                                    <Error className={classes.inputError}/>
                                                </i>
                                            )}
                                        </Form.Input>
                                    </GridRow>
                                </FormField>
                                <GridRow className={classes.gridRow}>
                                    <Button
                                        id="resetPasswordButton"
                                        primary
                                        tabIndex={3}
                                        basic={password.length <= 0}
                                        onClick={handleResetPasswordSubmit}
                                        className={classes.resetPasswordButton}
                                        data-cy="login-button"
                                    >
                                        {intl.formatMessage({id: "Reset Password"})}
                                    </Button>
                                </GridRow>
                            </Form>
                            <GridRow className={classes.fullWidth}>
                                <a href="/PackNet/login">{intl.formatMessage({id: "ReturnToLogin"})}</a>
                            </GridRow>
                        </div>
                    </Grid>
                )}
            </LoginSplashFrame>
        );
    }
;
export default PasswordReset;
