import React, { useEffect, useState } from "react";

import ProgressBarList from "@/views/reporting/progress-bar-list";
import SwimLanes from "@/views/reporting/swim-lanes";
import { getSwimLaneMaxiumum } from "@/views/reporting/functions/swimlanes";
import { useIntl } from "react-intl";

function TotalUsage({ reportData, dateRange, color, isMetric, showBales }) {
	const [presentationData, setPresentationData] = useState([]);
	const intl = useIntl();
	const [upperBound, setUpperBound] = useState(0);
	const unitLabel = showBales ? "|" : isMetric ? "sq meters" : "sq ft";

	function mapData(data) {
		let aggregation = [
			{
				totalConsumedCorrugate: data.totalConsumedCorrugate,
				...(showBales && data.totalBaleCount && { totalBaleCount: data.totalBaleCount }),
			},
		];

		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.totalConsumedCorrugate)
		);
		const upper = getSwimLaneMaxiumum(maximumConsumed);
		setUpperBound(upper);

		aggregation = aggregation.map((item) => {
			return {
				...item,
				percentage: (item.totalConsumedCorrugate / upper) * 100,
			};
		});

		setPresentationData(aggregation);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const items = presentationData.map((item) => {
		return {
			percent: item.percentage,
			value: `${item.totalConsumedCorrugate.toLocaleString(undefined, {
				maximumFractionDigits: 2,
			})} ${intl.formatMessage({ id: unitLabel })} ${
				showBales
					? item.totalBaleCount !== null && item.totalBaleCount !== undefined
						? item.totalBaleCount !== 0
							? item.totalBaleCount.toFixed(1).toLocaleString()
							: 0
						: "-"
					: ""
			}`,
		};
	});

	return items.length ? (
		<SwimLanes upperBound={upperBound}>
			<ProgressBarList color={color} items={items} />
		</SwimLanes>
	) : (
		<div></div>
	);
}

export default TotalUsage;
