import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Popup, Icon } from "semantic-ui-react";
import { incrementDateRange, midnightDate } from "@/views/reporting/functions/index";
import DashboardCard from "@/views/reporting/dashboard-card";
import Row from "../layout/row";
import { ReactComponent as CartonIcon } from "../img/carton-production.svg";
import { ReactComponent as ClockIcon } from "../img/clock.svg";
import { ReactComponent as ChartViewIcon } from "@/views/reporting/img/chartview-icon.svg";
import { ReactComponent as TableViewIcon } from "@/views/reporting/img/tableview-icon.svg";
import { getBucketCartonProductionByPlannedProductionSchedulesForSite } from "@/views/reporting/services/API/Reports/carton-production-api";
import { getPlannedProductionDataForSite } from "@/views/reporting/services/API/Reports/planned-production-api";
import CartonsMade from "./cards/cartons-made";
import AvgProductionTime from "./cards/avg-production-time";
import CartonsOverTime from "./cards/cartons-over-time";
import ProducedByZFold from "./cards/produced-by-z-fold";
import ProducedByMachines from "./cards/produced-by-machines";
import ExperienceContainer from "../layout/experience-container";
import errorBoundary from "@/components/error-boundary";

const useStyles = createUseStyles((theme) => ({
	cardHeader: {
		display: "flex",
		justifyContent: "space-between",
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingTop: "20px",
		paddingLeft: "0",
		paddingBottom: "31px",
		paddingRight: "0",
	},
	toggleBtnGrp: {
		"& > button:first-child": {
			borderTopRightRadius: "0px",
			borderBottomRightRadius: "0px",
		},
		"& > button:last-child": {
			borderTopLeftRadius: "0px",
			borderBottomLeftRadius: "0px",
		},
		"& > button": {
			height: "32px !important",
			width: "56px !important",
			padding: "0px !important",
			margin: "0px !important",
			border: "1px solid #dededf !important",
			"& svg": {
				height: "20px !important",
				width: "20px !important",
				alignItems: "center !important",
			},
		},
	},
	notActiveToggleBtn: {
		backgroundColor: "#ffffff !important",
		"& svg": {
			"& path": {
				fill: "#6b6b6b !important",
			},
		},
	},
	activeToggleBtn: {
		backgroundColor: "#f2f2f2 !important",
		"& svg": {
			"& path": {
				fill: "#353430 !important",
			},
		},
	},
}));

function CartonProduction({ currentUser }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const maxItemsOnCard = 6;
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showGraph, setShowGraph] = useState(true);
	const [internalNavigation, setInternalNavigation] = useState("");
	const selectedSites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [dateOptions, setDateOptions] = useState({
		start: midnightDate(new Date()),
		end: midnightDate(new Date()),
	});
	const [sortByMachine, setSortByMachine] = useState("none");
	const [sortByAmount, setSortByAmount] = useState("desc");
	const [machines, setMachines] = useState(() => {
		const machinesSaved = JSON.parse(localStorage.getItem("MACHINES"));

		return machinesSaved !== null && machinesSaved !== "undefined" ? machinesSaved : [];
	});

	const defaultColor = "#323430";

	async function getData(sites) {
		setIsLoading(true);
		let plannedProduction = [];
		const featureFlagWIP = false; // isFeatureFlagEnabled(currentUser, "pack-net-reporting-wip");
		const newDateRange = incrementDateRange(dateOptions);

		if (featureFlagWIP) {
			plannedProduction = await getPlannedProductionDataForSite(sites, newDateRange);
		}

		const cartonProduction = await getBucketCartonProductionByPlannedProductionSchedulesForSite(
			plannedProduction,
			sites,
			machines.filter((m) => m.checked).map((m) => m.id),
			newDateRange,
		);

		setData(cartonProduction);
		setIsLoading(false);
	}

	const subtitles = [
		{
			id: "Machines",
			onClick: () => {
				if (sortByAmount !== "none") {
					setSortByAmount("none");
				}
				if (sortByMachine === "asc") {
					setSortByMachine("desc");
				} else {
					setSortByMachine("asc");
				}
			},
			icon: () => {
				if (sortByMachine === "asc") {
					return <Icon name="arrow up" />;
				}
				if (sortByMachine === "desc") {
					return <Icon name="arrow down" />;
				}
				return "";
			},
		},
		{
			id: "Cartons Made",
			onClick: () => {
				if (sortByMachine !== "none") {
					setSortByMachine("none");
				}
				if (sortByAmount === "desc") {
					setSortByAmount("asc");
				} else {
					setSortByAmount("desc");
				}
			},
			icon: () => {
				if (sortByAmount === "asc") {
					return <Icon name="arrow up" />;
				}
				if (sortByAmount === "desc") {
					return <Icon name="arrow down" />;
				}
				return "";
			},
		},
	];

	function showProducedByZFold() {
		return (
			<Row>
				<DashboardCard
					key="Cartons Produced by z-Fold"
					title="Cartons Produced by zFold"
					subtitles={["zFold Width", "Cartons Made"]}
					setInternalNavigation={setInternalNavigation}
					limit={1000}
					navigationDestination={"producedByZFold"}
					dateRange={dateOptions}
				>
					<ProducedByZFold reportData={data} dateRange={dateOptions} limit={1000} color={defaultColor} />
				</DashboardCard>
			</Row>
		);
	}

	function showProducedByMachine() {
		return (
			<Row>
				<DashboardCard
					key="Cartons Produced by Machine"
					title="Cartons Produced by Machine"
					subtitles={subtitles}
					setInternalNavigation={setInternalNavigation}
					limit={1000}
					navigationDestination={"producedByMachine"}
					dateRange={dateOptions}
				>
					<ProducedByMachines
						reportData={data}
						dateRange={dateOptions}
						limit={1000}
						color={defaultColor}
						sortByAmount={sortByAmount}
						sortByMachine={sortByMachine}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function showOverview() {
		return [
			<Row size="half" key="carton-production-row-1-1">
				<DashboardCard title={<CartonIcon height={40} width={40} />} dateRange={dateOptions}>
					<CartonsMade reportData={data} dateRange={dateOptions} />
				</DashboardCard>
			</Row>,
			<Row size="half" key="carton-production-row-1-2">
				<DashboardCard title={<ClockIcon height={40} width={40} />} dateRange={dateOptions}>
					<AvgProductionTime reportData={data} dateRange={dateOptions} />
				</DashboardCard>
			</Row>,
			<Row key="carton-production-row-2">
				<DashboardCard
					dateRange={dateOptions}
					title={
						<div className={`${classes.cardHeader}`}>
							<FormattedMessage id={"Carton Production Over Time Per Machine"} />
							<span className={classes.toggleBtnGrp}>
								<Popup
									inverted
									trigger={
										<Button
											className={showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
											onClick={() => setShowGraph(true)}
										>
											<ChartViewIcon />
										</Button>
									}
									content="Graph View"
									position="bottom center"
								/>
								<Popup
									inverted
									trigger={
										<Button
											className={!showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
											onClick={() => setShowGraph(false)}
										>
											<TableViewIcon />
										</Button>
									}
									content="Table View"
									position="bottom center"
								/>
							</span>
						</div>
					}
				>
					<CartonsOverTime data={data} dateRange={dateOptions} sites={selectedSites} showGraph={showGraph} />
				</DashboardCard>
			</Row>,
			<Row size="half" bottom key="carton-production-row-3-1">
				<DashboardCard
					key="Cartons Produced by z-Fold"
					title="Cartons Produced by zFold"
					subtitles={["zFold Width", "Cartons Made"]}
					setInternalNavigation={setInternalNavigation}
					limit={maxItemsOnCard}
					navigationDestination={"producedByZFold"}
					color={defaultColor}
					dateRange={dateOptions}
				>
					<ProducedByZFold reportData={data} dateRange={dateOptions} limit={maxItemsOnCard} color={defaultColor} />
				</DashboardCard>
			</Row>,
			<Row size="half" bottom key="carton-production-row-3-2">
				<DashboardCard
					key="Cartons Produced by Machine"
					title="Cartons Produced by Machine"
					subtitles={subtitles}
					setInternalNavigation={setInternalNavigation}
					limit={maxItemsOnCard}
					navigationDestination={"producedByMachine"}
					dateRange={dateOptions}
				>
					<ProducedByMachines
						reportData={data}
						dateRange={dateOptions}
						limit={maxItemsOnCard}
						sortByAmount={sortByAmount}
						sortByMachine={sortByMachine}
					/>
				</DashboardCard>
			</Row>,
		];
	}

	function showDashboard() {
		switch (internalNavigation) {
			case "":
				return showOverview();
			case "producedByZFold":
				return showProducedByZFold();
			case "producedByMachine":
				return showProducedByMachine();
			default:
				return <div>{intl.formatMessage({ id: "This is not the card you are looking for" })}</div>;
		}
	}

	useEffect(() => {
		if (dateOptions.id) getData(selectedSites);
	}, [dateOptions, selectedSites, machines]);

	return (
		<ExperienceContainer
			currentUser={currentUser}
			header="Carton Production Overview"
			backMessage="Back to Carton Production Overview"
			dateOptions={dateOptions}
			setDateOptions={setDateOptions}
			internalNavigation={internalNavigation}
			setInternalNavigation={setInternalNavigation}
			machines={machines}
			setMachines={setMachines}
			isLoading={isLoading}
			noData={(data.byMachine && data.byMachine.length === 0) || machines.every((m) => !m.checked)}
		>
			{showDashboard()}
		</ExperienceContainer>
	);
}

export default errorBoundary(CartonProduction);
