import Flex from "@/components/flex";
import { JobQueueContext } from "../job-queue-provider";
import MachineGroupStatusIcon from "@/views/production/machine-group-control/machine-group-status-icon";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext } from "react";
import Card from "@/components/card";
import { Loader, Progress } from "semantic-ui-react";
import styled from "styled-components";
import { BodyBook, BodyLarge, BodyRegular, SmallBook } from "@/components/typography";
import InProgressJobsActionMenu from "../in-progress-jobs-action-menu";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { readLocalStorage } from "@/api/local-storage";
import { useCanManageJobs } from "@/components/user-context";
import InProductionOrder from "./in-production-order";
import { isScanToTriggerConfigured } from "@/views/configuration/production-groups/manual-scan-to-produce-dropdown";
import { ProductionContext } from "@/contexts/production-context";

const StyledCard = styled(Card)`
	&&& {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 144px;
		flex-shrink: 0;
	}
`;

const GreyBodyLarge = styled(BodyLarge)`
	color: ${({ theme }) => theme.colors.darkGreyText};
`;

function InProductionOrdersCard({ machineGroup }) {
	const intl = useIntl();
	const { selectedBatches, batchesLoading } = useContext(JobQueueContext);
	const { currentProductionGroup } = useContext(ProductionContext);
	const token = readLocalStorage("BEARER");
	const showTriggerValue = isScanToTriggerConfigured(currentProductionGroup.manualScanToProduce);

	const productionEnabled = machineGroup.productionEnabled;
	const userCanRemove = useCanManageJobs();

	const removeJobMutation = useMutation({
		mutationFn: (id) => {
			return axios.delete(`/SelectionPipelineApi/api/v1/selection/packagingSolutions/byBatchId/${id}`, {
				headers: { Authorization: token },
			});
		},
		onSuccess: (response) => {
			if (response.data.deleted) {
				enqueueSnackbar(
					intl.formatMessage({ id: "Successfully Removed Job", defaultMessage: "Successfully Removed Job" }),
					{
						variant: "customSuccess",
					},
				);
			} else {
				enqueueSnackbar(
					intl.formatMessage({ id: "No Jobs Remaining to Remove", defaultMessage: "No Jobs Remaining to Remove" }),
					{
						variant: "customInfo",
					},
				);
			}
		},
		onError: (error) => {
			console.error("Failed to remove job", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Remove Job", defaultMessage: "Failed to Remove Job" }), {
				variant: "customError",
			});
		},
	});

	const getMachineStatusMessage = () => {
		switch (machineGroup.status.toLowerCase()) {
			case "initializing":
				return "Machine group is initializing";
			case "service":
			case "machineservice":
				return "Machine is in service mode";
			case "error":
				return "Machine group is in error mode";
			case "paused":
				return "Machines are paused, ensure all machines are online and ready";
			case "offline":
				return "Machine group is not currently online, verify the machines are online";
			case "online":
				return "Machine group is online but paused, press play to start receiving jobs";
			default:
				return `State ${machineGroup.status.toLowerCase()} not handled`;
		}
	};

	return (
		<StyledCard>
			<Flex alignCenter justifyCenter gap={16} grow>
				{!productionEnabled && (
					<>
						<MachineGroupStatusIcon machineGroupStatus={machineGroup.status} />
						<GreyBodyLarge>
							<FormattedMessage id={getMachineStatusMessage()} />
						</GreyBodyLarge>
					</>
				)}
				{productionEnabled && batchesLoading && (
					<Flex column gap={8} style={{ width: "100%" }}>
						<BodyRegular>
							<FormattedMessage
								id="Producing on {machineGroupName}"
								defaultMessage="Producing on {machineGroupName}"
								values={{ machineGroupName: machineGroup.alias }}
							/>
						</BodyRegular>
						<Loader active inline="centered" data-testid="loader" />
					</Flex>
				)}
				{productionEnabled && !batchesLoading && selectedBatches.length === 0 && (
					<>
						<MachineGroupStatusIcon machineGroupStatus={machineGroup.status} />
						<GreyBodyLarge>
							<FormattedMessage id="Ready to receive jobs" />
						</GreyBodyLarge>
					</>
				)}
				{productionEnabled && !batchesLoading && selectedBatches.length > 0 && (
					<Flex column gap={16} style={{ width: "100%" }}>
						<BodyRegular>
							<FormattedMessage
								id="Producing on {machineGroupName} ({count})"
								defaultMessage="Producing on {machineGroupName} ({count})"
								values={{ machineGroupName: machineGroup.alias, count: selectedBatches.length }}
							/>
						</BodyRegular>
						{selectedBatches.map((batch) => (
							<InProductionOrder
								selectedBatch={batch}
								showTriggerValue={showTriggerValue}
								userCanRemove={userCanRemove}
								removeJobMutation={removeJobMutation}
							/>
						))}
					</Flex>
				)}
			</Flex>
		</StyledCard>
	);
}

export default InProductionOrdersCard;
