import { Button, Checkbox, Icon, List, Menu, Popup } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { isEqual } from "date-fns";
import { FormattedMessage } from "react-intl";
import Flex from "@/components/flex";
import DateRangeCalendar from "../date-range-calendar/date-range-calendar/date-range-calendar";
import { last30DaysDates, last7DaysDates, todayDates, yesterdayDates } from "@/components/date-picker-dropdown/utils";

const Today = "Today";
const Yesterday = "Yesterday";
const Last7Days = "Last7Days";
const Last30Days = "Last30Days";
const Custom = "Custom";

const options = [Today, Yesterday, Last7Days, Last30Days, Custom];

function getStartAndEndDates(selectedTimeOption) {
	switch (selectedTimeOption) {
		case Yesterday: {
			return yesterdayDates();
		}
		case Last7Days: {
			return last7DaysDates();
		}
		case Last30Days: {
			return last30DaysDates();
		}
		case Today:
		default: {
			return todayDates();
		}
	}
}

function getInitialSelectedOption(dateRange) {
	const today = todayDates();
	const yesterday = yesterdayDates();
	const last7Days = last7DaysDates();
	const last30Days = last30DaysDates();

	return dateRange.every((date, i) => isEqual(date, today[i]))
		? Today
		: dateRange.every((date, i) => isEqual(date, yesterday[i]))
		? Yesterday
		: dateRange.every((date, i) => isEqual(date, last7Days[i]))
		? Last7Days
		: dateRange.every((date, i) => isEqual(date, last30Days[i]))
		? Last30Days
		: Custom;
}

function DatePickerDropdown({ dateRange, setDateRange }) {
	const [open, setOpen] = useState(false);
	const [isCustomShown, setIsCustomShown] = useState(false);
	const [selectedOption, setSelectedOption] = useState(getInitialSelectedOption(dateRange));

	useEffect(() => {
		if (selectedOption === Custom) {
			setIsCustomShown(true);
		} else {
			const newDateRange = getStartAndEndDates(selectedOption);
			setDateRange(newDateRange);
			setIsCustomShown(false);
			setOpen(false);
		}
	}, [selectedOption]);

	return (
		<Menu fluid widths={1} style={{ margin: 0, boxShadow: "none" }}>
			<Popup
				flowing
				basic
				on="click"
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				position="bottom left"
				trigger={
					<Menu.Item>
						<Flex alignCenter justifyBetween style={{ width: "100%", padding: "0 15px" }}>
							<Flex gap={8} row>
								<Icon name="calendar outline" />
								<FormattedMessage id={selectedOption} />
								<span>{dateRange[0] ? dateRange[0].toLocaleDateString() : ""}</span>
								<FormattedMessage id="to" />
								<span>{dateRange[1] ? dateRange[1].toLocaleDateString() : ""}</span>
							</Flex>
							<Icon name="dropdown" />
						</Flex>
					</Menu.Item>
				}
			>
				<List relaxed="very" size="medium">
					{options.map((option) => (
						<List.Item key={option}>
							<label>
								<Flex gap={10}>
									<Checkbox radio checked={selectedOption === option} onChange={() => setSelectedOption(option)} />
									<FormattedMessage id={option} />
								</Flex>
							</label>
						</List.Item>
					))}
					{isCustomShown && [
						<Flex key="custom" column gap={5}>
							<DateRangeCalendar dateRange={dateRange} setDateRange={setDateRange} />
							<Flex justifyEnd>
								<Button onClick={() => setOpen(false)} disabled={dateRange.length !== 2} primary>
									<FormattedMessage id="Done" />
								</Button>
							</Flex>
						</Flex>,
					]}
				</List>
			</Popup>
		</Menu>
	);
}

export default DatePickerDropdown;
