import { Button, Modal, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import ConfirmModal from "../../../components/confirm-modal";
import DeleteButton from "@/components/delete-button";
import DiffView from "@/components/diff-view";
import EditButton from "@/components/edit-button";
import FailedRetry from "@/components/failed-retry";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	spacer: {
		width: "32px !important",
	},
}));

const ProductionGroups = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { list, setList, setEdit, loading, setLoading, failed, setFailed } = useContext(ListContext);
	const [configuredMachineGroups, setConfiguredMachineGroups] = useState([]);
	const [configuredZfolds, setConfiguredZfolds] = useState([]);
	const [machineGroups, setMachineGroups] = useState([]);
	const [corrugates, setCorrugates] = useState([]);
	const { get, remove } = useAxios("/ProductionGroupApi/api/v1/ProductionGroups", token);
	const machineGroupsApi = useAxios("/MachineGroupApi/api/v1/MachineGroups", token);
	const corrugatesApi = useAxios("/corrugateApi/api/v1/corrugates", token);
	const configurationAuditApi = useAxios("/PipelineAuditApi/api/v1/ConfigurationAudit", token);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [isDiffModalOpened, setIsDiffModalOpened] = useState(false);
	const [itemToDelete, setItemToDelete] = useState({});
	const [historyData, setHistoryData] = useState({});
	const [historyItem, setHistoryItem] = useState({});

	const selectionPipelineApi = useAxios("/SelectionPipelineApi/api/v1/SelectionPipelineConfigurations", token);

	const getConfiguredMachineGroups = (productionGroups, mgs) => {
		return productionGroups.reduce((acc, val) => {
			if (mgs.length === 0 || val.configuredMachineGroups.length === 0) return acc;

			const names = val.configuredMachineGroups.map((id) => {
				const machine = mgs.filter((m) => m.id === id);
				return machine.length > 0 ? machine[0].alias : "";
			});
			names.sort();
			acc[val.id] = names.join(", ");
			return acc;
		}, {});
	};

	const getConfiguredCorrugates = (productionGroups, zfolds) => {
		return productionGroups.reduce((acc, val) => {
			if (zfolds.length === 0 || val.configuredCorrugates.length === 0) return acc;

			const names = val.configuredCorrugates.map((id) => {
				const zfold = zfolds.filter((m) => m.id === id);
				return zfold.length > 0 ? zfold[0].alias : "";
			});
			names.sort();
			acc[val.id] = names.join(", ");
			return acc;
		}, {});
	};

	useEffect(() => {
		setConfiguredMachineGroups(getConfiguredMachineGroups(list, machineGroups));
	}, [list, machineGroups]);

	useEffect(() => {
		setConfiguredZfolds(getConfiguredCorrugates(list, corrugates));
	}, [list, corrugates]);

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);
		machineGroupsApi.get(
			(data) => setMachineGroups(data),
			(_) => {
				setLoading(false);
				setFailed(true);
			}
		);
		corrugatesApi.get(
			(data) => setCorrugates(data),
			(_) => {
				setLoading(false);
				setFailed(true);
			}
		);
		get(
			(data) => {
				setList(data);
				setLoading(false);
			},
			(_) => {
				setLoading(false);
				setFailed(true);
			}
		);
	};

	const removeItem = async (item) => {
		setItemToDelete(item);
		setIsDeleteModalOpened(true);
	};

	useEffect(load, []);

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<>
					<Modal
						size="fullscreen"
						centered={true}
						closeOnEscape={true}
						closeOnDimmerClick={true}
						className={classes.modal}
						open={isDiffModalOpened}
						onClose={async () => {
							setIsDiffModalOpened(false);
							setHistoryItem({});
						}}
					>
						<Modal.Header className={classes.title}>
							<FormattedMessage id="Production Group History:" /> {historyItem?.alias ?? ""}
						</Modal.Header>
						<Modal.Content className={classes.content}>
							<div className={classes.diff}>
								<DiffView
									data={historyData}
									additionalDataTransform={(data) => {
										delete data.UserName;

										var corrugateNames = data.ConfiguredCorrugates.map((cc) => {
											var corrugate = corrugates.find((c) => c.id === cc);
											if (corrugate) return corrugate.alias;
											return cc;
										});
										data.ConfiguredCorrugates = corrugateNames;

										var machineGroupNames = data.ConfiguredMachineGroups.map((cmg) => {
											var machineGroup = machineGroups.find((mg) => mg.id === cmg);
											if (machineGroup) return machineGroup.alias;
											return cmg;
										});
										data.ConfiguredMachineGroups = machineGroupNames;
										return data;
									}}
								/>
							</div>
						</Modal.Content>
					</Modal>
					<ConfirmModal
						open={isDeleteModalOpened}
						onClose={async (response) => {
							if (response) {
								if (itemToDelete.pipelineId && itemToDelete.pipelineId !== "00000000-0000-0000-0000-000000000000") {
									selectionPipelineApi.remove(itemToDelete.pipelineId, () => remove(itemToDelete.id, setList));
								} else {
									remove(itemToDelete.id, setList);
								}
							}
							setIsDeleteModalOpened(false);
							setItemToDelete({});
						}}
						titleText={intl.formatMessage(
							{
								id: "Delete production group",
							},
							{
								alias: itemToDelete && itemToDelete.alias ? itemToDelete.alias : "",
							}
						)}
						detailText="This change can not be undone"
						confirmText="Delete"
						cancelText="Cancel"
					/>
					<Table basic="very" className={classes.table}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>
									<FormattedMessage id="Name" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Description" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="z-Folds" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Machine Groups" />
								</Table.HeaderCell>
								<Table.HeaderCell className={classes.editButton} textAlign="center" collapsing>
									<FormattedMessage id="History" />
								</Table.HeaderCell>
								<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
								<Table.HeaderCell textAlign="center" collapsing>
									<FormattedMessage id="Edit" />
								</Table.HeaderCell>
								<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
								<Table.HeaderCell textAlign="right" collapsing>
									<FormattedMessage id="Delete" />
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{list.map((item) => (
								<Table.Row key={item.id}>
									<Table.Cell>{item.alias}</Table.Cell>
									<Table.Cell>{item.description}</Table.Cell>
									<Table.Cell>{configuredZfolds[item.id]}</Table.Cell>
									<Table.Cell>{configuredMachineGroups[item.id]}</Table.Cell>
									<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
										<Button
											basic
											icon="history"
											className={classes.actionButtons}
											onClick={() => {
												configurationAuditApi.getWithUrl(`ProductionGroup/${item.id}`, (data) => {
													setIsDiffModalOpened(true);
													setHistoryData(data);
													setHistoryItem(item);
												});
											}}
										/>
									</Table.Cell>
									<Table.Cell className={classes.spacer}></Table.Cell>
									<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
										<EditButton onClick={() => setEdit(item)} />
									</Table.Cell>
									<Table.Cell className={classes.spacer}></Table.Cell>
									<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
										<DeleteButton onClick={() => removeItem(item)} />
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</>
			)}
		</Fragment>
	);
};

export default ProductionGroups;
