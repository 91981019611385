import { Button, Segment } from "semantic-ui-react";
import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import CheckboxButton from "@/components/checkbox-button";
import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	upDownButton: {
		marginTop: "-10px !important",
	},
	checkboxRow: {
		paddingBottom: "0 !important",
		display: "flex",
		flexDirection: "row",
		alignContent: "space-between",
	},
	checkBoxLabel: {
		width: "80%",
	},
	upDownButtons: {
		float: "right",
		width: "20%",
	},
}));

export default function OrderedCheckbox({ step, index, added, removed, moved, required, downButtonDisabled }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });

	function checkChangedHandler(checked, step) {
		if (step.isRequired) {
			return;
		}

		if (checked) {
			added(step);
		} else {
			removed(step);
		}
	}

	return (
		<Segment basic className={classes.checkboxRow}>
			<CheckboxButton
				//floated="left"
				label={step.name}
				checked={step.isConfigured}
				disabled={step.isRequired}
				onChange={(_) => checkChangedHandler(!step.isConfigured, step)}
				className={classes.checkBoxLabel}
			>
				{step.name}
				{step.isRequired && (
					<span className={classes.required}>&nbsp;- ({intl.formatMessage({ id: "Required" })})</span>
				)}
			</CheckboxButton>

			{step.isConfigured && !required && (
				<div className={classes.upDownButtons}>
					<Button
						disabled={downButtonDisabled}
						icon="chevron down"
						floated="right"
						className={classes.upDownButton}
						onClick={() => {
							moved(index, true);
						}}
					/>
					<Button
						disabled={index <= 0}
						icon="chevron up"
						floated="right"
						className={classes.upDownButton}
						onClick={() => {
							moved(index, false);
						}}
					/>
				</div>
			)}
		</Segment>
	);
}
