export const addOpacity = (color, opacity) => {
	if (!color || !opacity) return color;

	if (opacity > 1 || opacity < 0) return color;

	const hex = color.replace(" ", "").replace("!important", "").replace("#", "");
	if (typeof hex !== "string" || hex.length !== 6 || isNaN(Number("0x" + hex))) return color;

	const opacityHex = Math.floor(opacity * 255).toString(16);
	return `#${hex}${opacityHex}`;
};
