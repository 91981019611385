import Card from "@/components/card";
import Flex from "@/components/flex";
import { Progress } from "semantic-ui-react";
import { BodyBook, Header1, SmallRegular } from "@/components/typography";
import { FormattedMessage, useIntl } from "react-intl";
import InProgressJobsActionMenu from "../in-progress-jobs-action-menu";

import styled from "styled-components";
import { getJobContentsString } from "@/views/production/orders/utils/utils";

const StyledCard = styled(Card)`
	&&& {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-shrink: 0;
		padding: 0;
		background-color: ${(props) => props.theme.colors.lightBackground};
	}
`;

const StyledHeader1 = styled(Header1)`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const GreySmallRegular = styled(SmallRegular)`
	color: ${(props) => props.theme.colors.darkGreyText};
`;

function Column({ messageId, value, ...props }) {
	return (
		<Flex column gap={8} {...props}>
			<GreySmallRegular>
				<FormattedMessage id={messageId} />
			</GreySmallRegular>
			<BodyBook>{value}</BodyBook>
		</Flex>
	);
}

function InProductionOrder({ selectedBatch, showTriggerValue, userCanRemove, removeJobMutation }) {
	const intl = useIntl();
	const { cartonCountPerJob, labelCountPerJob, cartonDimension } = selectedBatch;
	const hideContentColumn = cartonCountPerJob === undefined && labelCountPerJob === undefined;

	return (
		<StyledCard>
			<Flex style={{ margin: 16 }} justifyBetween alignCenter>
				<Flex column style={{ width: "30%" }}>
					<StyledHeader1>{selectedBatch.alias}</StyledHeader1>
				</Flex>
				{showTriggerValue && selectedBatch.licensePlateNumber && (
					<Column messageId="Trigger Value" value={selectedBatch.licensePlateNumber} />
				)}
				{!hideContentColumn && (
					<Column
						messageId="Contents"
						value={getJobContentsString({ cartonCountPerJob, labelCountPerJob, cartonDimension, intl })}
					/>
				)}
				<Column messageId="Quantity" value={`${selectedBatch.producedCount}/${selectedBatch.totalCount}`} />
				<Column messageId="Status" value={selectedBatch.status} style={{ minWidth: 140 }} />
				<Flex column>
					<InProgressJobsActionMenu
						batchId={selectedBatch.batchId}
						userCanRemove={userCanRemove}
						removeItem={(batchId) => {
							removeJobMutation.mutate(batchId);
						}}
					/>
				</Flex>
			</Flex>
			<Progress
				value={selectedBatch.producedCount}
				total={selectedBatch.totalCount}
				active
				attached="bottom"
				size="small"
				color={selectedBatch.status === "Paused" || selectedBatch.status === "Pausing" ? "grey" : "green"}
				style={{ margin: 0, height: 8 }}
			/>
		</StyledCard>
	);
}

export default InProductionOrder;
