import React, {useEffect, useState} from "react";
import {Grid, GridRow} from "semantic-ui-react";
import LoginSplashFrame from "./login-splash-frame";
import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_LightBG.svg";
import {useIntl} from "react-intl";
import {createUseStyles, useTheme} from "react-jss";
import {useSearchParams} from "react-router-dom";
import {readLocalStorage, removeLocalStorage, writeLocalStorage} from "@/api/local-storage";

const useStyles = createUseStyles((theme) => ({
    loginGridContainer: {
        flex: "1",
        width: "40%",
        minWidth: "360px",
        maxWidth: "600px",
    },
    loginBackground: {
        height: "100vh",
        flex: "1",
        padding: "0 10rem",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    fullWidth: {
        width: "100%",
    },
    gridRow: {
        paddingBottom: "2rem",
    },
    resetPasswordButton: {
        textTransform: "none !important",
        width: "100%",
    },
    trackingId: {
        color: "#7C7B79",
        paddingBottom: "2rem",
        fontFamily: "Stolzl-Book",
        fontSize: "14px"
    },
    header: {
        fontSize: "16px",
        fontStyle: "normal",
        paddingBottom: "8px",
    },
    body: {
        fontSize: "16px",
        paddingBottom: "16px",
        fontFamily: "Stolzl-Book",
    }
}));

const SsoError = () => {
    const theme = useTheme();
    const classes = useStyles({theme});
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const [ssoError, setSsoError] = useState();

    useEffect(() => {
        //Always prefer parameter
        let ssoErrorParam = decodeURI(searchParams.get("error"));
        if (ssoErrorParam && ssoErrorParam !== "null") {
            let fiveMinutes = 5 * 60 * 1000;
            try {
                let decodedSsoErrorParam = atob(ssoErrorParam);
                let jsonParsedErrorParam = JSON.parse(decodedSsoErrorParam);
                writeLocalStorage("ssoError", JSON.stringify({
                    ssoErrorParam: jsonParsedErrorParam,
                    expiresAfter: new Date(new Date().getTime() + fiveMinutes)
                }));

                setSsoError(jsonParsedErrorParam);
            }
            catch {
                console.info("Invalid error provided")
            }
        } else {
            let localStorageError = readLocalStorage("ssoError");
            if (localStorageError) {
                let localStorageErrorParsed = JSON.parse(localStorageError);
                if (new Date(localStorageErrorParsed.expiresAfter).getTime() < new Date().getTime()) {
                    removeLocalStorage("ssoError");
                }
                else {
                    setSsoError(localStorageErrorParsed.ssoErrorParam);
                }
            }
        }
    }, []);

    return (
        <LoginSplashFrame>
            <div className={classes.loginBackground}>
                <Grid className={classes.loginGridContainer}>
                    <div className={classes.fullWidth}>
                        <GridRow className={classes.gridRow}>
                            <div>
                                <img data-cy="packnet" src={CloudPlatformLogo} style={{height: "40px"}} alt="PackNet"/>
                            </div>
                        </GridRow>

                        <GridRow>
                            <div className={classes.header}>
                                <span>{intl.formatMessage({id: "SSO Login Failed"})}</span>
                            </div>
                            <div className={classes.body}>
                                {intl.formatMessage({id: "Please contact your administrator for more information"})}
                            </div>
                            <div className={classes.trackingId}>
                                Reference Id: {ssoError?.TrackingId ?? ""}
                            </div>
                        </GridRow>
                        <GridRow className={classes.fullWidth}>
                            <a href="/PackNet/login">{intl.formatMessage({id: "ReturnToLogin"})}</a>
                        </GridRow>
                    </div>
                </Grid>
            </div>
        </LoginSplashFrame>
    );
};
export default SsoError;
