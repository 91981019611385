/**
 * objectFromQuerystring :: string -> object
 *
 * take an incoming query string and turn it into
 * a javascript object
 *
 * example:
 *   objectFromQuerystring('?term=123456'); // { term: 123456 }
 */
const objectFromQuerystring = (queryString) =>
	(queryString.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((acc, v) => {
		acc[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1);
		return acc;
	}, {});

export { objectFromQuerystring as default };
