import { Header, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import FailedRetry from "@/components/failed-retry";
import FileUploadModal from "@/components/file-upload-modal";
import Loading from "@/components/loading";
import { importProductsBackground } from "@/api/dim-product-api";
import { useIsRoleEnabled } from "@/components/user-context";
import GlobalButton from "@/components/global-button";
import ImportHistory from "./import-history";
import { sleep } from "@/utils/sleep";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
		height: `calc(100vh - ${theme.measurements.menuBarHeight})`,
	},
	header: {
		border: "0 !important",
		margin: "16px 32px !important",
		padding: "0 !important",
		height: theme.measurements.pageHeaderHeight,
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		boxShadow: "0 0 !important",
		display: "flex",
		justifyContent: "space-between",
		wrap: "no-wrap",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontFamily: "Stolzl-Medium !important",
		fontSize: "32px !important",
		marginTop: "26px !important",
	},
	iconButton: {
		marginTop: "0 !important",
	},
	headerTextPadding: {
		paddingLeft: "16px !important",
		paddingTop: "8px !important",
	},
	defaultButton: {
		backgroundColor: `${theme.colors.light} !important`,
		color: "#0068ab !important",
		opacity: "1 !important",
		cursor: "pointer",
	},
	buttonPadding: {
		marginTop: "26px !important",
	},
}));

function ImportPage() {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const [isImportOpened, setIsImportOpened] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const hasManageProductsAccess = useIsRoleEnabled("manageDimensioningProducts");

	function importClickedHandler() {
		setIsImportOpened(true);
	}

	async function importConfirmedHandler(rawData, { name, type, size }) {
		setIsLoading(true);
		if (type !== "application/json") {
			throw new Error("Import data must be in JSON format");
		}

		try {
			const data = JSON.parse(rawData);
			if (data.length > 100000) {
				throw new Error("Product upload exceeded max of 100,000.");
			} else {
				const response = await importProductsBackground(name, data);
				if (response.isSuccessful) {
					await sleep(1000);
					setIsImportOpened(false);
				} else {
					throw new Error("Failed to import data, try again.");
				}
			}
		} catch (err) {
			throw new Error(`Error parsing JSON data for file "${name}" - ${err.message}`);
		}
		setIsLoading(false);
	}

	function importCancelledHandler() {
		setIsImportOpened(false);
	}

	return (
		<div className={classes.container}>
			<FileUploadModal
				open={isImportOpened}
				onConfirm={importConfirmedHandler}
				onCancel={importCancelledHandler}
				titleText={intl.formatMessage({
					id: "ImportProducts",
				})}
			/>
			<Segment className={classes.header}>
				<Header className={classes.headerText}>
					<FormattedMessage id="Import" />
				</Header>
				{hasManageProductsAccess && (
					<div className={classes.buttonPadding}>
						<GlobalButton
							type="primary"
							text="DimNewImport"
							disabled={dimCtx.isLoading}
							onClick={importClickedHandler}
						/>
					</div>
				)}
			</Segment>

			{(dimCtx.isLoading || isLoading) && <Loading />}
			{dimCtx.hasLoadFailed() && <FailedRetry retry={dimCtx.reload} />}

			{dimCtx.hasLoadSucceeded() && !dimCtx.isLoading && !isLoading && (
				<ImportHistory config={dimCtx.customerConfiguration} />
			)}
		</div>
	);
}

export default ImportPage;
