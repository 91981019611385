import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	fullRow: {
		flexBasis: "99%",
	},
	halfRow: {
		display: "flex",
		flexDirection: "column",
		gap: "18px 18px",
		flexBasis: "49% ",
	},
}));

function Row({ bottom, size, children }) {
	const classes = useStyles();
	const style = {
		paddingBottom: bottom ? "32px" : "16px",
	};

	function getClass(size) {
		switch (size) {
			case "half":
				return classes.halfRow;
			default:
				return classes.fullRow;
		}
	}

	return (
		<div className={getClass(size)} style={style}>
			{children}
		</div>
	);
}

export default Row;
