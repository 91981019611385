import { CartScan, SearchResults as CartSearchResults } from "@/views/cart/scan";
import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import { createUseStyles, useTheme } from "react-jss";

import { Outlet } from "react-router-dom";
import { Product } from "@/views/cart/product";
import React from "react";
import { Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

export const getAuthorizedCartTabs = (currentUser, intl) => {
	const tabs = [
		{
			defaultMenuItem: true,
			parentLink: "cart",
			link: "scan",
			text: intl.formatMessage({ id: "CartScan" }),
			component: <CartScan />,
			authorization: true, //TODO
			licensed: true, //TODO
		},
		{
			link: "search-results",
			text: intl.formatMessage({ id: "Search Results" }),
			component: <CartSearchResults />,
			authorization: true, //TODO
			licensed: true, //TODO
		},
		{
			link: "products/:productId",
			text: intl.formatMessage({ id: "Products" }),
			component: <Product />,
			authorization: "AccessRawDataReport",
			licensed: true,
		},
	];

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const CartRoutes = ({ currentUser }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedCartTabs(currentUser, useIntl());

	return (
		<>
			<Segment className={classes.submenu}>
				<ScrollMenu currentUser={currentUser} list={tabs.map((tab) => ({ ...tab }))} />
			</Segment>
			<Outlet />
		</>
	);
};

export default CartRoutes;
