import { Button, Icon, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { getExpirationFromLocalStorage, logout, refreshUser, requestExpireTime } from "@/components/user-context";
import { readLocalStorage, writeLocalStorage } from "@/api/local-storage";

import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { useQueryClient } from "@tanstack/react-query";

const useStyles = createUseStyles((theme) => ({
	title: {
		fontSize: "32pt !important",
		padding: "28px 28px !important",
		borderBottom: "none !important",
	},
	titleText: {
		paddingLeft: "12px",
	},
	content: {
		display: "flex !important",
		flexDirection: "column !important",
		gap: "40px",
		padding: "0px 28px 28px 28px !important",
	},
	saveButton: {
		marginRight: "24px !important",
	},
	detail: {
		fontSize: "16pt !important",
		alignItems: "left",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row-reverse",

		"& button": {
			color: theme.colors.primary,
			backgroundColor: "white !important",
		},
	},
	checkboxContainer: {
		flex: 1,
		"& i": {
			marginRight: "4px !important",
		},
	},
}));

const SESSION_REFRESH_COUNTDOWN_SECONDS = 5 * 60;

function addSeconds(numOfSeconds, date = new Date()) {
	const dateCopy = new Date(date.getTime());

	dateCopy.setSeconds(dateCopy.getSeconds() + numOfSeconds);

	return dateCopy;
}

export default function LogoutWarningModal() {
	const classes = useStyles();
	const [time, setTime] = useState({
		minutes: 0,
		seconds: 0,
	});
	const [open, setOpen] = useState(false);
	const [expiresInSeconds, setExpiresInSeconds] = useState();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (expiresInSeconds) {
			var expiresAt = addSeconds(expiresInSeconds);
			writeLocalStorage("LOCALTOKENEXPIRATION", expiresAt.getTime());
		}
	}, [expiresInSeconds]);

	useEffect(() => {
		const intervalInMinutes = 0.1;
		const expireTimePollingIntervalInMs = intervalInMinutes * 60 * 1000;
		const apiTimer = setInterval(async () => {
			var bearer = readLocalStorage("BEARER");
			if (!bearer) {
				setOpen(false);
				return;
			}
			var expireSeconds = await requestExpireTime(bearer);
			setExpiresInSeconds(expireSeconds);

			if (!expireSeconds) {
				//api could have failed, check localstorage
				expireSeconds = getExpirationFromLocalStorage();
			}

			if (expireSeconds <= 0) {
				setOpen(false);
				if (bearer) {
					queryClient.removeQueries();
					logout(`expireSeconds was empty or expired and token is stored ${expireSeconds}`);
				}
			} else if (expireSeconds > SESSION_REFRESH_COUNTDOWN_SECONDS) {
				setOpen(false);
			} else {
				setOpen(true);
			}
		}, expireTimePollingIntervalInMs);
		return () => {
			clearInterval(apiTimer);
		};
	}, []);

	useEffect(() => {
		let openTimer = null;
		if (open) {
			if (openTimer) clearInterval(openTimer);

			let expiresInAdd = 0;
			openTimer = setInterval(() => {
				var runningSeconds = expiresInSeconds - expiresInAdd;
				var expiresMinutes = Math.floor(runningSeconds / 60);
				var remainingSeconds = runningSeconds % 60;
				if (!expiresInSeconds || runningSeconds <= 0) {
					setOpen(false);
					queryClient.removeQueries();
					logout(`expiresInSeconds was empty or refresh timer expired ${expiresInSeconds} ${runningSeconds}`);
				}
				setTime({
					minutes: expiresMinutes,
					seconds: Math.floor(remainingSeconds % 60),
				});
				expiresInAdd++;
			}, 1000);
		}

		return () => {
			if (openTimer) clearInterval(openTimer);
		};
	}, [open, expiresInSeconds]); // eslint-disable-line react-hooks/exhaustive-deps

	const countdown = !time.minutes && !time.seconds ? "" : `${time.minutes}:${time.seconds.toString().padStart(2, "0")}`;

	async function continueSession() {
		setOpen(false);
		await refreshUser();
	}

	return (
		<Modal
			size="small"
			centered={true}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			className={classes.modal}
			open={open}
		>
			<Modal.Header className={classes.title}>
				<Icon name="warning sign" color="yellow" />
				<span data-cy="session-expiring-soon" className={classes.titleText}>
					<FormattedMessage id="Session expiring soon" />
				</span>
			</Modal.Header>
			<Modal.Content className={classes.content}>
				<div className={classes.detail}>
					<FormattedMessage id="Your session will expire in" values={{ countdown }} />
				</div>
				<div className={classes.buttons}>
					<Button onClick={() => continueSession()}>
						<FormattedMessage id="ContinueSession" />
					</Button>
				</div>
			</Modal.Content>
		</Modal>
	);
}
