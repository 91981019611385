import { Button, Card, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import EmChangeCorrugate from "./em-change-corrugate";
import FusionChangeCorrugate from "./fusion-change-corrugate";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import { ProductionContext } from "@/contexts/production-context";
import X4ChangeCorrugate from "./x4-change-corrugate-components/x4-change-corrugate";
import X5ChangeCorrugate from "./x5-change-corrugate";
import X6ChangeCorrugate from "./x6-change-corrugate";
import X7ChangeCorrugate from "./x7-change-corrugate";
import { machineTypes } from "../../../constants";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import useMachines from "@/views/configuration/machines/use-machines";
import emptyId from "@/constants/emptyId";

const useStyles = createUseStyles((theme) => ({
	...theme.mainPage,
	tracks: {
		margin: "40px !important",
	},
	machineHeading: {
		marginTop: "40px !important",
	},
	view: {
		backgroundColor: theme.colors.lightBackground,
		maxHeight: "calc(100vh - 128px)",
		height: "calc(100vh - 128px)",
		overflow: "hidden",
		border: "0 !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "500 !important",
		fontSize: "32px !important",
		margin: "0 !important",
		lineHeight: "normal !important",
		letterSpacing: "-.5px",
	},
}));

const ChangeCorrugate = () => {
	const intl = useIntl();
	const notLoadedCorrugate = {
		id: emptyId,
		alias: intl.formatMessage({ id: "z-Fold Not Loaded" }),
		width: 0,
		thickness: 0,
		quality: 0,
	};

	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const Machines = useMachines(token);
	const CorrugateApi = useAxios("/CorrugateApi/api/v1.0/corrugates", token);
	const [retry, setRetry] = useState(false);
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(false);
	const [corrugateList, setCorrugateList] = useState([notLoadedCorrugate]);
	const [machinesList, setMachinesList] = useState([]);
	const [selectedMachine, setSelectedMachine] = useState();
	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);

	useEffect(() => {
		if (!currentMachineGroup) {
			return;
		}

		setLoading(true);
		setFailed(false);

		const machinesIdsInMachineGroup = Object.keys(currentMachineGroup.configuredMachinesWithMachineType ?? {});
		if (machinesIdsInMachineGroup.length < 1) {
			setLoading(false);
			return;
		}

		Machines.get(
			(allMachines) => {
				const machines = allMachines
					.filter(
						(m) =>
							(m.machineType && m.machineType.toLowerCase() === machineTypes.Em.toLowerCase()) ||
							m.machineType.toLowerCase() === machineTypes.Fusion.toLowerCase() ||
							m.machineType.toLowerCase() === machineTypes.X4.toLowerCase() ||
							m.machineType.toLowerCase() === machineTypes.X5.toLowerCase() ||
							m.machineType.toLowerCase() === machineTypes.X6.toLowerCase() ||
							m.machineType.toLowerCase() === machineTypes.X7.toLowerCase(),
					)
					.filter((i) => machinesIdsInMachineGroup.includes(i.id));

				setMachinesList(machines);
				if (machines.length === 1) {
					setSelectedMachine(machines[0]);
				} else if (machines.length > 1 && selectedMachine) {
					const temp = machines.find((x) => x.id === selectedMachine.id);
					setSelectedMachine(temp);
				}

				CorrugateApi.get(
					(corrugates) => {
						const newCorrugates =
							currentProductionGroup?.configuredCorrugates?.length > 0
								? corrugates.filter((c) => currentProductionGroup.configuredCorrugates.includes(c.id))
								: [];

						const fullList = [notLoadedCorrugate, ...newCorrugates];
						setCorrugateList(fullList);
						setLoading(false);
					},
					(err) => {
						setCorrugateList([notLoadedCorrugate]);
						setMachinesList([]);
						setFailed(true);
						setLoading(false);
						console.error(err);
					},
				);
			},
			(error) => {
				setMachinesList([]);
				setCorrugateList([notLoadedCorrugate]);
				setFailed(true);
				setLoading(false);
				console.error(error);
			},
		);
	}, [currentMachineGroup, currentProductionGroup, retry]);

	if (loading) return <Loading />;

	if (failed) return <FailedRetry retry={() => setRetry(!retry)} />;

	if (!currentProductionGroup?.configuredCorrugates) {
		return (
			<div className={classes.view}>
				<FormattedMessage id="Production group is not assigned or has no configured z-Fold" />
			</div>
		);
	}

	if (currentProductionGroup?.configuredCorrugates.length === 0) {
		return (
			<div className={classes.view}>
				<FormattedMessage id="Production group has no configured z-Fold" />
			</div>
		);
	}

	if (machinesList.length < 1)
		return (
			<div className={classes.view}>
				<FormattedMessage id="No Machines in machine group that can change corrugates" />
			</div>
		);

	if (machinesList.length > 0 && !selectedMachine) {
		return (
			<div>
				<div className={`${classes.header} ${classes.leftPadding}`}>
					<FormattedMessage id="Select a Machine" />
				</div>
				<Segment basic className={classes.cards}>
					<Card.Group centered className={classes.cards}>
						{machinesList.map((m) => (
							<Card key={m.id} onClick={() => setSelectedMachine(m)}>
								<Card.Content>
									<Card.Header>{m.alias}</Card.Header>
									<Card.Meta>
										<FormattedMessage id={m.machineType} />
									</Card.Meta>
									<Card.Description>
										<FormattedMessage id={m.status || m.currentStatus} />
									</Card.Description>
								</Card.Content>
								<Card.Content extra>
									<Button primary fluid onClick={() => setSelectedMachine(m)}>
										<FormattedMessage id="Select Machine" />
									</Button>
								</Card.Content>
							</Card>
						))}
					</Card.Group>
				</Segment>
			</div>
		);
	}

	const machineType = selectedMachine.machineType;
	return (
		<div className={`${classes.view} ${classes.leftPadding} ${classes.rightPadding}`}>
			<Fragment>
				<div className={classes.header}>
					<FormattedMessage id="Change z-Fold" />
				</div>
				{(() => {
					switch (machineType.toLowerCase()) {
						case machineTypes.Em.toLowerCase():
							return (
								<EmChangeCorrugate
									selectedMachine={selectedMachine}
									setSelectedMachine={setSelectedMachine}
									corrugates={corrugateList}
								/>
							);
						case machineTypes.Fusion.toLowerCase():
							return <FusionChangeCorrugate selectedMachine={selectedMachine} corrugates={corrugateList} />;
						case machineTypes.X5.toLowerCase():
							return <X5ChangeCorrugate selectedMachine={selectedMachine} corrugates={corrugateList} />;
						case machineTypes.X6.toLowerCase():
							return <X6ChangeCorrugate machineId={selectedMachine.id} availableZFold={corrugateList} />;
						case machineTypes.X4.toLowerCase():
							return <X4ChangeCorrugate selectedMachine={selectedMachine} corrugates={corrugateList} />;
						case machineTypes.X7.toLowerCase():
							return <X7ChangeCorrugate selectedMachine={selectedMachine} corrugates={corrugateList} />;
						default: {
							console.error(`${machineType} not handled in change corrugate`);
							return <div>{machineType} not handled in change corrugate</div>;
						}
					}
				})()}
			</Fragment>
		</div>
	);
};

export default ChangeCorrugate;
