import { Button, Form, Grid, Header, Message, Modal, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import AttributeOptions from "./attribute-options";
import CloseButton from "@/components/close-button";
import ConfirmModal from "@/components/confirm-modal";
import DimDataContext from "../contexts/dimensioning-data-context";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import { isNullOrWhitespace } from "@/utils/form-validation";

const typeOptions = [
	{
		key: "String",
		value: "String",
		text: "Open ended",
	},
	{
		key: "Select",
		value: "Select",
		text: "Multiple Choice",
	},
];

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	modal: {
		padding: "0px !important",
	},
	form: {
		...theme.configurationDialog.form,
	},
	formInput: {
		height: "108px",
	},
	formRightCol: {
		height: "60vh",
		overflowY: "auto",
	},
}));

function CustomAttributeModal({ open: isVisible, onClose, attribute, setAttribute }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const [errors, setErrors] = useState({});
	const [options, setOptions] = useState(["", ""]);
	const [cancelModalOpened, setCancelModalOpened] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setErrors({});
			setAttribute({ type: "String" });
		}
	}, [isVisible]);

	const validate = () => {
		const newErrors = {};
		if (isNullOrWhitespace(attribute.name)) {
			newErrors.name = intl.formatMessage({ id: "Name is required" });
		}
		if (isNullOrWhitespace(attribute.type)) {
			newErrors.type = intl.formatMessage({
				id: "Question format is required",
			});
		}
		if (attribute.type === "Select") {
			if (options.findIndex((op) => isNullOrWhitespace(op)) >= 0) {
				newErrors.prompt = intl.formatMessage({
					id: "NoBlankOptions",
				});
			} else if (options.length !== new Set(options).size) {
				newErrors.prompt = intl.formatMessage({
					id: "NoDuplicateOptions",
				});
			}
		}
		setErrors(newErrors);
		return !Object.keys(newErrors).length;
	};

	const modalCancelled = () => {
		setCancelModalOpened(true);
	};

	const saveAndClose = async () => {
		if (!validate()) {
			return;
		}

		const newAttribute = {
			type: attribute.type,
			name: attribute.name,
			prompt: attribute.prompt,
			maxLength: attribute.maxLength,
		};
		if (attribute.type === "Select") {
			newAttribute.options = options;
		}

		try {
			await dimCtx.customAttributeCreated(newAttribute);
			onClose(true);
		} catch (ex) {
			if (ex.isAxiosError) {
				setErrors({ ...errors, name: ex.response.data.errors.join("\n") });
			}
		}
	};

	function attributeNameChanged(value) {
		setAttribute({ ...attribute, name: value });
	}

	function attributeTypeChanged(value) {
		setAttribute({ ...attribute, type: value });
		validate();
	}

	function attributePromptChanged(value) {
		setAttribute({ ...attribute, prompt: value });
	}

	function attributeMaxLengthChanged(value) {
		setAttribute({ ...attribute, maxLength: value });
	}

	function updateOption(text, index) {
		const updatedOptions = options.map((name, i) => {
			return i === index ? text : name;
		});
		setOptions(updatedOptions);
	}

	function addOption() {
		setOptions([...options, ""]);
	}

	function removeOption(index) {
		const updatedOptions = options.filter((name, i) => {
			return i !== index;
		});
		setOptions(updatedOptions);
	}

	return (
		<Modal
			size={"large"}
			centered={false}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			open={isVisible}
			onClose={modalCancelled}
		>
			<Modal.Content className={classes.modal}>
				<ConfirmModal
					open={cancelModalOpened}
					onClose={(affirmative) => {
						setCancelModalOpened(false);
						if (affirmative) {
							onClose(false);
						}
					}}
					titleText={intl.formatMessage({
						id: "Leave without saving?",
					})}
					detailText="All unsaved work will be lost"
					confirmText="Leave"
					cancelText="Cancel"
				/>
				<Segment.Group className={classes.group}>
					<Segment className={classes.header}>
						<Header as="h2" floated="left" className={classes.headerText}>
							<FormattedMessage id="CreateCustomAttribute" />
						</Header>
						<Header floated="right" className={classes.closeButton}>
							<CloseButton onClick={modalCancelled} />
						</Header>
						<Header floated="right" className={classes.saveButton}>
							<Button primary onClick={saveAndClose}>
								<FormattedMessage id="Save" />
							</Button>
						</Header>
					</Segment>
					<Segment className={classes.form}>
						<Form error>
							<Grid relaxed="very" stackable divided>
								<Grid.Row>
									<Grid.Column width={6}>
										<Form.Input
											required
											label={intl.formatMessage({ id: "Attribute Name" })}
											placeholder={intl.formatMessage({ id: "Name" })}
											value={attribute.name}
											onChange={(e) => attributeNameChanged(e.target.value)}
											error={errors.name ? { content: errors.name } : null}
											icon
											className={classes.formInput}
										>
											<input autoFocus />
											{errors.name && (
												<i class="icon">
													<Error className={classes.inputError} />
												</i>
											)}
										</Form.Input>
										<Form.Select
											required
											label={intl.formatMessage({ id: "Question Format" })}
											placeholder={intl.formatMessage({
												id: "Select question format",
											})}
											value={attribute.type ?? ""}
											options={typeOptions}
											onChange={(e, { value }) => attributeTypeChanged(value)}
											error={errors.type ? { content: errors.type } : null}
											className={classes.formInput}
										/>
									</Grid.Column>
									<Grid.Column width={8} className={classes.formRightCol}>
										{attribute.type === "String" && (
											<Fragment>
												<Form.Input
													label={intl.formatMessage({ id: "Question Text" })}
													placeholder={intl.formatMessage({
														id: "Enter Question Text",
													})}
													value={attribute.prompt}
													onChange={(e, { value }) => attributePromptChanged(value)}
													error={errors.name ? { content: errors.prompt } : null}
													icon
												></Form.Input>
												<Form.Input
													label={intl.formatMessage({ id: "Character Limit" })}
													value={attribute.maxLength}
													onChange={(e, { value }) => attributeMaxLengthChanged(value)}
													error={errors.maxLength ? { content: errors.maxLength } : null}
													icon
												></Form.Input>
											</Fragment>
										)}
										{attribute.type === "Select" && (
											<Fragment>
												<Form.Input
													label={intl.formatMessage({
														id: "Multiple Choice Label",
													})}
													placeholder={intl.formatMessage({
														id: "Enter Label",
													})}
													value={attribute.prompt}
													onChange={(e, { value }) => attributePromptChanged(value)}
													error={errors.prompt ? { content: errors.prompt } : null}
													icon
													className={classes.formInput}
												>
													<input />
													{errors.prompt && (
														<i class="icon">
															<Error className={classes.inputError} />
														</i>
													)}
												</Form.Input>
												<AttributeOptions
													options={options}
													updateOption={updateOption}
													addOption={addOption}
													removeOption={removeOption}
												/>
											</Fragment>
										)}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
						</Form>
					</Segment>
				</Segment.Group>
			</Modal.Content>
		</Modal>
	);
}

export default CustomAttributeModal;
