import { Header, Icon, Segment, Table } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";

import { FormattedMessage } from "react-intl";
import IconButton from "@/components/icon-button";
import OptimalProductionBlocked from "./optimal-production-blocked";
import OptimalProductionFailed from "./optimal-production-failed";
import OptimalProductionNonOptimal from "./optimal-production-nonoptimal";
import SubSectionButton from "@/components/sub-section-button";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { UserContext } from "@/components/user-context";
import { useQuery } from "@tanstack/react-query";
import Loading from "@/components/loading";
import FailedRetry from "@/components/failed-retry";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: `${theme.colors.lightBackground} !important`,
		maxHeight: "calc(100vh - 64px)",
		height: "calc(100vh - 64px)",
	},

	backGroup: {
		margin: "16px 32px !important",
		border: "none !important",
		boxShadow: "none !important",
		backgroundColor: `${theme.colors.lightBackground} !important`,
	},

	backMessage: {
		marginLeft: "16px !important",
		fontSize: "14px",
		fontFamily: "Stolzl-book",
		color: "#353430 !important",
	},

	mainGroup: {
		backgroundColor: "#FFF",
		margin: "0px 32px 32px 32px !important",
		maxHeight: "calc(100vh - 155px)",
		height: "calc(100vh - 155px)",
	},

	header: {
		margin: "32px !important",
		padding: "0 !important",
		lineHeight: "24px !important",
	},

	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		margin: "32px !important",
		fontSize: "16px !important",
		fontFamily: "Stolzl-medium !important",
	},

	buttonsGroup: {
		margin: "24px 32px !important",
		border: "none !important",
	},

	unknownSection: {
		margin: "32px 16px !important",
	},
}));

const OptimalProduction = () => {
	const sections = {
		blocked: "blocked",
		nonOptimal: "non-optimal",
		failed: "failed",
	};

	const theme = useTheme();
	const classes = useStyles({ theme });
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(useLocation().search);
	const selectedTab = searchParams.get("tab");
	const [activeItem, setActiveItem] = useState(selectedTab ?? sections.blocked);
	const onSelect = function (itemName) {
		setActiveItem(itemName);
	};
	const token = readLocalStorage("BEARER");
	const optimalProductionApi = useAxios("/PipelineAuditApi/api/v1/CurrentState/limitedProducibility", token);
	const queryKey = ["Audit-LimitedProducibility", { tenant: currentUser.Tenant }];

	const queryFn = async () => {
		const response = await optimalProductionApi.get();
		return response;
	};

	const { data, isLoading, refetch, error } = useQuery({
		queryKey,
		queryFn,
		refetchInterval: 15000,
	});

	if (isLoading) {
		return <Loading />;
	}

	if (error) {
		return <FailedRetry retry={refetch} />;
	}

	async function backClickedHandler() {
		navigate("/dashboard");
	}

	const getActiveTab = () => {
		switch (activeItem) {
			case sections.blocked:
				return <OptimalProductionBlocked details={data.details.blocked} count={data.counts.blocked} />;
			case sections.failed:
				return <OptimalProductionFailed details={data.details.failed} count={data.counts.failed} />;
			case sections.nonOptimal:
				return <OptimalProductionNonOptimal details={data.details.nonOptimal} count={data.counts.nonOptimal} />;
			default:
				return (
					<Segment className={classes.unknownSection}>
						<FormattedMessage id="Unknown Tab" />
					</Segment>
				);
		}
	};

	return (
		<div className={classes.container}>
			<Segment.Group horizontal className={classes.backGroup}>
				<span>
					<IconButton onClick={backClickedHandler}>
						<Icon name="angle left" color="blue" size="large" />
					</IconButton>
					<span className={classes.backMessage}>
						<FormattedMessage id="Back to Dashboard" />
					</span>
				</span>
			</Segment.Group>
			<Segment.Group vertical className={classes.mainGroup}>
				<Header className={classes.headerText}>
					<FormattedMessage id="Work to Produce" />
				</Header>
				<Segment.Group horizontal className={classes.buttonsGroup}>
					<SubSectionButton
						selectedItem={activeItem}
						itemName={sections.blocked}
						onSelect={onSelect}
						messageId="Blocked"
					/>
					<SubSectionButton
						selectedItem={activeItem}
						itemName={sections.nonOptimal}
						onSelect={onSelect}
						messageId="Non-optimal"
					/>
					<SubSectionButton
						selectedItem={activeItem}
						itemName={sections.failed}
						onSelect={onSelect}
						messageId="Failed"
					/>
				</Segment.Group>
				{getActiveTab()}
			</Segment.Group>
		</div>
	);
};

export const totalItems = (count, intl) => {
	const suffix = intl.formatMessage({ id: "Items Effected" });
	return `${count} ${suffix}`;
};

export const countSection = (count, intl) => {
	var suffix = count === 1 ? intl.formatMessage({ id: "carton" }) : intl.formatMessage({ id: "cartons" });
	return `${count} ${suffix}`;
};

export const resolutionSection = (resolutions, intl, getActionDescription, classes) => {
	return (
		<Table className={classes.actionItem}>
			<Table.Body>
				{resolutions.map((resolution, i) => {
					let joinWord = intl.formatMessage({ id: "or" });
					joinWord = ` ${joinWord} `;
					const assos = resolution.action.entitiesToBeAssociated.join(joinWord);
					return (
						<Table.Row key={i}>
							<Table.Cell className={classes.actionItem}>
								{getActionDescription(resolution.type, resolution.action.entityToBeModified, assos, intl)}
							</Table.Cell>
						</Table.Row>
					);
				})}
			</Table.Body>
		</Table>
	);
};

export default OptimalProduction;
