import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import ProgressBarList from "@/views/reporting/progress-bar-list";
import SwimLanes from "@/views/reporting/swim-lanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import { getPaginationResult, getSwimLaneMaxiumum, zFoldNameLine } from "@/views/reporting/functions/swimlanes";
import { getFilteredCorrugate } from "@/views/reporting/functions/corrugate";
import { sortByValue } from "@/views/reporting/functions/sort";

function TotalUsageByZFold({ reportData, dateRange, color, limit, isMetric, showBales }) {
	const [presentationData, setPresentationData] = useState([]);
	const intl = useIntl();
	const [upperBound, setUpperBound] = useState(0);
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});
	const unitLabel = showBales ? "|" : isMetric ? "sq meters" : "sq ft";

	function mapData(data) {
		let aggregation = sortByValue(data.byZFold, "totalConsumedCorrugate", "desc", "corrugateAlias", "asc");

		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.totalConsumedCorrugate)
		);

		const upper = getSwimLaneMaxiumum(maximumConsumed);
		setUpperBound(upper);
		aggregation = aggregation.map((item) => {
			return {
				...item,
				percentage: (item.totalConsumedCorrugate / upper) * 100,
			};
		});

		setPresentationData(
			aggregation.reduce((dict, el, index) => {
				!dict[el.tenantId] ? (dict[el.tenantId] = [el]) : dict[el.tenantId].push(el);
				return dict;
			}, [])
		);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let items = [];
	for (const property in presentationData) {
		const siteInformation = presentationData[property];
		const site = {
			heading: "",
		};
		const corrugated = getFilteredCorrugate(siteInformation).map((c) => {
			return {
				percent: c.percentage,
				text: zFoldNameLine(
					limit,
					c.corrugateAlias,
					c.corrugateWidth,
					c.corrugateBrand,
					c.corrugateFlute,
					intl.formatMessage({ id: "Brand" }),
					intl.formatMessage({ id: "Flute" })
				),
				value: `${c.totalConsumedCorrugate.toLocaleString(undefined, {
					maximumFractionDigits: 0,
				})} ${intl.formatMessage({ id: unitLabel })}  ${
					showBales
						? c.baleCount !== null && c.baleCount !== undefined
							? c.baleCount !== 0
								? c.baleCount.toFixed(1).toLocaleString()
								: 0
							: "-"
						: ""
				}`,
			};
		});
		site.items = corrugated;
		items.push(site);
	}

	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<SwimLanes upperBound={upperBound} numberOfHeadings={1} key="total-usage-by-z-fold-swim-lanes">
				{limit === 1000 &&
					items.map((item, i) => {
						return (
							<ProgressBarList
								color={color}
								items={item.items}
								limit={1000}
								key={`total-usage-by-z-fold-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 && (
					<ProgressBarList color={color} items={paginationResult.items} limit={paginationResult.items.length} />
				)}
			</SwimLanes>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="total-usage-by-z-fold-swim-lane-pagination"
			/>,
		]
	) : (
		<div></div>
	);
}

export default TotalUsageByZFold;
