import { List, Icon, Menu, Popup, Input } from "semantic-ui-react";
import { useEffect, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { getMachinesInTimeRange } from "./services/API/Reports/machine-selection-api";
import { isFeatureFlagEnabled } from "@/utils/auth";
import { incrementDateRange } from "@/views/reporting/functions/index";
import { sortByValue } from "@/views/reporting/functions/sort";
import { ReactComponent as FilterImage } from "@/views/reporting/img/filter-black.svg";
import CheckboxButton from "@/views/reporting/checkbox-button";
import { getPlannedProductionDataForSite } from "@/views/reporting/services/API/Reports/planned-production-api";

const useStyles = createUseStyles((theme) => ({
	dropdownHeading: {
		display: "flex",
		alignItems: "center",
	},
	image: {
		marginLeft: "15px !important",
		marginRight: "6px",
	},
	dropdownIcon: {
		marginRight: "15px !important",
	},
	checkboxListHeader: {
		display: "flex",
		flexDirection: "column",
		flexFlow: "column wrap",
	},
	checkboxListBody: {
		display: "flex",
		flexDirection: "column",
		gap: "1%",
		overflow: "auto",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "white",
		},
	},
	checkbox: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
	dropdownSpacing: {
		paddingBottom: "18px",
	},
}));

function MachineSelectorDropdown({ dateOptions, machines, setMachines, currentUser, dropdownWidth }) {
	const width = dropdownWidth + "px";
	const classes = useStyles();
	const intl = useIntl();
	const sites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [dropdownList, setDropdownList] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		localStorage.setItem("MACHINES", JSON.stringify(machines));
	}, [machines]);

	async function getMachines(dateOptions) {
		let plannedProduction = [];
		const featureFlagPlannedProduction = isFeatureFlagEnabled(currentUser, "pack-net-reporting-wip");
		const newDateRange = incrementDateRange(dateOptions);
		if (featureFlagPlannedProduction) {
			plannedProduction = await getPlannedProductionDataForSite(sites, newDateRange);
		}

		let resultMachines = await getMachinesInTimeRange(plannedProduction, sites, newDateRange);
		const storage = JSON.parse(localStorage.getItem("MACHINES"));
		let sortedResultMachines = storage;

		const storageIds = Object.values(storage).map((item) => item.id);
		const resultIds = Object.values(resultMachines).map((item) => item.id);

		if (
			storage !== null &&
			storage !== "undefined" &&
			storage !== "[]" &&
			storageIds.every((id) => resultIds.includes(id)) &&
			resultIds.every((id) => storageIds.includes(id))
		) {
			sortedResultMachines = JSON.parse(storage);
		} else {
			sortedResultMachines = sortByValue(resultMachines, "alias", "asc").map((machine) => ({
				...machine,
				checked: true,
				sites: sites,
			}));
		}

		setMachines(sortedResultMachines);
		setDropdownList(sortedResultMachines);
	}

	useEffect(() => {
		if (dateOptions.id) getMachines(dateOptions);
	}, [dateOptions]);

	const handleMachineSearchChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);

		if (query.length > 0) {
			const filteredMachines = machines.filter((m) => m.alias.toLowerCase().includes(query.toLowerCase()));
			setDropdownList(filteredMachines);
		} else {
			setDropdownList(machines);
		}
	};

	const allChecked = machines.every((item) => item.checked === true);

	async function toggle(input) {
		const updatedMachineList = machines.map((item) =>
			item.id === input.id ? { ...item, checked: !item.checked } : item,
		);
		const updatedFilteredMachineList = dropdownList.map((item) =>
			item.id === input.id ? { ...item, checked: !item.checked } : item,
		);

		setMachines(updatedMachineList);
		setDropdownList(updatedFilteredMachineList);
	}

	async function toggleAll() {
		const updatedMachineList = machines.map((item) => ({
			...item,
			checked: !allChecked,
		}));
		const updatedFilteredMachineList = dropdownList.map((item) => ({
			...item,
			checked: !allChecked,
		}));

		setMachines(updatedMachineList);
		setDropdownList(updatedFilteredMachineList);
	}

	const getDropdownMessage = () => {
		const checkedCount = machines.reduce((acc, item) => acc + (item.checked ? 1 : 0), 0);

		if (checkedCount === 0) {
			return intl.formatMessage({ id: "Select Machines" });
		} else if (checkedCount === machines.length) {
			return intl.formatMessage({ id: "All Machines Selected" });
		} else if (checkedCount === 1) {
			return intl.formatMessage({ id: `Machine Selected` }, { number: checkedCount });
		} else {
			return intl.formatMessage({ id: `Machines Selected` }, { number: checkedCount });
		}
	};

	const handleClose = () => {
		setSearchQuery("");
		setDropdownList(dropdownList);
	};

	const handleOpen = () => {
		setDropdownList(machines);
	};

	const menuPaddingStyle = {
		paddingRight: "15px !important",
		paddingLeft: "15px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	};

	return (
		<>
			{machines && machines.length > 1 && (
				<Menu fluid widths={1}>
					<Popup
						style={{ width: width }}
						flowing
						basic
						on="click"
						onClose={handleClose}
						onOpen={handleOpen}
						position="bottom right"
						trigger={
							<Menu.Item style={menuPaddingStyle}>
								<div className={classes.dropdownHeading}>
									<FilterImage className={classes.image} />
									{getDropdownMessage()}
								</div>
								<Icon name="dropdown" className={classes.dropdownIcon} />
							</Menu.Item>
						}
					>
						<Popup.Content className={classes.checkboxListHeader}>
							{machines.length >= 5 && (
								<div className={classes.dropdownSpacing}>
									<Input
										fluid
										iconPosition="left"
										placeholder={intl.formatMessage({ id: "Search..." })}
										value={searchQuery}
										onChange={handleMachineSearchChange}
									>
										<input />
										<Icon name="search" />
									</Input>
								</div>
							)}
							<List style={{ maxHeight: "250px", overflowY: "auto" }}>
								<List.Item>
									<CheckboxButton key={`cb-all`} color={"black"} checked={allChecked} onChange={() => toggleAll()}>
										{allChecked ? <FormattedMessage id="Deselect All" /> : <FormattedMessage id="Select All" />}
									</CheckboxButton>
								</List.Item>
								{dropdownList.map((item) => (
									<List.Item>
										<CheckboxButton
											key={`cb-${item.alias}`}
											color={"black"}
											checked={item.checked}
											className="checkbox"
											onChange={() => toggle(item)}
										>
											{item.alias}
										</CheckboxButton>
									</List.Item>
								))}
							</List>
						</Popup.Content>
					</Popup>
				</Menu>
			)}
		</>
	);
}

export default MachineSelectorDropdown;
