import { Container, Grid } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";

import React from "react";

const useStyles = createUseStyles({
	main: {
		padding: "40px",
	},
});

const FeatureFlags = (props) => {
	const { currentUser } = props;
	const theme = useTheme();
	const classes = useStyles({ theme });
	return (
		<Container className={classes.main}>
			<Grid padded columns={2} celled relaxed>
				<Grid.Row>
					<Grid.Column>Current User:</Grid.Column>
					<Grid.Column>{currentUser.sub}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Token Expiration (seconds):</Grid.Column>
					<Grid.Column>{currentUser.exp - currentUser.iat}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Token Expiration (hours):</Grid.Column>
					<Grid.Column>{(currentUser.exp - currentUser.iat) / 60 / 60}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Roles:</Grid.Column>
					<Grid.Column>
						<Grid padded>
							{currentUser.role.map((r) => (
								<Grid.Row key={r}>{r}</Grid.Row>
							))}
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Company:</Grid.Column>
					<Grid.Column>{currentUser.Company}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Site Id:</Grid.Column>
					<Grid.Column>{currentUser.SiteId}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Customer Id:</Grid.Column>
					<Grid.Column>{currentUser.CustomerId}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Tenant Id:</Grid.Column>
					<Grid.Column>{currentUser.Tenant}</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>Enabled Feature Flags:</Grid.Column>
					<Grid.Column>
						<Grid padded>
							{Object.keys(currentUser)
								.filter((key) => key.indexOf("ff-") > -1)
								.map((flag) => currentUser[flag] === "True" && <Grid.Row key={flag}>{flag}</Grid.Row>)}
						</Grid>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	);
};

export default FeatureFlags;
