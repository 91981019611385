import { useIntl } from "react-intl";
import React, { Fragment, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Divider, Message } from "semantic-ui-react";
import { findProduct, flattenCustomAttributes, putProduct, unflattenCustomAttributes } from "@/api/dim-product-api";
import { getIdentifiers } from "@/api/dim-config-api";

import AlphaNumeric from "@/views/dimensioning/capture-forms/alpha-numeric";
import Bool from "@/views/dimensioning/capture-forms/bool";
import Date from "@/views/dimensioning/capture-forms/date";
import Dimensions from "@/views/dimensioning/capture-forms/dimensions";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import Nesting from "@/views/dimensioning/capture-forms/nesting";
import Orientation from "@/views/dimensioning/capture-forms/orientation";
import PacksizeSelect from "@/views/dimensioning/capture-forms/select";
import DimensioningVoidFill from "@/views/dimensioning/capture-forms/void-fill";
import Weight from "@/views/dimensioning/capture-forms/weight";
import { sleep } from "@/utils/sleep";

const useStyles = createUseStyles((theme) => ({
	grid: {
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "space-between",
	},
	rightColumn: {
		margin: "0 33px",
		backgroundColor: "white",
		padding: "1.5em !important",
		paddingRight: "2.3em !important",
		height: "calc(100vh - 256px)",
		overflow: "auto",
		overflowY: "scroll !important",
		width: "100%",
		borderRadius: "8px",
	},
	header: {
		padding: "1em !important",
		paddingTop: "3em !important",
	},
	subHeader: {
		paddingTop: "1em !important",
	},
	formEntry: {
		borderTop: "2px !important",
		marginTop: "0px !important",
		paddingTop: "4px !important",
	},
	footer: {
		position: "fixed",
		bottom: "0",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignItems: "center",
		alignSelf: "auto",
		paddingLeft: "1.5rem",
		paddingBottom: "1.5rem",
	},
	breakLine: {
		marginLeft: "-30px !important",
		marginRight: "-30px !important",
	},
}));

function isNumber(value) {
	const num = parseFloat(value);
	return !isNaN(num);
}

function ProductEdit({ config, productId, triggerSave, setSaveDisabled, setSaveComplete }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const defaultProduct = {
		attributes: {},
		customAttributes: {},
		identifiers: {},
		dimensions: { length: 0, width: 0, height: 0 },
		quantity: 1,
		voidFill: { length: 0, width: 0, height: 0 },
	};
	const [product, setProduct] = useState(defaultProduct);
	const [cleanProduct, setCleanProduct] = useState(defaultProduct);
	const [identifiers, setIdentifiers] = useState([]);
	const [errors, setErrors] = useState({});
	const [mainIdType, setMainIdType] = useState("");
	const [messageBox, setMessageBox] = useState({
		show: false,
		message: "",
		isError: true,
		loading: false,
	});

	function validate(product) {
		let errors = [];
		config.attributeCaptureSteps.forEach((step) => {
			switch (step.type) {
				case "String":
					if (step.isRequired) {
						const id = identifiers.find((id) => id.key === step.name);
						if (id && (!product.identifiers || !product.identifiers[step.name])) {
							errors = { ...errors, [step.name]: true };
						} else if (step.name === "Name" && !product.name) {
							errors = { ...errors, [step.name]: true };
						} else if (step.name === "Description" && !product.description) {
							errors = { ...errors, [step.name]: true };
						}
					}
					if (step.maxLength && product[step.name]?.length > step.maxLength) {
						errors = { ...errors, [step.name]: true };
					}
					break;
				case "Dimensions":
					if (!isNumber(product?.dimensions?.length)) {
						errors = { ...errors, length: true };
					}
					if (!isNumber(product?.dimensions?.width)) {
						errors = { ...errors, width: true };
					}
					if (!isNumber(product?.dimensions?.height)) {
						errors = { ...errors, height: true };
					}
					break;
				case "VoidFill":
					if (product.canContain) {
						if (product?.voidFill?.length <= 0 || product?.voidFill?.length >= product?.dimensions.length) {
							errors = { ...errors, voidFillLength: true };
						}
						if (product.voidFill?.width <= 0 || product.voidFill?.width >= product.dimensions.width) {
							errors = { ...errors, voidFillWidth: true };
						}
						if (product.voidFill?.height <= 0 || product.voidFill?.height >= product.dimensions.height) {
							errors = { ...errors, voidFillHeight: true };
						}
						break;
					}
					break;

				default:
					break;
			}
		});
		return errors;
	}

	async function saveProduct(product) {
		const newErrors = validate(product);
		if (Object.keys(newErrors).length) {
			setErrors(newErrors);

			setMessageBox({
				show: true,
				message: "Validation Errors",
				isError: true,
			});

			await sleep(5 * 1000);

			setMessageBox({ show: false, loading: false });
			return;
		}
		setMessageBox({ show: true, message: "Saving Product...", isError: false, loading: true });
		setSaveDisabled(true);
		await sleep(1000);
		try {
			await putProduct(unflattenCustomAttributes(product, config), product.identifiers[mainIdType]);
			setMessageBox({ show: true, message: "Product Saved", isError: false });
			await sleep(1000);
			setMessageBox({ show: false, loading: false });
			setSaveComplete(true);
		} catch (err) {
			if (err?.response?.status === 409) {
				setErrors({ [err.response.data]: true });
				setMessageBox({
					show: true,
					message: `Duplicate ${err.response.data}`,
					isError: true,
				});
			} else {
				setMessageBox({
					show: true,
					message: "Unexpected Error",
					isError: true,
				});
				console.error(err);
			}
		}

		await sleep(5 * 1000);
		setMessageBox({ show: false, loading: false });
	}

	async function load() {
		setMessageBox({ show: false, loading: false });
		setFailed(false);
		setLoading(true);
		const ids = await getIdentifiers();
		setIdentifiers(ids);

		try {
			setMainIdType(config.mainIdentifierType);

			let newProduct;
			if (productId) {
				const result = await findProduct(productId);
				newProduct = flattenCustomAttributes(result.product, config);

				const finalProduct = {
					...newProduct,
					identifiers: { [config.mainIdentifierType]: productId },
				};
				setProduct(finalProduct);
				setCleanProduct(finalProduct);
			}
		} catch (err) {
			console.error(err);
			setFailed(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		if (triggerSave > 0) {
			saveProduct(product);
		}
	}, [triggerSave]);

	useEffect(() => {
		if (!loading) {
			setSaveDisabled(JSON.stringify(product) === JSON.stringify(cleanProduct));
		}
	}, [product]);

	function getFormComponent(step) {
		var set;

		switch (step.type) {
			case "String":
				set = (text) => {
					const id = identifiers.find((id) => id.key === step.name);

					if (id) {
						product.identifiers[step.name] = text;
					} else if (step.name === "Name") {
						product.name = text;
					} else if (step.name === "Description") {
						product.description = text;
					} else if (step.name === "Product Image") {
						product.productImage = text;
					} else {
						product[step.name] = text;
					}

					setProduct({ ...product });
				};
				let value;
				const id = identifiers.find((id) => id.key === step.name);
				if (id) {
					if (product.identifiers) value = product.identifiers[step.name];
				} else if (step.name === "Name") {
					value = product.name;
				} else if (step.name === "Description") {
					value = product.description;
				} else if (step.name === "Product Image") {
					value = product.productImage;
				} else {
					value = product[step.name];
				}
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt ?? step.name}
						defaultPrompt={intl.formatMessage({ id: "Enter Text" })}
						callback={set}
						error={errors[step.name]}
						value={value}
						disabled={step.name === mainIdType}
						maxLength={step.maxLength}
						isEdit
					/>
				);
			case "Date":
				return <Date prompt={step.prompt} isEdit />;
			case "Dimensions":
				set = (state) => {
					setProduct({
						...product,
						dimensions: {
							length: state.length,
							width: state.width,
							height: state.height,
						},
					});
				};
				return (
					<Dimensions
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Product Dimensions" })}
						callback={set}
						errors={errors}
						value={product.dimensions}
						isEdit
					/>
				);
			case "Nesting":
				return <Nesting product={product} setProduct={setProduct} isEdit />;
			case "Number":
				set = (text) => {
					if (step.name === "Quantity") {
						product.quantity = text;
					} else {
						product[step.name] = text;
					}
					setProduct({ ...product });
				};
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt ?? step.name}
						defaultPrompt={intl.formatMessage({ id: "Number" })}
						callback={set}
						value={step.name === "Quantity" ? product.quantity : product[step.name]}
						isEdit
					/>
				);
			case "Orientation":
				return <Orientation product={product} setProduct={setProduct} isEdit />;
			case "Select":
				set = (text) => {
					let newProduct = { ...product, [step.name]: text };
					setProduct(newProduct);
				};
				return <PacksizeSelect step={step} callback={set} value={product[step.name]} isEdit />;
			case "Bool":
				return <Bool prompt={step.prompt} isEdit />;
			case "VoidFill":
				return <DimensioningVoidFill product={product} setProduct={setProduct} errors={errors} isEdit />;
			case "Weight":
				return (
					<Weight
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Weight" })}
						product={product}
						setProduct={setProduct}
						isEdit
					/>
				);
			default:
				return <span>{step.name}</span>;
		}
	}

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed || !config?.attributeCaptureSteps ? (
				<FailedRetry retry={load} />
			) : (
				<Fragment>
					<div className={classes.grid}>
						<div className={classes.rightColumn}>
							{messageBox.show && (
								<Message
									error={messageBox.isError && !messageBox.loading}
									success={!messageBox.isError && !messageBox.loading}
									content={messageBox.message}
								/>
							)}
							{config.attributeCaptureSteps.map((step, index) => (
								<div className={classes.formEntry} key={index}>
									{getFormComponent(step)}
									{index < config.attributeCaptureSteps.length - 1 && <Divider section className={classes.breakLine} />}
								</div>
							))}
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
}

export default ProductEdit;
