import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import { Segment } from "semantic-ui-react";

import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import CartonProduction from "@/views/reporting/carton-production";
import ErrorsAndAvailability from "@/views/reporting/errors-availability";
import RawData from "@/views/reporting/raw-data";
import Trim from "@/views/reporting/trim";
import ZFoldUsage from "@/views/reporting/z-fold-usage";
import OverallEquipmentEffectiveness from "@/views/reporting/oee";
import { isFeatureFlagEnabled } from "@/utils/auth";

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

export const getAuthorizedReportingTabs = (currentUser, intl) => {
	// TODO: To finalize OEE tab authorization code need to delete feature flag
	/*
		1. Remove the `show` attribute
		2. Remove filter applied to the `tabs` variable
		3. Remove import for isFeatureFlagEnabled
	*/
	const tabs = [
		{
			defaultMenuItem: true,
			parentLink: "reporting",
			link: "carton-production",
			text: intl.formatMessage({ id: "Carton Production" }),
			component: <CartonProduction currentUser={currentUser} />,
			authorization: "AccessCartonProductionReport",
			licensed: true,
			show: true,
		},
		{
			link: "z-Fold",
			text: intl.formatMessage({ id: "zFold Usage" }),
			component: <ZFoldUsage currentUser={currentUser} />,
			authorization: "AccessZFoldUsageReport",
			licensed: true,
			show: true,
		},
		{
			link: "trim",
			text: intl.formatMessage({ id: "Trim" }),
			component: <Trim currentUser={currentUser} />,
			authorization: "AccessTrimReport",
			licensed: true,
			show: true,
		},
		{
			link: "errors",
			text: intl.formatMessage({ id: "Errors and Availability" }),
			component: <ErrorsAndAvailability currentUser={currentUser} />,
			authorization: "AccessMachineErrorsReport",
			licensed: true,
			show: true,
		},
		{
			link: "oee",
			text: intl.formatMessage({ id: "OEE" }),
			component: <OverallEquipmentEffectiveness currentUser={currentUser} />,
			authorization: "AccessOverallEquipmentEffectiveness",
			licensed: true,
			show: isFeatureFlagEnabled(currentUser, "reporting-allow-oee"),
		},
		{
			link: "raw-data",
			text: intl.formatMessage({ id: "Raw Data" }),
			component: <RawData currentUser={currentUser} />,
			authorization: "AccessRawDataReport",
			licensed: true,
			show: true,
		},
	].filter((tab) => tab.show !== false);

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const ReportingRoutes = ({ currentUser }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedReportingTabs(currentUser, useIntl());

	return (
		<>
			<Segment className={classes.submenu}>
				<ScrollMenu currentUser={currentUser} list={tabs.map((tab) => ({ ...tab }))} />
			</Segment>
			<Outlet />
		</>
	);
};

export default ReportingRoutes;
