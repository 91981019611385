export const PrintHelpers = {
	SubmitJob: async (psApi, mgApi, solutionToSend, selectedMachine, setCallBack, errorCallBack) => {
		let requestCartons = [];
		solutionToSend.cartons?.forEach((carton) => {
			// Allow 0 but not null or undefined
			// null in a >= is converted to 0 for the comparison but not equity checks
			// undefined is converted to NaN for all checks
			// let cartonQuantity = carton?.media?.attributes?.quantity;
			let quantity = 1; //cartonQuantity !== null && cartonQuantity >= 0 ? carton.media.attributes.quantity : 1;
			for (let index = 0; index < quantity; index++) {
				for (let index = 0; index < carton.labels?.length; index++) {
					carton.labels[index].attributes.quantity = 1;
				}
				requestCartons.push(carton);
			}
		});

		let requestLabels = [];
		solutionToSend.labels?.forEach((label) => {
			// Allow 0 but not null or undefined
			// null in a >= is converted to 0 for the comparison but not equity checks
			// undefined is converted to NaN for all checks
			// let labelQuantity = label?.attributes?.quantity;
			let quantity = 1; //labelQuantity !== null && labelQuantity >= 0 ? label.attributes.quantity : 1;
			for (let index = 0; index < quantity; index++) {
				requestLabels.push(label);
			}
		});
		await mgApi
			.get(
				() => {},
				() => {}
			)
			.then(async (data) => {
				let machineGroup = data.filter((mg) => {
					let machines = Object.keys(mg.configuredMachinesWithMachineType);
					return machines[0] === selectedMachine.id;
				});
				const location = {
					packStation: null,
					machine: null,
					machineGroup: machineGroup[0].alias,
					productionGroup: null,
					pickZone: null,
				};
				var solutionWithLocation = {
					...solutionToSend,
					cartons: requestCartons,
					labels: requestLabels,
					location: location,
					attributes: {},
				};
				solutionWithLocation.attributes.source = "PackageCreation";
				solutionWithLocation.attributes.quantity = "1";
				await psApi
					.addWithUrl(
						"sendtoqueue",
						{ solution: solutionWithLocation, quantity: "1" },
						() => {},
						() => {}
					)
					.then((data) => {
						setCallBack();
					})
					.catch((err) => {
						errorCallBack(err);
					});
			})
			.catch((err) => {
				errorCallBack(err);
			});
	},
	GetPrintImages: async (piApi, length, width, height, setCallBack, errorCallBack) => {
		await piApi
			.get(
				() => {},
				() => {}
			)
			.then((data) => {
				let filtered = data.filter(
					(image) =>
						image.dimensions.length === length && image.dimensions.width === width && image.dimensions.height === height
				);
				setCallBack(filtered);
			})
			.catch((error) => {
				errorCallBack(error);
			});
	},
	GetPrintConfig: async (
		piApi,
		setSelectColorProfileOptions,
		setSelectColorModeOptions,
		setSelectColorantOptions,
		setCallBack,
		errorCallBack
	) => {
		await piApi
			.get(
				() => {},
				() => {}
			)
			.then((json) => {
				let data = JSON.parse(json);
				if (data !== "No Print Config Found") {
					let ColorProfiles = [];
					data.ColorProfiles.forEach((profile, i) => {
						ColorProfiles.push({
							key: i,
							text: profile,
							value: profile.toLowerCase(),
						});
					});
					let ColorModes = [];
					data.ColorModes.forEach((mode, i) => {
						ColorModes.push({
							key: i,
							text: mode,
							value: mode.toLowerCase(),
						});
					});
					let Colorants = [];
					data.Colorants.forEach((colorant, i) => {
						Colorants.push({
							key: i,
							text: colorant,
							value: colorant.toLowerCase(),
						});
					});
					setSelectColorProfileOptions(ColorProfiles);
					setSelectColorModeOptions(ColorModes);
					setSelectColorantOptions(Colorants);
				}
				setCallBack();
			})
			.catch((error) => {
				errorCallBack(error);
			});
	},
	GetTechnicalJobs: async (tjApi, setTechnicalJobsOptions, setCallBack, errorCallBack) => {
		await tjApi
			.get(
				() => {},
				() => {}
			)
			.then((data) => {
				let technicaljobs = [];
				data.forEach((tj, i) => {
					technicaljobs.push({
						key: i,
						text: tj.title + ` (${tj.id})`,
						value: tj.title,
					});
				});
				setTechnicalJobsOptions(technicaljobs);
				setCallBack();
			})
			.catch((error) => {
				errorCallBack(error);
			});
	},
};

export default PrintHelpers;
