import { useCallback, useEffect, useRef, useState } from "react";
import { getImportPipelineExportData, scanImport } from "@/api/import-api";
import { useIsRoleEnabled } from "@/components/user-context";
import { enqueueSnackbar } from "notistack";
import { useIntl } from "react-intl";

const useScanInput = ({ currentMachineGroup, historyExpanded }) => {
	const intl = useIntl();
	const inputRef = useRef();
	const [isLoading, setIsLoading] = useState();
	const [isScanToCreateConfigured, setIsScanToCreateConfigured] = useState(false);

	const hasProduceCustomJobsAccess = useIsRoleEnabled("SendPackagingSolutionToQueue");
	const hasManageImportPipelines = useIsRoleEnabled("manageImportPipelines");
	const hasScanPerms = hasManageImportPipelines || hasProduceCustomJobsAccess;

	const handleKeyUp = useCallback(
		async (event) => {
			if (event.key === "Enter") {
				setIsLoading(true);
				try {
					await scanImport(currentMachineGroup.id, `"${event.target.value}"`);
					enqueueSnackbar(intl.formatMessage({ id: "Scan Succeeded" }), { variant: "customSuccess" });
				} catch (e) {
					console.error("Error invoking scan import: ", e);
					enqueueSnackbar(intl.formatMessage({ id: "Scan Failed" }), { variant: "customError" });
				} finally {
					setIsLoading(false);
					inputRef.current.value = "";
					setTimeout(() => inputRef.current.focus(), 200);
				}
			}
		},
		[currentMachineGroup?.id],
	);

	useEffect(() => {
		const load = async () => {
			if (!hasScanPerms) {
				setIsScanToCreateConfigured(false);
				return;
			}
			try {
				const response = await getImportPipelineExportData();
				const isScanToCreateConfigured = !!response.fileDrop.scanToCreateConfiguration?.headers?.length > 0;
				setIsScanToCreateConfigured(isScanToCreateConfigured);
				return;
			} catch (e) {
				console.error("Error fetching import configuration: ", e);
			}
			setIsScanToCreateConfigured(false);
		};
		load();
	}, [hasScanPerms]);

	const shouldRender =
		isScanToCreateConfigured &&
		!historyExpanded &&
		currentMachineGroup?.status.toLowerCase() === "online" &&
		currentMachineGroup?.productionEnabled;

	return { shouldRender, onKeyUp: handleKeyUp, inputRef, isLoading };
};

export default useScanInput;
