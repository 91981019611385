import React, { useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import OrderedCheckbox from "./ordered-checkbox";

const useStyles = createUseStyles((theme) => ({
	attributes: {
		overflow: "auto",
		maxHeight: "calc(100vh - 420px)",
	},
}));

function sortSteps(configuredSteps, defaultStepList, mainIdentifierType, setConfiguredSteps) {
	let nonConfiguredSteps = defaultStepList
		.filter((step) => configuredSteps.find((c) => c.name === step.name) === undefined)
		.sort((left, right) => {
			const leftName = left.name.toLowerCase();
			const rightName = right.name.toLowerCase();
			if (leftName === rightName) {
				return 0;
			}
			if (leftName < rightName) {
				return -1;
			}
			return 1;
		});

	let mainNonConfigured = nonConfiguredSteps.find((step) => step.name === mainIdentifierType);

	if (mainNonConfigured) {
		mainNonConfigured = { ...mainNonConfigured, isRequired: true };
		nonConfiguredSteps = nonConfiguredSteps.filter((step) => step.name !== mainNonConfigured.name);

		configuredSteps = [mainNonConfigured, ...configuredSteps];
		setConfiguredSteps(configuredSteps);
	}

	configuredSteps = configuredSteps.map((step) => {
		return { ...step, isConfigured: true };
	});
	nonConfiguredSteps.map((step) => {
		return { ...step, isConfigured: false };
	});

	// BUG: unrequired identifier
	return [...configuredSteps, ...nonConfiguredSteps];
}

function AttributeCaptureFlow() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);

	return (
		<div className={classes.attributes}>
			{sortSteps(
				dimCtx.customerConfiguration.attributeCaptureSteps,
				dimCtx.attributeTypes,
				dimCtx.customerConfiguration.mainIdentifierType,
				dimCtx.attributeCaptureStepsChanged
			).map((step, index) => (
				<OrderedCheckbox
					key={step.name}
					step={step}
					index={index}
					added={dimCtx.attributeCaptureStepAdded}
					removed={dimCtx.attributeCaptureStepRemoved}
					moved={dimCtx.attributeCaptureStepMoved}
				/>
			))}
		</div>
	);
}

export default AttributeCaptureFlow;
