import axios from "axios";

export default function useAxios(url, token) {
	const version = async (setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(`${url}/version`, {
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response.data;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const get = async (setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(url, {
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response.data;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const getWithUrl = async (urlArgs, setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(`${url}/${urlArgs}`, {
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const getJsonWithUrl = async (urlArgs, setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(`${url}/${urlArgs}`, {
				responseType: "json",
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const getBlob = async (setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(url, {
				responseType: "blob",
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response.data;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const getPdfArray = async (urlArgs, setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(`${url}/${urlArgs}`, {
				responseType: "arraybuffer",
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const getBlobWithUrl = async (urlArgs, setCallBack, errorCallBack) => {
		try {
			const response = await axios.get(`${url}/${urlArgs}`, {
				responseType: "blob",
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
			return response;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const getArrayBufferWithUrl = async (urlArgs, data, setCallBack, errorCallBack) => {
		try {
			const response = await axios.post(`${url}/${urlArgs}`, data, {
				headers: { Authorization: token },
				responseType: "arraybuffer",
			});
			if (setCallBack) setCallBack(response.data);
			return response;
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const add = async (data, setCallBack, errorCallBack) => {
		try {
			await axios.post(url, data, { headers: { Authorization: token } });
			if (setCallBack) get(setCallBack, errorCallBack);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const addWithUrl = async (urlArgs, data, setCallBack, errorCallBack) => {
		try {
			const response = await axios.post(`${url}/${urlArgs}`, data, {
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const update = async (data, setCallBack, errorCallBack) => {
		try {
			await axios.put(url, data, { headers: { Authorization: token } });
			if (setCallBack) get(setCallBack, errorCallBack);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const updateImage = async (data, setCallBack, errorCallBack) => {
		try {
			const response = await axios.post(url, data, {
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const updateWithUrl = async (urlArgs, data, setCallBack, errorCallBack) => {
		try {
			const response = await axios.put(`${url}/${urlArgs}`, data, {
				headers: { Authorization: token },
			});
			if (setCallBack) setCallBack(response.data);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const updateWithId = async (data, setCallBack, errorCallBack) => {
		try {
			await axios.put(`${url}/${data.id}`, data, {
				headers: { Authorization: token },
			});
			if (setCallBack) get(setCallBack, errorCallBack);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const remove = async (id, setCallBack, errorCallBack) => {
		try {
			await axios.delete(`${url}/${id}`, { headers: { Authorization: token } });
			if (setCallBack) get(setCallBack);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const removeWithUrl = async (urlArgs, setCallBack, errorCallBack) => {
		try {
			await axios.delete(`${url}/${urlArgs}`, {
				headers: { Authorization: token },
			});
			if (setCallBack) get(setCallBack);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	const removeWithData = async (urlArgs, data, setCallBack, errorCallBack) => {
		try {
			await axios.delete(`${url}/${urlArgs}`, {
				// Currently Axios doesn't set the content-type header when including a body w/Delete
				headers: { Authorization: token, "Content-Type": "application/json" },
				data: data,
			});
			if (setCallBack) get(setCallBack);
		} catch (err) {
			if (errorCallBack) errorCallBack(err);
			else throw err;
		}
	};

	return {
		get,
		getWithUrl,
		getJsonWithUrl,
		getBlob,
		getPdfArray,
		getBlobWithUrl,
		getArrayBufferWithUrl,
		add,
		addWithUrl,
		update,
		updateImage,
		updateWithUrl,
		updateWithId,
		remove,
		removeWithData,
		removeWithUrl,
		version,
	};
}
