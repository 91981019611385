import "./index.css";
import "semantic-ui-less/semantic.less";
import App from "./app";
import {BrowserRouter} from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import {enableMapSet} from "immer";

const loadFont = (fontFamily) => {
    const font = new FontFaceObserver(fontFamily);

    font
        .load()
        .then(() => console.info(`Font ${fontFamily} has loaded`))
        .catch((e) => console.error(`Font ${fontFamily} has failed to load`, e));
};

const loadFonts = () => {
    loadFont("Stolzl");
    loadFont("Stolzl-Bold");
    loadFont("Stolzl-Book");
    loadFont("Stolzl-Light");
    loadFont("Stolzl-Medium");
    loadFont("Stolzl-Thin");
    loadFont("icons");
    loadFont("brand-icons");
    loadFont("outline-icons");
};

loadFonts();
enableMapSet();

createRoot(document.getElementById("root")).render(
    <StrictMode>
        <BrowserRouter basename="PackNet">
            <App/>
        </BrowserRouter>
    </StrictMode>
);
