import { Icon } from "semantic-ui-react";
import Flex from "@/components/flex";
import { useLilius } from "use-lilius";
import { useIntl } from "react-intl";
import { addWeeks, compareAsc, differenceInDays, endOfDay, getDay, isFuture, format, isEqual } from "date-fns";
import styled from "styled-components";
import { useEffect } from "react";

const CenteredBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const BaseCalendarBox = styled(CenteredBox)`
	width: 100%;
	height: 48px;
`;

const CalendarDay = styled(CenteredBox)`
	height: 32px;
	width: 48px;
`;

const SelectedCalendarDay = styled(CenteredBox)`
	background-color: #294652;
	color: white;
	width: 32px;
	height: 32px;
	border-radius: 50%;
`;

const SelectedBackground = styled(BaseCalendarBox)`
	background: ${(props) =>
		props.firstSelected
			? "linear-gradient(to left, #d4dadc 50%, white 50%);"
			: props.lastSelected
			? "linear-gradient(to left, white 50%, #d4dadc 50%);"
			: null};
	background-clip: content-box;
	padding: 8px 0px;
`;

const InRangeCalendarDay = styled(BaseCalendarBox)`
	background-color: #d4dadc;
	color: #353430;
	background-clip: content-box;
	padding: 8px 0px;
`;

const FutureDay = styled(CalendarDay)`
	opacity: 0.4;
	cursor: auto;
`;

const DayText = styled(Flex)`
	width: 48px;
	height: 36px;
	font-family: ${(props) => props.theme.fonts.small};
	font-size: 12px;
`;

const DateRangeCalendar = ({ dateRange, setDateRange }) => {
	const intl = useIntl();
	const { calendar, deselect, inRange, select, selected, viewNextMonth, viewPreviousMonth } = useLilius({
		numberOfMonths: 1,
		selected: [dateRange[0], dateRange[1]],
		viewing: dateRange[1],
	});

	useEffect(() => {
		const sorted = dateRange.sort((a, b) => compareAsc(a, b));
		select(sorted, true);
	}, [dateRange]);

	const wrapInCalendarBox = (component, key) => <BaseCalendarBox key={key}>{component}</BaseCalendarBox>;

	const getDayComponent = (day) => {
		const diffInDays = differenceInDays(selected[0], selected[1]);
		const showBackgroundGradient = diffInDays > 0 || diffInDays < 0;
		const isFirstSelectedDay = isEqual(day, selected[0]);
		const isLastSelectedDay = isEqual(endOfDay(day), endOfDay(selected[1]));
		const dayKey = format(day, "MM/dd/yyyy");
		if (isFirstSelectedDay) {
			return wrapInCalendarBox(
				<SelectedBackground firstSelected={showBackgroundGradient}>
					<SelectedCalendarDay onClick={() => handleCalendarClick(day)}>{format(day, "dd")}</SelectedCalendarDay>
				</SelectedBackground>,
				dayKey
			);
		} else if (isLastSelectedDay) {
			return wrapInCalendarBox(
				<SelectedBackground lastSelected={showBackgroundGradient}>
					<SelectedCalendarDay onClick={() => handleCalendarClick(day)}>{format(day, "dd")}</SelectedCalendarDay>
				</SelectedBackground>,
				dayKey
			);
		} else if (inRange(day, selected[0], selected[1])) {
			return wrapInCalendarBox(
				<CalendarDay>
					<InRangeCalendarDay onClick={() => handleCalendarClick(day)}>{format(day, "dd")}</InRangeCalendarDay>
				</CalendarDay>,
				dayKey
			);
		} else if (isFuture(day)) {
			return wrapInCalendarBox(
				<FutureDay onClick={() => handleCalendarClick(day)}>{format(day, "dd")}</FutureDay>,
				dayKey
			);
		} else {
			return wrapInCalendarBox(
				<CalendarDay onClick={() => handleCalendarClick(day)}>{format(day, "dd")}</CalendarDay>,
				dayKey
			);
		}
	};

	const handleCalendarClick = (day) => {
		if (isFuture(day)) {
			return;
		}

		if (selected.length === 0) {
			select(day);
			return;
		}

		if (selected.length > 1) {
			selected.forEach((d) => deselect(d));
			select(day);
			return;
		}

		const sorted = [selected[0], day].sort((a, b) => compareAsc(a, b));

		select(sorted, true);
		setDateRange([sorted[0], endOfDay(sorted[1])]);
	};

	return (
		<Flex column justifyEvenly gap={10}>
			<Flex justifyAround style={{ padding: 8 }}>
				<Icon onClick={viewPreviousMonth} name="chevron left" size="large" data-testid="previous-month-button" />

				{calendar.map(([[firstDay]]) => {
					const year = format(addWeeks(firstDay, 1), "yyyy");
					const month = format(addWeeks(firstDay, 1), "MMMM");
					return (
						<span key={`month-${format(firstDay, "MM/yyyy")}`} style={{ fontSize: 24, fontWeight: 500 }}>
							{intl.formatMessage({ id: month }) + ` ${year}`}
						</span>
					);
				})}

				<Icon onClick={viewNextMonth} name="chevron right" size="large" data-testid="next-month-button" />
			</Flex>
			{calendar.map((month) => (
				<Flex column key={`month-${format(month[0][0], "LLL")}`} gap={15}>
					<Flex justifyEvenly>
						{month[0].map((day) => {
							const daysOfWeek = [
								"Sunday Abbrev",
								"Monday Abbrev",
								"Tuesday Abbrev",
								"Wednesday Abbrev",
								"Thursday Abbrev",
								"Friday Abbrev",
								"Saturday Abbrev",
							].map((d) => intl.formatMessage({ id: d }));
							return (
								<DayText justifyCenter alignCenter key={format(day, "EEE")}>
									{daysOfWeek[getDay(day)]}
								</DayText>
							);
						})}
					</Flex>

					{month.map((week) => (
						<Flex justifyEvenly key={`week-starting-on-${format(week[0], "MM/dd/yyyy")}`}>
							{week.map((day) => getDayComponent(day))}
						</Flex>
					))}
				</Flex>
			))}
		</Flex>
	);
};

export default DateRangeCalendar;
