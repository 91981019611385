import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "react-jss";

import AppRoutes from "./app-routes";
import LogoutWarningModal from "@/views/login/logout-warning-modal";
import ProductionProvider from "@/contexts/production-context";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import ToastProvider from "@/components/notifications/toast-provider";
import AppInsightsProvider, { reactPlugin } from "@/contexts/app-insights";
import theme from "./theme";
import VersionWarning from "@/components/version-warning";
import PublicSettingsProvider from "@/contexts/public-settings-context";
import LocalizationProvider from "@/contexts/locale-context";
import FullScreenProvider from "@/contexts/fullscreen-context";
import IconStyleWrapper from "@/components/icon-style-wrapper";
import UserContextProvider from "@/contexts/user-context-provider";
import { init as initFullStory } from "@fullstory/browser";
import { isDev } from "@/utils/isDev";
import Pendo from "@/components/pendo";

const fullStoryOrgId = "135FW3";
initFullStory({
	orgId: fullStoryOrgId,
	devMode: isDev(),
});

const queryClient = new QueryClient();

const App = () => (
	<QueryClientProvider client={queryClient}>
		<PublicSettingsProvider>
			<AppInsightsProvider>
				<UserContextProvider>
					<Pendo>
						<LocalizationProvider>
							<ThemeProvider theme={theme}>
								<SCThemeProvider theme={theme}>
									<IconStyleWrapper>
										<FullScreenProvider>
											<LogoutWarningModal />
											<ProductionProvider>
												<ToastProvider>
													<AppRoutes />
												</ToastProvider>
											</ProductionProvider>
											<VersionWarning />
										</FullScreenProvider>
									</IconStyleWrapper>
								</SCThemeProvider>
							</ThemeProvider>
						</LocalizationProvider>
					</Pendo>
				</UserContextProvider>
			</AppInsightsProvider>
		</PublicSettingsProvider>
	</QueryClientProvider>
);

const AppWithAITracking = withAITracking(reactPlugin, App);

export default AppWithAITracking;
