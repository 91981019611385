import { lazy } from "react";
import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import { createUseStyles, useTheme } from "react-jss";
import { Outlet } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { LazyRoute } from "@/components/lazy-route";

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

const Configuration = lazy(() => import("./vmi-views").then((m) => ({ default: m.Configuration })));
const SelfReport = lazy(() => import("./vmi-views").then((m) => ({ default: m.SelfReport })));
const History = lazy(() => import("./vmi-views").then((m) => ({ default: m.History })));

export const getAuthorizedVmiTabs = (currentUser, intl) => {
	const tabs = [
		{
			defaultMenuItem: true,
			parentLink: "vmi",
			link: "vmi-configuration",
			text: intl.formatMessage({ id: "Configuration" }),
			component: <LazyRoute Component={Configuration} />,
			//authorization: "AccessTrimReport",
			licensed: true,
		},
		{
			parentLink: "vmi",
			link: "self-report",
			text: intl.formatMessage({ id: "Self Report" }),
			component: <LazyRoute Component={SelfReport} />,
			//authorization: "AccessTrimReport",
			licensed: true,
		},
		{
			parentLink: "vmi",
			link: "history",
			text: intl.formatMessage({ id: "History" }),
			component: <LazyRoute Component={History} />,
			//authorization: "AccessTrimReport",
			licensed: true,
		},
	];

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const VMIRoutes = ({ currentUser }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedVmiTabs(currentUser, useIntl());

	return (
		<>
			<Segment className={classes.submenu}>
				<ScrollMenu currentUser={currentUser} list={tabs.map((tab) => ({ ...tab }))} />
			</Segment>
			<Outlet />
		</>
	);
};

export default VMIRoutes;
