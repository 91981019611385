import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import { createUseStyles, useTheme } from "react-jss";
import errorBoundary from "@/components/error-boundary";

import { Outlet } from "react-router-dom";
import { lazy } from "react";
import { Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { LazyRoute } from "@/components/lazy-route.jsx";

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

const LazyCubeConfigView = lazy(() => import("@/views/cube/configuration"));

export const getAuthorizedCubeTabs = (currentUser, intl) => {
	const tabs = [
		{
			parentLink: "cube",
			defaultMenuItem: true,
			link: "config",
			text: intl.formatMessage({ id: "Home" }),
			component: <LazyRoute Component={LazyCubeConfigView} />,
			licensed: "cube-allow",
			released: true,
		},
	];

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const CubeRoutes = ({ currentUser }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedCubeTabs(currentUser, useIntl());

	return (
		<>
			<Segment className={classes.submenu}>
				<ScrollMenu currentUser={currentUser} list={tabs.filter((tab) => tab.released)} />
			</Segment>
			<Outlet />
		</>
	);
};

export default errorBoundary(CubeRoutes);
