import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import ProgressBarList from "@/views/reporting/progress-bar-list";
import SwimLanes from "@/views/reporting/swim-lanes";
import { getPaginationResult, getSwimLaneMaxiumum, zFoldNameLine } from "@/views/reporting/functions/swimlanes";
import { getFilteredCorrugate } from "@/views/reporting/functions/corrugate";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";

function ProducedByZFold({ reportData, dateRange, color, limit }) {
	const [presentationData, setPresentationData] = useState([]);
	const intl = useIntl();
	const [upperBound, setUpperBound] = useState(0);
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});

	function mapData(data) {
		let aggregation = data.byZFold.sort((a, b) => (b.cartonsProduced > a.cartonsProduced ? 1 : -1));
		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.cartonsProduced),
		);

		const upper = getSwimLaneMaxiumum(maximumConsumed);
		setUpperBound(upper);

		aggregation = aggregation.map((item) => {
			return {
				...item,
				percentage: (item.cartonsProduced / upper) * 100,
			};
		});

		setPresentationData(
			aggregation.reduce((dict, el) => {
				!dict[el.tenantId] ? (dict[el.tenantId] = [el]) : dict[el.tenantId].push(el);
				return dict;
			}, []),
		);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let items = [];
	for (const property in presentationData) {
		const siteInformation = presentationData[property];
		const site = {
			heading: siteInformation[0].siteName,
		};
		const corrugated = getFilteredCorrugate(siteInformation).map((c) => {
			return {
				percent: c.percentage,
				text: zFoldNameLine(
					limit,
					c.corrugateAlias,
					c.corrugateWidth,
					c.corrugateQuality,
					c.corrugateFlute,
					intl.formatMessage({ id: "Brand" }),
					intl.formatMessage({ id: "Flute" }),
				),
				value: c.cartonsProduced.toLocaleString(undefined),
			};
		});

		site.items = corrugated;
		items.push(site);
	}

	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<SwimLanes upperBound={upperBound} numberOfHeadings={1} key="produced-by-z-fold-swim-lanes">
				{limit === 1000 &&
					items.map((item, i) => {
						return (
							<ProgressBarList
								heading={item.heading}
								color={color}
								items={item.items}
								limit={1000}
								key={`produced-by-z-fold-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 && (
					<ProgressBarList
						heading={paginationResult.heading}
						color={color}
						items={paginationResult.items}
						limit={paginationResult.items.length}
					/>
				)}
			</SwimLanes>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="produced-by-z-fold-swim-lane-pagination"
			/>,
		]
	) : (
		<div></div>
	);
}

export default ProducedByZFold;
