import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	Dropdown,
	Grid,
	Header,
	Image,
	Message,
	Table,
} from "semantic-ui-react";
import { readLocalStorage } from "@/api/local-storage";
import emptyId from "@/constants/emptyId";
import SaveButton from "@/components/save-button";
import X5 from "@/assets/images/machine-type/X5.png"; //TODO: Replace with x6 img
import X5HMI from "@/assets/images/machine-type/X5HMI.svg"; //TODO: Replace with x6 img
import { putWithPath } from "@/api/x6-api";
import { UserContext } from "@/components/user-context";
import { HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr";
import { machineTypes } from "@/constants";
const SignalRContext = createSignalRContext();
const websocketUrl = "/x6Api/hubs/x6";

const zfoldWidthTolerance = 5;

export default function X6ChangeCorrugate({ availableZFold, machineId }) {
	const intl = useIntl();
	const {
		currentUser: { unitofmeasure },
	} = useContext(UserContext);
	const tenantUsesInches = unitofmeasure === "inches";
	const [errorMessage, setErrorMessage] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [machineAlias, setMachineAlias] = useState();
	const [machineStatus, setMachineStatus] = useState();
	const [oldTrackMappings, setOldTrackMappings] = useState([]);
	const [tracks, setTracks] = useState([]);
	const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
	const [saveTrackConfiguration, setSaveTrackConfiguration] = useState(false);
	const [signalRConnectedState, setSignalRConnectedState] = useState(HubConnectionState.Disconnected);
	const token = readLocalStorage("BEARER");

	function updateOldTrackMappings(trackConfigurations) {
		setOldTrackMappings(
			trackConfigurations.map((track) => ({
				trackNumber: track.trackNumber,
				zFoldId: track.loadedZFold.id,
			}))
		);
	}

	useEffect(() => {
		if (!machineId || SignalRContext.connection?.state !== HubConnectionState.Connected) {
			return;
		}

		async function GetTrackConfiguration(abortController) {
			try {
				const response = await SignalRContext.invoke("joinTrackDataGroup", machineId);
				setMachineAlias(response.machineAlias);
				setMachineStatus(response.machineStatus);
				updateOldTrackMappings(response.trackConfigurations);
				setTracks(response.trackConfigurations);
			} catch (error) {
				if (!abortController?.signal?.aborted) {
					setErrorMessage(error.message);
				}
			}

			if (!abortController?.signal?.aborted) {
				setIsLoading(false);
			}
		}

		setIsLoading(true);
		const abortController = new AbortController();
		GetTrackConfiguration(abortController);
		return () => {
			abortController.abort();
			if (SignalRContext.connection?.state === HubConnectionState.Connected) {
				SignalRContext.invoke("leaveTrackDataGroup", machineId);
			}
		};
	}, [machineId, signalRConnectedState]);

	SignalRContext.useSignalREffect("trackDataChanged", (stringData) => {
		const data = JSON.parse(stringData);
		if (machineId === data.machineId) {
			console.info(`Processing ${data} SignalR event for machine: ${machineId}`);
			updateOldTrackMappings(data.trackConfigurations);
			setTracks(data.trackConfigurations);
		} else {
			console.info(`Received trackdata for a different machine ${data.machineId}}!`);
		}
	});

	useEffect(() => {
		if (isLoading || tracks?.length !== oldTrackMappings?.length) {
			return;
		}

		let modified = false;
		for (const track of tracks) {
			const oldTrackMapping = oldTrackMappings.find((x) => x.trackNumber === track.trackNumber);
			if (track.loadedZFold.id !== oldTrackMapping.zFoldId) {
				modified = true;
				break;
			}
		}

		setSaveButtonEnabled(modified);
	}, [isLoading, tracks, oldTrackMappings]);

	useEffect(() => {
		if (!saveTrackConfiguration) {
			return;
		}

		async function saveConfiguration() {
			try {
				const response = await putWithPath(machineId, "trackConfigurations", tracks);
				updateOldTrackMappings(response.trackConfigurations);
				setTracks(response.trackConfigurations);
			} catch (error) {
				if (!abortController?.signal?.aborted) {
					setErrorMessage(error.message);
				}
			}

			if (!abortController?.signal?.aborted) {
				setSaveTrackConfiguration(false);
				setIsLoading(false);
			}
		}

		setErrorMessage();
		setIsLoading(true);
		const abortController = new AbortController();
		saveConfiguration(abortController);
		return () => abortController.abort();
	}, [saveTrackConfiguration]);

	function translate(key) {
		return intl.formatMessage({ id: key });
	}

	function convertMmToInches(value) {
		return value / 25.4;
	}

	function getMinWidth(value) {
		const minWidth = value - zfoldWidthTolerance;
		if (tenantUsesInches) {
			return convertMmToInches(minWidth);
		}

		return minWidth;
	}

	function getMaxWidth(value) {
		const maxWidth = value + zfoldWidthTolerance;
		if (tenantUsesInches) {
			return convertMmToInches(maxWidth);
		}
		return maxWidth;
	}

	function getTrackWidthDisplayText(track) {
		const configuredTrackWidth = track.configuredTrackWidth;
		if (isNaN(configuredTrackWidth)) {
			return translate("Missing track width");
		}

		return `${getMinWidth(configuredTrackWidth).toFixed(2)} - ${getMaxWidth(configuredTrackWidth).toFixed(2)}`;
	}

	function getZFoldOptionsForTrack(track) {
		const minWidth = getMinWidth(track.configuredTrackWidth);
		const maxWidth = getMaxWidth(track.configuredTrackWidth);

		return availableZFold
			.filter((zFold) => zFold.id === emptyId || (zFold.width >= minWidth && zFold.width <= maxWidth))
			.map((zFold) => {
				return {
					key: zFold.id,
					text: zFold.alias,
					value: zFold.id,
				};
			});
	}

	function convertInchesToMm(value) {
		return value * 25.4;
	}

	function zFoldChanged(track, zFoldId) {
		setErrorMessage();

		const zFold = availableZFold.find((z) => z.id === zFoldId);
		if (zFold) {
			track.loadedZFold = {
				alias: zFold.alias,
				id: zFold.id,
				thickness: tenantUsesInches ? convertInchesToMm(zFold.thickness) : zFold.thickness,
				width: tenantUsesInches ? convertInchesToMm(zFold.width) : zFold.width,
				brand: zFold.quality,
			};
		}
		setTracks([...tracks]);
	}

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.None}
			url={websocketUrl}
			onOpen={() => {
				console.info(
					`${machineTypes.X6} zfold websocket opened and is in the ${SignalRContext.connection?.state} state`
				);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
			onReconnect={() => {
				console.info(
					`${machineTypes.X6} zfold websocket reconnected and is in the ${SignalRContext.connection?.state} state`
				);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
			onError={() => {
				console.info(`${machineTypes.X6} zfold websocket errored`);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
			onClosed={() => {
				console.info(`${machineTypes.X6} zfold websocket closed`);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
		>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4} stretched>
						<Card fluid style={{ boxShadow: "none", height: "100%" }}>
							<CardContent>
								<CardHeader>{machineAlias}</CardHeader>
								<CardDescription>Status: {machineStatus}</CardDescription>
							</CardContent>
							<Image src={X5} size="medium" />
						</Card>
					</Grid.Column>
					<Grid.Column width={8} verticalAlign="middle" stretched>
						<Table style={{ border: 0 }}>
							<Table.Header>
								<Table.Row verticalAlign="middle">
									<Table.HeaderCell width={3}>{translate("Track")}</Table.HeaderCell>
									<Table.HeaderCell width={5}>{translate("Configured Width")}</Table.HeaderCell>
									<Table.HeaderCell width={8}>{translate("Loaded z-Fold")}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{tracks.map((track) => (
									<Table.Row key={track.trackNumber} verticalAlign="middle">
										<Table.Cell>{track.trackNumber}</Table.Cell>
										<Table.Cell>{getTrackWidthDisplayText(track)}</Table.Cell>
										<Table.Cell>
											<Dropdown
												key={track.trackNumber}
												selection
												selectOnNavigation={false}
												value={track.loadedZFold.id}
												disabled={
													!(machineStatus === "Offline" || machineStatus === "Paused" || machineStatus === "Error")
												}
												icon={
													machineStatus === "Offline" || machineStatus === "Paused" || machineStatus === "Error"
														? "dropdown"
														: "dont"
												}
												options={getZFoldOptionsForTrack(track)}
												placeholder={
													track.loadedZFold?.alias ? track.loadedZFold.alias : translate("z-Fold Not Loaded")
												}
												onChange={(e, { value }) => zFoldChanged(track, value)}
											/>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Grid.Column>
					{machineStatus === "Online" ? (
						<Grid.Column width={4} verticalAlign="middle">
							<Grid>
								<Grid.Row>
									<Header>{translate("Pause Machine")}</Header>
								</Grid.Row>
								<Grid.Row>
									<Image src={X5HMI} style={{ width: "14.6875rem", height: "10.375rem" }} />
								</Grid.Row>
								<Grid.Row>
									<Header>{translate("To change zFold")}</Header>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					) : (
						<Grid.Column width={4}>
							<SaveButton
								isSaving={isLoading || saveTrackConfiguration}
								saveButtonEnabled={saveButtonEnabled}
								succesfulSave={false}
								onClick={() => setSaveTrackConfiguration(true)}
							/>
							{errorMessage && <Message negative>{errorMessage}</Message>}
						</Grid.Column>
					)}
				</Grid.Row>
			</Grid>
		</SignalRContext.Provider>
	);
}
