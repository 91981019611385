/* eslint-disable no-unused-expressions */

import { Button, Icon, Table } from "semantic-ui-react";
import React, { Fragment, useEffect, useRef } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import NumUpDown from "@/components/numeric-up-down/numeric-up-down";
import { productState } from "./product-state";
import { useNavigate } from "react-router-dom";
import { useIsRoleEnabled } from "@/components/user-context";

const useStyles = createUseStyles((theme) => ({
	table: {
		borderTop: "2px solid !important",
		borderTopColor: `${theme.light} !important`,
		margin: "0 !important",
	},
	tableHeaderCellLeft: {
		padding: "12px 16px !important",
		width: "190px",
	},
	tableHeaderCellCenter: {
		padding: "20px 0 20px 35px !important",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	tableCellCenter: {
		padding: "20px 0 !important",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	tableHeaderCellRight: {
		padding: "12px 16px !important",
		width: "140px",
	},
	statusCell: {
		paddingRight: "25px !important",
		width: "90px",
	},
	tableHeader: {
		backgroundColor: "#FCFDFD !important",
	},
	dataTable: {
		margin: "0 !important",
	},
	dataTableContainer: {
		overflow: "auto",
		height: "100%",
		borderRadius: "8px",
	},
	statusText: {
		color: "grey",
		paddingRight: "8px",
	},
	trash: {
		boxShadow: "none !important",
		borderRadius: "none !important",
	},
	clearButton: {
		margin: "10px 10px 0 0 !important",
		float: "right",
	},
	rowItem: {
		display: "flex",
		justifyContent: "space-between",
	},
	link: {
		color: `${theme.primary}`,
		textDecoration: "underline",
		maxWidth: "170px",
		width: "100%",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	numUpDown: {
		"& input": {
			width: "5rem !important",
		},
	},
}));

function MultiScanProductsTable({
	scannedProducts,
	removeProductHandler,
	quantityChangedHandler,
	clearProductsHandler,
}) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const endOfListRef = useRef(null);
	const navigate = useNavigate();
	const hasManageProductsAccess = useIsRoleEnabled("manageDimensioningProducts");

	useEffect(() => {
		endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [scannedProducts]);

	return (
		<Fragment>
			<Table basic="very" className={classes.table}>
				<Table.Header className={classes.tableHeader}>
					<Table.Row>
						<Table.HeaderCell className={classes.tableHeaderCellLeft}>
							<FormattedMessage id="Quantity"></FormattedMessage>
						</Table.HeaderCell>
						<Table.HeaderCell textAlign="left" className={classes.tableHeaderCellCenter}>
							<FormattedMessage id="ID"></FormattedMessage>
						</Table.HeaderCell>
						<Table.HeaderCell className={classes.tableHeaderCellRight}>
							{scannedProducts.length > 0 && (
								<Button primary size="mini" className={classes.clearButton} onClick={clearProductsHandler}>
									<FormattedMessage id="Clear"></FormattedMessage>
								</Button>
							)}
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			</Table>
			<div className={classes.dataTableContainer}>
				<Table basic="very" className={classes.dataTable}>
					<Table.Body>
						{scannedProducts.map((prod, index) => (
							<Table.Row key={prod.key}>
								<Table.Cell className={classes.tableHeaderCellLeft}>
									{prod.state === productState.NOT_FOUND ? (
										<Icon name="exclamation circle" size="big" color="red" />
									) : (
										<NumUpDown
											number={prod.quantity}
											className={classes.numUpDown}
											setValue={(newQuantity) => {
												quantityChangedHandler(prod.key, newQuantity);
											}}
											minValue={1}
										/>
									)}
								</Table.Cell>
								<Table.Cell className={classes.tableCellCenter}>
									<div className={classes.rowItem}>
										{prod.state === productState.NOT_FOUND && hasManageProductsAccess ? (
											<div
												className={classes.link}
												onClick={() => {
													// prettier-ignore
													navigate(`/dimensioning/products?productId=${prod.productId}&returnTo=scan`);
												}}
											>
												{prod.productId}
											</div>
										) : (
											<div>{prod.productId}</div>
										)}
										<div>
											{(prod.state === productState.LOADING || prod.state === productState.NONE) && (
												<Icon loading name="spinner" size="large" />
											)}
										</div>
									</div>
								</Table.Cell>
								<Table.Cell textAlign="right" className={classes.statusCell}>
									<Button
										floated="right"
										basic
										icon="trash"
										className={classes.trash}
										onClick={() => removeProductHandler(prod.key)}
									/>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<div ref={endOfListRef} />
			</div>
		</Fragment>
	);
}

export default MultiScanProductsTable;
