/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Form, Header, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useRef, useState } from "react";

import CloseButton from "@/components/close-button";
import { createUseStyles } from "react-jss";
import { isDistoInput } from "../contexts/scan-page-context";
import { isNullOrWhitespace } from "@/utils/form-validation";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	closeIcon: {
		border: "none !important",
	},
	form: {
		padding: "48px 96px",
		display: "flex",
		flexDirection: "column",
	},
	upperCard: {
		height: "300px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontWeight: "bold",
		fontSize: "64px",
		color: "#7c7b79",
	},
	buttonRow: {
		display: "flex",
		justifyContent: "space-between",
	},
	hiddenInput: {
		width: "0px",
		overflow: "hidden",
	},
}));

const cardStates = {
	ready: 0,
	accepted: 1,
	send: 2,
};

const ACCEPTED_DELAY = 1_000;

export default function DistoModal({ isVisible, startingDim, onSave, onCancel }) {
	const intl = useIntl();
	const classes = useStyles();
	const [errors, setErrors] = useState({});
	const [dim1, setDim1] = useState("");
	const [dim2, setDim2] = useState("");
	const [dim3, setDim3] = useState("");
	const [dim4, setDim4] = useState("");
	const dim1Ref = useRef();
	const dim2Ref = useRef();
	const dim3Ref = useRef();
	const dim4Ref = useRef();
	const [cardState, setCardState] = useState({
		state: cardStates.ready,
		id: 1,
	});

	useEffect(() => {
		setDim1(startingDim);
	}, [startingDim]);

	function validate() {
		let newErrors = {};
		if (isNullOrWhitespace(dim1)) {
			newErrors = { ...newErrors, dim1: true };
		}
		if (isNullOrWhitespace(dim2)) {
			newErrors = { ...newErrors, dim2: true };
		}
		if (isNullOrWhitespace(dim3)) {
			newErrors = { ...newErrors, dim3: true };
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	}

	function focus1() {
		setCardState({
			state: cardStates.ready,
			id: 1,
		});
		dim1Ref.current?.select();
	}

	useEffect(() => {
		if (isVisible && isDistoInput(dim1)) {
			setCardState({
				state: cardStates.accepted,
				id: 1,
			});

			setTimeout(() => {
				dim2Ref.current?.focus();
			}, ACCEPTED_DELAY);
		}
	}, [isVisible, dim1]);

	function focus2() {
		setCardState({
			state: cardStates.ready,
			id: 2,
		});
		dim2Ref.current?.select();
	}

	useEffect(() => {
		if (isVisible && isDistoInput(dim2)) {
			setCardState({
				state: cardStates.accepted,
				id: 2,
			});

			setTimeout(() => {
				dim3Ref.current?.focus();
			}, ACCEPTED_DELAY);
		}
	}, [isVisible, dim2]);

	function focus3() {
		setCardState({
			state: cardStates.ready,
			id: 3,
		});
		dim3Ref.current?.select();
	}

	useEffect(() => {
		if (isVisible && isDistoInput(dim3)) {
			setCardState({
				state: cardStates.accepted,
				id: 3,
			});

			setTimeout(() => {
				dim4Ref.current?.focus();
			}, ACCEPTED_DELAY);
		}
	}, [isVisible, dim3]);

	function focus4() {
		setCardState({
			state: cardStates.send,
			id: 4,
		});
	}

	useEffect(() => {
		if (isVisible && isDistoInput(dim4)) {
			saveClickedHandler();
		}
	}, [isVisible, dim4]);

	function saveClickedHandler() {
		if (!validate()) {
			return;
		}

		onSave({
			dim1: parseFloat(dim1),
			dim2: parseFloat(dim2),
			dim3: parseFloat(dim3),
		});

		setDim1("");
		setDim2("");
		setDim3("");
		setDim4("");
	}

	function cancelClickedHandler() {
		setDim1("");
		setDim2("");
		setDim3("");
		setDim4("");

		onCancel();
	}

	return (
		<Modal
			dimmer="blurring"
			size="large"
			centered={false}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			open={isVisible}
		>
			<Segment.Group className={classes.group}>
				<Segment className={classes.header}>
					<Header as="h2" floated="left" className={classes.headerText}>
						<FormattedMessage id={"DistoInput"} />
					</Header>
					<Header floated="right" className={classes.closeButton}>
						<CloseButton onClick={cancelClickedHandler} />
					</Header>
					<Header floated="right" className={classes.saveButton}>
						<Button primary onClick={saveClickedHandler}>
							<FormattedMessage id="Send" />
						</Button>
					</Header>
				</Segment>
				<Segment>
					<Form className={classes.form}>
						<div className={classes.upperCard}>
							{cardState.state === cardStates.ready && (
								<>
									<div className={classes.readyCard}>Ready to scan</div>
									<div className={classes.readyCard}>Dimension {cardState.id}</div>
								</>
							)}
							{cardState.state === cardStates.accepted && (
								<>
									<div>
										<Icon name="check circle" color="green" size="large" />
									</div>
									<div>Dimension {cardState.id}</div>
									<div>{dim1}</div>
								</>
							)}
							{cardState.state === cardStates.send && <div className={classes.readyCard}>Any scan to send</div>}
						</div>
						<div className={classes.buttonRow}>
							<div className={classes.distoItem}>
								<Form.Field
									autoFocus
									control={Input}
									error={errors.dim1}
									required
									className={classes.input}
									value={dim1}
									onChange={(_, { value }) => {
										setDim1(value);
									}}
									label={intl.formatMessage({ id: "Dim1" })}
									onFocus={focus1}
								>
									<input type="text" ref={dim1Ref} />
								</Form.Field>
							</div>
							<div className={classes.distoItem}>
								<Form.Field
									control={Input}
									error={errors.dim2}
									required
									className={classes.input}
									value={dim2}
									onChange={(_, { value }) => {
										setDim2(value);
									}}
									label={intl.formatMessage({ id: "Dim2" })}
									onFocus={focus2}
								>
									<input type="text" ref={dim2Ref} />
								</Form.Field>
							</div>
							<div className={classes.distoItem}>
								<Form.Field
									control={Input}
									error={errors.dim3}
									required
									className={classes.input}
									value={dim3}
									onChange={(_, { value }) => {
										setDim3(value);
									}}
									label={intl.formatMessage({ id: "Dim3" })}
									onFocus={focus3}
								>
									<input type="text" ref={dim3Ref} />
								</Form.Field>
							</div>
						</div>
					</Form>
					<div className={classes.hiddenInput}>
						<input
							type="text"
							value={dim4}
							onChange={(e) => {
								setDim4(e.target.value);
							}}
							ref={dim4Ref}
							onFocus={focus4}
						/>
					</div>
				</Segment>
			</Segment.Group>
		</Modal>
	);
}
