import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";

import { getPaginationResult, zFoldNameLine } from "@/views/reporting/functions/swimlanes";
import { getFilteredCorrugate } from "@/views/reporting/functions/corrugate";
import { sortByValue } from "@/views/reporting/functions/sort";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import ProgressBarList from "@/views/reporting/progress-bar-list";

function PercentByZFold({ reportData, dateRange, color, limit, isMetric }) {
	const [presentationData, setPresentationData] = useState([]);
	const intl = useIntl();
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});
	const unitLabel = isMetric ? "sq meters" : "sq ft";

	function mapItem(item) {
		let percent = item.percentage * 100;

		return {
			percent: percent,
			text: zFoldNameLine(
				limit,
				item.corrugateAlias,
				item.corrugateWidth,
				item.corrugateBrand,
				item.corrugateFlute,
				intl.formatMessage({ id: "Brand" }),
				intl.formatMessage({ id: "Flute" })
			),
			value: `${percent.toLocaleString(undefined, {
				maximumFractionDigits: 0,
			})}%`,
			popupText:
				item.totalConsumedCorrugate.toLocaleString(undefined, {
					maximumFractionDigits: 2,
				}) +
				" " +
				intl.formatMessage({ id: unitLabel }),
		};
	}

	function mapData(data) {
		let aggregation = sortByValue(data.byZFold, "percentage", "desc", "corrugateAlias", "asc");
		setPresentationData(aggregation);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let items = [
		{
			items: getFilteredCorrugate(presentationData).map(mapItem),
		},
	];

	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<div key="percent-by-z-fold-progress-bar-list-area">
				{limit === 1000 &&
					items.map((item, i) => {
						return (
							<ProgressBarList
								heading={item.heading}
								color={color}
								items={item.items}
								limit={1000}
								key={`percent-by-z-fold-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 && (
					<ProgressBarList
						heading={paginationResult.heading}
						color={color}
						items={paginationResult.items}
						limit={paginationResult.items.length}
					/>
				)}
			</div>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="percent-by-z-fold-swim-lane-pagination"
			/>,
		]
	) : (
		<div></div>
	);
}

export default PercentByZFold;
