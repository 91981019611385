import { Button } from "semantic-ui-react";
import { ReactComponent as Pencil } from "@/assets/images/edit.svg";
import React from "react";

const EditButton = (props) => (
  <Button basic {...props} data-cy="edit-button">
    <Pencil />
  </Button>
);
export default EditButton;
