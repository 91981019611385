import { Form, Input } from "semantic-ui-react";
import { useIntl } from "react-intl";
import React, { useContext } from "react";
import ScanPageContext, { scanStepNames } from "../contexts/scan-page-context";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import ScanIcon from "./scan-icon";

const useStyles = createUseStyles((theme) => ({
	leftCard: {
		flexBasis: "35%",
	},
	scanArea: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
		width: "25vw",
		margin: "auto",

		"& h1, & h3": {
			textAlign: "center",
			marginBottom: "16px",
		},

		"& .field": {
			display: "flex",
			flexDirection: "column",
			marginBottom: "24px",
		},
	},
	readyText: {
		fontSize: "32px !important",
		marginBottom: "48px",

		"& input": {
			textAlign: "center !important",
		},
	},
	scanCount: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
}));

export default function LeftCard({
	onScanInputFocus,
	onScanInputBlur,
	scanInputRef,
	scanKeyPressedHandler,
	lpnInputRef,
	jobInputRef,
	children,
}) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const scanCtx = useContext(ScanPageContext);

	const identifier = dimCtx.customerConfiguration.mainIdentifierType;

	const scanPlaceholder =
		identifier !== "Unconfigured"
			? intl.formatMessage({ id: "ReadyToScan" }) + (identifier ? ` ${identifier}` : "")
			: intl.formatMessage({ id: "SetupRequired" });

	async function enterPressedHandler(e, inputType) {
		if (e.charCode !== 13) {
			return;
		}

		// prettier-ignore
		let currentStep = dimCtx.customerConfiguration.scanSteps.findIndex((step) => step.name === inputType);
		let nextStep = dimCtx.customerConfiguration.scanSteps[currentStep + 1];

		// prettier-ignore
		if (dimCtx.customerConfiguration.scanSteps[currentStep].name === scanStepNames.uniqueIdentifier &&
      !scanCtx.isSingleScan
    ) {
      scanKeyPressedHandler(e);
      return;
    }

		if (!nextStep) {
			scanKeyPressedHandler(e);
			return;
		}

		let input = null;
		switch (nextStep.name) {
			case scanStepNames.licensePlateNumber:
				input = lpnInputRef.current;
				break;

			case scanStepNames.jobTitle:
				input = jobInputRef.current;
				break;

			case scanStepNames.uniqueIdentifier:
			default:
				input = scanInputRef.current;
				break;
		}

		if (input) {
			input.focus();
			input.select();
		}
	}

	return (
		<div className={classes.leftCard}>
			<div className={classes.scanArea}>
				{!scanCtx.isDistoVisible && (
					<>
						<ScanIcon />
						<Input
							id="scanInput"
							className={classes.readyText}
							transparent
							type="text"
							placeholder={scanPlaceholder}
							value={scanCtx.scanCode}
							onChange={(e, { value }) => {
								scanCtx.setScanCode(value);
							}}
							onFocus={onScanInputFocus}
							onBlur={onScanInputBlur}
							ref={scanInputRef}
							onKeyPress={(e) => enterPressedHandler(e, "Unique Identifier")}
						>
							<input autoComplete="off" />
						</Input>
					</>
				)}
				<div>
					{dimCtx.scanLicensePlateNumberRequired() && (
						<Form.Field
							id="lpnInput"
							disabled={scanCtx.sendBusy}
							control={Input}
							label={intl.formatMessage({ id: "LicensePlateNumber" })}
							placeholder={intl.formatMessage({
								id: "LicensePlateNumberPrompt",
							})}
							value={scanCtx.licensePlateNumber}
							onChange={(_, { value }) => scanCtx.setLicensePlateNumber(value)}
							onKeyPress={(e) => enterPressedHandler(e, "License Plate Number")}
							onFocus={onScanInputFocus}
							onBlur={onScanInputBlur}
						>
							<input autoComplete="off" ref={lpnInputRef} />
						</Form.Field>
					)}
					{dimCtx.scanJobTitleRequired() && (
						<Form.Field
							id="jobTitleInput"
							disabled={scanCtx.sendBusy}
							control={Input}
							label={intl.formatMessage({ id: "JobTitle" })}
							placeholder={intl.formatMessage({
								id: "JobTitlePrompt",
							})}
							value={scanCtx.jobTitle}
							onChange={(_, { value }) => scanCtx.setJobTitle(value)}
							onKeyPress={(e) => enterPressedHandler(e, "Job Title")}
							onFocus={onScanInputFocus}
							onBlur={onScanInputBlur}
						>
							<input autoComplete="off" ref={jobInputRef} />
						</Form.Field>
					)}
				</div>
				{children}
			</div>
		</div>
	);
}
