import axios from "axios";
import { readLocalStorage } from "./local-storage";
import packNetAxios from "../utils/axios";

export async function getAllBrands() {
	const token = readLocalStorage("BEARER");

	const response = await axios.get("/CorrugateApi/api/v1/corrugates/brands", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export const getCorrugates = async () => {
	const response = await packNetAxios.get("/corrugateApi/api/v1/corrugates");

	return response.data;
};
