import { useEffect, useState } from "react";
import { getImportPipelineExportData } from "@/api/import-api";
import { useIsRoleEnabled } from "@/components/user-context";

const useScanToTriggerConfigured = () => {
	const [isScanToTriggerConfigured, setIsScanToTriggerConfigured] = useState(undefined);
	const hasProduceCustomJobsAccess = useIsRoleEnabled("SendPackagingSolutionToQueue");
	const hasManageImportPipelines = useIsRoleEnabled("manageImportPipelines");
	const hasScanPerms = hasManageImportPipelines || hasProduceCustomJobsAccess;
	useEffect(() => {
		(async () => {
			if (!hasScanPerms) {
				setIsScanToTriggerConfigured(false);
				return;
			}
			try {
				const response = await getImportPipelineExportData();
				const isEnabled = !!response.fileDrop.scanToTriggerConfiguration?.triggerField;
				setIsScanToTriggerConfigured(isEnabled);
				return;
			} catch (e) {
				console.error("Error fetching import configuration: ", e);
			}
			setIsScanToTriggerConfigured(false);
		})();
	}, [hasScanPerms]);

	return { isScanToTriggerConfigured };
};

export default useScanToTriggerConfigured;
