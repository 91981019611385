import { Button, Card, Header, Icon, Segment } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { readLocalStorage, writeLocalStorage } from "@/api/local-storage";

import { FormattedMessage } from "react-intl";
import { Fragment } from "react";
import { ProductionContext } from "@/contexts/production-context";
import { MachineGroupContext } from "@/contexts/machine-group/machine-group-context";
import { sortObjectsAscending } from "@/utils/sort";

const useStyles = createUseStyles((theme) => ({
	cards: {},
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: "#f2f4f5 !important",
		borderRadius: "0px !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	headerContent: {
		display: "flex",
		alignItems: "center",
	},
	useGroupButton: {
		marginBottom: "6px !important",
	},
	favoriteButton: {
		boxShadow: "unset !important",
		margin: "unset !important",
		padding: "unset !important",
		"& i": {
			margin: "unset !important",
		},
	},
	online: {
		color: theme.colors.online,
	},
	paused: {
		color: theme.colors.paused,
	},
	error: {
		color: theme.colors.error,
	},
}));

const SelectMachineGroup = ({ displayHeader = true }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const machineGroupFavorites = JSON.parse(readLocalStorage("MachineGroupFavorites"));
	const [favorites, setFavorites] = useState(machineGroupFavorites ?? []);
	const { machineGroups } = useContext(ProductionContext);
	const { changeMachineGroup } = useContext(MachineGroupContext);
	const [sortedMachineGroups, setSortedMachineGroups] = useState([]);

	useEffect(() => {
		const favorited = machineGroups.filter((mg) => favorites?.includes(mg.id));
		const notFavorited = machineGroups.filter((mg) => !favorites?.includes(mg.id));
		const sortedFavorited = sortObjectsAscending(favorited, "alias");
		const sortedNotFavorited = sortObjectsAscending(notFavorited, "alias");
		const sorted = sortedFavorited.concat(sortedNotFavorited);

		setSortedMachineGroups(sorted);
	}, [machineGroups, favorites]);

	const selectMg = (mg) => {
		changeMachineGroup(mg);
	};

	const toggleFavorite = (mg) => {
		const newFavorites = favorites.includes(mg.id) ? favorites.filter((f) => f !== mg.id) : [...favorites, mg.id];
		writeLocalStorage("MachineGroupFavorites", JSON.stringify(newFavorites));
		setFavorites(newFavorites);
	};

	return (
		<Fragment>
			{displayHeader === true && (
				<Segment clearing className={classes.header}>
					<Header floated="left" className={classes.headerText}>
						<FormattedMessage id="Select Machine Group" />
					</Header>
				</Segment>
			)}
			<Segment basic className={classes.cards}>
				<Card.Group centered>
					{sortedMachineGroups.map((mg) => (
						<Card key={mg.id} onClick={() => selectMg(mg)}>
							<Card.Content>
								<Button
									basic
									floated="right"
									className={classes.favoriteButton}
									onClick={(e) => {
										toggleFavorite(mg);
										e.stopPropagation();
									}}
								>
									{favorites?.includes(mg.id) ? <Icon name="star" color="yellow" /> : <Icon name="star outline" />}
								</Button>
								<Card.Header>{mg.alias}</Card.Header>
								<Card.Meta className={classes[mg?.status.toLowerCase()]}>
									<FormattedMessage id={mg.status ?? "Unknown"} />
								</Card.Meta>
								<Card.Description textAlign="center">
									<Button
										primary
										data-cy={`use-mg-${mg.alias}`}
										className={classes.useGroupButton}
										onClick={(e) => {
											selectMg(mg);
											e.stopPropagation();
										}}
									>
										<FormattedMessage id="Use Machine Group" />
									</Button>
								</Card.Description>
							</Card.Content>
						</Card>
					))}
				</Card.Group>
			</Segment>
		</Fragment>
	);
};

export default SelectMachineGroup;
