import React from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";

const useStyles = createUseStyles((theme) => ({
	/* The container */
	container: {
		display: "block",
		position: "relative",
		paddingLeft: "35px",
		cursor: "pointer",
		userSelect: "none",
		fontFamily: "Stolzl-book",

		/* Hide the browser's default checkbox */
		"& input": {
			position: "absolute",
			opacity: "0",
			cursor: "pointer",
		},

		/* On mouse-over, add a grey background color */
		"&:hover input ~ .checkmark": {
			backgroundColor: "#ccc",
			border: "2px solid #0068AB",
		},

		/* When the radio button is checked, add a blue background */
		"& input:checked ~ $checkmark": {
			backgroundColor: "#0068ab",
			border: "none",
		},

		/* Show the checkmark when checked */
		"& input:checked ~ $checkmark:after": {
			display: "block",
		},

		/* Style the checkmark */
		"& $checkmark:after": {
			top: "1px",
			left: "6px",
			width: "8px",
			height: "14px",
			border: "solid white",
			borderWidth: "0 2px 2px 0",
			backgroundColor: "#0068AB",
			transform: "rotate(45deg)",
		},
	},

	/* Create a custom checkbox */
	checkmark: {
		position: "absolute",
		top: "0",
		left: "0",
		height: "20px",
		width: "20px",
		borderRadius: "3px",
		border: "2px solid #7C7B79",

		"&$disabled": {
			borderColor: theme.disabled,
		},

		/* Create the indicator the checkmark - (hidden when not checked) */
		"&:after": {
			content: '""',
			position: "absolute",
			display: "none",
		},
	},

	disabled: {},
	disabledStyle: {
		color: `${theme.disabled} !important`,
	},
}));

export default function CheckboxButton({ children, className, ...others }) {
	const classes = useStyles();

	return (
		<label
			data-component="CheckboxButton"
			className={clsx(classes.container, className, others.disabled && classes.disabledStyle)}
			style={others.style}
		>
			{children}
			<input type="checkbox" {...others} />
			<span className={clsx(classes.checkmark, others.disabled && classes.disabled)} />
		</label>
	);
}
