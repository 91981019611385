import { FormattedMessage } from "react-intl";

import React from "react";

function ProductCount({ products, totalProducts }) {
	if (!products || products.length === 0) {
		return null;
	}

	if (totalProducts > products.length) {
		return (
			<FormattedMessage
				id="ProductCountPage"
				values={{
					total: products.length,
					totalCount: totalProducts,
				}}
			/>
		);
	}

	return (
		<FormattedMessage
			id={products.length === 1 ? "ProductCountSingular" : "ProductCountPlural"}
			values={{
				total: products.length,
			}}
		/>
	);
}

export default ProductCount;
