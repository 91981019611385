import { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";

export default function useSignalRHub({ hubUrl, hubCleanup, token }) {
	const [hubConnection, setHubConnection] = useState();
	const [hubConnectionEstablished, setHubConnectionEstablished] = useState(false);
	const [usePolling, setUsePolling] = useState(true);

	const options = {
		transport: signalR.HttpTransportType.WebSockets,
		logger: signalR.LogLevel.Information,
		accessTokenFactory: () => token.replace("BEARER ", ""),
	};

	useEffect(() => {
		if (token) {
			const connection = new signalR.HubConnectionBuilder().withUrl(hubUrl, options).withAutomaticReconnect().build();
			setHubConnection(connection);
		}
	}, [token]);

	// Start and stop the SignalR hub connection
	useEffect(() => {
		if (!hubConnection) return;

		hubConnection.start().then(() => {
			setHubConnectionEstablished(true);
			setUsePolling(false);
		});

		hubConnection.onclose(() => {
			setHubConnectionEstablished(false);
			setUsePolling(true);
		});
		hubConnection.onreconnected(() => {
			setHubConnectionEstablished(true);
			setUsePolling(false);
		});
		hubConnection.onreconnecting(() => {
			setHubConnectionEstablished(false);
			setUsePolling(true);
		});

		// Cleanup block
		return () => {
			setUsePolling(true);
			if (hubConnection.state === signalR.HubConnectionState.Connected) {
				try {
					if (hubCleanup) hubCleanup(hubConnection);
				} catch (error) {
					console.warn("Error when calling hub cleanup", error);
				} finally {
					hubConnection.stop();
				}
			}
		};
	}, [hubConnection]);

	return { hubConnection, hubConnectionEstablished, usePolling };
}
