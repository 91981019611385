import React, { useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import OrderedCheckbox from "./ordered-checkbox";

const useStyles = createUseStyles((theme) => ({
	scanSteps: {
		overflow: "auto",
		maxHeight: "calc(100vh - 420px)",
	},
}));

function sortSteps(configuredSteps, defaultStepList, mainIdentifierType, setConfiguredSteps) {
	let nonConfiguredSteps = defaultStepList.filter(
		(step) => configuredSteps.find((c) => c.name === step.name) === undefined
	);

	let mainNonConfigured = nonConfiguredSteps.find((step) => step.name === mainIdentifierType);

	if (mainNonConfigured) {
		mainNonConfigured = { ...mainNonConfigured, isRequired: true };
		nonConfiguredSteps = nonConfiguredSteps.filter((step) => step.name !== mainNonConfigured.name);

		configuredSteps = [mainNonConfigured, ...configuredSteps];
		setConfiguredSteps(configuredSteps);
	}

	configuredSteps = configuredSteps.map((step) => {
		return { ...step, isConfigured: true };
	});
	nonConfiguredSteps.map((step) => {
		return { ...step, isConfigured: false };
	});

	// BUG: unrequired identifier
	return [...configuredSteps, ...nonConfiguredSteps];
}

function SettingsCaptureFlow() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const steps = sortSteps(
		dimCtx.customerConfiguration.scanSteps,
		dimCtx.scanSteps,
		"Unique Identifier",
		dimCtx.scanStepsChanged
	);

	return (
		<div className={classes.scanSteps}>
			{steps.map((step, index) => (
				<OrderedCheckbox
					key={step.name}
					required={step.name === "Unique Identifier"}
					downButtonDisabled={index >= dimCtx.customerConfiguration.scanSteps.length - 2}
					step={step}
					index={index}
					added={dimCtx.scanStepAdded}
					removed={dimCtx.scanStepRemoved}
					moved={dimCtx.scanStepMoved}
				/>
			))}
		</div>
	);
}

export default SettingsCaptureFlow;
