import { Form, Grid } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import AttributeCaptureFlow from "@/views/dimensioning/configuration/attribute-capture-flow";
import DimDataContext from "../contexts/dimensioning-data-context";
import Loading from "@/components/loading";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
	},
	row: {
		paddingBottom: "0 !important",
	},
	colum: {
		backgroundColor: "white",
		padding: "1.5em 2.3em 1.5em 1.5em !important",
		borderRadius: "8px",
		width: "100%",
	},
}));

function AttributesTab() {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [identifiers, setIdentifiers] = useState([]);
	const [initFinished, setInitFinished] = useState(false);
	const dimCtx = useContext(DimDataContext);

	useEffect(() => {
		if (dimCtx.isLoading) {
			return;
		}

		setIdentifiers(
			dimCtx.identifiers.map((id) => ({
				key: id.key,
				value: id.key,
				text: id.name,
			}))
		);

		setInitFinished(true);
	}, [dimCtx.isLoading]);

	const mainIdType =
		!dimCtx.customerConfiguration.mainIdentifierType ||
		dimCtx.customerConfiguration.mainIdentifierType !== "Unconfigured"
			? dimCtx.customerConfiguration.mainIdentifierType
			: "";

	return (
		<Fragment>
			{!initFinished ? (
				<Loading />
			) : (
				<Form error className={classes.container}>
					<Grid.Row className={classes.row}>
						<Grid.Column className={classes.colum}>
							<Form.Select
								label={intl.formatMessage({ id: "Unique Identifier" })}
								placeholder={intl.formatMessage({ id: "Select One" })}
								value={mainIdType}
								onChange={(e, { value }) => dimCtx.mainIdentifierTypeChanged(value)}
								options={identifiers}
							/>
							<div className="field">
								<label>
									<FormattedMessage id="Attribute Capture Flow" />
								</label>
							</div>
							<AttributeCaptureFlow />
						</Grid.Column>
					</Grid.Row>
				</Form>
			)}
		</Fragment>
	);
}

export default AttributesTab;
