import { Icon, Loader } from "semantic-ui-react";
import React, { useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import ScanPageStatusContext from "../contexts/scan-page-status-context";
import { productState } from "./product-state";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
		fontSize: "32px",
		textAlign: "center",
		width: "430px",
		height: "260px",

		"& h1": {
			height: "87px",
			overflow: "hidden",
		},
	},
	loader: {
		marginTop: "32px !important",
	},
}));

export default function ScanIcon() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { pageStatus } = useContext(ScanPageStatusContext);
	return (
		<div className={classes.container}>
			{pageStatus.status === productState.LOADING && (
				<Loader size="massive" active inline="centered" className={classes.loader} />
			)}
			{pageStatus.status === productState.FOUND && (
				<>
					<Icon name="check circle" size="huge" color="green" />
					<h1>{pageStatus.message}</h1>
				</>
			)}
			{pageStatus.status === productState.NOT_FOUND && (
				<>
					<Icon name="exclamation circle" size="huge" color="red" />
					<h1>{pageStatus.message}</h1>
				</>
			)}
			{pageStatus.status === productState.ERROR && (
				<>
					<Icon name="warning circle" size="huge" color="red" />
					{pageStatus.message.length > 15 ? <h3>{pageStatus.message}</h3> : <h1>{pageStatus.message}</h1>}
				</>
			)}
		</div>
	);
}
