import { Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { FileUpload } from "@styled-icons/material-rounded";
import { useCallback, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	fileInput: {
		display: "none",
	},
	uploadBox: {
		fontSize: "22px !important",
		borderRadius: "8px",
		border: "dashed 2px #a2adb3",
		width: "100%",
		padding: "16px",
		height: "144px",
		display: "flex",
		flexDirection: "column",
	},
	uploadWidgets: {
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center",
	},
	defaultButton: {
		backgroundColor: `${theme.colors.white} !important`,
		color: "#0068ab !important",
		opacity: "1 !important",
		cursor: "pointer !important",
		padding: "13px 0 !important",
	},
	drop: {
		display: "flex",
		alignItems: "center",
	},
	icon: {
		margin: "0 16px 0 0",
		width: "40px",
		height: "40px",
	},
	dropPrompt: {
		textAlign: "center",
		height: "144px",
		padding: "36px",
	},
	selectedFilename: {
		textAlign: "center",
		marginTop: "12px",
	},
}));

export const UploadFileBox = ({ busy, allowedTypes, selectedFileName, processFile, onProcessFileError }) => {
	const [dragOver, setDragOver] = useState(false);
	const fileInputRef = useRef(null);
	const classes = useStyles();

	const dragOverHandler = useCallback((e, enter) => {
		e.preventDefault();

		if (!enter && e.target.id !== "uploadBox") {
			return;
		}
		setDragOver(enter);
	}, []);

	const selectFileClickedHandler = useCallback(() => {
		fileInputRef.current.click();
	}, []);

	const onFileChangeHandler = useCallback(
		async (e) => {
			try {
				await processFile(e.target.files[0]);
			} catch (err) {
				console.error(err);
			}
		},
		[processFile],
	);

	const isAllowedType = useCallback(
		(fileName) => {
			if (!allowedTypes) {
				return true;
			}
			const parts = fileName.split(".");
			const ext = `.${parts[parts.length - 1]}`;
			return allowedTypes.includes(ext);
		},
		[allowedTypes],
	);

	const onDrop = useCallback(
		async (e) => {
			// Prevent default behavior (Prevent file from being opened)
			e.preventDefault();
			e.stopPropagation();
			let file = null;
			if (e.dataTransfer.items) {
				// Use DataTransferItemList interface to access the file(s)
				if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === "file") {
					file = e.dataTransfer.items[0].getAsFile();
				}
			} else if (e.dataTransfer.files.length) {
				// use DataTransfer interface
				file = e.dataTransfer.files[0];
			}

			if (file) {
				if (isAllowedType(file.name)) {
					await processFile(file);
				} else {
					onProcessFileError(`File must be of type ${allowedTypes.join(", ")}`);
				}
			} else {
				onProcessFileError("Not a valid file");
			}
		},
		[allowedTypes, isAllowedType, onProcessFileError, processFile],
	);

	return (
		<div
			id="uploadBox"
			className={classes.uploadBox}
			onDrop={async (event) => {
				try {
					await onDrop(event);
				} finally {
					setDragOver(false);
				}
			}}
			onDragOver={(e) => dragOverHandler(e, true, "dragover")}
			onDragLeave={(e) => dragOverHandler(e, false, "dragleave")}
		>
			{dragOver ? (
				<div className={classes.dropPrompt}>Drop here</div>
			) : (
				<>
					<div key="001" className={classes.uploadWidgets}>
						<div className={classes.fileInput}>
							<input
								data-cy="file-upload-input"
								ref={fileInputRef}
								type="file"
								onChange={onFileChangeHandler}
								accept={allowedTypes ? allowedTypes.join(",") : "*"}
							/>
						</div>
						<Button className={classes.defaultButton} onClick={selectFileClickedHandler} disabled={busy}>
							<FormattedMessage id="Select File" />
						</Button>
						<div>
							<FormattedMessage id="or" />
						</div>
						<div className={classes.drop}>
							<span>
								<FileUpload className={classes.icon} />
							</span>
							<span>
								<FormattedMessage id="Drop file here" />
							</span>
						</div>
					</div>
					<div key="002" className={classes.selectedFilename}>
						{selectedFileName && <div>{selectedFileName}</div>}
					</div>
				</>
			)}
		</div>
	);
};
