import { Button, Header, Icon, Input, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DataTable from "react-data-table-component";
import History from "@/views/production/job-history/job-history";
import { HttpTransportType } from "@microsoft/signalr";
import Loading from "@/components/loading";
import { ProductionContext } from "@/contexts/production-context";
import { createSignalRContext } from "react-signalr";
import errorBoundary from "@/components/error-boundary";
import useAxios from "@/api/useAxios";
import { Navigate } from "react-router-dom";
import useScanToTriggerConfigured from "../use-scan-to-trigger-configured";
import useScanToTrigger from "./use-scan-to-trigger";
import useToken from "@/hooks/use-token";

const useStyles = createUseStyles((theme) => ({
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: "#f2f4f5 !important",
		borderRadius: "0px !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	headerContent: {
		display: "flex",
		alignItems: "center",
	},
	trigger: {
		"& input": {
			fontFamily: "Stolzl-Book !important",
			fontSize: "16px !important",
			minWidth: "340px",
		},
	},
	ready: {
		margin: "0px !important",
		padding: "100px !important",
		backgroundColor: theme.colors.lightBackground,
		height: "calc(100vh - 224px) !important",
	},
	bigButton: {
		margin: "0px !important",
		padding: "32px !important",
		background: "white !important",
	},
	checkBox: {
		fontSize: "6em !important",
	},
}));

const columns = [
	{
		name: "Name",
		selector: (row) => row.alias,
		sortable: true,
		maxWidth: "40vw",
		grow: 3,
	},
	{
		hide: "md",
		wrap: true,
		grow: 1,
	}, //Add some space between the columns
	{
		name: "Design",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.style : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Length",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.dimensions.length : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Width",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.dimensions.width : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Height",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.dimensions.height : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Quantity",
		cell: (row) => (!row.cartons ? "" : row.cartons.length),
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Actions",
		cell: (row) => {
			return <span>...</span>;
		},
		maxWidth: "100px",
		right: true,
		grow: 0,
	},
];

const JobQueueSignalRContext = createSignalRContext();

const JobQueue = () => {
	let interval;
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = useToken();

	const defaultPollingInterval = 3000;
	const signalRPollingInterval = 300000;

	const { isScanToTriggerConfigured } = useScanToTriggerConfigured();

	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);

	const [jobs, setJobs] = useState([]);
	const [refreshInterval, setRefreshInterval] = useState(signalRPollingInterval);
	const [signalrSubscribedMgId, setSignalRSubscribedMgId] = useState(null);
	const [authorized, setAuthorized] = useState(true);
	const [releasedCountData, setReleasedCountData] = useState({});
	const [notReleased, setNotReleased] = useState([]);
	const [notReleasedLoading, setNotReleasedLoading] = useState(true);

	const PipelineAuditApi = useAxios("/PipelineAuditApi/api/v1/CurrentState", token);
	const MachineGroupApi = useAxios("/MachineGroupApi/api/v1/MachineGroups", token);
	const TriggerProductionApi = useAxios("/SelectionPipelineApi/api/v1/triggerProduction", token);

	const notReleasedColumns = [
		{
			name: "Name",
			selector: (row) => row.Alias,
			sortable: true,
			maxWidth: "40vw",
			grow: 3,
		},
		{
			name: "LPN",
			selector: (row) => row.LicensePlateNumber,
			sortable: true,
			maxWidth: "200px",
			grow: 1,
		},
		{
			hide: "md",
			wrap: true,
			grow: 1,
		}, //Add some space between the columns
		{
			name: "Design",
			cell: (row) => {
				if (!row.Cartons) return "";
				return row.Cartons.length ? row.Cartons[0].Media.Style : "";
			},
			maxWidth: "100px",
			center: true,
			grow: 0,
		},
		{
			name: "Length",
			cell: (row) => {
				if (!row.cartons) return "";
				return row.Cartons.length ? row.Cartons[0].Media.Dimensions.Length : "";
			},
			maxWidth: "100px",
			center: true,
			grow: 0,
		},
		{
			name: "Width",
			cell: (row) => {
				if (!row.cartons) return "";
				return row.Cartons.length ? row.Cartons[0].Media.Dimensions.Width : "";
			},
			maxWidth: "100px",
			center: true,
			grow: 0,
		},
		{
			name: "Height",
			cell: (row) => {
				if (!row.cartons) return "";
				return row.Cartons.length ? row.Cartons[0].Media.Dimensions.Height : "";
			},
			maxWidth: "100px",
			center: true,
			grow: 0,
		},
		{
			name: "Quantity",
			cell: (row) => (!row.Cartons ? "" : row.Cartons.length),
			maxWidth: "100px",
			center: true,
			grow: 0,
		},
		{
			name: "",
			cell: (row) => {
				return (
					<Button
						className={classes.releaseButton}
						inline
						primary
						onClick={() => triggerJobByPackagingSolutionId(row.InternalUniqueId)}
					>
						<FormattedMessage id="Release" />
					</Button>
				);
			},
			maxWidth: "200px",
			right: true,
			grow: 1,
		},
	];

	const refresh = async () => {
		if (
			!authorized ||
			!currentMachineGroup ||
			currentMachineGroup.status.toLowerCase() !== "online" ||
			!currentMachineGroup.productionEnabled
		)
			return;

		PipelineAuditApi.getWithUrl(
			`PackagingSolutions/byStatus/InProgress/byMachineGroup/${currentMachineGroup.id}`,
			(data) => {
				setJobs(data);
			},
			(err) => {
				if (err?.response?.status === 401) {
					setAuthorized(false);
				}
				console.error("Error fetching queue items", err);
			},
		);

		TriggerProductionApi.getWithUrl(
			`released/${currentProductionGroup.id}/count`,
			(data) => setReleasedCountData(data),
			(err) => {
				console.error("Error fetching box last count data", err);
			},
		);
		TriggerProductionApi.getWithUrl(
			`notreleased/${currentProductionGroup.id}`,
			(data) => {
				setNotReleased(data);
				setNotReleasedLoading(false);
			},
			(err) => {
				console.error("Error fetching box last Not Released Jobs", err);
			},
		);
	};

	const triggerJobByLPN = (currentMachineGroup, lpn) => {
		TriggerProductionApi.updateWithUrl(
			`packagingSolutions/forMachineGroup/${currentMachineGroup.id}/byLicensePlateNumber/${lpn}`,
			{},
			() => refresh(),
			(err) => console.error("Failed to trigger packaging solution by lpn", lpn),
		);
	};

	const {
		shouldRender: shouldRenderScanInput,
		inputRef: scanInputRef,
		onKeyUp: onScanInputKeyUp,
		isLoading: isScanInputLoading,
	} = useScanToTrigger({ currentMachineGroup, triggerJobByLPN, historyExpanded: false });

	JobQueueSignalRContext.useSignalREffect("OnJobQueueVersion", (version) => {
		console.info(
			`signalr JobQueue OnJobQueueVersion for connectionid ${JobQueueSignalRContext.connection.connectionId} - ${version}`,
		);
	});

	JobQueueSignalRContext.useSignalREffect("OnSubscribed", (message) => {
		console.info("signalr SubscribedToMachineGroupEvents ", message);
		setSignalRSubscribedMgId(currentMachineGroup.id);
		clearInterval(interval);
		setRefreshInterval(signalRPollingInterval);
		refresh();
	});

	JobQueueSignalRContext.useSignalREffect("OnUnsubscribed", (message) => {
		console.info("signalr UnSubscribedFromMachineGroupEvents ", message);
		setSignalRSubscribedMgId(currentMachineGroup?.id);
		if (currentMachineGroup.id && JobQueueSignalRContext.connection.state === "Connected") {
			JobQueueSignalRContext.connection.invoke("SubscribeToMachineGroupEvents", currentMachineGroup.id);
		}
		clearInterval(interval);
		setRefreshInterval(defaultPollingInterval);
		refresh();
	});

	JobQueueSignalRContext.useSignalREffect("OnProductionQueueUpdated", (tenantId, machineGroupId, selectedJobs) => {
		if (tenantId !== currentMachineGroup.tenantId || machineGroupId !== currentMachineGroup.id) return;
		setJobs(selectedJobs);
	});

	const triggerJobByPackagingSolutionId = (id) => {
		TriggerProductionApi.updateWithUrl(
			`packagingSolutions/forMachineGroup/${currentMachineGroup.id}/byPackagingSolutionId/${id}`,
			{},
			() => refresh(),
			(err) => console.error("Failed to trigger packaging solution by id", id),
		);
	};

	useEffect(() => {
		if (interval) clearInterval(interval);
		if (authorized && refreshInterval && refreshInterval > 0) {
			interval = setInterval(async () => await refresh(), refreshInterval);
			return () => clearInterval(interval);
		}
	}, [refreshInterval, authorized]);

	useEffect(() => {
		if (
			signalrSubscribedMgId &&
			currentMachineGroup &&
			currentMachineGroup.id !== signalrSubscribedMgId &&
			JobQueueSignalRContext.connection.state === "Connected"
		) {
			JobQueueSignalRContext.connection.invoke("UnSubscribeFromMachineGroupEvents", signalrSubscribedMgId);
		}

		if (
			currentMachineGroup &&
			currentMachineGroup.status.toLowerCase() === "online" &&
			currentMachineGroup.productionEnabled &&
			!interval
		) {
			refresh();
		} else if (
			!currentMachineGroup ||
			currentMachineGroup.status.toLowerCase() !== "online" ||
			(!currentMachineGroup.productionEnabled && interval)
		) {
			clearInterval(interval);
		}
	}, [currentMachineGroup]);

	const toggleMachineGroupPlay = () => {
		if (currentMachineGroup) {
			MachineGroupApi.updateWithUrl(
				`${currentMachineGroup.id}/enableProduction`,
				{ enabled: !currentMachineGroup.productionEnabled },
				() => {},
				(err) => console.error("Error enabling/disabling production", err),
			);
		}
	};

	const customInQueueStyles = {
		rows: {
			style: {
				minHeight: "90px",
			},
		},
		cells: {
			style: {
				paddingLeft: "8px", // override the cell padding for data cells
				paddingRight: "8px",
			},
		},
	};

	const AvailableJobs = () => {
		return notReleasedLoading ? (
			<Loading />
		) : (
			// ) : failed ? (
			// 	<FailedRetry retry={load} />
			<Segment basic>
				<Header as="h3">
					<FormattedMessage
						id="{count} Available jobs, {releasedCount} Released jobs"
						defaultMessage="{count} Available jobs, {releasedCount} Released jobs"
						values={{
							count: releasedCountData.notReleased,
							releasedCount: releasedCountData.released,
						}}
					/>
				</Header>
				<DataTable noHeader columns={notReleasedColumns} data={notReleased} customStyles={customInQueueStyles} />
			</Segment>
		);
	};

	if (isScanToTriggerConfigured === undefined) {
		return <Loading />;
	}

	if (isScanToTriggerConfigured === false) {
		return <Navigate to={"/production"} />;
	}

	return (
		<JobQueueSignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.WebSockets}
			url={"/PipelineAuditApi/hubs/jobQueue"}
			//skipNegotiation
			onReconnect={() => {
				console.info(`JobQueueSignalRContext websocket reconnected, current polling interval is ${refreshInterval}`);
			}}
			onOpen={() => {
				if (JobQueueSignalRContext.connection.state !== "Connected") return; //TODO: Our rerendering is out of control and causing the ws to connect/disconnect, it eventually works but this needs to be fixed
				console.info(`JobQueueSignalRContext websocket connected, polling interval set to ${signalRPollingInterval}`);
				JobQueueSignalRContext.connection.invoke("JobQueueVersion");
				//We always have a currentmachinegroup if the code gets here
				JobQueueSignalRContext.connection.invoke("SubscribeToMachineGroupEvents", currentMachineGroup.id);
			}}
			onBeforeClose={() => new Promise((resolve) => setTimeout(() => resolve(), 1000))}
			onError={(error) => {
				console.error(`JobQueueSignalRContext error: ${error}.`);

				if (signalrSubscribedMgId && JobQueueSignalRContext.connection.state === "Connected")
					JobQueueSignalRContext.connection.invoke("UnSubscribeFromMachineGroupEvents", signalrSubscribedMgId);

				if (authorized) {
					console.error(`Reverting JobQueueSignalRContext to polling at interval ${defaultPollingInterval}`);
					setRefreshInterval(defaultPollingInterval); //Fallback for when websocket is not connected
					refresh();
				}
			}}
			onClosed={() => {
				console.info(
					`JobQueueSignalRContext closed, reverting to polling at interval ${defaultPollingInterval}`,
					JobQueueSignalRContext.connection?.state,
				);
				if (signalrSubscribedMgId && JobQueueSignalRContext.connection.state === "Connected")
					JobQueueSignalRContext.connection.invoke("UnSubscribeFromMachineGroupEvents", signalrSubscribedMgId);
				setRefreshInterval(defaultPollingInterval); //Fallback for when websocket is not connected
				refresh();
			}}
		>
			<Segment clearing className={classes.header}>
				<Header floated="left" className={classes.headerText}>
					{intl.formatMessage({ id: "Box Last" })}
				</Header>
				<Header size="large" floated="right" className={classes.headerContent}>
					{shouldRenderScanInput && (
						<Input
							icon={{ size: "tiny" }}
							loading={isScanInputLoading}
							disabled={isScanInputLoading}
							input={{ ref: scanInputRef, onKeyUp: onScanInputKeyUp }}
							className={classes.trigger}
							data-cy="scan-input"
							placeholder={intl.formatMessage({ id: "LPN" })}
						/>
					)}
					<History />
				</Header>
			</Segment>
			{(function () {
				const productionEnabled = currentMachineGroup.productionEnabled;
				switch (currentMachineGroup.status.toLowerCase()) {
					case "initializing":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="refresh" size="huge" color="grey" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine group is initializing" />
								</Header>
							</Segment>
						);
					case "service":
					case "machineservice":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="exclamation circle" size="huge" color="red" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine is in service mode" />
								</Header>
							</Segment>
						);
					case "error":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="exclamation circle" size="huge" color="red" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine group is in error mode" />
								</Header>
							</Segment>
						);
					case "online": {
						if (productionEnabled) {
							if (jobs.length > 0)
								return (
									<>
										<Segment basic>
											<Header as="h3">
												<FormattedMessage id="Available jobs" />
											</Header>
											<DataTable noHeader columns={columns} data={jobs} customStyles={customInQueueStyles} />
										</Segment>
										<AvailableJobs />
									</>
								);
							else
								return (
									<>
										<Segment basic textAlign="center">
											<Header as="h3">
												<FormattedMessage id="No jobs have been released or released jobs can not be produced on this machine group" />
											</Header>
											<Header as="h3">
												<Icon name="check circle" size="big" color="green" />
												<FormattedMessage id="Ready to receive jobs" />
											</Header>
										</Segment>
										<AvailableJobs />
									</>
								);
						} else if (!productionEnabled)
							return (
								<Segment basic textAlign="center" className={classes.ready}>
									<Button icon className={classes.bigButton} onClick={toggleMachineGroupPlay}>
										<Icon name="play" size="huge" className={classes.checkBox} />
									</Button>
									<Header as="h2">
										<FormattedMessage id="Machine group is online but paused, press play to start receiving jobs" />
									</Header>
								</Segment>
							);
						break;
					}
					case "paused":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="pause" size="huge" color="yellow" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machines are paused, ensure all machines are online and ready" />
								</Header>
							</Segment>
						);
					case "offline":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="close" size="huge" color="black" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine group is not currently online, verify the machines are online" />
								</Header>
							</Segment>
						);
					default:
						return <div>State {currentMachineGroup.status.toLowerCase()} not handled</div>;
				}
			})()}
		</JobQueueSignalRContext.Provider>
	);
};

export default errorBoundary(JobQueue);
