import { Icon, Dropdown } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { SmallBook } from "@/components/typography";
import { HighlightOff } from "@styled-icons/material-rounded";
import Flex from "@/components/flex";

const InProgressJobsActionMenu = ({ batchId, removeItem, userCanRemove }) => {
	if (!userCanRemove) return <></>;

	return (
		<Dropdown icon={<Icon name="ellipsis vertical" />} direction="left">
			<Dropdown.Menu>
				<Dropdown.Item onClick={() => removeItem(batchId)}>
					<Flex alignCenter gap={12}>
						<HighlightOff size={24} />
						<SmallBook>
							<FormattedMessage id="Cancel Production" />
						</SmallBook>
					</Flex>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default InProgressJobsActionMenu;
