import { useCallback, useRef, useState } from "react";
import useScanToTriggerConfigured from "../use-scan-to-trigger-configured";

const useScanInput = ({ currentMachineGroup, triggerJobByLPN, historyExpanded }) => {
	const inputRef = useRef();
	const [isLoading, setIsLoading] = useState();
	const { isScanToTriggerConfigured } = useScanToTriggerConfigured();

	const handleKeyUp = useCallback(
		async (event) => {
			if (event.key === "Enter") {
				setIsLoading(true);
				try {
					await triggerJobByLPN(currentMachineGroup, event.target.value);
				} catch (e) {
					console.error("Error invoking scan import: ", e);
				} finally {
					setIsLoading(false);
					inputRef.current.value = "";
					setTimeout(() => inputRef.current.focus(), 200);
				}
			}
		},
		[currentMachineGroup, triggerJobByLPN],
	);

	const shouldRender =
		isScanToTriggerConfigured &&
		!historyExpanded &&
		currentMachineGroup?.status.toLowerCase() === "online" &&
		currentMachineGroup?.productionEnabled;

	return { shouldRender, onKeyUp: handleKeyUp, inputRef, isLoading };
};

export default useScanInput;
