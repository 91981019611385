import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

import NoData from "@/views/reporting/no-data";
import ProgressBarList from "@/views/reporting/progress-bar-list";
import { getSwimLaneMaxiumum } from "@/views/reporting/functions/swimlanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";

function TopErrorsByMachine({ reportData, dateRange, color, limit, isNewFormat }) {
	const [presentationData, setPresentationData] = useState([]);
	const [pagination, setPagination] = useState({
		perPage: 1,
		page: 1,
	});
	const intl = useIntl();

	function mapData(data) {
		let aggregation;
		if (isNewFormat) {
			aggregation = data.topErrorsByMachine.map((machine) => {
				return {
					machineName: machine.machineName,
					errors: machine.topErrors,
				};
			});
		} else {
			aggregation = data.reduce((groups, item) => {
				const group = groups.find((i) => i.machineId === item.machineId);
				if (group) {
					group.count += 1;
					const err = group.errors.find((x) => x.errorCode === item.errorCode);
					if (err) {
						err.count += 1;
					} else {
						group.errors.push({ errorCode: item.errorCode, errorName: item.errorName, count: 1 });
					}

					return groups;
				} else {
					return [
						...groups,
						{
							errors: [
								{
									errorCode: item.errorCode,
									errorName: item.errorName,
									count: 1,
								},
							],
							machineId: item.machineId,
							machineName: item.machineName,
						},
					];
				}
			}, []);
		}

		aggregation = aggregation.map((i) => {
			const sum = i.errors.reduce((accumulator, object) => {
				return accumulator + object.count;
			}, 0);
			i.errors.sort((a, b) => (a.count < b.count ? 1 : -1));
			i.errors = i.errors.slice(0, limit);
			return { ...i, totalErrors: sum };
		});

		aggregation = aggregation.sort((a, b) => (b.totalErrors > a.totalErrors ? 1 : -1));

		let maximumConsumed = Math.max.apply(Math, aggregation.map((a) => a.errors.map((e) => e.count)).flat(2));
		let upper = getSwimLaneMaxiumum(maximumConsumed);
		aggregation = aggregation.map((item) => {
			let newErrors = item.errors.map((e) => {
				return {
					errorCode: e.errorCode,
					errorName: e.errorName,
					count: e.count,
					percentage: (e.count / upper) * 100,
				};
			});
			return {
				...item,
				errors: newErrors,
			};
		});

		setPresentationData(aggregation);
	}

	function getPaginationResult(machines, pageSize, page) {
		if (machines.length === 0) {
			return {
				items: [],
				heading: "",
				hasNext: false,
				hasPrevious: false,
				totalPages: 0,
			};
		}

		const machine = machines.slice(page - 1, page)[0];

		const result = {
			items: machine.items,
			heading: machine.machineName,
			hasNext: page < machines.length,
			hasPrevious: page > 1,
			totalPages: machines.length,
		};

		return result;
	}

	useEffect(() => {
		if (!isNewFormat) {
			if (reportData?.length) mapData(reportData, dateRange);
		} else {
			mapData(reportData, dateRange);
		}
	}, [reportData, dateRange]);

	let items = presentationData.map((p) => {
		const errors = p.errors.map((e) => {
			let key = e.errorName;
			if (!key || key.trim() === "") key = "Placeholder Error";
			return {
				percent: e.percentage,
				text: `${intl.formatMessage({ id: key })} - ${e.errorCode}`,
				value: e.count,
			};
		});
		return {
			machineName: p.machineName,
			items: errors,
		};
	});

	const paginationResult = getPaginationResult(items, pagination.perPage, pagination.page);
	return (
		<>
			{isNewFormat ? reportData.topErrorsByMachine.length === 0 && <NoData /> : reportData.length === 0 && <NoData />}
			{limit === 1000 &&
				(isNewFormat ? reportData.topErrorsByMachine.length : reportData.length) > 0 &&
				items.map((item, i) => (
					<ProgressBarList
						heading={item.machineName}
						color={color}
						items={item.items}
						limit={1000}
						key={`top-errors-by-machine-progress-bar-list-${i}`}
					/>
				))}
			{limit < 1000 && (isNewFormat ? reportData.topErrorsByMachine.length : reportData.length) > 0 && (
				<ProgressBarList
					heading={paginationResult.heading}
					color={color}
					items={paginationResult.items}
					limit={paginationResult.items.length}
				/>
			)}
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="top-errors-by-machine-swim-lane-pagination"
			/>
		</>
	);
}

export default TopErrorsByMachine;
