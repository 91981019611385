import { Modal } from "semantic-ui-react";
import { createUseStyles } from "react-jss";

const useGenericModalStyles = createUseStyles({
	title: {
		fontSize: "24pt !important",
		padding: "1.5rem !important",
		borderBottom: "none !important",
		display: "flex !important",
		alignItems: "center",
		columnGap: 8,
		fontWeight: "normal !important",
	},
	content: {
		display: "flex !important",
		flexDirection: "column !important",
		padding: "1.5rem !important",
		paddingTop: "0 !important",
		rowGap: 16,

		"& p": {
			fontSize: 16,
			fontWeight: "normal",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.4,
			letterSpacing: "normal",
		},
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		gap: "1rem",
		flexDirection: "row-reverse",
	},
});

const GenericModal = ({ open, title, body, actions, size = "small", ...props }) => {
	const classes = useGenericModalStyles();

	return (
		<Modal size={size} open={open} className={classes.modal} {...props}>
			<Modal.Header className={classes.title}>{title}</Modal.Header>

			<Modal.Content className={classes.content}>
				<div>{body}</div>

				<div className={classes.buttons}>{actions}</div>
			</Modal.Content>
		</Modal>
	);
};

export default GenericModal;
