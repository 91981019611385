import { Icon, Dropdown } from "semantic-ui-react";
import { useIsFeatureFlagEnabled } from "@/hooks/use-is-feature-flag-enabled.js";
import Flex from "@/components/flex";
import { HighlightOff, MoveUp, Upgrade } from "@styled-icons/material-rounded";
import TwoLineDropdownItem from "@/components/dropdown/two-line-dropdown-item";

const AvailableJobsActionMenu = ({ batchId, removeItem, produceNext, produceNow }) => {
	const canProduceNow = useIsFeatureFlagEnabled("produce-now");

	return (
		<Dropdown icon={<Icon name="ellipsis vertical" />} direction="left">
			<Dropdown.Menu>
				<Dropdown.Item onClick={() => removeItem(batchId)}>
					<Flex alignCenter gap={12}>
						<HighlightOff size={24} />
						<TwoLineDropdownItem mainTextId="Cancel Production" subTextId="Do not produce" />
					</Flex>
				</Dropdown.Item>
				<Dropdown.Item onClick={() => produceNext(batchId)}>
					<Flex alignCenter gap={12}>
						<MoveUp size={24} />
						<TwoLineDropdownItem mainTextId="Produce Next" subTextId="Produce after current job" />
					</Flex>
				</Dropdown.Item>
				{canProduceNow && (
					<Dropdown.Item onClick={() => produceNow(batchId)}>
						<Flex alignCenter gap={12}>
							<Upgrade size={24} />
							<TwoLineDropdownItem mainTextId="Produce Now" subTextId="Pause current job to produce" />
						</Flex>
					</Dropdown.Item>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default AvailableJobsActionMenu;
