import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import { isNullOrWhitespace } from "@/utils/form-validation";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
}));

const NewPickZone = (props) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { closeModal } = props;
	const [errors, setErrors] = useState({});
	const { setList, edit, setEdit } = useContext(ListContext);
	const { add, updateWithId } = useAxios("/PickZoneApi/api/v1/pickzones", token);

	const validate = () => {
		const newErrors = {};
		if (isNullOrWhitespace(edit.alias)) newErrors.alias = intl.formatMessage({ id: "Name is required" });
		setErrors(newErrors);
		return newErrors;
	};

	const close = () => {
		closeModal();
	};

	const saveAndClose = async () => {
		var errors = validate();
		if (Object.keys(errors).length) return;

		try {
			if (edit.id) {
				delete edit.status;
				await updateWithId(edit, setList);
			} else await add(edit, setList);

			close();
		} catch (e) {
			if (e.response && e.response.status === 409)
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage(
						{
							id: "Failed to save Pick Zone: An item already exists with the name {name}",
						},
						{ name: edit.alias }
					),
				});
			else
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage({ id: "Failed to save Pick Zone" }),
				});
		}
	};

	return (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit Pick Zone" : "New Pick Zone"} />
				</Header>
				<Header floated="right" className={classes.closeButton}>
					<CloseButton onClick={close} />
				</Header>
				<Header floated="right" className={classes.saveButton}>
					<Button primary onClick={saveAndClose}>
						<FormattedMessage id="Save" />
					</Button>
				</Header>
			</Segment>
			<Segment className={classes.form}>
				<Form error>
					<Grid relaxed="very" stackable className={classes.topGrid}>
						<Grid.Row columns={1}>
							<Grid.Column>
								<Form.Input
									required
									label={intl.formatMessage({ id: "Name" })}
									placeholder={intl.formatMessage({ id: "Name" })}
									value={edit.alias}
									onChange={(e) => {
										setEdit({ ...edit, alias: e.target.value });
									}}
									error={errors.alias ? { content: errors.alias } : null}
									icon
								>
									<input />
									{errors.alias && (
										<i class="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
								<Form.TextArea
									label={intl.formatMessage({ id: "Description" })}
									placeholder={intl.formatMessage({ id: "Description" })}
									value={edit.description}
									onChange={(e) => setEdit({ ...edit, description: e.target.value })}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
				</Form>
			</Segment>
		</Segment.Group>
	);
};

export default NewPickZone;
