/* eslint-disable default-case */
/* eslint-disable no-unused-expressions */

import { Icon, Table } from "semantic-ui-react";
import React, { Fragment, useEffect, useRef } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import { productState } from "./product-state";

const TITLE_HEIGHT = "96px";

const useStyles = createUseStyles((theme) => ({
	table: {
		borderTop: "2px solid !important",
		borderTopColor: `${theme.light} !important`,
		margin: "0 !important",
	},
	tableHeaderCell: {
		padding: "12px 16px !important",
	},

	tableHeader: {
		backgroundColor: "#FCFDFD !important",
	},
	dataTable: {
		margin: "0 !important",
	},
	dataTableContainer: {
		overflow: "auto",
		height: `calc(100vh - 128px - ${TITLE_HEIGHT} - 147px)`,
		borderRadius: "8px",
	},
	statusText: {
		color: "grey",
		paddingRight: "8px",
	},
	statusCell: {
		paddingRight: "25px !important",
	},
}));

function SingleScanProductsTable({ scannedProducts }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const endOfListRef = useRef(null);

	useEffect(() => {
		endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [scannedProducts]);

	function rowState(state, message) {
		switch (state) {
			case productState.LOADING:
				return <FormattedMessage id="Loading" />;

			case productState.FOUND:
				return [
					<span key={`found-${state.key}`} className={classes.statusText}>
						{message}
					</span>,
					<Icon key={`found-icon-${state.key}`} name="check circle" size="large" color="green" />,
				];

			case productState.NOT_FOUND:
				return [
					<span key={`not-found-${state.key}`} className={classes.statusText}>
						{message}
					</span>,
					<Icon key={`not-found-icon-${state.key}`} name="exclamation circle" size="large" color="red" />,
				];

			case productState.ERROR:
				return [
					<span key={`error-${state.key}`} className={classes.statusText}>
						{message}
					</span>,
					<Icon key={`error-icon-${state.key}`} name="warning circle" size="large" color="red" />,
				];
		}

		return null;
	}

	return (
		<Fragment>
			<Table basic="very" className={classes.table}>
				<Table.Header className={classes.tableHeader}>
					<Table.Row>
						<Table.HeaderCell className={classes.tableHeaderCell}>
							<FormattedMessage id="ID"></FormattedMessage>
						</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			</Table>
			<div className={classes.dataTableContainer}>
				<Table basic="very" className={classes.dataTable}>
					<Table.Body>
						{scannedProducts.map((prod) => (
							<Table.Row key={prod.key}>
								<Table.Cell className={classes.tableHeaderCell}>{prod.productId}</Table.Cell>
								<Table.Cell key={`prod-cell-${prod.key}`} textAlign="right" className={classes.statusCell}>
									{rowState(prod.state, prod.message)}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<div ref={endOfListRef} />
			</div>
		</Fragment>
	);
}

export default SingleScanProductsTable;
