import { Form, Grid, Table } from "semantic-ui-react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import DimDataContext from "../contexts/dimensioning-data-context";
import CartPageContext from "../contexts/cart-page-context";
import Loading from "@/components/loading";
import EditButton from "@/components/edit-button";
import DeleteButton from "@/components/delete-button";
import ConfirmModal from "@/components/confirm-modal";
import NewCartModal from "./new-cart-modal";

const TABLE_HEADER_HEIGHT = "43px";
const emptyCart = {
	name: "",
	description: "",
	ip: "",
	port: 0,
};

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
	},
	row: {
		paddingBottom: "0 !important",
	},
	column: {
		backgroundColor: "white",
		padding: "1.5em 2.3em 1.5em 1.5em !important",
		borderRadius: "8px",
		width: "100%",
	},
	workArea: {
		display: "flex",
		flexDirection: "column",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight} - ${theme.measurements.pageHeaderHeight})`,
	},
	table: {
		margin: "unset !important",
		border: "none !important",
		borderRadius: "unset !important",
		backgroundColor: "#FCFDFD !important",

		"& th": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
			fontWeight: "bold !important",
			width: "100vw",
			height: TABLE_HEADER_HEIGHT,
		},
	},
	tableBody: {
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	},
	headerButton: {
		margin: "0 !important",
		backgroundColor: `${theme.colors.white} !important`,
		color: "#0068ab !important",
	},
	tableCell: {
		maxWidth: "0",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontFamily: "Stolzl-Book",
	},
	tableRowFirstColumn: {
		paddingLeft: "32px !important",
	},
	tableRowLastColumn: {
		textAlign: "right !important",
		paddingRight: "32px !important",
	},
	actionButtons: {
		"& > .ui.basic.button": {
			boxShadow: "none !important",
		},
	},
	searchPrompt: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
	},
}));

function CartsTab() {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [initFinished, setInitFinished] = useState(false);
	const dimCtx = useContext(DimDataContext);
	const cartCtx = useContext(CartPageContext);
	const [isDeleteConfirmModalOpened, setIsDeleteConfirmModalOpened] = useState(false);
	const [idToDelete, setIdToDelete] = useState("");
	const [rowNumberToDelete, setRowNumberToDelete] = useState(0);
	const [nameToDelete, setNameToDelete] = useState("");
	const [totalCarts, setTotalCarts] = useState(0);
	const [cartModalOpen, setCartModalOpen] = useState(false);

	useEffect(() => {
		if (dimCtx.isLoading || cartCtx.isLoading) {
			return;
		}
		setInitFinished(true);
	}, [cartCtx.isLoading]);

	useEffect(() => {
		if (dimCtx.isLoading || cartCtx.isLoading) {
			return;
		}
		setInitFinished(true);
	}, [dimCtx.isLoading]);

	async function deleteCartHandler(rowNumber, id) {
		var success = cartCtx.removeCart(id);

		if (success) {
			const currentCarts = cartCtx.carts;
			const newCarts = [...currentCarts.slice(0, rowNumber), ...currentCarts.slice(rowNumber + 1)];
			cartCtx.setCarts(newCarts);
			setTotalCarts(totalCarts - 1);
		}
	}
	async function saveAndClose() {
		try {
			await cartCtx.saveCart(cartCtx.editCart);
		} catch (err) {
			if (err?.isAxiosError && err.response.status === 404) {
				return;
			}
		}
		setCartModalOpen(false);
		cartCtx.setEditCart(emptyCart);
		cartCtx.reload();
	}

	async function confirmDeleteHandler(answerYes) {
		setIsDeleteConfirmModalOpened(false);
		if (!answerYes) {
			await cartCtx.reload();
			return;
		}

		await deleteCartHandler(rowNumberToDelete, idToDelete);
		await cartCtx.reload();
	}

	return (
		<Fragment>
			{!initFinished ? (
				<Loading />
			) : (
				<Form error className={classes.container}>
					<NewCartModal
						open={cartModalOpen}
						closeWithoutSaving={() => setCartModalOpen(false)}
						saveAndClose={() => saveAndClose()}
						cart={cartCtx.editCart}
						setCart={cartCtx.setEditCart}
						edit
					/>
					<ConfirmModal
						open={isDeleteConfirmModalOpened}
						onClose={confirmDeleteHandler}
						titleText={intl.formatMessage({ id: "DeleteCart" }, { cartId: nameToDelete })}
						detailText={intl.formatMessage({ id: "DeleteCartDescription" })}
						confirmText={intl.formatMessage({ id: "Delete" })}
						cancelText={intl.formatMessage({ id: "Cancel" })}
					/>
					<Grid.Row className={classes.row}>
						<Grid.Column className={classes.column}>
							<div className={classes.workArea}>
								{cartCtx.carts && cartCtx.carts.length > 0 && (
									<Fragment>
										<Table basic="very" className={classes.table}>
											<colgroup>
												<col
													key={`col-name`}
													style={{
														width: `${100 / 5}%`,
													}}
												/>
												<col
													key={`col-description`}
													style={{
														width: `${100 / 5}%`,
													}}
												/>
											</colgroup>
											<Table.Header>
												<Table.Row className={classes.tableRow}>
													<Table.HeaderCell key={"headerRowName"} className={classes.tableRowFirstColumn}>
														<FormattedMessage id="Name" />
													</Table.HeaderCell>
													<Table.HeaderCell key={"headerRowDescription"}>
														<FormattedMessage id="Description" />
													</Table.HeaderCell>
													<Table.HeaderCell></Table.HeaderCell>
												</Table.Row>
											</Table.Header>
										</Table>
										<div className={classes.tableBody}>
											<Table basic="very" className={classes.table}>
												<colgroup>
													<col
														key={`col-name`}
														style={{
															width: `${100 / 5}%`,
														}}
													/>
													<col
														key={`col-description`}
														style={{
															width: `${100 / 5}%`,
														}}
													/>
												</colgroup>
												<Table.Body>
													{cartCtx.carts.map((cart, rowNum) => (
														<Table.Row key={`cart-row-${rowNum}`}>
															<Table.Cell
																key={cart.mainId + "row"}
																className={`${classes.tableCell} ${classes.tableRowFirstColumn}`}
															>
																{cart.name}
															</Table.Cell>
															<Table.Cell key={cart.mainId + "rowdescription"} className={classes.tableCell}>
																{cart.description}
															</Table.Cell>
															<Table.Cell
																className={`${classes.tableCell} ${classes.tableRowLastColumn} ${classes.actionButtons}`}
															>
																<EditButton
																	onClick={() => {
																		cartCtx.setEditCart(cart);
																		setCartModalOpen(true);
																	}}
																/>
																<DeleteButton
																	onClick={() => {
																		const id = cart.mainId;
																		setIdToDelete(id);
																		setRowNumberToDelete(rowNum);
																		setNameToDelete(cart.name);

																		setIsDeleteConfirmModalOpened(true);
																	}}
																/>
															</Table.Cell>
														</Table.Row>
													))}
												</Table.Body>
											</Table>
										</div>
									</Fragment>
								)}
								{cartCtx.carts && cartCtx.carts.length === 0 && initFinished && (
									<div className={classes.searchPrompt}>
										<h2>
											<FormattedMessage id="NewCartMessage01" />
											<br></br>
											<FormattedMessage id="NewCartMessage02" />
										</h2>
									</div>
								)}
							</div>
						</Grid.Column>
					</Grid.Row>
				</Form>
			)}
		</Fragment>
	);
}

export default CartsTab;
