import { Button, Divider, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import CheckboxButton from "@/components/checkbox-button";
import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import errorBoundary from "@/components/error-boundary";
import { isNullOrWhitespace } from "@/utils/form-validation";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import useMachines from "../machines/use-machines";
import useOnLoadedEffect from "@/components/use-on-loaded-effect";
import Flex from "@/components/flex";
import Tooltip from "@/components/tooltip/tooltip";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	machine: {
		marginTop: "10px !important",
		marginBottom: "10px !important",
	},
}));

const NewMachineGroup = ({ closeModal }) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [errors, setErrors] = useState({});
	const [machineList, setMachineList] = useState([]);
	const { setList, edit, setEdit } = useContext(ListContext);
	const { add, updateWithId } = useAxios("/MachineGroupApi/api/v1/machinegroups", token);
	const Machines = useMachines(token);

	useOnLoadedEffect(() => {
		Machines.get().then(setMachineList);
	});

	const validate = () => {
		const newErrors = {};
		if (isNullOrWhitespace(edit.alias)) newErrors.alias = intl.formatMessage({ id: "Name is required" });
		setErrors(newErrors);
		return newErrors;
	};

	const close = () => {
		closeModal();
	};

	const saveAndClose = async () => {
		var errors = validate();
		if (Object.keys(errors).length) return;

		try {
			if (edit.id) {
				await updateWithId(edit, setList);
			} else await add(edit, setList);

			close();
		} catch (e) {
			if (e.response && e.response.status === 409) {
				if (e.response.data === "UniqueTenantAlias")
					setErrors({
						...errors,
						addOrUpdate: intl.formatMessage(
							{
								id: "Failed to save Machine Group: An item already exists with the name {name}",
							},
							{ name: edit.alias }
						),
					});
				else if (e.response.data === "UniqueConfiguredMachineIds")
					setErrors({
						...errors,
						addOrUpdate: intl.formatMessage({
							id: "Failed to save Machine Group: A selected machine already belongs to another Machine Group",
						}),
					});
				else if (e.response.data === "X7MachineGroupConstraint")
					setErrors({
						...errors,
						addOrUpdate: intl.formatMessage({
							id: "An X7 can be the only machine configured in a Machine Group",
						}),
					});
				else
					setErrors({
						...errors,
						addOrUpdate: intl.formatMessage({
							id: "Failed to save Machine Group",
						}),
					});
			} else
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage({
						id: "Failed to save Machine Group",
					}),
				});
		}
	};

	if (Object.keys(edit).length === 0) {
		setEdit({
			configuredMachinesWithMachineType: {},
		});
	}

	return (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit Machine Group" : "New Machine Group"} />
				</Header>
				<Header floated="right" className={classes.closeButton}>
					<CloseButton onClick={close} />
				</Header>
				<Header floated="right" className={classes.saveButton}>
					<Button primary onClick={saveAndClose}>
						<FormattedMessage id="Save" />
					</Button>
				</Header>
			</Segment>
			<Segment className={classes.form}>
				<Form error>
					<Grid relaxed="very" stackable divided>
						<Grid.Row>
							<Grid.Column width={6}>
								<Form.Input
									required
									label={intl.formatMessage({ id: "Name" })}
									placeholder={intl.formatMessage({ id: "Name" })}
									value={edit.alias}
									onChange={(e) => setEdit({ ...edit, alias: e.target.value })}
									error={errors.alias ? { content: errors.alias } : null}
									icon
								>
									<input />
									{errors.alias && (
										<i class="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
								<Form.TextArea
									label={intl.formatMessage({ id: "Description" })}
									placeholder={intl.formatMessage({ id: "Description" })}
									value={edit.description ?? ""}
									onChange={(e) => setEdit({ ...edit, description: e.target.value })}
								/>
								<Divider />
								<Header as="h4">
									<FormattedMessage id="Optional Settings" />
								</Header>
								<Flex gap={8} alignCenter>
									<CheckboxButton
										floated="left"
										checked={edit.enableProductionAutomatically}
										onClick={(e) => setEdit({ ...edit, enableProductionAutomatically: e.target.checked })}
									>
										<FormattedMessage id="Enable Production Automatically" />
									</CheckboxButton>
									<Tooltip
										content={intl.formatMessage({
											id: "AutoEnableMachineGroupTooltip",
											defaultMessage:
												"When enabled, the Machine Group will automatically be put in play when it comes online",
										})}
									/>
								</Flex>
							</Grid.Column>
							<Grid.Column width={8}>
								<div className={classes.text}>
									<b>
										<FormattedMessage id="Available Machines" />
									</b>
								</div>
								<div className={classes.text}>
									<i>
										<FormattedMessage id="Select which machines you would like to add to the machine group" />
									</i>
								</div>
								<Grid columns={2} className={classes.fullWidth}>
									{machineList.map((machine) => (
										<Grid.Column key={machine.id}>
											<CheckboxButton
												key={machine.id}
												className={classes.machine}
												defaultChecked={
													edit.configuredMachinesWithMachineType && edit.configuredMachinesWithMachineType[machine.id]
												}
												onChange={(e) => {
													if (e.target.checked) {
														setEdit({
															...edit,
															configuredMachinesWithMachineType: {
																...edit.configuredMachinesWithMachineType,
																[machine.id]: machine.machineType,
															},
														});
													} else {
														const { [machine.id]: _, ...selectedMachines } = edit.configuredMachinesWithMachineType;
														setEdit({
															...edit,
															configuredMachinesWithMachineType: selectedMachines,
														});
													}
												}}
											>
												<FormattedMessage id={machine.alias ?? "Unknown"} />
											</CheckboxButton>
										</Grid.Column>
									))}
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
				</Form>
			</Segment>
		</Segment.Group>
	);
};

export default errorBoundary(NewMachineGroup);
