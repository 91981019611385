import { Header, Segment, Modal, Step, Icon, Image, Message } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useEffect, useState, useRef } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { getCurrentConfiguration } from "@/api/dim-config-api";
import { postProduct, unflattenCustomAttributes } from "@/api/dim-product-api";

import DimDataContext from "../../contexts/dimensioning-data-context";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import CloseButton from "@/components/close-button";
import GlobalButton from "@/components/global-button";

import AlphaNumeric from "@/views/dimensioning/capture-forms/alpha-numeric";
import Bool from "@/views/dimensioning/capture-forms/bool";
import Date from "@/views/dimensioning/capture-forms/date";
import Dimensions from "@/views/dimensioning/capture-forms/dimensions";
import Nesting from "@/views/dimensioning/capture-forms/nesting";
import Orientation from "@/views/dimensioning/capture-forms/orientation";
import PacksizeSelect from "@/views/dimensioning/capture-forms/select";
import DimensioningVoidFill from "@/views/dimensioning/capture-forms/void-fill";
import Weight from "@/views/dimensioning/capture-forms/weight";

import { sleep } from "@/utils/sleep";

const TITLE_HEIGHT = "112px";
const COLUMN_TITLE_HEIGHT = "85px";
const FOOTER_HEIGHT = "80px";

const identifiers = [
	{ key: "UPC", name: "UPC", isGlobal: true },
	{ key: "GTIN", name: "GTIN", isGlobal: true },
	{ key: "GTIN_8", name: "GTIN-8", isGlobal: true },
	{ key: "GTIN_12", name: "GTIN-12", isGlobal: true },
	{ key: "GTIN_13", name: "GTIN-13", isGlobal: true },
	{ key: "GTIN_14", name: "GTIN-14", isGlobal: true },
	{ key: "EAN", name: "EAN", isGlobal: true },
	{ key: "MPN", name: "MPN", isGlobal: true },
	{ key: "ISBN", name: "ISBN", isGlobal: true },
	{ key: "ISBN_10", name: "ISBN-10", isGlobal: true },
	{ key: "ISBN_13", name: "ISBN-13", isGlobal: true },
	{ key: "SKU", name: "SKU", isGlobal: false },
	{ key: "ItemNumber", name: "ItemNumber", isGlobal: false },
];

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	container: {
		backgroundColor: theme.light,
		height: "calc(100vh - 200px)",
	},
	columnsHolder: {
		display: "flex",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight} - 80px)`,
		padding: "0 16px 16px 16px",
		justifyContent: "space-between",

		"&>div": {
			margin: "0 16px 16px 16px",
		},
	},
	stepsColumn: {
		background: "white",
		flexBasis: "25%",
		borderRadius: "8px",
		overflow: "none",
		padding: "24px",
	},
	inputColumn: {
		background: "white",
		flexBasis: "40%",
		borderRadius: "8px",
		overflow: "none",
		padding: "24px",
	},
	inputColumnNoImage: {
		background: "white",
		flexBasis: "75%",
		borderRadius: "8px",
		overflow: "none",
		padding: "24px",
	},
	imageColumn: {
		background: "white",
		flexBasis: "35%",
		borderRadius: "8px",
		overflow: "none",
		padding: "24px",
	},
	listTitleBlue: {
		padding: "16px",
		margin: "0",
		color: theme.colors.primary,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	listTitle: {
		padding: "16px",
		margin: "0",
	},
	columnContentSteps: {
		margin: "0 -23px",
		overflow: "auto",
		height: `calc(100vh - ${TITLE_HEIGHT} - ${COLUMN_TITLE_HEIGHT} - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
	},
	columnContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		borderTop: "2px solid !important",
		borderTopColor: `${theme.light} !important`,
		margin: "0 -23px",
		overflow: "auto",
		height: `calc(100vh - ${TITLE_HEIGHT} - ${COLUMN_TITLE_HEIGHT} - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
	},
	columnFooter: {
		height: FOOTER_HEIGHT,
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		borderTop: "2px solid !important",
		borderTopColor: `${theme.light} !important`,
	},
	footerButton: {
		marginRight: "32px !important",
	},
	skipButton: {
		backgroundColor: `${theme.colors.light} !important`,
		color: "#0068ab !important",
		opacity: "1 !important",
		cursor: "pointer",
		marginRight: "32px !important",
	},
	firstStepGroup: {
		margin: "16px 24px 0 24px !important",
	},
	stepGroup: {
		margin: "0 24px !important",
	},
	stepGroupVertical: {
		marginBottom: "16px !important",
	},
	stepNumber: {
		fontSize: "20px",
		marginRight: "8px",
	},
	stepNumberBlue: {
		fontSize: "20px",
		marginRight: "8px",
		color: theme.colors.primary,
	},
	step: {
		"&:after": {
			display: "none !important",
		},
		"&.active": {
			border: `1px solid ${theme.colors.primary} !important`,
		},
	},
	stepStatus: {
		marginRight: "14px !important",
		marginTop: "-55px !important",
		zIndex: "999999",
		position: "relative",
		width: "30px",
		float: "right",
	},
	formHolder: {
		padding: "24px",
		height: `calc(100vh - ${TITLE_HEIGHT} - ${COLUMN_TITLE_HEIGHT} - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight}) - ${FOOTER_HEIGHT}`,
	},
	imageHolder: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
	},
	errorText: {
		color: "#ff3743",
		paddingTop: "24px !important",
	},
}));

function isNumber(value) {
	const num = parseFloat(value);
	return !isNaN(num);
}

function ProductCreate({ open, onConfirm, onClose, productId }) {
	const theme = useTheme();
	const classes = useStyles({ theme });

	const intl = useIntl();
	const [pageLoading, setPageLoading] = useState(true);
	const [loadingError, setLoadingError] = useState(undefined);
	const dimCtx = useContext(DimDataContext);
	const [errors, setErrors] = useState({});
	const [tenantConfiguration, setTenantConfiguration] = useState({});
	const [mainIdType, setMainIdType] = useState("");
	const [steps, setSteps] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const defaultProduct = {
		attributes: {},
		customAttributes: {},
		identifiers: {},
		dimensions: { length: 0, width: 0, height: 0 },
		quantity: 1,
		voidFill: { length: 0, width: 0, height: 0 },
	};
	const [product, setProduct] = useState(defaultProduct);
	const [messageBox, setMessageBox] = useState({
		show: false,
		message: "",
		isError: true,
	});
	const [formLoading, setFormLoading] = useState(false);
	const inputRefs = useRef([]);

	async function pageLoad() {
		setPageLoading(true);
		setFormLoading(true);
		setLoadingError(undefined);
		setMessageBox({ show: false });

		try {
			const config = await getCurrentConfiguration();
			setTenantConfiguration(config);
			setMainIdType(config.mainIdentifierType);
			config.attributeCaptureSteps.map((step) => {
				step.status = step.name === config.mainIdentifierType && productId ? "complete" : "incomplete";
				step.error = { hasError: false, message: "" };
				step.isRequired = step.name === config.mainIdentifierType || step.name === "Dimensions";
				switch (step.name.toLowerCase()) {
					case "description":
					case "dimensions":
					case "nesting":
					case "weight":
					case "gtin":
					case "gtin_8":
					case "gtin_12":
					case "gtin_13":
					case "gtin_14":
					case "ean":
					case "mpn":
					case "isbn":
					case "isbn_10":
					case "isbn_13":
					case "sku":
					case "itemnumber":
					case "suppliernumber":
					case "cavity":
					case "layering":
					case "orientation":
						step.hasImage = true;
						break;
					default:
						step.hasImage = false;
				}
				return step;
			});
			setSteps(config.attributeCaptureSteps);
			const finalProduct = {
				...defaultProduct,
				identifiers: { [config.mainIdentifierType]: productId },
			};
			setProduct(finalProduct);
			setActiveStep(productId ? 1 : 0);
		} catch (err) {
			// eslint-disable-next-line
			console.error(err);
		}
		setPageLoading(false);
		setFormLoading(false);
	}

	useEffect(() => {
		if (open) {
			pageLoad();
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!formLoading && open) {
			changeInputFocus();
		}
	}, [formLoading, activeStep]);

	const useKeyDown = (callback) => {
		const onKeyDown = (event) => {
			callback(event);
		};
		useEffect(() => {
			document.addEventListener("keydown", onKeyDown);
			return () => {
				document.removeEventListener("keydown", onKeyDown);
			};
		}, [onKeyDown]);
	};
	useKeyDown((e) => {
		keyPressHandler(e);
	});

	function keyPressHandler(e) {
		if (e.keyCode === 13) {
			if (steps.length > activeStep + 1) {
				onNextClick(activeStep + 1);
			} else if (!formCompletedCheck()) {
				saveProduct(product);
			}
		}
	}

	function validate(product) {
		let errors = [];
		tenantConfiguration.attributeCaptureSteps.forEach((step) => {
			switch (step.type) {
				case "String":
					if (step.isRequired) {
						const id = identifiers.find((id) => id.key === step.name);
						if (id && (!product.identifiers || !product.identifiers[step.name])) {
							errors = { ...errors, [step.name]: true };
						} else if (step.name === "Name" && !product.name) {
							errors = { ...errors, [step.name]: true };
						} else if (step.name === "Description" && !product.description) {
							errors = { ...errors, [step.name]: true };
						}
					}
					if (step.maxLength && product[step.name]?.length > step.maxLength) {
						errors = { ...errors, [step.name]: true };
					}
					break;
				case "Dimensions":
					if (!isNumber(product?.dimensions?.length) || product?.dimensions?.length <= 0) {
						errors = { ...errors, length: true };
					}
					if (!isNumber(product?.dimensions?.width) || product?.dimensions?.length <= 0) {
						errors = { ...errors, width: true };
					}
					if (!isNumber(product?.dimensions?.height) || product?.dimensions?.length <= 0) {
						errors = { ...errors, height: true };
					}
					break;
				case "VoidFill":
					if (product.canContain) {
						if (product?.voidFill?.length <= 0 || product?.voidFill?.length >= product?.dimensions.length) {
							errors = { ...errors, voidFillLength: true };
						}
						if (product.voidFill?.width <= 0 || product.voidFill?.width >= product.dimensions.width) {
							errors = { ...errors, voidFillWidth: true };
						}
						if (product.voidFill?.height <= 0 || product.voidFill?.height >= product.dimensions.height) {
							errors = { ...errors, voidFillHeight: true };
						}
						break;
					}
					break;

				default:
					break;
			}
		});
		return errors;
	}

	function changeInputFocus() {
		const refToFocus = inputRefs.current[activeStep];
		if (refToFocus) {
			refToFocus.focus();
			refToFocus.select();
		}
	}

	function formCompletedCheck() {
		const foundError = steps.find((step) => {
			return step.status === "error";
		});

		return foundError;
	}

	async function saveProduct(product) {
		const newErrors = validate(product);
		if (Object.keys(newErrors).length) {
			setErrors(newErrors);

			setMessageBox({
				show: true,
				message: "Validation Errors Found Please Review All Steps",
				isError: true,
			});

			await sleep(5 * 1000);

			setMessageBox({ show: false });
			return;
		}
		setPageLoading(true);
		try {
			await postProduct(unflattenCustomAttributes(product, tenantConfiguration));

			setProduct(defaultProduct);
			setMessageBox({ show: true, message: "Product Saved", isError: false });
		} catch (err) {
			if (err?.response?.status === 409) {
				setErrors({ [err.response.data]: true });
				setMessageBox({
					show: true,
					message: `Duplicate Product Exists ${err.response.data}`,
					isError: true,
				});
			} else {
				setMessageBox({
					show: true,
					message: "Errors Found Please Review All Steps",
					isError: true,
				});
				console.error(err);
			}
			return;
		}

		setPageLoading(false);

		await sleep(500);
		setMessageBox({ show: false });
		onConfirm();
	}

	async function onNextClick(newStep) {
		setFormLoading(true);
		let error = false;
		let message;
		const step = steps[activeStep];
		switch (step.type) {
			case "String":
				if (step.isRequired || step.name === mainIdType) {
					const id = identifiers.find((id) => id.key === step.name);
					if (id && (!product.identifiers || !product.identifiers[step.name])) {
						error = true;
					} else if (step.name === "Name" && !product.name) {
						error = true;
					} else if (step.name === "Description" && !product.description) {
						error = true;
					}
					if (error) {
						message = `${step.name} is required.`;
					}
				}
				if (step.maxLength && product[step.name]?.length > step.maxLength) {
					error = true;
					message = `${step.name} cannot excede ${step.maxLength} characters.`;
				}
				break;
			case "Dimensions":
				if (!isNumber(product?.dimensions?.length) || product?.dimensions?.length <= 0) {
					error = true;
				}
				if (!isNumber(product?.dimensions?.width) || product?.dimensions?.width <= 0) {
					error = true;
				}
				if (!isNumber(product?.dimensions?.height) || product?.dimensions?.height <= 0) {
					error = true;
				}
				if (error) {
					message = `Dimensions are required and all must be greater than 0.`;
				}
				break;
			case "VoidFill":
				if (product.canContain) {
					if (product?.voidFill?.length <= 0 || product?.voidFill?.length >= product?.dimensions.length) {
						error = true;
					}
					if (product.voidFill?.width <= 0 || product.voidFill?.width >= product.dimensions.width) {
						error = true;
					}
					if (product.voidFill?.height <= 0 || product.voidFill?.height >= product.dimensions.height) {
						error = true;
					}
					if (error) {
						message = "Required when 'Yes' is selected";
					}
					break;
				}
				break;
			case "Nesting":
				if (product.canNest) {
					if (
						!product?.nesting ||
						!product?.nesting?.nestGrowthDimension1 ||
						!product?.nesting?.nestGrowthDimension2 ||
						!product?.nesting?.nestGrowthDimension3 ||
						!product?.nesting?.nestMax
					) {
						error = true;
					}
					if (
						product?.nesting?.nestGrowthDimension1 <= 0 ||
						product?.nesting?.nestGrowthDimension1 >= product?.dimensions.length
					) {
						error = true;
					}
					if (
						product?.nesting?.nestGrowthDimension2 <= 0 ||
						product?.nesting?.nestGrowthDimension2 >= product.dimensions.width
					) {
						error = true;
					}
					if (
						product?.nesting?.nestGrowthDimension3 <= 0 ||
						product?.nesting?.nestGrowthDimension3 >= product.dimensions.height
					) {
						error = true;
					}
					if (product?.nesting?.nestMax <= 0) {
						error = true;
					}
					if (error) {
						message = `Nesting dimensions and max number of products are required and all must be greater than 0.`;
					}
					break;
				}
				break;
			default:
				break;
		}
		setSteps(
			steps.map((s, index) => {
				if (index === activeStep) {
					let statusState = "incomplete";
					if (error) {
						statusState = "error";
					} else if (
						(product[s.name] !== undefined && product[s.name] !== "") ||
						s.type === "VoidFill" ||
						s.type === "Nesting" ||
						s.isRequired === true
					) {
						statusState = "complete";
					}
					return {
						...s,
						status: statusState,
						error: error ? { hasError: true, message } : { hasError: false, message: "" },
					};
				}
				return s;
			})
		);
		await setActiveStep(newStep);
		setFormLoading(false);
	}

	function onSkipClick() {
		setSteps(
			steps.map((s, index) => {
				if (index === activeStep) {
					return {
						...s,
						status: "incomplete",
						error: { hasError: false, message: "Skipped" },
					};
				}
				return s;
			})
		);
		setActiveStep(activeStep + 1);
	}

	function getFormComponent(step) {
		var set;

		switch (step.type) {
			case "String":
				set = (text) => {
					const id = identifiers.find((id) => id.key === step.name);

					if (id) {
						product.identifiers[step.name] = text;
					} else if (step.name === "Name") {
						product.name = text;
					} else if (step.name === "Description") {
						product.description = text;
					} else if (step.name === "Product Image") {
						product.productImage = text;
					} else {
						product[step.name] = text;
					}

					setProduct({ ...product });
				};
				let value;
				const id = identifiers.find((id) => id.key === step.name);
				if (id) {
					if (product.identifiers) value = product.identifiers[step.name];
				} else if (step.name === "Name") {
					value = product.name;
				} else if (step.name === "Description") {
					value = product.description;
				} else if (step.name === "Product Image") {
					value = product.productImage;
				} else {
					value = product[step.name];
				}
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.name === mainIdType ? step.prompt + "*" : step.prompt ?? step.name}
						example={step.exampleText}
						defaultPrompt={intl.formatMessage({ id: "Enter Text" })}
						callback={set}
						error={errors[step.name]}
						value={value}
						maxLength={step.maxLength}
						inputRefs={inputRefs}
						activeStep={activeStep}
					/>
				);
			case "Date":
				return <Date prompt={step.prompt} />;
			case "Dimensions":
				set = (state) => {
					setProduct({
						...product,
						dimensions: {
							length: state.length,
							width: state.width,
							height: state.height,
						},
					});
				};
				return (
					<Dimensions
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Product Dimensions" })}
						callback={set}
						errors={errors}
						value={product.dimensions}
						inputRefs={inputRefs}
						activeStep={activeStep}
					/>
				);
			case "Nesting":
				return <Nesting product={product} setProduct={setProduct} />;
			case "Number":
				set = (text) => {
					if (step.name === "Quantity") {
						product.quantity = text;
					} else {
						product[step.name] = text;
					}
					setProduct({ ...product });
				};
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt ?? step.name}
						defaultPrompt={intl.formatMessage({ id: "Number" })}
						example={step.exampleText}
						callback={set}
						value={step.name === "Quantity" ? product.quantity : product[step.name]}
					/>
				);
			case "Orientation":
				return <Orientation product={product} setProduct={setProduct} />;
			case "Select":
				set = (text) => {
					let newProduct = { ...product, [step.name]: text };
					setProduct(newProduct);
				};
				return <PacksizeSelect step={step} callback={set} value={product[step.name]} />;
			case "Bool":
				return <Bool prompt={step.prompt} />;
			case "VoidFill":
				return <DimensioningVoidFill product={product} setProduct={setProduct} errors={errors} />;
			case "Weight":
				return (
					<Weight
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Weight" })}
						product={product}
						setProduct={setProduct}
						example={step.exampleText}
						inputRefs={inputRefs}
						activeStep={activeStep}
					/>
				);

			default:
				return <span>{step.name}</span>;
		}
	}

	return (
		<Modal
			size="fullscreen"
			centered={true}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			className={classes.modal}
			open={open}
		>
			<div className={classes.container}>
				<Segment className={classes.header}>
					<Header as="h2" floated="left" className={classes.headerText}>
						<FormattedMessage id="DimAddNewProduct" />
					</Header>
					<Header floated="right" className={classes.closeButton}>
						<CloseButton
							onClick={() => {
								onClose();
								pageLoad();
							}}
						/>
					</Header>
					<Header floated="right" className={classes.saveButton}>
						<GlobalButton
							type="secondary"
							disabled={formCompletedCheck()}
							onClick={() => {
								saveProduct(product);
							}}
							text="Save"
						/>
					</Header>
					<Header floated="right">
						{messageBox.show &&
							(messageBox.isError ? (
								<Message error content={messageBox.message} />
							) : (
								<Message success content={messageBox.message} />
							))}
					</Header>
				</Segment>

				{pageLoading && dimCtx.isLoading ? (
					<Loading />
				) : loadingError ? (
					<FailedRetry retry={pageLoad} />
				) : (
					<div>
						{true && (
							<div className={classes.columnsHolder}>
								<div className={classes.stepsColumn}>
									<div className={classes.columnContentSteps}>
										{steps &&
											steps.map((step, index) => {
												return (
													<div className={index === 0 ? classes.firstStepGroup : classes.stepGroup}>
														<Step.Group fluid vertical className={classes.stepGroupVertical}>
															<Step
																active={activeStep === index}
																className={classes.step}
																onClick={() => onNextClick(index)}
															>
																<span className={activeStep === index ? classes.stepNumberBlue : classes.stepNumber}>
																	{index + 1}.
																</span>
																<Step.Content>
																	<Step.Title>
																		{step.name}
																		{step.isRequired || step.name === mainIdType ? "*" : ""}
																	</Step.Title>
																</Step.Content>
															</Step>
														</Step.Group>
														{step.status !== "incomplete" && (
															<div className={classes.stepStatus}>
																{step.status === "complete" && <Icon color="green" name="check" size="large" />}
																{step.status === "error" && <Icon color="red" name="warning" size="large" />}
															</div>
														)}
													</div>
												);
											})}
									</div>
								</div>
								<div className={steps[activeStep]?.hasImage ? classes.inputColumn : classes.inputColumnNoImage}>
									<h2 className={classes.listTitle}>{steps[activeStep]?.name}</h2>
									{!formLoading && (
										<div className={classes.columnContent}>
											<div className={classes.formHolder}>
												{steps[activeStep] && getFormComponent(steps[activeStep])}

												{
													// prettier-ignore
													steps[activeStep]?.error.hasError && (
																<div className={classes.errorText}>{steps[activeStep]?.error.message}</div>)
												}
											</div>
											<div className={classes.columnFooter}>
												{!steps[activeStep]?.isRequired && steps.length > activeStep + 1 && (
													<GlobalButton type="text" text="Skip" onClick={onSkipClick} className={classes.skipButton} />
												)}
												{steps.length > activeStep + 1 && (
													<GlobalButton
														type="primary"
														text="Next"
														onClick={() => onNextClick(activeStep + 1)}
														className={classes.footerButton}
													/>
												)}
											</div>
										</div>
									)}
								</div>
								{steps[activeStep]?.hasImage && (
									<div className={classes.imageColumn}>
										<h2 className={classes.listTitle}>
											<FormattedMessage id="DimReferenceImage"></FormattedMessage>
										</h2>
										<div className={classes.columnContent}>
											{!formLoading && (
												<div className={classes.imageHolder}>
													<Image src={`/PackNet/images/dim/${steps[activeStep]?.name}.svg`} centered size="medium" />
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
}

export default ProductCreate;
