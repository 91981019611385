import { Button, Divider, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import CheckboxButton from "@/components/checkbox-button";
import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import { UserContext } from "@/components/user-context";
import axios from "axios";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	roleCheck: {
		marginBottom: "10px !important",
	},
	permissionsTitle: {
		fontSize: "16px !important",
	},
}));

// These three permissions arrays have a default list in the controller that must match the values here.
export const allAdministratorPermissions = [
	{
		name: "accessConfigurationAudit",
		text: "Access Configuration Audit",
	},
	{
		name: "accessDashboard",
		text: "Access Dashboard",
		licensed: "pack-net-dashboard",
	},
	// {
	// 	name: "accessMachineHistory",
	// 	text: "Access Machine History",
	// 	licensed: "pack-net-machine-history",
	// },
	{
		name: "accessServiceModePage",
		text: "Access Service Mode Page",
	},
	{
		name: "manageDimensioningConfiguration",
		text: "Manage Dimensioning Configuration",
		licensed: "dim-allow",
	},
	{
		name: "manageDimensioningProducts",
		text: "Manage Dimensioning Products",
		licensed: "dim-allow",
	},
	{
		name: "managePackagingSolutions",
		text: "Manage Packaging Solutions",
		licensed: "pack-net-packaging-creation",
	},
	{
		name: "manageClassifications",
		text: "Manage Classifications",
		licensed: "pack-net-classifications",
	},
	{
		name: "manageCorrugates",
		text: "Manage z-Fold",
	},
	{
		name: "manageCPGs",
		text: "Manage Carton Property Groups",
		licensed: "pack-net-carton-property-groups",
	},
	{
		name: "manageDesigns",
		text: "Manage Designs",
	},
	{
		name: "manageEventNotifications",
		text: "Manage Event Notifications",
		licensed: "pack-net-event-notifications",
	},
	{
		name: "manageLabelTemplates",
		text: "Manage Label Templates",
		licensed: "pack-net-label-templates",
	},
	{
		name: "manageImportPipelines",
		text: "Manage Import Pipelines",
		licensed: "pack-net-import",
	},
	{
		name: "manageMachines",
		text: "Manage Machines",
	},
	{
		name: "manageMachineGroups",
		text: "Manage Machine Groups",
		licensed: "pack-net-machine-groups",
	},
	{
		name: "manageProductionGroups",
		text: "Manage Production Groups",
		licensed: "pack-net-production-groups",
	},
	{
		name: "ManagePrintImages",
		text: "Manage Print Images",
		licensed: "pack-net-image-templates",
	},

	{
		name: "managePickZones",
		text: "Manage Pick Zones",
		licensed: "pack-net-pick-zones",
	},
	{
		name: "managePlannedProduction",
		text: "Manage Planned Production",
		licensed: "pack-net-planned-production",
	},
	{
		name: "manageRoles",
		text: "Manage Roles",
	},
	{
		name: "manageSettings",
		text: "Manage Settings",
	},
	{
		name: "manageUsers",
		text: "Manage Users",
	},
];

export const allOperatorPermissions = [
	{
		name: "accessMachineControl",
		text: "Access Machine Control",
	},
	{
		name: "changeLoadedCorrugatesOnMachine",
		text: "Change Loaded Corrugates On Machine",
	},
	{
		name: "changeMachineGroupsProductionGroup",
		text: "Move Machine Groups",
	},
	{
		name: "manageJobActions",
		text: "Manage Job Actions",
	},
	{
		name: "modifyAndSendPackagingSolutionToQueue",
		text: "Modify Packaging Solution Before Producing",
		licensed: "pack-net-packaging-creation",
	},
	{
		name: "sendPackagingSolutionToQueue",
		text: "Produce Custom Jobs",
		licensed: "pack-net-packaging-creation",
	},
	{
		name: "createPackagingSolution",
		text: "Create Packaging Solution",
		licensed: "pack-net-packaging-creation",
	},
	{
		name: "createPrintSolution",
		text: "Create Packaging Solution With Print",
		licensed: "production-print-manager",
	},
];

export const allReportingPermissions = [
	{
		name: "accessTrimReport",
		text: "Access Trim",
		licensed: null,
	},
	{
		name: "accessZFoldUsageReport",
		text: "Access zFold Usage",
		licensed: null,
	},
	{
		name: "accessRawDataReport",
		text: "Access Raw Data",
		licensed: null,
	},
	{
		name: "accessCartonProductionReport",
		text: "Access Carton Production",
		licensed: null,
	},
	{
		name: "accessMachineErrorsReport",
		text: "Access Machine Errors and Availability",
		licensed: null,
	},
	{
		name: "accessProcurementReport",
		text: "Access Procurement",
		licensed: null,
	},
	{
		name: "AccessOverallEquipmentEffectiveness",
		text: "Access Overall Equipment Effectiveness",
		licensed: null,
	},
];

const NewRole = (props) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { closeModal } = props;
	const [errors, setErrors] = useState({});
	const [name, setName] = useState("");
	const [administratorPermissions, setAdministratorPermissions] = useState([]);
	const [reportingPermissions, setReportingPermissions] = useState([]);
	const [operatorPermissions, setOperatorPermissions] = useState([]);
	const { setList, edit } = useContext(ListContext);
	const { currentUser } = useContext(UserContext);

	const url = "/IdentityApi/api/v1/roles";
	const { get, add } = useAxios(url, token);
	const update = async (data, setCallBack) => {
		await axios.put(`${url}/${data.id}`, data, {
			headers: { Authorization: token },
		});
		get(setCallBack);
	};

	useEffect(() => {
		if (edit.permissions && edit.permissions.Administrator)
			setAdministratorPermissions([...edit.permissions.Administrator]);
		if (edit.permissions && edit.permissions.Operator) setOperatorPermissions([...edit.permissions.Operator]);
		if (edit.permissions && edit.permissions.Reporting) setReportingPermissions([...edit.permissions.Reporting]);
		if (edit.name) setName(edit.name);
	}, [edit]);

	const validate = () => {
		const newErrors = {};
		if (!name || name.length === 0) newErrors.name = intl.formatMessage({ id: "Name is required" });
		setErrors(newErrors);
		return newErrors;
	};

	const close = () => {
		closeModal();
	};

	const saveAndClose = async () => {
		var errors = validate();
		if (Object.keys(errors).length) return;

		try {
			edit.name = name;
			edit.tenantId = currentUser.Tenant;
			edit.permissions = {
				Administrator: administratorPermissions,
				Operator: operatorPermissions,
				Reporting: reportingPermissions,
			};
			if (edit.id) await update(edit, setList);
			else await add(edit, setList);

			close();
		} catch (e) {
			if (e.response && e.response.status === 409)
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage(
						{
							id: "Failed to save Role: An item already exists with the name {name}",
						},
						{ name: name }
					),
				});
			else
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage({ id: "Failed to save Role" }),
				});
		}
	};

	const checkChanged = (permissions, checked, key) => {
		if (!permissions) permissions = [];
		const lowerKey = key.toLowerCase();
		const lowerPermisions = permissions.map((p) => p.toLowerCase());
		if (checked) {
			if (!lowerPermisions.includes(lowerKey)) lowerPermisions.push(lowerKey);
		} else {
			const index = lowerPermisions.indexOf(lowerKey);
			if (index > -1) {
				lowerPermisions.splice(index, 1);
			}
		}
		return lowerPermisions;
	};

	const licensedOperatorPermissions = allOperatorPermissions.filter((t) => {
		if (t.licensed == null) return true;
		else return currentUser["ff-" + t.licensed] === "True";
	});

	const licensedAdministratorPermissions = allAdministratorPermissions.filter((t) => {
		if (t.licensed == null) return true;
		else return currentUser["ff-" + t.licensed] === "True";
	});

	const licensedReportingPermissions = allReportingPermissions.filter((t) => {
		if (t.licensed == null) return true;
		else return currentUser["ff-" + t.licensed] === "True";
	});

	return (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit Role" : "New Role"} />
				</Header>
				<Header floated="right" className={classes.closeButton}>
					<CloseButton onClick={close} />
				</Header>
				<Header floated="right" className={classes.saveButton}>
					<Button primary onClick={saveAndClose}>
						<FormattedMessage id="Save" />
					</Button>
				</Header>
			</Segment>
			<Segment className={classes.form}>
				<Form error>
					<Grid relaxed="very" stackable className={classes.topGrid}>
						<Grid.Row columns={1}>
							<Grid.Column>
								<Form.Input
									required
									label={intl.formatMessage({ id: "Name" })}
									placeholder={intl.formatMessage({ id: "Name" })}
									value={name}
									onChange={(e) => setName(e.target.value)}
									error={errors.name ? { content: errors.name } : null}
									icon
								>
									<input />
									{errors.name && (
										<i class="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={3}>
							<Grid.Column verticalAlign="top" width={5}>
								<Segment vertical>
									<Header floated="left" className={classes.selectAll}>
										<CheckboxButton
											className={classes.permissionsTitle}
											checked={licensedOperatorPermissions.every((v) =>
												operatorPermissions.some((p) => p.toLowerCase() === v.name.toLowerCase())
											)}
											onChange={(e) =>
												setOperatorPermissions(e.target.checked ? licensedOperatorPermissions.map((p) => p.name) : [])
											}
										>
											<FormattedMessage id="Operator Permissions" />
										</CheckboxButton>
									</Header>
									<div>&nbsp;</div>
									<Divider />
									{licensedOperatorPermissions.map((permission) => (
										<div key={permission.name}>
											<CheckboxButton
												checked={operatorPermissions.some((p) => p.toLowerCase() === permission.name.toLowerCase())}
												onChange={(e) => {
													const newOperatorPermissions = checkChanged(
														operatorPermissions,
														e.target.checked,
														permission.name
													);
													setOperatorPermissions([...newOperatorPermissions]);
												}}
												className={classes.roleCheck}
											>
												<FormattedMessage id={permission.text} />
											</CheckboxButton>
										</div>
									))}
								</Segment>
							</Grid.Column>
							<Grid.Column verticalAlign="top" width={5}>
								<Segment vertical>
									<Header floated="left" className={classes.selectAll}>
										<CheckboxButton
											className={classes.permissionsTitle}
											checked={licensedReportingPermissions.every((v) =>
												reportingPermissions.some((p) => p.toLowerCase() === v.name.toLowerCase())
											)}
											onChange={(e) =>
												setReportingPermissions(e.target.checked ? licensedReportingPermissions.map((p) => p.name) : [])
											}
										>
											<FormattedMessage id="Reporting Permissions" />
										</CheckboxButton>
									</Header>
									<div>&nbsp;</div>
									<Divider />
									{licensedReportingPermissions.map((permission) => (
										<div key={permission.name}>
											<CheckboxButton
												checked={reportingPermissions.some((p) => p.toLowerCase() === permission.name.toLowerCase())}
												onChange={(e) => {
													const newReportingPermissions = checkChanged(
														reportingPermissions,
														e.target.checked,
														permission.name
													);
													setReportingPermissions([...newReportingPermissions]);
												}}
												className={classes.roleCheck}
											>
												<FormattedMessage id={permission.text} />
											</CheckboxButton>
										</div>
									))}
								</Segment>
							</Grid.Column>
							<Grid.Column verticalAlign="top" width={6}>
								<Segment vertical>
									<Header floated="left" className={classes.selectAll}>
										<CheckboxButton
											className={classes.permissionsTitle}
											checked={licensedAdministratorPermissions.every((v) =>
												administratorPermissions.some((p) => p.toLowerCase() === v.name.toLowerCase())
											)}
											onChange={(e) =>
												setAdministratorPermissions(
													e.target.checked ? licensedAdministratorPermissions.map((p) => p.name) : []
												)
											}
										>
											<FormattedMessage id="Administrator Permissions" />
										</CheckboxButton>
									</Header>
									<div>&nbsp;</div>
									<Divider />
									{licensedAdministratorPermissions.map((permission) => (
										<div key={permission.name}>
											<CheckboxButton
												checked={administratorPermissions.some(
													(p) => p.toLowerCase() === permission.name.toLowerCase()
												)}
												onChange={(e) => {
													const newAdministratorPermissions = checkChanged(
														administratorPermissions,
														e.target.checked,
														permission.name
													);
													setAdministratorPermissions([...newAdministratorPermissions]);
												}}
												className={classes.roleCheck}
											>
												<FormattedMessage id={permission.text} />
											</CheckboxButton>
										</div>
									))}
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
				</Form>
			</Segment>
		</Segment.Group>
	);
};

export default NewRole;
