import React from "react";

import { createUseStyles, useTheme } from "react-jss";
import DualProgressBar from "./dual-progress-bar";

const useStyles = createUseStyles((theme) => ({
	heading: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingBottom: "8px",
		"& > span": {
			width: "50%",
		},
	},
	progressWrapper: {
		display: "flex",
		flex: "1",
		"& > div": {
			flex: "1",
		},
	},
	value: {
		display: "flex",
		justifyContent: "right",
		alignItems: "center",
		fontSize: "17px",
		fontStyle: "normal",
		fontFamily: "Stolzl",
		fontWeight: "bold",
		lineHeight: "normal",
		fontStretch: "normal",
		letterSpacing: "normal",
	},
}));

function DualProgressBarGroup({ failedPercent, successPercent, leftText, rightText, successNumber, failedNumber }) {
	const theme = useTheme();

	const classes = useStyles({ theme });
	return [
		<div className={classes.heading} key={`dual-progress-bar-group-${leftText}-heading`}>
			<span>{leftText}</span>
			<span className={classes.value}>{rightText}</span>
		</div>,
		<div className={classes.progressWrapper} key={`dual-progress-bar-group-${leftText}-wrapper`}>
			<DualProgressBar
				successPercent={successPercent}
				successColor={"#353430"}
				failedPercent={failedPercent}
				failedColor={"#ff005c"}
				successNumber={successNumber}
				failedNumber={failedNumber}
			/>
		</div>,
	];
}

export default DualProgressBarGroup;
