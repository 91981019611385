import { Icon, Input, Message, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { searchProduct } from "@/api/dim-product-api";
import { readLocalStorage, writeLocalStorage } from "@/api/local-storage";

import DeleteButton from "@/components/delete-button";
import EditButton from "@/components/edit-button";
import EmptySearchResults from "@/components/empty-seach-results";
import ProductCount from "./product-count";
import ProductId from "./product-id";
import { sleep } from "@/utils/sleep";
import { useIsRoleEnabled } from "@/components/user-context";
import DimDataContext from "../contexts/dimensioning-data-context";
import { ReactComponent as Close } from "@/assets/images/close.svg";
import GlobalButton from "@/components/global-button";

const SEARCH_BAR_HEIGHT = "32px";
const TABLE_HEADER_HEIGHT = "43px";
const LOCAL_STORAGE_SEARCH_TERM = "dimension-products-search-term";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		background: "#FCFDFD",
		paddingTop: "32px",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight} - ${SEARCH_BAR_HEIGHT})`,
	},
	searchBar: {
		margin: "0 32px !important",
		display: "flex",
		justifyContent: "space-between",
		height: SEARCH_BAR_HEIGHT,
	},
	searchInput: {
		width: "439px",
		marginRight: "24px !important",
	},
	searchClearIcon: {
		position: "absolute",
		right: "35px",
		top: "6px",
		cursor: "pointer",
	},
	productCount: {
		marginLeft: "24px",
		color: theme.colors.greyText,
	},
	messageInfo: {
		color: theme.colors.error,
		padding: "16px",
	},
	workArea: {
		display: "flex",
		flexDirection: "column",
		paddingTop: "32px",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight} - ${SEARCH_BAR_HEIGHT} - ${TABLE_HEADER_HEIGHT})`,
	},
	table: {
		margin: "unset !important",
		border: "none !important",
		borderRadius: "unset !important",
		backgroundColor: "#FCFDFD !important",
		width: "100vw !important",

		"& th": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
			fontWeight: "bold !important",
			width: "100vw",
			height: TABLE_HEADER_HEIGHT,
		},
	},
	tableBody: {
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	},
	headerButton: {
		margin: "0 !important",
		backgroundColor: `${theme.colors.white} !important`,
		color: "#0068ab !important",
	},
	tableRowFirstColumn: {
		paddingLeft: "32px !important",
	},
	tableRowLastColumn: {
		textAlign: "left !important",
		paddingRight: "32px !important",
	},
	actionBtnHeader: {
		textAlign: "left !important",
		paddingLeft: "35px !important",
	},
	tableCell: {
		maxWidth: "0",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontFamily: "Stolzl-Book",
	},
	actionButtons: {
		"& > .ui.basic.button": {
			boxShadow: "none !important",
		},
	},
	error: {
		margin: "32px",
	},
	warningLabel: {
		backgroundColor: "#ffebec !important",
	},
}));

function ProductSearch({ config, addNew, viewProduct, editItemHandler, showDelete, triggerClearSearch }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const searchEditRef = useRef();
	const [searchText, setSearchText] = useState("");
	const [searchTextDirty, setSearchTextDirty] = useState(false);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState(undefined);
	const [totalProducts, setTotalProducts] = useState(0);
	const [messageInfo, setMessageInfo] = useState();
	const hasManageProductsAccess = useIsRoleEnabled("managedimensioningproducts");
	const dimCtx = useContext(DimDataContext);

	function refocus() {
		if (!searchEditRef.current) {
			// Component has been unmounted
			return;
		}

		searchEditRef.current.focus();
		searchEditRef.current.select();
	}

	async function setErrorMessage(message) {
		setMessageInfo(message);
		await sleep(5 * 1000);
		setMessageInfo(undefined);
	}

	function clearSearchResults() {
		setSearchText("");
		setProducts(undefined);
		setTotalProducts(0);
		writeLocalStorage(LOCAL_STORAGE_SEARCH_TERM, "");
	}

	async function searchHandler(searchTerm) {
		if (searchTerm.length < 3) {
			return;
		}

		writeLocalStorage(LOCAL_STORAGE_SEARCH_TERM, searchTerm);
		setLoading(true);
		setProducts(undefined);
		setTotalProducts(0);
		setSearchTextDirty(false);

		let results;
		try {
			results = await searchProduct(searchTerm);
		} catch (err) {
			if (err?.isAxiosError && err.response.status === 404) {
				setLoading(false);
				return;
			}

			setErrorMessage(intl.formatMessage({ id: "SystemError" }));
		}
		if (results) {
			setProducts(results.products);
			setTotalProducts(results.totalCount);
		}
		setLoading(false);
		refocus();
	}

	async function onLoad() {
		dimCtx.fetchTotalProductsCount();
		const searchTerm = readLocalStorage(LOCAL_STORAGE_SEARCH_TERM) || "";
		setSearchText(searchTerm);
		if (searchTerm) {
			await searchHandler(searchTerm);
		}
	}

	useEffect(() => {
		onLoad();
	}, []);

	useEffect(() => {
		if (triggerClearSearch > 0) {
			clearSearchResults();
		}
	}, [triggerClearSearch]);

	async function scanKeyPressedHandler(e) {
		if (e.charCode !== 13 || searchText.length < 3) {
			return;
		}

		await searchHandler(searchText);
	}

	return (
		<div className={classes.container}>
			<div className={classes.searchBar}>
				<div id="search-left-align">
					<Input
						autoFocus
						type="text"
						placeholder={intl.formatMessage(
							{
								id: "SearchDimensioningProducts",
							},
							{ idType: config.mainIdentifierType }
						)}
						value={searchText}
						disabled={loading}
						onChange={(e, { value }) => {
							setSearchText(value);
							setSearchTextDirty(true);
						}}
						onKeyPress={scanKeyPressedHandler}
					>
						<input ref={searchEditRef} className={classes.searchInput} />
						<div onClick={() => clearSearchResults()} className={classes.searchClearIcon}>
							<Close />
						</div>
					</Input>
					<GlobalButton
						type={searchText.length >= 3 && !loading ? "primary" : "secondary"}
						primary={searchText.length >= 3 && !loading}
						disabled={
							searchText.length < 3 ||
							loading ||
							readLocalStorage(LOCAL_STORAGE_SEARCH_TERM)?.toLowerCase() === searchText.toLowerCase()
						}
						onClick={() => searchHandler(searchText)}
						loading={loading}
						text="Enter"
					/>
					<span className={classes.productCount}>
						<ProductCount products={products} totalProducts={totalProducts} />
					</span>
				</div>
			</div>
			<div className={classes.workArea}>
				{products && products.length > 0 && (
					<Fragment>
						<Table basic="very" className={classes.table}>
							<colgroup>
								{config.columnConfig.map((_, i) => (
									<col
										key={`col-${i}`}
										style={{
											width: `${100 / (config.columnConfig.length + 1)}%`,
										}}
									/>
								))}
							</colgroup>
							<Table.Header>
								<Table.Row className={classes.tableRow}>
									{config.columnConfig.map((col, i) => {
										const _class = i === 0 ? classes.tableRowFirstColumn : undefined;
										return (
											<Table.HeaderCell key={i} className={_class}>
												{col.name}
											</Table.HeaderCell>
										);
									})}
									{hasManageProductsAccess && (
										<Table.HeaderCell className={classes.actionBtnHeader}>Actions</Table.HeaderCell>
									)}
								</Table.Row>
							</Table.Header>
						</Table>
						<div className={classes.tableBody}>
							<Table basic="very" className={classes.table}>
								<colgroup>
									{config.columnConfig.map((_, i) => (
										<col
											key={`col-${i}`}
											style={{
												width: `${100 / (config.columnConfig.length + 1)}%`,
											}}
										/>
									))}
								</colgroup>
								<Table.Body>
									{products.map((product, rowNum) => (
										<Table.Row key={`product-row-${rowNum}`}>
											{config.columnConfig.map((col, i) => {
												const _class =
													i === 0 ? `${classes.tableCell} ${classes.tableRowFirstColumn}` : classes.tableCell;
												const text = col.selector(product) || "--";
												return (
													<Table.Cell
														key={i}
														className={_class}
														onClick={() => viewProduct(product.identifiers[config.mainIdentifierType.toLowerCase()])}
													>
														{i === 0 ? <ProductId text={text} searchText={searchText} /> : text}
													</Table.Cell>
												);
											})}

											<Table.Cell
												className={`${classes.tableCell} ${classes.tableRowLastColumn} ${classes.actionButtons}`}
											>
												{hasManageProductsAccess && (
													<>
														<EditButton
															onClick={() => {
																// prettier-ignore
																const id = product.identifiers[config.mainIdentifierType.toLowerCase()];
																editItemHandler(id);
															}}
														/>
														<DeleteButton
															onClick={() => {
																// prettier-ignore
																const id = product.identifiers[config.mainIdentifierType.toLowerCase()];
																showDelete(id);
															}}
														/>
													</>
												)}
											</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</div>
					</Fragment>
				)}
				{((products && products.length === 0) || !products) && !loading && dimCtx && dimCtx?.productsCount === 0 && (
					<EmptySearchResults
						icon={<Icon size="big" name="search" />}
						mainText={intl.formatMessage(
							{
								id: "DimEnterProductIdEmpty",
							},
							{ idType: config.mainIdentifierType }
						)}
						subText={<FormattedMessage id="DimFindOrAddAProduct" />}
					/>
				)}
				{((products && products.length === 0) || !products) &&
					!loading &&
					dimCtx &&
					dimCtx?.productsCount > 0 &&
					hasManageProductsAccess && (
						<>
							{searchText.length > 2 && !searchTextDirty ? (
								<>
									<EmptySearchResults
										icon={<Icon color="red" size="big" name="exclamation circle" />}
										mainText={intl.formatMessage(
											{
												id: "DimProductDoesNotExist",
											},
											{ id: searchText }
										)}
										subText={<FormattedMessage id="DimAddAProductQuestion" />}
										hasButton
										button={<GlobalButton type="primary" text="DimAddProduct" onClick={() => addNew(searchText)} />}
									/>
								</>
							) : (
								<>
									<EmptySearchResults
										icon={<Icon size="big" name="search" />}
										mainText={intl.formatMessage(
											{
												id: "DimEnterProductIdEmpty",
											},
											{ idType: config.mainIdentifierType }
										)}
										subText={<FormattedMessage id="DimFindOrAddAProduct" />}
									/>
								</>
							)}
						</>
					)}
				{((products && products.length === 0) || !products) &&
					!loading &&
					dimCtx &&
					dimCtx?.productsCount > 0 &&
					!hasManageProductsAccess && (
						<>
							{searchText.length > 2 && !searchTextDirty ? (
								<>
									<EmptySearchResults
										icon={<Icon size="big" name="search" />}
										mainText={<FormattedMessage id="DimNoResults" />}
										subText={<FormattedMessage id="DimNoResultsDetails" />}
									/>
								</>
							) : (
								<>
									<EmptySearchResults
										icon={<Icon size="big" name="search" />}
										mainText={intl.formatMessage(
											{
												id: "DimEnterProductId",
											},
											{ idType: config.mainIdentifierType }
										)}
										subText={<FormattedMessage id="DimFindAProduct" />}
									/>
								</>
							)}
						</>
					)}
				{messageInfo && (
					<div className={classes.messageInfo}>
						<Message negative>
							<Message.Header>Error</Message.Header>
							<p>{messageInfo}</p>
						</Message>
					</div>
				)}
			</div>
		</div>
	);
}

export default ProductSearch;
