import { useParams } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { Grid, Container, Select, Form, Segment, Input, Button, Image } from "semantic-ui-react";

import ProductNav from "@/views/cart/product/product-nav";
import mockVideoFeedSrc from "@/assets/images/mock-video-feed.png";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: `${theme.colors.light}`,
	},
	dimensioningContainer: {
		padding: "0 0 1em",
	},
	dimensioningFormContainer: {
		padding: "1em 1em 0",
	},
	videoFeed: {
		width: "100%",
	},
}));

const Product = () => {
	const { productId } = useParams();
	const theme = useTheme();
	const classes = useStyles({ theme });

	// tmp: mocked product
	const product = Object.freeze({ id: productId });

	return (
		<div className={classes.container}>
			<ProductNav product={product} />
			<Container text>
				<Grid padded>
					<Grid.Row>
						<Grid.Column>
							<Segment className={classes.dimensioningContainer}>
								<Image className={classes.videoFeed} src={mockVideoFeedSrc} />
								<Grid className={classes.dimensioningFormContainer}>
									<Grid.Row columns={4}>
										<Grid.Column>
											<Form>
												<Form.Field control={Input} label="Length" />
											</Form>
										</Grid.Column>
										<Grid.Column>
											<Form>
												<Form.Field control={Input} label="Width" />
											</Form>
										</Grid.Column>
										<Grid.Column>
											<Form>
												<Form.Field control={Input} label="Height" />
											</Form>
										</Grid.Column>
										<Grid.Column>
											<Form>
												<Form.Field control={Input} label="Weight" />
											</Form>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<Button basic primary fluid>
												CAPTURE
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form>
								<Segment>
									<Form.Field control={Input} label="UPC" placeholder="Enter UPC" value={product.id} />
								</Segment>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form>
								<Segment>
									<Form.Field control={Input} label="Item Number" placeholder="Enter Item Number" />
								</Segment>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form>
								<Segment>
									<Form.Field
										control={Select}
										label="Fragile"
										placeholder="Is this product fragile?"
										options={[
											{ text: "Yes", value: "yes" },
											{ text: "No", value: "no" },
										]}
									/>
								</Segment>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form>
								<Segment>
									<Form.Field
										control={Select}
										label="Nesting"
										placeholder="Can this product nest?"
										options={[
											{ text: "Yes", value: "yes" },
											{ text: "No", value: "no" },
										]}
									/>
								</Segment>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</div>
	);
};

export { Product as default };
