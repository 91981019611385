import { Button, Header, Menu, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { readLocalStorage, writeLocalStorage } from "@/api/local-storage";

import AttributesTab from "./attributes-tab";
import CustomAttributeModal from "./custom-attribute-modal";
import DimDataContext from "../contexts/dimensioning-data-context";
import CartPageContext from "../contexts/cart-page-context";
import Loading from "@/components/loading";
import SettingsTab from "./settings-tab";
import CartsTab from "./carts-tab";
import NewCartModal from "./new-cart-modal";

const LOCAL_STORAGE_ACTIVE_TAB = "dimension-configuration-active-tab";
const emptyCart = {
	name: "",
	description: "",
	ip: "",
	port: 0,
};

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
		height: `calc(100vh - ${theme.measurements.menuBarHeight})`,
	},
	header: {
		border: "0 !important",
		margin: "37px 45px !important",
		padding: "0 !important",
		height: `${theme.measurements.pageHeaderHeight}`,
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		boxShadow: "0 0 !important",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	headerLeft: {
		display: "flex",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
		margin: "0px 24px 0 0 !important",
	},
	headerMenu: {
		margin: "-5px 0 0 0 !important",
	},
	content: {
		backgroundColor: theme.light,
		padding: "0 45px !important",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
	},
	basicButton: {
		color: theme.primary,
		backgroundColor: `${theme.light} !important`,
		marginRight: "32px !important",
	},
}));

const ConfigurationPage = ({ currentUser }) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const cartCtx = useContext(CartPageContext);
	const [activeTab, setActiveTab] = useState("Attributes");
	const [modalOpen, setModalOpen] = useState(false);
	const [createCartModalOpen, setCreateCartModalOpen] = useState(false);
	const [newAttribute, setNewAttribute] = useState({});

	useEffect(() => {
		const activeTab = readLocalStorage(LOCAL_STORAGE_ACTIVE_TAB);
		setActiveTab(activeTab || "Attributes");
		cartCtx.setEditCart(emptyCart);
	}, []);

	function tabClickedHandler(link) {
		setActiveTab(link);
		writeLocalStorage(LOCAL_STORAGE_ACTIVE_TAB, link);
	}

	const tabs = [
		{
			link: "Attributes",
			text: intl.formatMessage({ id: "Attributes" }),
			licensed: "dim-allow",
		},
		{
			link: "Carts",
			text: intl.formatMessage({ id: "Carts" }),
			licensed: "pack-net-carts",
		},
		{
			link: "Settings",
			text: intl.formatMessage({ id: "Settings" }),
			licensed: "dim-allow",
		},
	];

	async function saveButtonClickHandler() {
		await dimCtx.saveCustomerConfiguration();
	}

	async function saveAndClose() {
		try {
			await cartCtx.setEditCart(cartCtx.editCart);
			await cartCtx.saveCart(cartCtx.editCart);
			await cartCtx.reload();
		} catch (err) {
			if (err?.isAxiosError && err.response.status === 404) {
				return;
			}
		}
		setCreateCartModalOpen(false);
		cartCtx.setEditCart(emptyCart);
	}

	return (
		<Fragment>
			<CustomAttributeModal
				open={modalOpen}
				onClose={() => {
					setNewAttribute({});
					setModalOpen(false);
				}}
				attribute={newAttribute}
				setAttribute={setNewAttribute}
			/>
			<NewCartModal
				open={createCartModalOpen}
				closeWithoutSaving={() => setCreateCartModalOpen(false)}
				saveAndClose={() => saveAndClose()}
				cart={cartCtx.editCart}
				setCart={cartCtx.setEditCart}
			/>
			{dimCtx.isLoading ? (
				<Loading />
			) : (
				<div className={classes.container}>
					<Segment className={classes.header}>
						<div className={classes.headerLeft}>
							<Header className={classes.headerText}>
								<FormattedMessage id="Configuration" />
							</Header>
							<Menu secondary className={classes.headerMenu}>
								{tabs.map((t) => {
									if (currentUser && currentUser["ff-" + t.licensed]) {
										return (
											<Menu.Item
												key={t.link}
												text={intl.formatMessage({ id: t.link })}
												active={activeTab === t.link}
												className={activeTab === t.link ? classes.activeSubItem : classes.subItem}
												onClick={() => tabClickedHandler(t.link)}
											>
												{intl.formatMessage({ id: t.link })}
											</Menu.Item>
										);
									}
									return;
								})}
							</Menu>
						</div>
						<div>
							{activeTab === "Attributes" && (
								<Button onClick={() => setModalOpen(true)} className={classes.basicButton}>
									<FormattedMessage id="CreateCustomAttribute" />
								</Button>
							)}
							{activeTab === "Carts" && (
								<Button primary onClick={() => setCreateCartModalOpen(true)}>
									<FormattedMessage id="AddNew" />
								</Button>
							)}
							{activeTab !== "Carts" && (
								<Button primary disabled={!dimCtx.customerConfiguration.hasChanges} onClick={saveButtonClickHandler}>
									{intl.formatMessage({ id: "Save" })}
								</Button>
							)}
						</div>
					</Segment>
					<div className={classes.content}>
						{activeTab === "Attributes" && <AttributesTab />}
						{activeTab === "Carts" && <CartsTab />}
						{activeTab === "Settings" && <SettingsTab />}
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default ConfigurationPage;
