import { Form, Input, Select } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";

import React from "react";
import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	container: {
		flexBasis: "25%",
		backgroundColor: "white",
		borderRadius: "8px",
		overflow: "auto",
		padding: "24px",
	},
	xvalues: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		"& .field": {
			width: "45%",
		},
	},
}));

export default function ScanOptionsCard({
	location,
	setLocation,
	locationOptions,
	brand,
	setBrand,
	brandOptions,
	designId,
	setDesignId,
	designOptions,
	xvaluesList,
	setXvalue,
	isLoadingLocationOptions,
	errors,
}) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();

	const selectOptions = locationOptions.map((option, index) => ({
		key: index,
		text: `${option.text} - ${index + 1}`,
		value: index,
	}));

	const selectedLocation = location?.packStation ? Number(location.packStation) - 1 : null;

	return (
		<div className={classes.container}>
			<Form error>
				{locationOptions?.length > 1 && (
					<Form.Select
						id="SelectMachineGroup"
						required
						className={classes.select}
						label={intl.formatMessage({ id: "Location" })}
						placeholder={intl.formatMessage({
							id: "Select Location",
						})}
						value={selectedLocation}
						options={selectOptions}
						onChange={(e, { value }) => {
							setLocation(locationOptions[Number(value)]);
						}}
						loading={isLoadingLocationOptions}
						error={errors.location}
					/>
				)}
				<Form.Field
					id="SelectBrand"
					className={classes.select}
					control={Select}
					label={intl.formatMessage({ id: "Brand" })}
					placeholder={intl.formatMessage({
						id: "Select",
					})}
					value={brand}
					onChange={setBrand}
					options={brandOptions}
					error={errors.brand}
				></Form.Field>
				<Form.Field
					id="SelectCartonDesign"
					className={classes.select}
					control={Select}
					label={intl.formatMessage({ id: "Carton Design" })}
					placeholder={intl.formatMessage({
						id: "Select",
					})}
					value={designId}
					onChange={setDesignId}
					options={designOptions}
					required
					error={errors.designId}
				/>
				<div className={classes.xvalues}>
					{xvaluesList.map((x, index) => (
						<Form.Field
							key={index}
							label={x.alias}
							control={Input}
							value={x.value}
							min={0}
							onChange={(e, { value }) => setXvalue(x.alias, value)}
						/>
					))}
				</div>
			</Form>
		</div>
	);
}
