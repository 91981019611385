import { EntityHistoryContext } from "@/contexts/entity-history-context";
import { useCallback, useMemo, useState } from "react";
import EntityHistoryModal from "@/components/entity-history";

const EntityHistoryProvider = ({ children }) => {
	const [entityHistoryModalProps, setEntityHistoryContext] = useState({});

	const showHistory = useCallback(({ entityType, entityId, ...args }) => {
		setEntityHistoryContext({
			...args,
			entityHistoryUrl: `${entityType}/${entityId}`,
		});
	}, []);

	const onCloseModal = useCallback(() => {
		setEntityHistoryContext({});
	}, []);

	const value = useMemo(() => {
		return { showHistory, closeHistory: onCloseModal };
	}, [onCloseModal, showHistory]);

	return (
		<EntityHistoryContext.Provider value={value}>
			{children}

			<EntityHistoryModal {...entityHistoryModalProps} onClose={onCloseModal} />
		</EntityHistoryContext.Provider>
	);
};

export default EntityHistoryProvider;
