import { lazy } from "react";
import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import { createUseStyles, useTheme } from "react-jss";
import { Outlet } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { LazyRoute } from "@/components/lazy-route";

const Dashboard = lazy(() =>
	import("./dashboard-views").then((m) => ({
		default: m.Dashboard,
	})),
);

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

export const getAuthorizedDashboardTabs = (currentUser, intl) => {
	const tabs = [
		{
			defaultMenuItem: true,
			parentLink: "dashboard",
			link: "dashboard",
			text: intl.formatMessage({ id: "Home" }),
			component: <LazyRoute Component={Dashboard} />,
			authorization: "AccessDashboard",
		},
		//{
		//   link: "machine-history",
		//   text: intl.formatMessage({ id: "Machine History" }),
		//   component: () => <div>Machine History</div>,
		//   authorization: "AccessMachineHistory",
		//   licensed: "pack-net-machine-history",
		// },
	];
	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const DashboardNav = ({ currentUser }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedDashboardTabs(currentUser, useIntl());

	return (
		<>
			<Segment className={classes.submenu}>
				<ScrollMenu currentUser={currentUser} list={tabs.map((tab) => ({ ...tab }))} />
			</Segment>
			<Outlet />
		</>
	);
};

export default DashboardNav;
