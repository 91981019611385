import { Icon, Table } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import EmptySearchResults from "@/components/empty-seach-results";
import Loading from "@/components/loading";
import ImportStatus from "./import-status";
import { getImportStatusHistory } from "@/api/dim-product-api";

const TABLE_HEADER_HEIGHT = "43px";
const PAGE_HEADER_HEIGHT = "32px";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		background: "#FCFDFD",
		paddingTop: "32px",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight} - ${PAGE_HEADER_HEIGHT})`,
	},
	messageInfo: {
		color: theme.colors.error,
		padding: "16px",
	},
	workArea: {
		display: "flex",
		flexDirection: "column",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight} - ${TABLE_HEADER_HEIGHT} - ${PAGE_HEADER_HEIGHT})`,
	},
	table: {
		margin: "unset !important",
		border: "none !important",
		borderRadius: "unset !important",
		backgroundColor: "#FCFDFD !important",
		width: "100vw !important",

		"& th": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
			fontWeight: "bold !important",
			width: "100vw",
			height: TABLE_HEADER_HEIGHT,
		},
		"& td": {
			align: "left",
			width: "100vw",
		},
	},
	tableBody: {
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	},
	headerButton: {
		margin: "0 !important",
		backgroundColor: `${theme.colors.white} !important`,
		color: "#0068ab !important",
	},
	tableRowFirstColumn: {
		paddingLeft: "32px !important",
	},
	tableRowLastColumn: {
		textAlign: "left !important",
		paddingRight: "32px !important",
	},
	actionBtnHeader: {
		textAlign: "left !important",
		paddingLeft: "35px !important",
	},
	tableCell: {
		maxWidth: "0",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontFamily: "Stolzl-Book",
		paddingLeft: "0",
	},
	actionButtons: {
		"& > .ui.basic.button": {
			boxShadow: "none !important",
		},
	},
	error: {
		margin: "32px",
	},
	warningLabel: {
		backgroundColor: "#ffebec !important",
	},
}));

function ImportHistory() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [imports, setImports] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);

	async function onLoad() {
		setIsLoading(true);
		try {
			const results = await getImportStatusHistory();
			if (results) {
				setImports(results);
			}
		} catch (e) {
			console.error(e);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		onLoad();
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.workArea}>
				{isLoading && <Loading />}
				{imports && imports.length > 0 && !isLoading && (
					<>
						<Table basic="very" className={classes.table}>
							<colgroup>
								<col
									key="fileName"
									style={{
										width: "30%",
									}}
								/>
								<col
									key="date"
									style={{
										width: "20%",
									}}
								/>
								<col
									key="status"
									style={{
										width: "20%",
									}}
								/>
								<col
									key="quantity"
									style={{
										width: "20%",
									}}
								/>
							</colgroup>
							<Table.Header>
								<Table.Row className={classes.tableRow}>
									<Table.HeaderCell key="fileNameCell" className={classes.tableRowFirstColumn}>
										<FormattedMessage id="DimFileName" />
									</Table.HeaderCell>
									<Table.HeaderCell key="dateCell">
										<FormattedMessage id="DimDate" />
									</Table.HeaderCell>
									<Table.HeaderCell key="statusCell">
										<FormattedMessage id="Status" />
									</Table.HeaderCell>
									<Table.HeaderCell key="quantityCell">
										<FormattedMessage id="DimProductQuantity" />
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
						</Table>
						<div className={classes.tableBody}>
							<Table basic="very" className={classes.table}>
								<colgroup>
									<col
										key="fileName"
										style={{
											width: "30%",
										}}
									/>
									<col
										key="date"
										style={{
											width: "20%",
										}}
									/>
									<col
										key="status"
										style={{
											width: "20%",
										}}
									/>
									<col
										key="quantity"
										style={{
											width: "20%",
										}}
									/>
								</colgroup>
								<Table.Body>
									{imports.map((item, rowNum) => (
										<Table.Row key={`product-row-${rowNum}`}>
											<Table.Cell key={`filename-${item.fileName}`} className={classes.tableRowFirstColumn}>
												{item.filename}
											</Table.Cell>
											<Table.Cell key={`date-${item.created}`}>
												{new Date(item?.created)?.toLocaleString() || "n/a"}
											</Table.Cell>
											<Table.Cell key={`status-${item.status}-${item.fileName}`}>
												<ImportStatus statusNumber={item.status} />
											</Table.Cell>
											<Table.Cell key={`quantity-${item.count}-${item.fileName}`}>
												{Intl.NumberFormat("en-us", {}).format(
													(item?.processingRecordNumber || 0) - (item?.errors?.length || 0)
												)}{" "}
												of {Intl.NumberFormat("en-us", {}).format(item.totalCount)}
											</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</div>
					</>
				)}
				{((imports && imports.length === 0) || !imports) && !isLoading && (
					<EmptySearchResults
						icon={<Icon size="big" name="search" />}
						mainText={<FormattedMessage id="DimNoImportHistoryFound" />}
						subText={<FormattedMessage id="DimImportProductsToCreateHistory" />}
					/>
				)}
			</div>
		</div>
	);
}

export default ImportHistory;
