import React from "react";
import { Icon, Popup } from "semantic-ui-react";

import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";

const useStyles = createUseStyles(() => ({
	baseBar: {
		borderRadius: "15px",
		height: "15px",
		position: "absolute",
	},
	bar: {
		composes: ["$baseBar"],
		borderBottomRightRadius: "2px",
		borderTopRightRadius: "2px",
	},
	container: {
		borderRadius: "15px",
		height: "15px",
		backgroundColor: "#E6E6E6; !important",
		flexGrow: "1",
		zIndex: "2",
		position: "relative",
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
	progress: {
		display: "flex",
		flexDirection: "row",
		gap: "16px",
		zIndex: "3",
		"& > span": {
			textAlign: "right",
			width: "70px",
			height: "23px",
			fontWeight: "bold",
			fontFamily: "Stolzl-Medium",
			fontSize: "19px",
			fontStretch: "normal",
		},
	},
	completedCircle: {
		backgroundColor: "#353430",
		marginRight: "8px",
	},
	failedCircle: {
		backgroundColor: "#ff005c",
		maringRight: "8px",
	},
	numberPadding: {
		marginLeft: "16px",
	},
	divider: {
		marginTop: "16px",
	},
	popup: {
		border: "2px solid #353430 !important",
		"&:before": {
			borderBottom: "2px solid #353430",
			borderRight: "2px solid #353430",
			borderBottomRightRadius: "2px",
			bottom: "-0.4em !important",
		},
	},
}));

function DualProgressBar({ successPercent, successColor, failedColor, failedPercent, successNumber, failedNumber }) {
	const classes = useStyles();
	const intl = useIntl();
	const threshold = 1.5;
	const originalFailedPercent = Math.abs(failedPercent - successPercent);
	if (successNumber > 0 && successPercent < threshold) {
		successPercent = threshold;
	}
	if (failedNumber > 0 && originalFailedPercent < threshold) {
		failedPercent = threshold + successPercent;
	}
	const successBarStyle = {
		backgroundColor: successColor,
		width: `${successPercent}%`,
	};
	const failedBarStyle = {
		backgroundColor: failedColor,
		width: `${failedPercent}%`,
	};
	const barContainer = {
		width: `${failedPercent}%`,
	};

	return (
		<div className={classes.progress}>
			<div className={classes.container}>
				<Popup
					className={classes.popup}
					content={
						<>
							<div>
								<Icon circular className={classes.completedCircle} size="mini" />
								{intl.formatMessage({ id: "Complete" })}
							</div>
							<div className={classes.numberPadding}>{successNumber.toLocaleString(undefined)}</div>
							<div className={classes.divider} />
							<div>
								<Icon circular className={classes.failedCircle} size="mini" />
								{intl.formatMessage({ id: "Broken" })}
							</div>
							<div className={classes.numberPadding}>{failedNumber.toLocaleString(undefined)}</div>
						</>
					}
					trigger={
						<div style={barContainer}>
							<div className={classes.baseBar} style={failedBarStyle}>
								&nbsp;
							</div>
							<div className={failedNumber > 0 ? classes.bar : classes.baseBar} style={successBarStyle}>
								&nbsp;
							</div>
						</div>
					}
					position="top center"
					hideOnScroll
				/>
			</div>
		</div>
	);
}

export default DualProgressBar;
