import axios from "axios";
import { readLocalStorage } from "./local-storage";

export async function getX7Machines(setCallBack, errorCallback) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.get(`/x7api/api/v1/machines`, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});
		const data = await response.data;
		const x7Machines = data;
		if (setCallBack) setCallBack(x7Machines);
		return x7Machines;
	} catch (err) {
		if (errorCallback) errorCallback(err);
		else throw err;
	}
}

export async function createX7Machine(data, setCallBack, errorCallBack) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.post(`/x7api/api/v1/machines`, data, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});

		await response.data;

		getX7Machines(setCallBack, errorCallBack);
	} catch (err) {
		if (errorCallBack) errorCallBack(err);
		else throw err;
	}
}

export async function updateX7Machine(data, setCallBack, errorCallBack) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.put(`/x7api/api/v1/machines/${data.id}`, data, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});

		const responseData = await response.data;

		getX7Machines(setCallBack, errorCallBack);

		return responseData;
	} catch (err) {
		if (errorCallBack) errorCallBack(err);
		else throw err;
	}
}

export async function deleteX7Machine(machineId, callback, errorCallback) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.delete(`/x7api/api/v1/machines/${machineId}`, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});

		const responseData = await response.data;
		if (callback) callback(responseData);
		return responseData;
	} catch (err) {
		if (errorCallback) errorCallback(err);
		else throw err;
	}
}
