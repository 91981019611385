import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import TextValueList from "@/views/reporting/text-value-list";

function Performance({ reportData, dateRange, color }) {
	const [percentageData, setPercentageData] = useState([]);
	const [detailData, setDetailData] = useState([]);

	function mapData(data) {
		const aggregation = Object.entries(data)
			.filter(([key]) => key !== "score")
			.map(([key, value]) => ({
				headerText: key,
				value: value,
			}));

		setDetailData({ items: aggregation });
		setPercentageData({ percentage: data.score * 100 });
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	return percentageData && !isNaN(percentageData.percentage) ? (
		<div>
			<div>
				<Chart
					options={{
						plotOptions: {
							radialBar: {
								inverseOrder: true,
								hollow: {
									size: "60%",
								},
								track: {
									background: "#e3e7e9",
								},
								dataLabels: {
									showOn: "always",
									value: {
										color: "#353430",
										width: "114px",
										height: "62px",
										flexGrow: "0",
										fontFamily: "Stolzl",
										fontSize: "51px",
										fontWeight: "bold",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										offsetY: 0,
										show: true,
									},
								},
								offsetX: 0,
							},
						},
						stroke: {
							lineCap: "round",
							colors: ["red"],
						},
						fill: {
							colors: [color],
							opacity: 1,
							type: "solid",
						},
						labels: [""],
						states: {
							hover: {
								filter: {
									type: "none",
								},
							},
							active: {
								filter: {
									type: "none",
								},
							},
						},
					}}
					series={[percentageData?.percentage.toLocaleString(undefined, { maximumFractionDigits: 1 })]}
					type="radialBar"
					height={350}
				/>
			</div>
			<div>
				<TextValueList items={detailData.items} />
			</div>
		</div>
	) : (
		<div></div>
	);
}

export default Performance;
