export const getScanOptionsRequest = (scanOptions, productionCtx, xValues, scannedProducts, dimCtx, scanCtx) => {
	const packStation = scanOptions.location?.packStation ? scanOptions.location.packStation : null;

	var location = {
		packStation,
		machine: scanOptions.location.machine,
		machineGroup: productionCtx.currentMachineGroup?.alias || "",
		productionGroup: productionCtx.currentProductionGroup?.alias || "",
		pickZone: null,
	};

	const scanRequest = {
		location,
		brand: String(scanOptions.brand),
		cartonDesign: String(scanOptions.designId),
		xValues,
		products: scannedProducts.map((product) => ({
			quantity: product.quantity,
			content: product.product,
		})),
	};

	if (dimCtx.scanJobTitleRequired) {
		scanRequest.alias = scanCtx.jobTitle;
	}

	if (dimCtx.scanLicensePlateNumberRequired) {
		scanRequest.licensePlateNumber = scanCtx.licensePlateNumber;
	}

	return scanRequest;
};
