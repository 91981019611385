import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "semantic-ui-react";
import { createUseStyles } from "react-jss";

import { getDateIndex, getStartOfWeekDate } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		overflow: "scroll",
		maxHeight: "376px",
		marginBottom: "20px",
		width: "calc(97vw - 90px)",
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	headerCellBase: {
		backgroundColor: "#2e475f !important",
		color: "white !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		borderLeft: "1px solid #c4c4c4 !important",
		minWidth: "150px",
		height: "80px",
	},
	headerCell: {
		composes: "$headerCellBase",
		fontWeight: "500 !important",
	},
	headerCellTotal: {
		composes: "$headerCellBase",
		fontWeight: "800 !important",
	},
	zfoldCell: {
		composes: "$headerCellBase",
		"& >span:first-child": {
			fontWeight: "800 !important",
		},
		"& >span:last-child": {
			fontWeight: "500 !important",
		},
	},
	contentRow: {
		color: "#353430 !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		"& > td": {
			border: "1px solid #c4c4c4 !important",
		},
	},
	evenRow: {
		composes: "$contentRow",
		backgroundColor: "#FFF !important",
	},
	oddRow: {
		composes: "$contentRow",
		backgroundColor: "rgba(0, 0, 50, 0.02) !important",
	},
	totalCell: {
		composes: "$contentRow",
		backgroundColor: "#EAECEE !important",
		fontStyle: "bold !important",
		fontWeight: "800 !important",
	},
	tableHeader: {
		position: "sticky",
		top: 0,
	},
}));

function ZFoldUsageOverTimeTable({ reportData, dateRange, isMetric }) {
	const classes = useStyles();
	const [aggregation, setAggregation] = useState([]);
	const [headers, setHeaders] = useState([]);

	const unitLabel = isMetric ? "sq meters" : "sq ft";

	function mapData(data) {
		const dateIndex = getDateIndex(incrementDateRange(dateRange));
		const aggregation = data.overTime;

		let key = "";
		switch (dateIndex) {
			case "Hours":
				key = "hour";
				break;
			case "Days":
				key = "day";
				break;
			case "Weeks":
				key = "week";
				break;
			default:
				break;
		}

		setHeaders(aggregation[0].data.map((a) => getDateColumn(key, a)));
		setAggregation(aggregation);
	}

	function getDateColumn(dateKey, item) {
		switch (dateKey) {
			case "hour":
				const temp = new Date(item.year, item.month - 1, item.day, item.hour).toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				});
				return temp;
			case "day":
				return new Date(item.year, item.month - 1, item.day).toLocaleDateString();
			case "week":
				return getStartOfWeekDate(item.week, item.year);
			default:
				return "";
		}
	}

	function getRow(corrugate) {
		const total = corrugate.data.reduce((accumulator, obj) => {
			return (accumulator += obj.value);
		}, 0);

		const alias = corrugate.corrugateAlias;
		const columns = [
			<Table.Cell className={classes.zfoldCell} key={`over-time-table-body-${corrugate}-name`}>
				<span>{alias}</span>
				<br />
				<span>
					{corrugate.corrugateBrand && corrugate.corrugateFlute
						? `${corrugate.corrugateBrand} - ${corrugate.corrugateFlute}`
						: corrugate.corrugateBrand
						? corrugate.corrugateBrand
						: corrugate.corrugateFlute
						? corrugate.corrugateFlute
						: ""}
				</span>
			</Table.Cell>,
		];
		columns.push(
			...corrugate.data.map((d, i) => {
				return (
					<Table.Cell textAlign="right" key={`over-time-table-body-${corrugate}-${i}`}>
						{d.value.toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</Table.Cell>
				);
			})
		);
		columns.push(
			<Table.Cell textAlign="right" className={classes.totalCell} key={`over-time-table-body-${corrugate}-total`}>
				{total.toLocaleString(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})}
			</Table.Cell>
		);
		return columns;
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let coloredRow = false;
	return aggregation.length ? (
		<div className={classes.container}>
			<Table singleLine={true}>
				<Table.Header className={classes.tableHeader}>
					<Table.Row>
						<Table.HeaderCell className={classes.headerCell} key="over-time-table-header-z-fold">
							<FormattedMessage id="z-Fold" />
						</Table.HeaderCell>
						{headers.map((h) => {
							return (
								<Table.HeaderCell textAlign="right" className={classes.headerCell} key={`over-time-table-header-${h}`}>
									{h}
								</Table.HeaderCell>
							);
						})}
						<Table.HeaderCell className={classes.headerCellTotal} key="over-time-table-header-total">
							<FormattedMessage id="Grand Total" />
							<br />(<FormattedMessage id={unitLabel} />)
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{aggregation.map((corrugate, index) => {
						coloredRow = !coloredRow;
						return (
							<Table.Row
								className={coloredRow ? classes.evenRow : classes.oddRow}
								key={`over-time-table-body-row-${index}`}
							>
								{getRow(corrugate)}
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</div>
	) : (
		<div></div>
	);
}

export default ZFoldUsageOverTimeTable;
