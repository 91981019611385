const data = {
	DesignApi: "/PackagingDesignApi/api/v1.0/packagingdesigns",
	EmApi: "/emMachineApi/api/v1/machines",
	FusionApi: "/fusionapi/api",
	PackingStationApi: "/manualpackingapi/api",
	X4Api: "/x4Api/api/v1",
	ZebraApi: "/zebraPrinterMachineApi/api/v1/Machines",
};

export default data;
