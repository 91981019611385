import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import { createUseStyles, useTheme } from "react-jss";

import Collect from "@/views/dimensioning/collect";
import ConfigurationPage from "@/views/dimensioning/configuration/configuration-page";
import { DimDataProvider } from "@/views/dimensioning/contexts/dimensioning-data-context";
import { CartPageProvider } from "@/views/dimensioning/contexts/cart-page-context";
import { Outlet } from "react-router-dom";
import Products from "@/views/dimensioning/products";
import Import from "@/views/dimensioning/import";
import React from "react";
import ScanPage from "@/views/dimensioning/scan/scan-page";
import { ScanPageProvider } from "@/views/dimensioning/contexts/scan-page-context";
import { ScanPageStatusProvider } from "@/views/dimensioning/contexts/scan-page-status-context";
import { Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import RequireCurrentMachineGroup from "@/views/production/require-current-machine-group";

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

export const getAuthorizedDimensioningTabs = (currentUser, intl) => {
	const hasScan = currentUser && currentUser["ff-dim-allow"];
	const tabs = [
		{
			defaultMenuItem: hasScan,
			parentLink: "dimensioning",
			link: "scan",
			text: intl.formatMessage({ id: "Scan to Produce" }),
			component: (
				<RequireCurrentMachineGroup>
					<ScanPage currentUser={currentUser} />
				</RequireCurrentMachineGroup>
			),
			licensed: "dim-allow",
			released: true,
		},
		{
			link: "collect",
			text: intl.formatMessage({ id: "Collect Data" }),
			component: <Collect />,
			licensed: "dim-allow",
			released: false,
		},
		{
			defaultMenuItem: !hasScan,
			parentLink: "dimensioning",
			link: "products",
			text: intl.formatMessage({ id: "Products" }),
			component: <Products currentUser={currentUser} />,
			licensed: "dim-allow",
			released: true,
		},
		{
			parentLink: "dimensioning",
			link: "import",
			text: intl.formatMessage({ id: "Import" }),
			component: <Import currentUser={currentUser} />,
			licensed: "dim-allow",
			released: true,
		},
		{
			link: "dim-config",
			text: intl.formatMessage({ id: "Configuration" }),
			component: <ConfigurationPage currentUser={currentUser} />,
			licensed: "dim-allow",
			authorization: "manageDimensioningConfiguration",
			released: true,
		},
	];

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const DimensioningRoutes = ({ currentUser }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedDimensioningTabs(currentUser, useIntl());

	// const redirectLink =
	// 	currentUser && currentUser["ff-pack-net-production"] === "True" ? "/dimensioning/products" : "/dimensioning/scan";

	return (
		// 	<Segment className={classes.submenu}>
		// 		<ScrollMenu
		// 			currentUser={currentUser}
		// 			list={tabs.map((tab) => ({
		// 				link: tab.link,
		// 				text: tab.text,
		// 			}))}
		// 		/>
		// 	</Segment>
		// 	<Outlet />

		<DimDataProvider>
			<CartPageProvider>
				<ScanPageProvider>
					<ScanPageStatusProvider>
						<Segment className={classes.submenu}>
							<ScrollMenu currentUser={currentUser} list={tabs.filter((tab) => tab.released)} />
						</Segment>
						<Outlet />
						{/* <Routes>
							{tabs.map((tab) => (
								<AuthorizedRoute key={tab.link} path={tab.link} currentUser={currentUser} component={tab.component} />
							))}
							<Route path="/dimensioning/collect-wizard">
								<CollectWizard />
							</Route>

							<Route>
								<Navigate to={redirectLink} />
							</Route>
						</Routes> */}
					</ScanPageStatusProvider>
				</ScanPageProvider>
			</CartPageProvider>
		</DimDataProvider>
	);
};

export default DimensioningRoutes;
