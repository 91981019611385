import React from "react";

import OEEOverTimeGraph from "./oee-over-time-graph";
import OEEOverTimeTable from "./oee-over-time-table";

function OEEOverTime({ showGraph, dateRange, reportData, order }) {
	return (
		<>
			{showGraph ? (
				<OEEOverTimeGraph reportData={reportData} dateRange={dateRange} order={order} />
			) : (
				<OEEOverTimeTable reportData={reportData} dateRange={dateRange} order={order} />
			)}
		</>
	);
}

export default OEEOverTime;
