import React, { Fragment, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Select } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
	},
	mainInput: {
		width: "400px !important",
	},
}));

const DimSelect = ({ step, callback, value, showName = true }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [formValue, setFormValue] = useState(value ?? "");

	return (
		<Fragment>
			{showName && <div className={classes.prompt}>{step.name}</div>}
			<Select
				placeholder={step.prompt ?? step.name}
				className={classes.mainInput}
				value={formValue ?? ""}
				options={step.options.map((op) => {
					return {
						key: op,
						value: op,
						text: op,
					};
				})}
				onChange={(e, { value }) => {
					if (value) {
						setFormValue(value);
						if (callback) callback(value);
					}
				}}
			/>
		</Fragment>
	);
};

export default DimSelect;
