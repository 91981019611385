import React from "react";
import { Icon } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

const useStyles = createUseStyles((theme) => ({
	...theme.typography,
	base: {
		textTransform: "capitalize !important",
	},
	importing: {
		color: theme.colors.primary,
	},
	complete: {
		color: theme.colors.success,
	},
	failed: {
		color: theme.colors.error,
	},
	incomplete: {
		color: theme.colors.warning,
	},
}));
/*
Status Numbers
 0 - Importing
 1 - Complete
 2 - Failed
 3 - Incomplete
 */
export default function ImportStatus({ statusNumber = 0 }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	let typeClass = classes.base;
	let icon = "circle notch";
	let text = "importing";
	switch (statusNumber) {
		case 0:
			typeClass = classes.importing;
			break;
		case 1:
			typeClass = classes.complete;
			icon = "check";
			text = "complete";
			break;
		case 2:
			typeClass = classes.failed;
			icon = "warning";
			text = "failed";
			break;
		default:
			typeClass = classes.incomplete;
			icon = "warning sign";
			text = "incomplete";
			break;
	}
	return (
		<div className={clsx(classes.base, typeClass)}>
			<span>
				<Icon loading={statusNumber === 0} name={icon} />
				<FormattedMessage id={text} />
			</span>
		</div>
	);
}
