import packNetAxios from "../utils/axios";

export const scanImport = async (machineGroup, barcode) => {
	await packNetAxios.post("/importApi/api/v1/scan/" + machineGroup, barcode, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};

export async function getImportPipelineExportData() {
	const response = await packNetAxios.get("/importApi/api/v1/ImportExport");
	return response.data;
}

export async function upgradeLegacyPipeline(data) {
	const response = await packNetAxios.post("/importApi/api/v1/UpgradeLegacyPipeline", data);
	return response.data;
}

export async function postImportPipeline(data) {
	const response = await packNetAxios.post("/importApi/api/v1/ImportExport", data);
	return response.data;
}

export const postImportData = async (data) => {
	const response = await packNetAxios.post("/importApi/api/v1/importData", data);
	return response.data;
};

export const downloadImportClient = async () => {
	const response = await packNetAxios.get("/importApi/api/v1/importClient");

	const link = document.createElement("a");
	link.href = response.data.downloadUri;
	try {
		document.body.appendChild(link);
		link.click();
	} finally {
		document.body.removeChild(link);
	}
};
