import packNetAxios from "../utils/axios";
import axios from "axios";
async function handle(callback) {
	let result;

	try {
		result = formatApiReturn(await callback());
	} catch (e) {
		let response = {
			...e,
			status: e.status ?? e.code,
		};
		result = formatApiReturn(response);
	}

	return result;
}

function formatApiReturn(response) {
	let result = {};
	switch (response.status) {
		case 200: {
			result.success = true;
			result.responseData = response.data;
			break;
		}
		case 403: {
			result.success = false;
			result.friendlyErrorMessage = "Access denied. You do not have the correct permissions.";
			result.responseData = response.data;
			break;
		}
		default: {
			result.success = false;
			result.friendlyErrorMessage = "Something went wrong, try again in a few";
			result.responseData = response.data;
		}
	}
	return result;
}

export async function getUserDashboardApi(token) {
	let url = `/IdentityApi/api/v1/Dashboard`;
	const response = await axios.get(url, {
		headers: { Authorization: token },
	});
	if (response.status === 200) {
		return response.data;
	}
}

export async function IsUserSsoEnabled(username) {
	let url = `/IdentityApi/api/v1/oauth/token/tenantSsoUrl/byUser/${username}`;
	return await handle(async () => await packNetAxios.get(url));
}

export async function GetUserSsoInfo(email) {
	let url = `/IdentityApi/api/v1/GetSsoInfo`;
	return await handle(
		async () =>
			await packNetAxios.post(url, {
				email,
			})
	);
}

export async function saveDashboardLayoutApi(layout, token) {
	let url = `/IdentityApi/api/v1/Dashboard/layouts`;
	const response = await packNetAxios.put(url, layout);
	return response;
}

export async function saveSelectedWidgetsApi(selectedWidgets, token) {
	let url = `/IdentityApi/api/v1/Dashboard/selectedWidgets`;
	const response = await packNetAxios.put(url, selectedWidgets);
	return response;
}

export async function validateEnterpriseSsoToken(token) {
	let url = "/IdentityApi/api/v1/oauth/token/validateEntraToken";
	return await handle(
		async () =>
			await packNetAxios.post(url, {
				EntraToken: token,
			})
	);
}

export async function requestToken(username, password) {
	return await handle(
		async () =>
			await packNetAxios.post("/IdentityApi/api/v1/oauth/token/acquire", {
				username: username,
				password: password,
			})
	);
}

export async function GetAdditionalTenants(dto) {
	const url = "/IdentityApi/api/v1/users/GetAdditionalTenants";
	return await handle(async () => await packNetAxios.post(url, dto));
}

export async function getSettings() {
	const url = "/identityApi/api/v1/Settings";
	return await handle(async () => await packNetAxios.get(url));
}

export async function updateSettings(dto) {
	const url = `/identityApi/api/v1/Settings/${dto.id}`;
	return await handle(async () => await packNetAxios.put(url, dto));
}

export async function getTenantTimezone() {
	const url = `/identityApi/api/v1/tenants/timeZoneOffset`;
	return await handle(async () => await packNetAxios.get(url));
}

export async function createApimSubscription(dto) {
	const url = `/identityApi/api/v1/tenants/AddApimExternalAccessToTenant`;
	return await handle(async () => await packNetAxios.post(url, dto));
}

export async function getApimSubscriptions(tenantId) {
	const url = `/identityApi/api/v1/tenants/GetApimSubscriptionsByTenant/${tenantId}`;
	return await handle(async () => await packNetAxios.get(url));
}

export async function removeApimSubscription(dto) {
	const url = `/identityApi/api/v1/tenants/DeleteApimSubscription`;
	return await handle(async () => await packNetAxios.post(url, dto));
}

export async function initiatePasswordReset(passwordResetDto) {
	const url = `/identityApi/api/v1/users/InitiatePasswordReset`;
	return await handle(async () => await axios.post(url, passwordResetDto));
}

export async function validatePasswordResetOtp(otpDto) {
	const url = `/identityApi/api/v1/users/ValidatePasswordResetOtp`;
	return await handle(async () => await axios.post(url, otpDto));
}

export async function forgotPasswordReset(resetPasswordDto) {
	const url = `/identityApi/api/v1/users/ForgotPasswordReset`;
	return await handle(async () => await packNetAxios.post(url, resetPasswordDto));
}

export async function getLicensingInfo(tenantId) {
	const url = `/identityApi/api/v1/tenants/GetLicensing/${tenantId}`;
	return await handle(async () => await packNetAxios.get(url));
}
