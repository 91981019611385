import { Button, Segment } from "semantic-ui-react";
import CheckboxButton from "@/components/checkbox-button";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	checkboxSegment: {
		padding: "0 !important",
		margin: "1px !important",
		marginRight: "24px !important",
		"& .ui.button:disabled": {
			boxShadow: "0px 0px 0px 1px #e3e7e9 inset !important",
			opacity: ".3 !important",
		},
	},
	upDownButton: {
		backgroundColor: "#ffffff !important",
		boxShadow: "0px 0px 0px 1px #e3e7e9 inset !important",
		marginTop: "-40px !important",
	},
}));

const moveArrayItem = (arr, index, up) => arr.splice(up ? index + 1 : index - 1, 0, arr.splice(index, 1)[0]);

function OrderedCheckbox({ pipeline, category, name, label, conflictsWith, updatePipeline, handleClick }) {
	const classes = useStyles();
	if (!pipeline) return <></>;

	const names = pipeline[category]?.map((i) => i.name) ?? [];
	const index = names.indexOf(name);
	const stepConfigured = names.some((step) => step === name);
	const hasConflict = !stepConfigured && conflictsWith && names.some((name) => conflictsWith.includes(name));

	return (
		<Segment basic className={classes.checkboxSegment}>
			<CheckboxButton
				floated="left"
				defaultChecked={!pipeline ? false : stepConfigured}
				disabled={hasConflict}
				onClick={handleClick}
				style={{ width: "fit-content" }}
			>
				<FormattedMessage id={label} />
			</CheckboxButton>
			{stepConfigured && (
				<>
					<Button
						disabled={index === names.length - 1}
						icon="chevron down"
						floated="right"
						className={classes.upDownButton}
						onClick={() => {
							moveArrayItem(pipeline[category], index, true);
							updatePipeline(pipeline);
						}}
					/>
					<Button
						disabled={index <= 0}
						icon="chevron up"
						floated="right"
						className={classes.upDownButton}
						onClick={() => {
							moveArrayItem(pipeline[category], index, false);
							updatePipeline(pipeline);
						}}
					/>
				</>
			)}
		</Segment>
	);
}

export default OrderedCheckbox;
