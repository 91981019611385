import { Button, Form, Grid, Header } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";

import { ReactComponent as BarCode } from "@/assets/images/barcode.svg";
import { FormattedMessage } from "react-intl";
import { useState } from "react";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: `${theme.colors.light}`,
		height: "calc(100vh - 65px)",
	},
	barcode: {
		height: 250,
		width: 160,
	},
	headerText: {
		fontSize: "32px",
	},
	text: {
		color: `${theme.colors.subtleText}`,
	},
}));

const CartScan = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const [term, setTerm] = useState("");
	const classes = useStyles({ theme });

	const handleSubmit = (evt) => {
		if (evt.charCode !== 13) return;

		navigate(`/cart/search-results?term=${term}`);
	};

	return (
		<div className={classes.container}>
			<Grid relaxed="very">
				<Grid.Row centered>
					<BarCode className={classes.barcode} />
				</Grid.Row>
				<Grid.Row centered>
					<Header as="h1" className={classes.headerText}>
						Enter Product Barcode
					</Header>
				</Grid.Row>
				<Grid.Row centered>
					<span className={classes.text}>
						<FormattedMessage id="cart scan" />
					</span>
				</Grid.Row>
				<Grid.Row columns={2} centered>
					<Grid.Column>
						<Form>
							<Form.Input
								required
								value={term}
								onChange={(evt) => setTerm(evt.target.value)}
								error={null}
								onKeyPress={handleSubmit}
							/>
						</Form>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row centered>
					<Link to={`/cart/search-results?term=${term}`}>
						<Button primary>ENTER</Button>
					</Link>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export { CartScan as default };
