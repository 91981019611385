import { useCallback, useEffect, useState } from "react";
import GenericModal from "@/components/modals/generic-modal.jsx";
import styled from "styled-components";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import GlobalButton from "./global-button";
import { UploadFileBox } from "@/components/upload-file-box.jsx";

const ErrorWrapper = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.error};
	margin: 8px;
	min-height: 16px;
`;

const FileUploadModal = ({ open, onConfirm, onCancel, titleText, allowedTypes }) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (open) {
			setSelectedFile(null);
			setError(null);
		}
	}, [open]);

	const uploadRequestHandler = useCallback(async () => {
		setBusy(true);

		try {
			const rawContent = await selectedFile.text();
			if (onConfirm) {
				/*
        selectedFile will have the following properties:
          lastModified: Unix epoch
          lastModifiedDate: DateTime object
          name: Name of the file WITHOUT the path.
          size: The size in bytes.
          type: MIME type

        NOTE: The content is RAW BYTES, so remember to check the
          content type before you mess with it.
      */

				await onConfirm(rawContent, selectedFile);
			}
		} catch (err) {
			setError(err.message);
			console.error(err);
		}

		setBusy(false);
	}, [onConfirm, selectedFile]);

	const processFile = useCallback((file) => {
		setSelectedFile(file);
		setError(null);
	}, []);

	const onProcessFileError = useCallback((error) => {
		setError(error);
	}, []);

	return (
		<GenericModal
			open={open}
			title={titleText}
			body={
				<>
					<UploadFileBox
						processFile={processFile}
						selectedFileName={selectedFile?.name}
						allowedTypes={allowedTypes}
						busy={busy}
						onPressFileError={onProcessFileError}
					/>
					{error && (
						<ErrorWrapper data-cy="file-upload-error-message">
							<Error /> <span>&nbsp;</span> {error}
						</ErrorWrapper>
					)}
				</>
			}
			actions={
				<>
					<GlobalButton
						primary
						data-cy="import-btn"
						text="Import"
						disabled={!selectedFile || busy}
						loading={busy}
						onClick={uploadRequestHandler}
					/>
					<GlobalButton text="Cancel" disabled={busy} type="text" onClick={() => onCancel && onCancel()} />
				</>
			}
		/>
	);
};

export default FileUploadModal;
