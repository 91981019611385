/* eslint-disable no-unused-expressions */

import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import DistoModal from "./disto-modal";
import LeftCard from "./left-card";
import { ProductionContext } from "@/contexts/production-context";
import ScanPageContext from "../contexts/scan-page-context";
import ScanPageStatusContext from "../contexts/scan-page-status-context";
import SingleScanProductsTable from "./single-scanned-products-table";
import { findProduct } from "@/api/dim-product-api";
import { getScanOptionsRequest } from "./utils";
import { isDistoInput } from "../contexts/scan-page-context";
import { postDimMultipleScan } from "@/api/dim-scan-api";
import { productState } from "./product-state";
import { sleep } from "@/utils/sleep";
import { v4 as uuid } from "uuid";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
	},
	main: {
		display: "flex",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
		padding: "0 16px 16px 16px",
		justifyContent: "space-between",

		"&>div": {
			margin: "0 16px 16px 16px",
		},
	},
	scanCount: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	rightCard: {
		flexBasis: "40%",
		backgroundColor: "white",
		borderRadius: "8px",
	},
	listTitle: {
		padding: "16px",
		margin: "0",
	},
}));

function useScanHistory(intl) {
	const [items, setItems] = useState([]);

	function add(description) {
		const key = uuid();
		setItems((current) => [
			...current,
			{
				key,
				productId: description,
				state: productState.LOADING,
				message: "",
			},
		]);

		return key;
	}

	function update(key, state, message) {
		setItems((current) => current.map((item) => (item.key !== key ? item : { ...item, state, message })));
	}

	function remove(key) {
		setItems((current) => current.filter((item) => item.key !== key));
	}

	return {
		items,
		add,
		update,
		remove,
	};
}

function SingleScan({
	scanOptions,
	onScanInputFocus,
	onScanInputBlur,
	scanInputRef,
	lpnInputRef,
	jobInputRef,
	onValidate,
	setInputFocus,
	children,
}) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const graphicTimerRef = useRef();
	const dimCtx = useContext(DimDataContext);
	const scanCtx = useContext(ScanPageContext);
	const productionCtx = useContext(ProductionContext);

	const { pageStatusChanged } = useContext(ScanPageStatusContext);
	const scanHistory = useScanHistory(intl);

	useEffect(() => {
		scanCtx.setDistoOpeningCallback(checkPageState);
		return () => scanCtx.setDistoOpeningCallback();
	}, [checkPageState]);

	const identifier = dimCtx.customerConfiguration.mainIdentifierType;
	const scanSteps = dimCtx.customerConfiguration.scanSteps;

	function checkPageState() {
		if (identifier === "Unconfigured") {
			pageStatusChanged(productState.ERROR, "SetupRequired");
			return false;
		}

		if (graphicTimerRef.current) {
			clearTimeout(graphicTimerRef.current);
			graphicTimerRef.current = undefined;
		}

		if (!onValidate()) {
			pageStatusChanged(productState.ERROR, "RequiredFieldsNotSet");
			scanCtx.setScanCode("");

			return false;
		}

		if (scanSteps) {
		}

		return true;
	}

	async function scanKeyPressedHandler(e) {
		if (e.charCode !== 13 || !scanCtx.scanCode) {
			return;
		}

		if (!checkPageState()) {
			return;
		}

		if (isDistoInput(scanCtx.scanCode)) {
			return;
		}

		// prettier-ignore
		await sendJob({ description: scanCtx.scanCode, productId: scanCtx.scanCode });
	}

	async function distoSaveHandler(dimensions) {
		scanCtx.distoClosedHandler();

		// prettier-ignore
		const description = `${intl.formatMessage({id: "Order"})} ${scanCtx.jobTitle} - ${dimensions.dim1} × ${dimensions.dim2} × ${dimensions.dim3}`;

		const distoData = {
			name: description,
			dimensions: {
				length: dimensions.dim1,
				width: dimensions.dim2,
				height: dimensions.dim3,
			},
		};

		sendJob({ description, distoData });
	}

	async function sendJob({ description, productId = null, distoData = null }) {
		const historyKey = scanHistory.add(description);
		pageStatusChanged(productState.LOADING);
		scanCtx.setSendBusy(true);

		let productData = null;
		if (productId) {
			try {
				const result = await findProduct(productId);

				productData = result.product;
			} catch (err) {
				let state, message;
				if (err.isAxiosError) {
					if (err.response.status === 404) {
						state = productState.NOT_FOUND;
						message = intl.formatMessage({ id: "NotFound" });
					} else {
						state = productState.ERROR;
						message = intl.formatMessage({ id: "Unexpected Error" });
					}
				} else {
					state = productState.ERROR;
					message = intl.formatMessage({ id: "SystemError" });
				}

				pageStatusChanged(state, message);
				scanHistory.update(historyKey, productState.NOT_FOUND, message);
			}
		} else {
			productData = distoData;
		}

		if (productData) {
			const xValues = {};
			scanOptions.xvalues.forEach((xvalue) => {
				xValues[xvalue.alias] = String(xvalue.value);
			});

			const scanRequest = getScanOptionsRequest(
				scanOptions,
				productionCtx,
				xValues,
				[
					{
						quantity: 1,
						product: productData,
					},
				],
				dimCtx,
				scanCtx,
			);

			try {
				const result = await postDimMultipleScan(scanRequest);

				const message = result.reason ?? "Scanned";
				pageStatusChanged(productState.FOUND, message, false);
				scanHistory.update(historyKey, productState.FOUND, message);

				scanCtx.setScanCode("");
				scanCtx.setLicensePlateNumber("");
				scanCtx.setJobTitle("");
			} catch (err) {
				let message = intl.formatMessage({ id: "Unexpected Error" });
				if (err.isAxiosError) {
					message = err?.response?.data?.errors?.[0] || intl.formatMessage({ id: "ServerError" });
				}

				pageStatusChanged(productState.ERROR, message, false);
				scanHistory.update(historyKey, productState.ERROR, message);
			}
		}
		scanCtx.setSendBusy(false);
		setInputFocus();

		if (graphicTimerRef.current) {
			clearTimeout(graphicTimerRef.current);
		}
		graphicTimerRef.current = setTimeout(() => pageStatusChanged(productState.NONE), 30 * 1000);

		await sleep(90 * 1000);

		scanHistory.remove(historyKey);
	}

	function distoCancelHandler() {
		scanCtx.distoClosedHandler();
	}

	return (
		<div className={classes.container}>
			<div className={classes.main}>
				<DistoModal
					isVisible={scanCtx.isDistoVisible}
					startingDim={scanCtx.scanCode}
					onSave={distoSaveHandler}
					onCancel={distoCancelHandler}
				/>
				<LeftCard
					onScanInputFocus={onScanInputFocus}
					onScanInputBlur={onScanInputBlur}
					scanInputRef={scanInputRef}
					lpnInputRef={lpnInputRef}
					jobInputRef={jobInputRef}
					scanKeyPressedHandler={scanKeyPressedHandler}
				/>
				{children}
				<div className={classes.rightCard}>
					<h2 className={classes.listTitle}>
						<FormattedMessage id="Scanned Products"></FormattedMessage>
					</h2>
					<SingleScanProductsTable scannedProducts={scanHistory.items} />
				</div>
			</div>
		</div>
	);
}

export default SingleScan;
