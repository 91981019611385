import * as identityApi from "../../api/identity-api";
import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_LightBG.svg";
import {Button, Form, Grid, GridRow, Icon} from "semantic-ui-react";
import React, {useContext, useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {getUser, setSsoUser, UserContext} from "@/components/user-context";
import {FormattedMessage, useIntl} from "react-intl";
import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {useNavigate, useSearchParams} from "react-router-dom";
import {readLocalStorage, removeLocalStorage, writeLocalStorage} from "@/api/local-storage";
import LoginSplashFrame from "./login-splash-frame";
import LoadingBar from "@/components/loadingBar";
import {isNullOrWhitespace} from "@/utils/form-validation";
import {GetUserSsoInfo} from "identity-api-sdk";

const useStyles = createUseStyles((theme) => ({
    errorState: {
        color: "#FF3743",
    },
    passwordInput: {
        "& .eye": {

            marginRight: ".5em !important",
            
        }
    },
    imageRow: {
        "padding-bottom": "2rem",
    },
    expireRow: {
        paddingBottom: "2rem",
    },
    loginBackground: {
        height: "100vh",
        flex: "1",
        padding: "0 10rem",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    rowStyle: {
        padding: "1rem 0",
    },
    loginbutton: {
        width: "100%",
        textTransform: "none !important",
    },
    field: {
        "& .pointing.above.prompt.label": {
            border: "none !important",
            "margin-top": 0,
        },
        "& .label": {
            "&:before": {
                display: "none",
            },
        },
    },
    fullWidth: {
        width: "100%",
    },
    fullHeight: {
        height: "100%",
    },
    form: {
        width: "100%",
    },
    languageSelector: {
        position: "absolute",
        top: 0,
        right: "0px",
        padding: "30px",
    },
    dropdown: {
        border: "none !important",
        "line-height": "1.25em !important",
        width: "120px",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "left",
        gap: "1em",
        flexDirection: "row-reverse !important",
        "& :selected": {
            border: "none",
        },
        "&.active": {
            boxShadow: "none !important",
        },
        "& .menu": {
            border: "none !important",
            borderRadius: "0",
            "& :selected": {
                border: "none",
                borderRadius: "0",
            },
        },
    },
    noShow: {
        display: "none",
    },
    dropdownCarat: {
        "&:before": {
            color: "#7C7B79",
            right: "25px",
            top: "38%",
            float: "right",
            position: "absolute",
            zIndex: "100",
        },
    },
    loginGridContainer: {
        flex: "1",
        width: "40%",
        minWidth: "360px",
        maxWidth: "600px",
    },
    loader: {
        marginTop: "2rem !important",
    },
    forgotPassword: {
        cursor: "pointer",
    },
    closeButton: {
        "& path": {
            fill: "#7C7B79 !important",
        },
    },
    modal: {
        "& > :first-child": {
            padding: "0px !important",
        },
    },
    fullSize: {
        borderRadius: "6px",
        background: "#f2f4f5",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "2rem",
        gap: "1rem",
    },
    modalHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& h1": {
            margin: "0 !important",
        },
    },
    content: {
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        borderRadius: "6px",
        fontFamily: "Stolzl-Book",
        fontSize: "16px",
        fontWeight: "400",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    "#cancelButton": {
        boxShadow: "none !important",
        border: "none !important",
    },
    footer: {
        display: "flex",
        justifyContent: "end",
        "& #cancelButton": {
            boxShadow: "none !important",
            border: "none !important",
        },
    },
}));
const UniversalLogin = ({sessionExpired = false, ssoErrorParam = null}) => {
    const theme = useTheme();
    const intl = useIntl();
    const classes = useStyles({theme});
    const navigate = useNavigate();
    const {currentUser, setCurrentUser} = useContext(UserContext);
    const [loggedOut, setLoggedOut] = useState(sessionExpired);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(true);
    const [hasLoginErrors, setHasLoginErrors] = useState(false);
    const [ssoError, setSsoError] = useState();
    const [isPasswordRequired, setIsPasswordRequired] = useState(false);

    const [searchParams] = useSearchParams();
    const hasValidInputs = (username, password) => username !== "" && password !== "";
    const hasAdditionalTenants = (user) => user?.AdditionalTenants && user?.AdditionalTenants.length > 1;

    useEffect(() => {
        
        if (currentUser?.id) navigate("/");

        let preAuth = readLocalStorage("preAuth");
        if (preAuth) {
            setIsLoggingIn(true);
        } else {
            setIsLoggingIn(false);
        }

        if (!ssoError && ssoErrorParam) {
            try {
                setSsoError(JSON.parse(atob(ssoErrorParam)));
            } catch {
                console.info("Invalid error format provided.");
            }
        }
        let token = searchParams.get("token");
        if (token) {
            setIsLoggingIn(true);
            handleSSOToken(token);
        }
        
        setTimeout(function () {
            setIsLoggingIn(false);
            removeLocalStorage("preAuth");
        }, 10000);
    }, []);

    const handleSSOToken = async (encodedToken) => {
        try {
            const decodedToken = atob(encodedToken, "base64");
            await setSsoUser(decodedToken, setCurrentUser).then((updatedCurrentUser) => {
                redirectAfterLogin(updatedCurrentUser);
            });
        } catch (err) {
            setIsLoggingIn(false);
            console.error("Error setting SSO user token", err);
            setHasLoginErrors(intl.formatMessage({id: "Sso Sign In Failure"}));
        }
    };

    const redirectAfterLogin = (loggedInUser) => {
        if (!loggedInUser) {
            navigate("/login");
        } else if (hasAdditionalTenants(loggedInUser)) {
            navigate("/site-select", {state: {pathName: "login"}});
        } else {
            navigate("/");
        }
    };
    const checkSsoUser = async () => {
        setLoggedOut(false);
        if (username === "") {
            setHasLoginErrors(true);
            return;
        }

        setHasLoginErrors(false);

        const result = await GetUserSsoInfo(username);
        if (result?.responseData?.isSsoEnabled) {
            setIsLoggingIn(true);
            if (!isNullOrWhitespace(result.responseData.redirectUrl)) {
                writeLocalStorage("preAuth", username);
                window.location.replace(result.responseData.redirectUrl);
            }
        } else {
            setIsPasswordRequired(true);
        }
    };

    const handleEnterpriseAuthToken = async (token = null) => {
        let result = await identityApi.validateEnterpriseSsoToken(token);

        if (result?.success) {
            const loggedInUser = await setSsoUser(result.responseData.access_token, setCurrentUser);
            if (loggedInUser?.id) {
                removeLocalStorage("preAuth");
                redirectAfterLogin(loggedInUser);
            } else {
                setIsLoggingIn(false);
                setHasLoginErrors(true);
            }
        } else {
            setIsLoggingIn(false);
            setHasLoginErrors(true);
        }
    };

    const handleSubmitWithPassword = async () => {
        removeLocalStorage("preAuth");

        if (!hasValidInputs(username, password)) {
            setHasLoginErrors(true);
            return;
        }
        setHasLoginErrors(false);
        setIsLoggingIn(true);

        const loggedInUser = await getUser(username, password);
        if (loggedInUser?.id) {
            let user = await setCurrentUser(loggedInUser);
            redirectAfterLogin(user);
        } else {
            setIsLoggingIn(false);
            setHasLoginErrors(true);
        }
    };
    const navigateToForgotPassword = () => {
        removeLocalStorage("preAuth");
        navigate("/forgot-password");
    };

    return (
        <LoginSplashFrame>
            {isLoggingIn && (
                <div data-testid="loader">
                    <div className={classes.loaderStack}>
                        <img data-cy="loader" src={CloudPlatformLogo} style={{height: "60px"}} alt="PackNet"/>
                        <div className={classes.loader}>
                            <LoadingBar className={classes.loader}></LoadingBar>
                        </div>
                    </div>
                </div>
            )}
            {!isLoggingIn && (
                <div className={classes.loginBackground}>
                    <Grid className={classes.loginGridContainer}>
                        <div className={classes.fullWidth}>
                            {/* Language selection dropdown */}
                            {/*<div className={classes.languageSelector}>*/}
                            {/*	<Grid className={classes.fullWidth}>*/}
                            {/*		<GridColumn>*/}
                            {/*			<div*/}
                            {/*				style={{*/}
                            {/*					display: "flex",*/}
                            {/*					alignItems: "center",*/}
                            {/*					justifyContent: "center",*/}
                            {/*				}}*/}
                            {/*			>*/}
                            {/*				<Dropdown*/}
                            {/*					options={countryOptions}*/}
                            {/*					tabIndex={0}*/}
                            {/*					icon={*/}
                            {/*						<img*/}
                            {/*							className="icon"*/}
                            {/*							data-cy="language-icon"*/}
                            {/*							src={LanguageIcon}*/}
                            {/*							style={{ height: "24px" }}*/}
                            {/*							alt="language-icon"*/}
                            {/*						/>*/}
                            {/*					}*/}
                            {/*					data-cy="language"*/}
                            {/*					selection*/}
                            {/*					image={"angle down"}*/}
                            {/*					inline*/}
                            {/*					defaultValue={"en"}*/}
                            {/*					onChange={(e, data) => setLocale(data.value)}*/}
                            {/*					className={classes.dropdown}*/}
                            {/*					style={{ color: "#7C7B79" }}*/}
                            {/*				/>*/}
                            {/*				<Icon name={"angle down"} className={classes.dropdownCarat}></Icon>*/}
                            {/*			</div>*/}
                            {/*		</GridColumn>*/}
                            {/*	</Grid>*/}
                            {/*</div>*/}
                            <GridRow className={classes.imageRow}>
                                <div>
                                    <img data-cy="packnet" src={CloudPlatformLogo} style={{height: "30px"}} alt="PackNet"/>
                                </div>
                            </GridRow>
                            <GridRow className={classes.expireRow}>
                                <div className={loggedOut ? "" : classes.noShow}>
                                    <FormattedMessage id="Session expired"/>
                                </div>
                            </GridRow>
                            <Form method="post" className={classes.form}>
                                <div className={classes.formFields}>
                                    <GridRow className={classes.rowStyle}>
                                        <Form.Field className={classes.field} data-cy="form-field-username">
											<span className={hasLoginErrors ? classes.errorState : ""}>
												<FormattedMessage id="Email" className={hasLoginErrors ? classes.errorState : ""}/>
											</span>
                                            <Form.Input
                                                fluid
                                                placeholder={intl.formatMessage({
                                                    id: "Email Address Placeholder",
                                                    defaultMessage: "Enter your email address...",
                                                })}
                                                id="usernameInput"
                                                type="text"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                error={hasLoginErrors}
                                                tabIndex={1}
                                                data-cy="username"
                                            />
                                        </Form.Field>
                                    </GridRow>
                                    {isPasswordRequired && (
                                        <GridRow className={classes.rowStyle}>
                                            <Form.Field className={classes.field} data-cy="form-field-password">
												<span class={hasLoginErrors ? classes.errorState : ""}>
													<FormattedMessage id="Password" className={hasLoginErrors ? classes.errorState : ""}/>
												</span>
                                                <Form.Input
                                                    className={classes.passwordInput}
                                                    fluid
                                                    placeholder={intl.formatMessage({id: "Enter Password"})}
                                                    id="password"
                                                    type={showPassword ? "text" : "password"}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    error={hasLoginErrors ? intl.formatMessage({id: "Invalid Credentials"}) : false}
                                                    tabIndex={2}
                                                    data-cy="password"
                                                    icon={
                                                        <Icon
                                                            name={!showPassword ? "eye" : "eye slash"}
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            link/>
                                                    }
                                                />
                                            </Form.Field>
                                            <div>
                                                <a className={classes.forgotPassword} onClick={navigateToForgotPassword}>
                                                    Forgot Password?
                                                </a>
                                            </div>
                                        </GridRow>
                                    )}
                                    <GridRow className={classes.rowStyle}>
                                        {isPasswordRequired ? (
                                            <Button
                                                id="loginButton"
                                                primary
                                                tabIndex={3}
                                                onClick={handleSubmitWithPassword}
                                                className={classes.loginbutton}
                                                data-cy="login-button"
                                            >
                                                <FormattedMessage id="Login"/>
                                            </Button>
                                        ) : (
                                            <Button
                                                id="nextButton"
                                                primary
                                                basic={username.length > 0 ? false : true}
                                                tabIndex={3}
                                                onClick={checkSsoUser}
                                                className={classes.loginbutton}
                                                data-cy="next-button"
                                            >
                                                <FormattedMessage id="Next"/>
                                            </Button>
                                        )}
                                    </GridRow>
                                </div>
                            </Form>
                        </div>
                    </Grid>
                </div>
            )}
        </LoginSplashFrame>
    );
};
export default UniversalLogin;
