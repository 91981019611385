import Fuse from "fuse.js";

const fuseOptions = (keys) => {
	return {
		isCaseSensitive: false,
		keys,
		minMatchCharLength: 3,
		// includeMatches: false,
		// findAllMatches: false,
		// location: 0,
		threshold: 0,
		// distance: 100,
		// useExtendedSearch: false,
		ignoreLocation: true,
		// ignoreFieldNorm: false,
	};
};

export const search = (searchStr, items, keys) => {
	const options = fuseOptions(keys);
	const fuse = new Fuse(items, options);
	const searchResults = fuse.search(searchStr);
	return searchResults.map((result) => result.item);
};
