import React from "react";

import { createUseStyles, useTheme } from "react-jss";
import ProgressBar from "./progress-bar";

const useStyles = createUseStyles((theme) => ({
	heading: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingBottom: "8px",
		"& > span": {
			width: "50%",
		},
	},
	progressWrapper: {
		display: "flex",
		flex: "1",
		"& > div": {
			flex: "1",
		},
	},
	value: {
		display: "flex",
		justifyContent: "right",
		alignItems: "center",
		fontSize: "17px",
		fontStyle: "normal",
		fontFamily: "Stolzl",
		fontWeight: "bold",
		lineHeight: "normal",
		fontStretch: "normal",
		letterSpacing: "normal",
	},
}));

function ProgressBarGroup({ color, percent, leftText, rightText, popupText }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	return [
		<div className={classes.heading} key={`progress-bar-group-${leftText}-heading`}>
			<span>{leftText}</span>
			<span className={classes.value}>{rightText}</span>
		</div>,
		<div className={classes.progressWrapper} key={`progress-bar-group-${leftText}-wrapper`}>
			<ProgressBar percent={percent} color={color} popupText={popupText} />
		</div>,
	];
}

export default ProgressBarGroup;
