import { Form, Grid } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import React, { Fragment, useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import Loading from "@/components/loading";
import SettingsCaptureFlow from "./settings-capture-flow";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
	},
	row: {
		paddingBottom: "0 !important",
	},
	colum: {
		backgroundColor: "white",
		padding: "1.5em 2.3em 1.5em 1.5em !important",
		borderRadius: "8px",
		width: "100%",
	},
}));

function SettingsTab() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);

	return (
		<Fragment>
			{dimCtx.isLoading ? (
				<Loading />
			) : (
				<Form error className={classes.container}>
					<Grid.Row className={classes.row}>
						<Grid.Column className={classes.colum}>
							<div className="field">
								<h3>
									<FormattedMessage id="ScanToProduceCaptureFlow" />
								</h3>
							</div>
							<SettingsCaptureFlow />
						</Grid.Column>
					</Grid.Row>
				</Form>
			)}
		</Fragment>
	);
}

export default SettingsTab;
