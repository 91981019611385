import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import ProgressBarList from "../../progress-bar-list";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import { getPaginationResult } from "@/views/reporting/functions/swimlanes";
import { sortByValue } from "@/views/reporting/functions/sort";

function TrimByMachine({ reportData, dateRange, limit, color, isMetric, sortByAmount, sortByMachine }) {
	const intl = useIntl();
	const [presentationData, setPresentationData] = useState([]);
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});
	const unitLabel = isMetric ? "sq meters" : "sq ft";

	function sortData(data) {
		let newData = [];

		if (sortByAmount !== "none") {
			newData = sortByValue(data[0].machines, "percentage", sortByAmount, "machineName", "asc");
		}

		if (sortByMachine !== "none") {
			newData = sortByValue(data[0].machines, "machineName", sortByMachine);
		}

		return [{ machines: newData }];
	}

	function mapData(data) {
		data.byMachine = data.byMachine.map((item) => {
			return {
				...item,
				percentage: item.totalZFoldArea > 0 ? (item.trimArea / item.totalZFoldArea) * 100 : 0,
			};
		});
		data.byMachine = sortByValue(data.byMachine, "percentage", "desc", "machineName", "asc");

		setPresentationData([
			{
				machines: data.byMachine,
			},
		]);
	}

	function machineNameLine(machine) {
		if (limit === 1000)
			return (
				machine.machineName +
				" - " +
				machine.totalZFoldArea.toLocaleString(undefined, {
					maximumFractionDigits: 2,
				}) +
				" " +
				intl.formatMessage({ id: unitLabel }) +
				" (" +
				intl.formatMessage({ id: "total corrugated" }) +
				") - " +
				machine.trimArea.toLocaleString(undefined, {
					maximumFractionDigits: 2,
				}) +
				" " +
				intl.formatMessage({ id: unitLabel }) +
				" (" +
				intl.formatMessage({ id: "total trim" }) +
				")"
			);
		return machine.machineName;
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	useEffect(() => {
		if (presentationData.length === 0) return;
		let newData = sortData(presentationData);
		if (newData.length > 0) setPresentationData(newData);
	}, [sortByMachine, sortByAmount]);

	const items = presentationData.map((item) => {
		return {
			items: item.machines.map((machine) => {
				let percentage = machine.percentage;
				return {
					percent: percentage,
					text: machineNameLine(machine),
					value: `${percentage !== 0 ? percentage.toFixed(1).toLocaleString() : 0}%`,
					popupText:
						machine.trimArea.toLocaleString(undefined, {
							maximumFractionDigits: 2,
						}) +
						" " +
						intl.formatMessage({ id: unitLabel }),
				};
			}),
		};
	});

	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<div key={`trim-by-machine-progress-bar-list-section`}>
				{limit === 1000 &&
					items.map((item, i) => {
						return (
							<ProgressBarList
								color={color}
								items={item.items}
								limit={1000}
								key={`trim-by-machine-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 && (
					<ProgressBarList color={color} items={paginationResult.items} limit={paginationResult.items.length} />
				)}
			</div>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key={`trim-by-machine-swim-lane-pagination`}
			/>,
		]
	) : (
		<div></div>
	);
}

export default TrimByMachine;
