import { Button, Form } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment } from "react";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	inputContainer: {
		display: "flex",
		marginBottom: "16px",
	},
	formInput: {
		width: "100%",
	},
	basicButton: {
		borderColor: "transparent !important",
		boxShadow: "none !important",
		color: theme.primary,
		float: "right",
		backgroundColor: `${theme.light} !important`,
	},
	trash: {
		boxShadow: "none !important",
		borderRadius: "none !important",
		width: "40px",
		padding: "0px !important",
		margin: "0px !important",
	},
}));

const AttributeOptions = ({ options, updateOption, addOption, removeOption }) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });

	function displayOption(name, index) {
		return (
			<div className={classes.inputContainer}>
				<Form.Input
					label={intl.formatMessage({ id: "Option" }, { num: index + 1 })}
					placeholder=""
					value={name}
					onChange={(e, { value }) => updateOption(value, index)}
					className={classes.formInput}
				/>
				{index > 1 && (
					<Button floated="right" basic icon="trash" className={classes.trash} onClick={() => removeOption(index)} />
				)}
			</div>
		);
	}

	return (
		<Fragment>
			{options.map((name, index) => displayOption(name, index))}
			<Button className={classes.basicButton} onClick={() => addOption()}>
				<FormattedMessage id="AddOption" />
			</Button>
		</Fragment>
	);
};

export default AttributeOptions;
