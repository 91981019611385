/* eslint-disable no-unused-expressions */

import { Button } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { isErrorState, productState } from "./product-state";
import { readLocalStorage, writeLocalStorage } from "@/api/local-storage";

import ConfirmModal from "@/components/confirm-modal";
import DimDataContext from "../contexts/dimensioning-data-context";
import LeftCard from "./left-card";
import MultiScanProductsTable from "./multi-scan-products-table";
import { ProductionContext } from "@/contexts/production-context";
import ScanPageContext from "../contexts/scan-page-context";
import ScanPageStatusContext from "../contexts/scan-page-status-context";
import { findProduct } from "@/api/dim-product-api";
import { getScanOptionsRequest } from "./utils";
import { postDimMultipleScan } from "@/api/dim-scan-api";
import { sleep } from "@/utils/sleep";
import { v4 as uuid } from "uuid";

const LOCAL_STORAGE_SCANNED = "dimension-scanned-products";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
	},
	header: {
		border: "0 !important",
		margin: "0 32px !important",
		padding: "0 !important",
		height: `calc(100vh - ${theme.measurements.pageHeaderHeight})`,
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		boxShadow: "0 0 !important",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},

	activeButton: {
		textTransform: "uppercase !important",
		color: `${theme.light} !important`,
		backgroundColor: theme.colors.primary,
	},
	inactiveButton: {
		textTransform: "uppercase !important",
		color: theme.colors.primary,
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: `${theme.light} !important`,
	},
	main: {
		display: "flex",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
		padding: "0 16px 16px 16px",
		justifyContent: "space-between",

		"&>div": {
			margin: "0 16px 16px 16px",
		},
	},
	leftCard: {
		flexBasis: "35%",
		maxWidth: "35%",
	},
	scanArea: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: `calc(100vh - ${theme.measurements.menuBarHeight} - ${theme.measurements.pageHeaderHeight})`,
		width: "25vw",
		margin: "auto",

		"& h1, & h3": {
			textAlign: "center",
			marginBottom: "16px",
		},

		"& .field": {
			display: "flex",
			flexDirection: "column",
			marginBottom: "24px",
		},
	},
	readyText: {
		fontSize: "32px !important",
		marginBottom: "48px",

		"& input": {
			textAlign: "center !important",
		},
	},
	messageText: {
		width: "50px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	scanCount: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	centerCard: {
		flexBasis: "25%",
		backgroundColor: "white",
		borderRadius: "8px",
		overflow: "auto",
		padding: "24px",
	},
	xvalues: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		"& .field": {
			width: "45%",
		},
	},
	rightCard: {
		flexBasis: "40%",
		maxWidth: "40%",
		backgroundColor: "white",
		borderRadius: "8px",

		display: "flex",
		flexDirection: "column",
	},
	listTitle: {
		padding: "16px",
		margin: "0",
	},
	tableFooter: {
		backgroundColor: `${theme.accent}`,
		height: "88px",
		borderRadiusBottom: "8px",
		display: "flex",
		justifyContent: "space-between",
	},
	footerMessage: {
		paddingTop: "30px",
		width: "68%",
		textAlign: "right",
		color: "#888B8C",
	},
	footerButton: {
		margin: "20px !important",
		fontSize: "15px !important",
		whiteSpace: "nowrap",
	},
	errorBanner: {
		padding: "20px",
		backgroundColor: "#ff3743",
		height: "52px",
		fontSize: "16px !important",
		textAlign: "center",
		color: "white",
	},
}));

function MultiScan({
	scanOptions,
	onScanInputFocus,
	onScanInputBlur,
	scanInputRef,
	lpnInputRef,
	jobInputRef,
	onValidate,
	setInputFocus,
	setInputFocusToEmptyField,
	children,
}) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();
	const dimCtx = useContext(DimDataContext);
	const scanCtx = useContext(ScanPageContext);
	const { pageStatusChanged } = useContext(ScanPageStatusContext);
	const [numberOfCartons, setNumberOfCartons] = useState(0);

	const [numberOfErrors, setNumberOfErrors] = useState(0);
	const [scannedProducts, setScannedProducts] = useState([]);
	const graphicTimerRef = useRef();

	scanCtx.setDistoOpeningCallback(() => true);
	const productionCtx = useContext(ProductionContext);
	const identifier = dimCtx.customerConfiguration.mainIdentifierType;

	function addOrUpdateProductHandler(products, code, key) {
		var idx = products.findIndex((item) => item.productId === code);
		if (idx >= 0) {
			return products.map((item) =>
				item.productId === code ? { ...item, quantity: Number(item.quantity) + 1 } : item,
			);
		}

		const newItem = [
			...products,
			{
				key,
				productId: code,
				quantity: 1,
				state: productState.LOADING,
				message: "",
			},
		];

		return newItem;
	}

	function removeProductHandler(key) {
		let count = 0,
			errors = 0;
		const newList = scannedProducts.filter((item) => {
			const isKeeper = item.key !== key;
			if (isKeeper) {
				count += item.quantity;
				errors += isErrorState(item.state) ? 1 : 0;
			}
			return isKeeper;
		});

		writeLocalStorage(LOCAL_STORAGE_SCANNED, JSON.stringify(newList));
		setScannedProducts(newList);
		setNumberOfCartons(count);
		setNumberOfErrors(errors);
	}

	function quantityChangedHandler(key, newQuantity) {
		let count = 0,
			errors = 0;
		const newList = scannedProducts.map((item) => {
			const newItem = item.key === key ? { ...item, quantity: newQuantity } : item;
			count += newItem.quantity;
			errors += isErrorState(newItem.state) ? 1 : 0;
			return newItem;
		});

		writeLocalStorage(LOCAL_STORAGE_SCANNED, JSON.stringify(newList));
		setScannedProducts(newList);
		setNumberOfCartons(count);
		setNumberOfErrors(errors);
	}

	function clearProductsHandler(state = productState.NONE, message = "") {
		setScannedProducts([]);
		setNumberOfCartons(0);
		setNumberOfErrors(0);
		writeLocalStorage(LOCAL_STORAGE_SCANNED, "");
		scanCtx.setScanCode("");
		pageStatusChanged(state, message);
		scanCtx.setLicensePlateNumber("");
		scanCtx.setJobTitle("");

		setInputFocus();
	}

	useEffect(() => {
		const productsString = readLocalStorage(LOCAL_STORAGE_SCANNED);
		if (!productsString) {
			return;
		}

		const products = JSON.parse(productsString);
		let count = 0;
		let errorCount = 0;
		products.forEach((product) => {
			count += product.quantity;
			if (isErrorState(product.state)) {
				findProduct(product.productId)
					.then((data) =>
						setScannedProducts((current) =>
							current.map((item) =>
								item.productId === product.productId
									? {
											...item,
											state: productState.FOUND,
											product: data.product,
										}
									: item,
							),
						),
					)
					.catch((_) => {
						errorCount++;
						setNumberOfErrors(errorCount);
						setScannedProducts((current) =>
							current.map((item) =>
								item.productId === product.productId ? { ...item, state: productState.NOT_FOUND } : item,
							),
						);
					});

				product.state = productState.LOADING;
			}
		});

		setScannedProducts(products);
		setNumberOfCartons(count);
		setNumberOfErrors(errorCount);
	}, []);

	async function scanKeyPressedHandler(e) {
		if (e.charCode !== 13 || !scanCtx.scanCode) {
			return;
		}

		if (identifier === "Unconfigured") {
			pageStatusChanged(productState.ERROR, "SetupRequired");
			return;
		}

		if (dimCtx.scanLicensePlateNumberRequired() && !scanCtx.licensePlateNumber) {
			setInputFocusToEmptyField("lpn");
			return;
		}

		if (dimCtx.scanJobTitleRequired() && !scanCtx.jobTitle) {
			setInputFocusToEmptyField("job");
			return;
		}

		if (scanCtx.scanCode === "SEND") {
			if (numberOfErrors === 0) {
				sendHandler();
			}
			return;
		}

		if (scanCtx.scanCode === "CLEAR") {
			clearProductsHandler();
			return;
		}

		if (graphicTimerRef.current) {
			clearTimeout(graphicTimerRef.current);
			graphicTimerRef.current = undefined;
		}
		pageStatusChanged(productState.LOADING);
		scanCtx.setScanCode("");

		const key = uuid();
		setScannedProducts((current) => {
			const newProductList = addOrUpdateProductHandler(current, scanCtx.scanCode, key);
			writeLocalStorage(LOCAL_STORAGE_SCANNED, JSON.stringify(newProductList));
			return newProductList;
		});

		const xValues = {};
		scanOptions.xvalues.forEach((xvalue) => {
			xValues[xvalue.alias] = String(xvalue.value);
		});

		let state, message, product;
		try {
			const result = await findProduct(scanCtx.scanCode);
			state = productState.FOUND;
			message = result.reason ?? intl.formatMessage({ id: "Scanned" });
			product = result.product;
		} catch (err) {
			if (err.isAxiosError) {
				if (err.response.status === 404) {
					state = productState.NOT_FOUND;
					message =
						err?.response?.data?.reason ||
						intl.formatMessage({ id: "NotFoundScanCode" }, { scanCode: scanCtx.scanCode });
				} else {
					state = productState.ERROR;
					message = err?.response?.data?.reason || intl.formatMessage({ id: "Unexpected Error" });
				}
			} else {
				state = productState.ERROR;
				message = intl.formatMessage({ id: "SystemError" });
			}
		}

		pageStatusChanged(state, `${scanCtx.scanCode} - ${message}`, false);

		let count = 0,
			errors = 0;
		setScannedProducts((current) => {
			count = 0;
			errors = 0;
			const newList = current.map((item) => {
				const newItem =
					item.key !== key
						? item
						: {
								...item,
								key,
								productId: scanCtx.scanCode,
								state,
								message,
								product,
							};

				count += newItem.quantity;
				errors += isErrorState(newItem.state) ? 1 : 0;

				return newItem;
			});
			writeLocalStorage(LOCAL_STORAGE_SCANNED, JSON.stringify(newList));
			return newList;
		});
		setNumberOfCartons(count);
		setNumberOfErrors(errors);
	}

	async function sendHandler() {
		if (!onValidate()) {
			pageStatusChanged(productState.ERROR, "RequiredFieldsNotSet");

			return;
		}

		const xValues = {};
		scanOptions.xvalues.forEach((xvalue) => {
			xValues[xvalue.alias] = String(xvalue.value);
		});

		scanCtx.setSendBusy(true);
		try {
			const scanRequest = getScanOptionsRequest(scanOptions, productionCtx, xValues, scannedProducts, dimCtx, scanCtx);
			await postDimMultipleScan(scanRequest);
			scanCtx.setSendBusy(false);

			clearProductsHandler(
				productState.FOUND,
				`${scanCtx.jobTitle ? scanCtx.jobTitle + " - " : ""}${intl.formatMessage({
					id: "Sent to Queue",
				})}`,
			);

			await sleep(5 * 1000);

			pageStatusChanged(productState.NONE);
			setInputFocus();
		} catch (err) {
			scanCtx.setSendBusy(false);
			let message = intl.formatMessage({ id: "Unexpected Error" });
			if (err.isAxiosError) {
				message = err?.response?.data?.errors?.[0] || intl.formatMessage({ id: "ServerError" });
			}

			pageStatusChanged(productState.ERROR, message, false);
		}
	}

	return (
		<div className={classes.container}>
			<ConfirmModal
				open={scanCtx.isDistoVisible}
				onClose={(isConfirm) => {
					if (isConfirm) {
						scanCtx.singleScanChanged(true);
					}

					scanCtx.distoClosedHandler();
				}}
				titleText={intl.formatMessage({ id: "MoveToSingleScanDisto" })}
				detailText="MoveToSingleScanDescription"
				confirmText="Continue"
				cancelText="Cancel"
			/>

			<div className={classes.main}>
				<LeftCard
					onScanInputFocus={onScanInputFocus}
					onScanInputBlur={onScanInputBlur}
					scanInputRef={scanInputRef}
					lpnInputRef={lpnInputRef}
					jobInputRef={jobInputRef}
					scanKeyPressedHandler={scanKeyPressedHandler}
				/>
				{children}
				<div className={classes.rightCard}>
					<h2 className={classes.listTitle}>
						<FormattedMessage id="Scanned Products"></FormattedMessage>
					</h2>
					<MultiScanProductsTable
						scannedProducts={scannedProducts}
						removeProductHandler={removeProductHandler}
						quantityChangedHandler={quantityChangedHandler}
						clearProductsHandler={clearProductsHandler}
					/>
					{numberOfErrors > 0 && (
						<div className={classes.errorBanner}>
							<FormattedMessage
								id={numberOfErrors === 1 ? "ProductNotFound" : "ProductsNotFound"}
								values={{ count: numberOfErrors }}
							/>
						</div>
					)}
					<div className={classes.tableFooter}>
						<h3 className={classes.footerMessage}>
							<FormattedMessage id="ProductsInCarton" values={{ count: numberOfCartons }} />
						</h3>
						<Button
							primary
							className={classes.footerButton}
							disabled={
								scannedProducts.length === 0 ||
								!scannedProducts.every((item) => item.state === productState.FOUND) ||
								scanCtx.sendBusy
							}
							onClick={sendHandler}
							loading={scanCtx.sendBusy}
						>
							<FormattedMessage id="Send" />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MultiScan;
