import axios from "axios";
import { readLocalStorage } from "./local-storage";

export async function createUpdateCart(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.put("/DimensionApi/api/v1/Carts", data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function deleteCart(cartId) {
	const token = readLocalStorage("BEARER");

	const response = await axios.delete(`/DimensionApi/api/v1/Carts/${cartId}`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getCart(cartId) {
	const token = readLocalStorage("BEARER");

	const response = await axios.get(`/DimensionApi/api/v1/Carts/${cartId}`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getCartsByTenant() {
	const token = readLocalStorage("BEARER");

	const response = await axios.get("/DimensionApi/api/v1/Carts/GetCartsByTenant", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}
