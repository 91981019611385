import React from "react";

import { createUseStyles } from "react-jss";

function LegendDot({ children, className, color }) {
	const useStyles = createUseStyles((theme) => ({
		container: {
			display: "block",
			position: "relative",
			inlineSize: "max-content",
			paddingLeft: "35px",
			marginBottom: "8px",
			userSelect: "none",
		},

		legendDot: {
			position: "absolute",
			top: "25%",
			left: "0",
			height: "10px",
			width: "10px",
			backgroundColor: color,
			borderRadius: "50%",
			border: `2px solid ${color}`,
		},

		text: {
			fontSize: "13px",
		},
	}));
	const classes = useStyles();
	return (
		<label className={className ? `${classes.container} ${className}` : classes.container}>
			<span className={classes.text}>{children}</span>
			<span className={classes.legendDot} />
		</label>
	);
}

export default LegendDot;
