import styled from "styled-components";
import Flex from "@/components/flex";
import { Icon } from "semantic-ui-react";
import { addOpacity } from "@/theme/utils";

const getMachineGroupStatusColor = (status, theme) => {
	switch (status.toLowerCase()) {
		case "online":
			return addOpacity(theme.colors.online, 0.1);
		case "offline":
			return addOpacity(theme.colors.disabled, 0.1);
		case "error":
			return addOpacity(theme.colors.error, 0.1);
		case "initializing":
			return addOpacity(theme.colors.white, 0.1);
		case "paused":
			return addOpacity(theme.colors.paused, 0.1);
		default:
			return addOpacity(theme.colors.white, 0.1);
	}
};

const StatusContainer = styled(Flex)`
	background-color: ${(props) => getMachineGroupStatusColor(props.$machineGroupStatus, props.theme)};
	border: ${(props) => (props.$showBorder ? `1px solid ${props.theme.colors.border1}` : "none")};
	border-radius: 6px;
	padding: 8px;
	margin: 0;
`;

const StyledIcon = styled(Icon)`
	&&& {
		margin: 0;
	}
`;

function MachineGroupStatusIcon({ machineGroupStatus, border, children }) {
	const getMachineGroupStatusIcon = () => {
		switch (machineGroupStatus.toLowerCase()) {
			case "online":
				return <StyledIcon size="large" name="check circle outline" color="green" />;
			case "offline":
				return <StyledIcon size="large" name="times circle outline" color="grey" />;
			case "error":
				return <StyledIcon size="large" name="exclamation circle" color="red" />;
			case "initializing":
				return <StyledIcon size="large" name="refresh" color="grey" />;
			case "paused":
				return <StyledIcon size="large" name="pause circle outline" color="yellow" />;
			default:
				break;
		}
	};

	return (
		<StatusContainer alignCenter $machineGroupStatus={machineGroupStatus} $showBorder={border} gap={4}>
			{getMachineGroupStatusIcon()}
			{children}
		</StatusContainer>
	);
}

export default MachineGroupStatusIcon;
