import axios from "axios";
import { readLocalStorage } from "./local-storage";

export async function getX5Machines(setCallBack, errorCallback) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.get(`/x5api/api/v1/machines`, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});
		const data = await response.data;
		const x5Machines = data; //transformX5Machines(data);
		if (setCallBack) setCallBack(x5Machines);
		return x5Machines;
	} catch (err) {
		if (errorCallback) errorCallback(err);
		else throw err;
	}
}

export async function createX5Machine(data, setCallBack, errorCallBack) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.post(`/x5api/api/v1/machines`, data, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});

		await response.data;

		getX5Machines(setCallBack, errorCallBack);

	} catch (err) {
		if (errorCallBack) errorCallBack(err);
		else throw err;
	}
}

export async function updateX5Machine(data, setCallBack, errorCallBack) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.put(`/x5api/api/v1/machines/${data.id}/configuration`, data, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});

		const responseData = await response.data;

		getX5Machines(setCallBack, errorCallBack);

		return responseData;
	} catch (err) {
		if (errorCallBack) errorCallBack(err);
		else throw err;
	}
}

export async function deleteX5Machine(machineId, callback, errorCallback) {
	try {
		const bearer = readLocalStorage("BEARER");
		const response = await axios.delete(`/x5api/api/v1/machines/${machineId}`, {
			headers: {
				Authorization: `${bearer}`,
				"Content-Type": "application/json",
			},
		});

		const responseData = await response.data;
		if (callback) callback(responseData);
		return responseData;
	} catch (err) {
		if (errorCallback) errorCallback(err);
		else throw err;
	}
}

// TODO: find out how to load a non-exported function to test
export const transformX5Machines = (rawData) => {
	return rawData.reduce((x5Machines, data) => {
		try {
			return [
				...x5Machines,
				{
					id: data.id,
					alias: data.alias,
					ipOrDnsName: data.ipOrDnsName,
					port: data.port,
					assignedOperator: data.assignedOperator,
					currentStatus: data.currentStatus, //getHumanStatus(data.currentStatus)
					machineType: data.machineType,
					plcModel: data.plcModel,
				},
			];
		} catch (err) {
			console.error(err);
			console.error("Invalid X5 Machine: ", rawData);
			return x5Machines;
		}
	}, []);
};
