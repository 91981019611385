import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage } from "react-intl";
import { Button, Popup } from "semantic-ui-react";
import ExperienceContainer from "../layout/experience-container";
import Row from "../layout/row";
import DashboardCard from "@/views/reporting/dashboard-card";
import InvertedPopup from "../inverted-popup";
import Availability from "./cards/availability";
import Quality from "./cards/quality";
import Performance from "./cards/performance";
import OEE from "./cards/oee";
import OEEOverTime from "./cards/oee-over-time";
import { getPlannedProductionDataForSite } from "@/views/reporting/services/API/Reports/planned-production-api";
import { getBucketOEEByPlannedProductionSchedulesForSite } from "@/views/reporting/services/API/Reports/oee-api";
import { incrementDateRange, midnightDate } from "@/views/reporting/functions/index";
import { ReactComponent as ChartViewIcon } from "@/views/reporting/img/chartview-icon.svg";
import { ReactComponent as TableViewIcon } from "@/views/reporting/img/tableview-icon.svg";
import { isFeatureFlagEnabled } from "@/utils/auth";
import errorBoundary from "@/components/error-boundary";

const useStyles = createUseStyles(() => ({
	quarter: {
		display: "flex",
		flexDirection: "row",
		gap: "18px 18px",
		"& > div": {
			flexBasis: "49%",
		},
	},
	cardHeader: {
		display: "flex",
		justifyContent: "space-between",
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingTop: "20px",
		paddingLeft: "0",
		paddingBottom: "31px",
		paddingRight: "0",
	},
	toggleBtnGrp: {
		"& > button:first-child": {
			borderTopRightRadius: "0px",
			borderBottomRightRadius: "0px",
		},
		"& > button:last-child": {
			borderTopLeftRadius: "0px",
			borderBottomLeftRadius: "0px",
		},
		"& > button": {
			height: "32px !important",
			width: "56px !important",
			padding: "0px !important",
			margin: "0px !important",
			border: "1px solid #dededf !important",
			"& svg": {
				height: "20px !important",
				width: "20px !important",
				alignItems: "center !important",
			},
		},
	},
	notActiveToggleBtn: {
		backgroundColor: "#ffffff !important",
		"& svg": {
			"& path": {
				fill: "#6b6b6b !important",
			},
		},
	},
	activeToggleBtn: {
		backgroundColor: "#f2f2f2 !important",
		"& svg": {
			"& path": {
				fill: "#353430 !important",
			},
		},
	},
}));

function OverallEquipmentEffectiveness({ currentUser }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const defaultColor = "#353430";
	const defaultOrder = ["Availability", "Performance", "Quality", "OEE"];
	const [availabilityData, setAvailabilityData] = useState([]);
	const [qualityData, setQualityData] = useState([]);
	const [performanceData, setPerformanceData] = useState([]);
	const [oeeData, setOEEData] = useState([]);
	const [overTimeData, setOverTimeData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showGraph, setShowGraph] = useState(true);
	const selectedSites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [dateOptions, setDateOptions] = useState({
		start: midnightDate(new Date()),
		end: midnightDate(new Date()),
	});
	const [machines, setMachines] = useState(() => {
		const machinesSaved = localStorage.getItem("MACHINES");
		return machinesSaved !== null && machinesSaved !== "undefined" ? JSON.parse(machinesSaved) : [];
	});

	async function getData(sites) {
		setIsLoading(true);
		let plannedProduction = [];
		const ffAvailabilityPlannedProduction = isFeatureFlagEnabled(
			currentUser,
			"reporting-allow-availability-planned-production",
		);
		const ffPlannedProduction = isFeatureFlagEnabled(currentUser, "pack-net-reporting-wip");
		const newDateRange = incrementDateRange(dateOptions);

		if (ffPlannedProduction || ffAvailabilityPlannedProduction) {
			plannedProduction = await getPlannedProductionDataForSite(sites, newDateRange);
		}

		const data = await getBucketOEEByPlannedProductionSchedulesForSite(
			plannedProduction,
			sites,
			machines.filter((m) => m.checked).map((m) => m.id),
			newDateRange,
		);

		setIsLoading(false);
		setAvailabilityData(data.availability);
		setQualityData(data.quality);
		setPerformanceData(data.performance);
		setOEEData(data);
		setOverTimeData(data.overTime);
	}

	function showOverview() {
		const title = "OEE Over Time";
		return [
			<Row size="half" key="left-row-1">
				<div className={classes.quarter}>
					<DashboardCard
						title="Availability"
						titlePopup={
							<InvertedPopup
								title={<FormattedMessage id="Availability" />}
								contentText={
									<div>
										<FormattedMessage id="runTime" /> / <FormattedMessage id="plannedProductionTime" />
									</div>
								}
							/>
						}
						dateRange={dateOptions}
					>
						<Availability reportData={availabilityData} dateRange={dateOptions} color={defaultColor} />
					</DashboardCard>
					<DashboardCard
						title="Performance"
						titlePopup={
							<InvertedPopup
								title={<FormattedMessage id="Performance" />}
								contentText={
									<div>
										(<FormattedMessage id="weightedIdealCycleTime" /> x <FormattedMessage id="totalCount" />) /{" "}
										<FormattedMessage id="runTime" />
									</div>
								}
							/>
						}
						dateRange={dateOptions}
					>
						<Performance reportData={performanceData} dateRange={dateOptions} color={defaultColor} />
					</DashboardCard>
				</div>
			</Row>,
			<Row size="half" key="right-row-1">
				<div className={classes.quarter}>
					<DashboardCard
						title="Quality"
						titlePopup={
							<InvertedPopup
								title={<FormattedMessage id="Quality" />}
								contentText={
									<div>
										<FormattedMessage id="successfulCount" /> / <FormattedMessage id="totalCount" />
									</div>
								}
							/>
						}
						dateRange={dateOptions}
					>
						<Quality reportData={qualityData} dateRange={dateOptions} color={defaultColor} />
					</DashboardCard>
					<DashboardCard
						title="OEE"
						titlePopup={
							<InvertedPopup
								title={<FormattedMessage id="OEE" />}
								contentText={
									<div>
										<FormattedMessage id="Availability" /> x <FormattedMessage id="Performance" /> x{" "}
										<FormattedMessage id="Quality" />
									</div>
								}
							/>
						}
						dateRange={dateOptions}
					>
						<OEE reportData={oeeData} dateRange={dateOptions} color={defaultColor} order={defaultOrder} />
					</DashboardCard>
				</div>
			</Row>,
			<Row key="oee-over-time-row-2">
				<DashboardCard
					title={
						<div className={`${classes.cardHeader}`}>
							<FormattedMessage id={title} />
							<span className={classes.toggleBtnGrp}>
								<Popup
									inverted
									trigger={
										<Button
											className={showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
											onClick={() => setShowGraph(true)}
										>
											<ChartViewIcon />
										</Button>
									}
									content="Graph View"
									position="bottom center"
								/>
								<Popup
									inverted
									trigger={
										<Button
											className={!showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
											onClick={() => setShowGraph(false)}
										>
											<TableViewIcon />
										</Button>
									}
									content="Table View"
									position="bottom center"
								/>
							</span>
						</div>
					}
					dateRange={dateOptions}
				>
					<OEEOverTime
						key="OEE Over Time"
						title="OEE Over Time"
						reportData={overTimeData}
						dateRange={dateOptions}
						showGraph={showGraph}
						order={defaultOrder}
					></OEEOverTime>
				</DashboardCard>
			</Row>,
		];
	}

	useEffect(() => {
		if (dateOptions.id) getData(selectedSites, dateOptions);
	}, [dateOptions, selectedSites, machines]);

	return (
		<ExperienceContainer
			currentUser={currentUser}
			header="Overall Equipment Effectiveness"
			dateOptions={dateOptions}
			setDateOptions={setDateOptions}
			internalNavigation={""}
			machines={machines}
			setMachines={setMachines}
			isLoading={isLoading}
			noData={(oeeData && oeeData.length === 0) || machines.every((m) => !m.checked)}
		>
			{showOverview()}
		</ExperienceContainer>
	);
}

export default errorBoundary(OverallEquipmentEffectiveness);
