import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  /* The container */
  container: {
    display: "block",
    position: "relative",
    paddingLeft: "35px",
    marginBottom: "12px",
    cursor: "pointer",
    userSelect: "none",

    /* Hide the browser's default radio button */
    "& input": {
      display: "none"
    },

    /* When the radio button is checked, add a blue background */
    "& input:checked ~ $checkmark": {
      backgroundColor: "white",
      border: "2px solid #0068AB",
    },

    /* Show the indicator (dot/circle) when checked */
    "& input:checked ~ $checkmark:after": {
      display: "block",
    },

    /* Style the indicator (dot/circle) */
    "& $checkmark:after": {
      top: "3px",
      left: "3px",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: "#0068AB",
    },
  },

  /* Create a custom radio button */
  checkmark: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    border: "2px solid #7C7B79",
    borderRadius: "50%",

    /* Create the indicator (the dot/circle - hidden when not checked) */
    "&:after": {
      content: '""',
      position: "absolute",
      display: "none",
    },
  },
}));

// <RadioButton name="myGroup" checked={() => {}} onChange={onChange}>Whatever text</RadioButton>

export default function RadioButton({ children, className, ...others }) {
  const classes = useStyles();

  return (
    <label className={`${classes.container} ${className}`}>
      {children}
      <input type="radio" {...others} />
      <span className={classes.checkmark} />
    </label>
  );
}
