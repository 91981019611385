import { getISOWeeksInYear, setISOWeek, startOfISOWeek, differenceInDays } from "date-fns";

export function getStartOfWeekDate(w, y) {
	const max = getISOWeeksInYear(new Date(y, 0, 1));
	if (w < 1 || w > max) {
		return -1;
	} else {
		const d = setISOWeek(new Date(y, 1, 1), w);
		return startOfISOWeek(d, { weekStartsOn: 1 }).toLocaleDateString();
	}
}

export function twentyFourHourToTwelveHourHourFormat(rawHour) {
	let hour = rawHour % 12;
	if (hour === 0) hour = 12;
	const suffix = rawHour > 11 ? "pm" : "am";
	return `${hour}${suffix}`;
}

export function timeLabelFormatter(dateObject, formatType, index) {
	switch (formatType) {
		case "Hours":
			if (index % 4 === 0) {
				const d = new Date(dateObject.year, dateObject.month - 1, dateObject.day, dateObject.hour);
				const rawHour = d.getHours();
				return twentyFourHourToTwelveHourHourFormat(rawHour);
			}
			return "";
		case "Days":
			if (index % 3 === 0) {
				return new Date(dateObject.year, dateObject.month - 1, dateObject.day).toLocaleDateString();
			}
			return "";

		case "Weeks":
			return getStartOfWeekDate(dateObject.week, dateObject.year);
		default:
			return "";
	}
}

export function getDateIndex(dateRange) {
	let indexDate;
	switch (dateRange.id) {
		case "Today":
		case "Yesterday":
			indexDate = "Hours";
			break;
		case "Last7Days":
		case "Last30Days":
			indexDate = "Days";
			break;
		case "Custom":
			const start = new Date(dateRange.start);
			const end = new Date(dateRange.end);
			const diff = differenceInDays(end, start);

			if (diff < 3) {
				indexDate = "Hours";
			} else if (diff < 31) {
				indexDate = "Days";
			} else {
				indexDate = "Weeks";
			}

			break;
		default:
			break;
	}
	return indexDate;
}
