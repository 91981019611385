const data = {
	AutoGluer4: "AutoGluer4",
	Em: "Em",
	ExternalSystem: "ExternalSystem",
	Fusion: "Fusion",
	KVM: "KVM",
	M1: "M1",
	PackingStation: "PackingStation",
	Scan2Pack: "Scan2Pack",
	X4: "X4",
	X5: "X5",
	X6: "X6",
	X7: "X7",
	X5wPrint: "X5wPrint",
	ZebraPrinter: "ZebraPrinter",
};

export default data;
