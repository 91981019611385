import { createUseStyles, useTheme } from "react-jss";

import { Button } from "semantic-ui-react";
import { ReactComponent as Close } from "@/assets/images/close.svg";
import React from "react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	button: {
		boxShadow: "none !important",
		margin: "0 !important",
		padding: "0 !important",
		backgroundColor: "transparent !important",
	},
}));
const CloseButton = (props) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	return (
		<Button
			data-component="CloseButton"
			className={`${classes.button} ${props.className}`}
			onClick={props.onClick}
			data-cy="close-button"
		>
			<Close data-element="Close" />
		</Button>
	);
};
export default CloseButton;
