import { Dropdown, Grid, Header, Icon, Popup, Table } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import SaveButton from "@/components/save-button";
import X5 from "@/assets/images/machine-type/X5.png";
import X5HMI from "@/assets/images/machine-type/X5HMI.svg";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	...theme.typography,
	successColor: {
		color: theme.success,
	},
	warningColor: {
		color: theme.warning,
	},
	header: {
		...theme.typography.subtitle,
		color: theme.colors.text,
	},
	subHeader: {
		fontSize: theme.typography.body["font-size"] + "!important",
		color: theme.colors.text,
	},
	machineHeading: {
		fontSize: theme.typography.textSmallBold["font-size"] + "!important",
	},
	machineAlias: {
		marginTop: "0.25rem !important",
	},
	machineStatus: {
		marginTop: "0.625rem !important",
		marginBottom: "2rem !important",
	},
	leftBox: {
		borderRadius: "0rem 0.375rem 0.375rem 0rem",
		backgroundColor: theme.colors.white,
		paddingTop: "1.5rem",
		paddingBottom: "1.5rem",
		height: "100%",
	},
	middleBox: {
		borderRadius: "0.375rem",
		paddingTop: "1.5rem",
		backgroundColor: theme.colors.white,
		height: "100%",
		"& td": {
			paddingTop: "1.5rem !important",
			paddingBottom: "1.5rem !important",
			overflow: "visible !important",
		},
		"& td:first-child, th:first-child": {
			paddingLeft: "2rem !important",
		},
		"& td:last-child, th:last-child": {
			paddingRight: "3rem !important",
		},
	},
	rightSideIcon: {
		"& i": {
			position: "absolute !important",
			right: "1rem !important",
		},
		"& i.dropdown": {
			top: "0.6rem !important",
		},
		"& i.dont": {
			top: "0.8125rem !important",
		},
		width: "100%",
	},
	columns: {
		...theme.typography.textSmallBold,
		color: theme.colors.text,
	},
	pausePrompt: {
		textAlign: "center",
		marginTop: "7rem !important",
		"& .row": {
			justifyContent: "center !important",
			paddingTop: "0.75rem !important",
			paddingBottom: "0.75rem !important",
		},
	},
	savePrompt: {
		textAlign: "center",
	},
	saveButtonParent: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		marginBottom: "5rem",
	},
	saveButton: {
		marginRight: "1rem !important",
	},
	centerVertically: {
		display: "flex",
		alignItems: "center",
	},
	trackWidths: {
		color: theme.colors.subtleText,
	},
	info: {
		color: theme.colors.primary,
		boxShadow: `0em 0em 0em 0.2em #0068ab inset !important`,
	},
	popup: {
		maxWidth: "500px",
	},
}));

const X5ChangeCorrugate = ({ corrugates, selectedMachine }) => {
	const intl = useIntl();
	const refreshInterval = 2000;
	const [allowChange, setAllowChange] = useState(false);
	const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [originalTracks, setOriginalTracks] = useState([]);
	const [succesfulSave, setSuccesfulSave] = useState(false);
	const [tracks, setTracks] = useState([...selectedMachine.tracks]);
	const token = readLocalStorage("BEARER");
	const x5Api = useAxios("/x5api/api/v1.0/machines", token);
	const theme = useTheme();

	const classes = useStyles({ theme });

	const getConfiguredTrackWidth = (machine, track) => machine.configuredTrackWidths[track.trackNumber - 1];

	const getCorrugateWithinTolerance = (corrugate, machine, track) =>
		corrugate.width <= getConfiguredTrackWidth(machine, track) + machine.corrugateWidthTolerance &&
		corrugate.width >= getConfiguredTrackWidth(machine, track) - machine.corrugateWidthTolerance;

	const getTrackSpanText = (machine, track) => {
		if (!Number.isNaN(machine.configuredTrackWidths[track.trackNumber - 1] + machine.corrugateWidthTolerance)) {
			var text =
				(machine.configuredTrackWidths[track.trackNumber - 1] - machine.corrugateWidthTolerance).toFixed(2) +
				" - " +
				(machine.configuredTrackWidths[track.trackNumber - 1] + machine.corrugateWidthTolerance).toFixed(2);
			return <div>{text}</div>;
		}

		return (
			<div>
				<span style={{ paddingRight: "10px" }}>{translate("Track settings missing")}</span>
				<Popup
					basic
					flowing
					position="bottom center"
					on="click"
					trigger={<Icon size="small" circular name="info" className={classes.info} />}
				>
					<Popup.Content>
						<span>{translate("Missing track settings settings is loaded from machine at startup")}</span>
					</Popup.Content>
				</Popup>
			</div>
		);
	};

	const getValidCorrugatesForTrack = (machine, track) => {
		var res = [];
		[
			...corrugates.filter((c) => c.id === "00000000-0000-0000-0000-000000000000"),
			...corrugates.filter((corrugate) => getCorrugateWithinTolerance(corrugate, machine, track)),
		].map((validCorrugate) =>
			res.push({
				key: `${selectedMachine.alias}-${track.trackNumber}_${validCorrugate.id}`,
				text: validCorrugate.alias,
				value: validCorrugate.id,
			})
		);
		return res;
	};

	const allowTrackToBeChanged = (machine, track) => {
		if (allowChange === false) return false;
		if (machine.settings.configuredTrackWidths[track.trackNumber - 1] === undefined) return false;
		return true;
	};

	const isValidTrackConfiguration = (machine, track) => {
		if (track.loadedCorrugate === null) return true;

		var validTracks = getValidCorrugatesForTrack(machine, track);
		var result = validTracks.filter((c) => c.value === track.loadedCorrugate.id);
		return result.length > 0;
	};

	const refresh = async () => {
		x5Api.getWithUrl(
			`${selectedMachine.id}`,
			(data) => {
				selectedMachine.status = data.status;
				selectedMachine.configuredTrackWidths = data.configuredTrackWidths;
				selectedMachine.corrugateWidthTolerance = data.corrugateWidthTolerance;
				setAllowChange(data.status === "Paused" || data.status === "Offline" || data.status === "Error");
			},
			(err) => {
				console.error(err);
			}
		);
	};

	useEffect(() => {
		const f = async () => await refresh();
		f();

		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(refresh, refreshInterval);
			return () => clearInterval(interval);
		}
	}, [refreshInterval]);

	const storeOriginalTracks = (reset = false) => {
		const localtracks = [];
		if (reset || originalTracks.length === 0) {
			tracks.forEach((track) => {
				localtracks.push(track.corrugateId);
			});
			setOriginalTracks(localtracks);
		}
	};

	useEffect(() => {
		storeOriginalTracks();
	}, [tracks]);

	useEffect(() => {
		checkSaveButtonEnabled();
	}, [originalTracks, succesfulSave]);

	useEffect(() => {
		if (succesfulSave && !allowChange) {
			setSuccesfulSave(false);
		}
	}, [allowChange]);

	const checkSaveButtonEnabled = () => {
		var modified = false;
		tracks.forEach((trk, idx) => {
			if (trk.corrugateId !== originalTracks[idx]) {
				modified = true;
			}
		});
		if (succesfulSave && modified) {
			setSuccesfulSave(false);
		}
		setSaveButtonEnabled(modified);
	};

	const selectedCorrugateChanged = (corrugateId, track, m) => {
		const corrugate = corrugates.find((c) => c.id === corrugateId);
		if (corrugate !== null || corrugate !== undefined) {
			track.alias = corrugate.alias;
			track.corrugateId = corrugate.id;
			track.corrugatePrint = corrugate.quality; // x5 print is packnet quality
			track.corrugateQuality = 0.0;
			track.corrugateThickness = corrugate.thickness;
			track.corrugateWidth = corrugate.width;
		}
		checkSaveButtonEnabled();
		setTracks([...tracks]);
	};

	const saveLoadedCorrugate = async () => {
		const updates = [];
		const request = {
			MachineId: selectedMachine.id,
			TenantId: selectedMachine.tenantId,
			Tracks: tracks,
		};
		const task = x5Api.updateWithUrl(
			`${selectedMachine.id}/corrugates`,
			request,
			(response) => {
				storeOriginalTracks(true);
				setSuccesfulSave(true);
				setIsSaving(false);
			},
			(err) => {
				setIsSaving(false);
				console.error(err);
			}
		);
		updates.push(task);
		setIsSaving(true);
		await Promise.all(updates);
	};

	const translate = (key) => {
		return intl.formatMessage({ id: key });
	};

	return (
		<Grid padded>
			<Grid.Row>
				<Grid.Column width={4} className={classes.leftBox}>
					<Grid>
						<Grid.Column width={1} />
						<Grid.Column width={14}>
							<Grid.Row>
								<Header textAlign="left" className={classes.machineHeading}>
									<div className={classes.textSmallBold}>{translate("Machine")}</div>
									<div className={classes.header2 + " " + classes.machineAlias}>{selectedMachine.alias}</div>
									<div className={classes.centerVertically + " " + classes.machineStatus}>
										<Icon
											size="large"
											className={selectedMachine.status === "Online" ? classes.successColor : classes.warningColor}
											name={(selectedMachine.status === "Online" ? "check" : "pause") + " circle"}
										/>
										<span className={classes.textSmallBold}>{translate(selectedMachine.status)}</span>
									</div>
								</Header>
								<img src={X5} style={{ width: "100%", maxWidth: "15.3125rem" }} />
							</Grid.Row>
						</Grid.Column>
					</Grid>
				</Grid.Column>
				<Grid.Column width={8}>
					<div key={selectedMachine.id} className={classes.middleBox}>
						<Table fixed singleline="true" basic="very">
							<Table.Header>
								<Table.Row verticalAlign="middle">
									<Table.HeaderCell className={classes.columns} width={3}>
										{translate("Track")}
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.columns} width={5}>
										{translate("Configured Width")}
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.columns} width={8}>
										{translate("Loaded z-Fold")}
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{tracks.map((track, i) => (
									<Table.Row key={i} verticalAlign="middle">
										<Table.Cell className={classes.columns}>{track.trackNumber}</Table.Cell>
										<Table.Cell className={classes.columns + " " + classes.trackWidths}>
											{getTrackSpanText(selectedMachine, track)}
										</Table.Cell>
										<Table.Cell className={classes.columns}>
											<Dropdown
												key={`${selectedMachine.alias}-${track.trackNumber}`}
												selection
												selectOnNavigation={false}
												value={track.corrugateId}
												className={classes.rightSideIcon}
												disabled={allowTrackToBeChanged(selectedMachine, track) === false}
												icon={allowChange ? "dropdown" : "dont"}
												options={getValidCorrugatesForTrack(selectedMachine, track)}
												placeholder={track.alias}
												style={
													isValidTrackConfiguration(selectedMachine, track)
														? {}
														: { color: "red", border: "1px solid rgb(253 4 4 / 15%)", ".dt": "" }
												}
												onChange={(e, { value }) => selectedCorrugateChanged(value, track, selectedMachine)}
											/>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</div>
				</Grid.Column>
				<Grid.Column width={4}>
					{allowChange === true ? (
						<div className={classes.savePrompt}>
							<div className={classes.saveButtonParent}>
								<SaveButton
									isSaving={isSaving}
									succesfulSave={succesfulSave}
									saveButtonEnabled={saveButtonEnabled}
									onClick={saveLoadedCorrugate}
									className={classes.saveButton}
								/>
							</div>
							<div className={classes.header}>
								{succesfulSave ? translate("Saved Successfully") : translate("Change z-Fold")}
							</div>
						</div>
					) : (
						<Grid className={classes.pausePrompt}>
							<Grid.Row>
								<span className={classes.header}>{translate("Pause Machine")}</span>
							</Grid.Row>
							<Grid.Row>
								<img src={X5HMI} style={{ width: "14.6875rem", height: "10.375rem" }} />
							</Grid.Row>
							<Grid.Row>
								<span className={classes.subHeader}>{translate("To change zFold")}</span>
							</Grid.Row>
						</Grid>
					)}
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default X5ChangeCorrugate;
