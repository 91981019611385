import { Form, Grid, Message, Modal, Segment, Header, Button } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { ReactComponent as Error } from "@/assets/images/error.svg";
import { isNullOrWhitespace } from "@/utils/form-validation";
import Loading from "@/components/loading";
import NumUpDown from "@/components/numeric-up-down/numeric-up-down";
import CloseButton from "@/components/close-button";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	nudContainer: {
		display: "flex",
		"align-items": "baseline",
	},
	nudText: {
		"padding-right": "30px",
	},
	title: {
		fontSize: "32pt !important",
		padding: "28px 28px !important",
		borderBottom: "none !important",
	},
	content: {
		display: "flex !important",
		flexDirection: "column !important",
		gap: "40px",
		padding: "0px 28px 28px 28px !important",
	},
	saveButton: {
		marginRight: "24px !important",
	},
	detail: {
		fontSize: "16pt !important",
		alignItems: "left",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row-reverse",
	},
	checkboxContainer: {
		flex: 1,
		"& i": {
			marginRight: "4px !important",
		},
	},
	leftColumn: {
		padding: "0 30px 0 20px !important",
	},
	rightColumn: {
		paddingLeft: "40px !important",
	},

	port: {
		display: "flex",
		justifyContent: "space-between !important",
		alignItems: "center !important",
	},
}));

const emptyCart = {
	mainId: "",
	name: "",
	description: "",
	ip: "",
	port: 0,
};

function NewCartModal({ open, closeWithoutSaving, saveAndClose, cart, setCart, edit = false }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setLoading(true);
		if (!edit) {
			setCart(emptyCart);
		}

		setLoading(false);
	}, []);

	const validate = () => {
		const newErrors = {};
		if (isNullOrWhitespace(cart.name)) newErrors.name = intl.formatMessage({ id: "Name is required" });
		if (isNullOrWhitespace(cart.ip)) newErrors.ip = intl.formatMessage({ id: "IP is required" });
		if (
			!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
				cart.ip
			)
		)
			newErrors.ip = intl.formatMessage({ id: "IPWrongFormat" });
		if (isNullOrWhitespace(cart.port)) newErrors.port = intl.formatMessage({ id: "Port is required" });
		setErrors(newErrors);
		return newErrors;
	};

	return (
		<Modal
			size={"large"}
			centered={true}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			className={classes.modal}
			open={open}
		>
			<Modal.Content className={classes.content}>
				<Segment.Group className={classes.group}>
					<Segment className={classes.header}>
						<Header as="h2" floated="left" className={classes.headerText}>
							<FormattedMessage id={edit ? "EditCart" : "NewCart"} />
						</Header>
						<Header floated="right" className={classes.closeButton}>
							<CloseButton
								onClick={() => {
									setErrors({});
									setCart(emptyCart);
									closeWithoutSaving();
								}}
							/>
						</Header>
						<Header floated="right" className={classes.saveButton}>
							<Button
								primary
								onClick={() => {
									const errors = validate();
									if (Object.keys(errors).length) return;
									saveAndClose();
								}}
							>
								<FormattedMessage id="Save" />
							</Button>
						</Header>
					</Segment>
					<Segment className={classes.form}>
						<Form error>
							<Grid stackable divided>
								<Grid.Row>
									{loading ? (
										<Loading />
									) : (
										<>
											<Grid.Column width={7} className={classes.leftColumn}>
												<Form.Input
													required
													label={intl.formatMessage({ id: "Name" })}
													placeholder={intl.formatMessage({ id: "Name" })}
													value={cart.name}
													onChange={(e) => setCart({ ...cart, name: e.target.value })}
													icon
													error={errors.name ? { content: errors.name } : null}
												>
													<input />
													{errors.alias && (
														<i class="icon">
															<Error className={classes.inputError} />
														</i>
													)}
												</Form.Input>
												<Form.TextArea
													label={intl.formatMessage({ id: "Description" })}
													value={cart.description}
													onChange={(e) => setCart({ ...cart, description: e.target.value })}
												></Form.TextArea>
											</Grid.Column>
											<Grid.Column width={7} className={classes.rightColumn}>
												<Form.Input
													required
													label={intl.formatMessage({ id: "IP Address" })}
													placeholder={intl.formatMessage({ id: "IP Address" })}
													value={cart.ip}
													onChange={(e) => setCart({ ...cart, ip: e.target.value })}
													icon
													error={errors.ip ? { content: errors.ip } : null}
												>
													<input />
													{errors.alias && (
														<i class="icon">
															<Error className={classes.inputError} />
														</i>
													)}
												</Form.Input>
												<Form.Input required label={intl.formatMessage({ id: "Port" })}>
													<NumUpDown
														number={parseInt(cart?.port, 10)}
														required
														minValue={1}
														maxValue={65535}
														setValue={(num) => {
															setCart({ ...cart, port: num });
														}}
													/>
												</Form.Input>
											</Grid.Column>
										</>
									)}
								</Grid.Row>
							</Grid>
							{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
						</Form>
					</Segment>
				</Segment.Group>
			</Modal.Content>
		</Modal>
	);
}

export default NewCartModal;
