import { useContext, useState } from "react";
import { ProductionContext } from "@/contexts/production-context";
import Loading from "@/components/loading";
import FailedRetry from "@/components/failed-retry";
import { readLocalStorage } from "@/api/local-storage";
import AvailableJobsTable from "./available-jobs-table";
import { useMutation, useQuery, useQueryClient, keepPreviousData } from "@tanstack/react-query";
import useAxios from "@/api/useAxios";
import { enqueueSnackbar } from "notistack";
import { useIntl, FormattedMessage } from "react-intl";
import Flex from "@/components/flex";
import styled from "styled-components";
import InProductionOrdersCard from "./in-production-orders-card/in-production-orders-card";
import Card from "@/components/card";
import { BodyRegular, Header1, SmallBook } from "@/components/typography";
import ScanInput from "./scan-input/scan-input";
import { useIsFeatureFlagEnabled } from "@/hooks/use-is-feature-flag-enabled.js";

const OrdersContainer = styled(Flex)`
	padding: 24px;
	background-color: ${(props) => props.theme.colors.lightBackground};
	min-height: calc(100vh - ${(props) => props.theme.measurements.menuBarHeight});
`;

const FlexCard = styled(Card)`
	&&& {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0;
	}
`;

const DarkGreyText = styled(SmallBook)`
	color: ${(props) => props.theme.colors.darkGreyText};
`;

function Orders() {
	const intl = useIntl();
	const { currentProductionGroup, currentMachineGroup } = useContext(ProductionContext);
	const productionGroupId = currentProductionGroup?.id;
	const token = readLocalStorage("BEARER");
	const queryClient = useQueryClient();

	const useNewEndpoint = useIsFeatureFlagEnabled("orders-page-sort-available");

	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 10;

	const selectionApiRemoveBatch = useAxios(
		"/SelectionPipelineApi/api/v1/selection/packagingSolutions/byBatchId",
		token,
	);
	const selectionApi = useAxios("/SelectionPipelineApi/api/v1", token);

	const orderByQueryKey = ["selectionPipelineOrderBy", productionGroupId];

	const orderByQueryFn = async () => {
		const response = await selectionApi.getWithUrl(
			`SelectionPipelineConfigurations/${currentProductionGroup.pipelineId}/orderBy`,
		);
		return useNewEndpoint ? response.data.sortSteps : response.data.orderBy;
	};

	const { data: orderByData, isLoading: orderByLoading } = useQuery({
		queryKey: orderByQueryKey,
		queryFn: orderByQueryFn,
		refetchInterval: false,
	});

	const batchSummaryQueryKey = ["selectionBatchSummary", productionGroupId, pageNumber];

	const batchSummaryQueryFn = async () => {
		if (useNewEndpoint) {
			let data;
			await selectionApi.addWithUrl(
				`batch/batchSummary/${productionGroupId}/pageNumber/${pageNumber}/pageSize/${pageSize}`,
				orderByData,
				(responseData) => (data = responseData),
			);

			return data;
		} else {
			const response = await selectionApi.getWithUrl(
				`batch/batchSummary/${productionGroupId}/pageNumber/${pageNumber}/pageSize/${pageSize}?orderBy=${orderByData}`,
			);
			return response.data;
		}
	};

	const { data, error, isLoading, refetch } = useQuery({
		queryKey: batchSummaryQueryKey,
		queryFn: batchSummaryQueryFn,
		refetchInterval: 3000,
		enabled: Boolean(productionGroupId) && Boolean(orderByData),
		placeholderData: keepPreviousData,
	});

	const triggerJobByLPNMutation = useMutation({
		mutationFn: ({ currentMachineGroup, lpn }) => {
			return selectionApi.updateWithUrl(
				`triggerProduction/packagingSolutions/forMachineGroup/${currentMachineGroup.id}/byLicensePlateNumber/${lpn}`,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: batchSummaryQueryKey.slice(0, -1),
			});
			enqueueSnackbar(
				intl.formatMessage({ id: "Successfully Triggered Job", defaultMessage: "Successfullly Triggered Job" }),
				{
					variant: "customSuccess",
				},
			);
		},
		onError: (error) => {
			console.error("Failed to trigger job", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Trigger Job", defaultMessage: "Failed to Trigger Job" }), {
				variant: "customError",
			});
		},
	});

	const removeJobMutation = useMutation({
		mutationFn: (id) => {
			return selectionApiRemoveBatch.remove(id);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: batchSummaryQueryKey.slice(0, -1),
			});
			enqueueSnackbar(
				intl.formatMessage({ id: "Successfully Removed Job", defaultMessage: "Successfullly Removed Job" }),
				{
					variant: "customSuccess",
				},
			);
		},
		onError: (error) => {
			console.error("Failed to remove job", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Remove Job", defaultMessage: "Failed to Remove Job" }), {
				variant: "customError",
			});
		},
	});

	const produceNextMutation = useMutation({
		mutationFn: (batchId) => {
			return selectionApi.addWithUrl(`batch/batchId/${batchId}/ProduceNext`, {
				productionGroupId,
				pipelineId: currentProductionGroup.pipelineId,
			});
		},
		onSuccess: () => {
			enqueueSnackbar(intl.formatMessage({ id: "Producing Next", defaultMessage: "Producing Next" }), {
				variant: "customSuccess",
			});
		},
		onError: (error) => {
			console.error("Failed to produce next", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Produce Next", defaultMessage: "Failed to Produce Next" }), {
				variant: "customError",
			});
		},
	});

	const produceNowMutation = useMutation({
		mutationFn: (batchId) => {
			return selectionApi.addWithUrl(`batch/batchId/${batchId}/ProduceNow`, {
				machineGroupId: currentMachineGroup.id,
			});
		},
		onSuccess: () => {
			enqueueSnackbar(intl.formatMessage({ id: "Producing Now", defaultMessage: "Producing Now" }), {
				variant: "customSuccess",
			});
		},
		onError: (error) => {
			console.error("Failed to produce now", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Produce Now", defaultMessage: "Failed to Produce Now" }), {
				variant: "customError",
			});
		},
	});

	if (isLoading || orderByLoading) {
		return <Loading />;
	}
	if (error || !data) {
		return <FailedRetry retry={refetch} />;
	}

	return (
		<OrdersContainer column gap={16}>
			<Flex gap={32} alignCenter>
				<Header1>
					<FormattedMessage id="Orders" />
				</Header1>
				<ScanInput
					triggerJobByLPN={(currentMachineGroup, lpn) => triggerJobByLPNMutation.mutate({ currentMachineGroup, lpn })}
				/>
			</Flex>
			<InProductionOrdersCard machineGroup={currentMachineGroup} />
			<FlexCard>
				<Flex justifyBetween alignCenter style={{ padding: "16px" }}>
					<BodyRegular>
						<FormattedMessage
							id="Available Orders In {pgAlias}"
							defaultMessage="Available Orders In {pgAlias}"
							values={{ pgAlias: currentProductionGroup.alias }}
						/>
					</BodyRegular>
					<DarkGreyText>{`${data.totalResults} ${intl.formatMessage({ id: "Available" })}`}</DarkGreyText>
				</Flex>
				<AvailableJobsTable
					batches={data.results}
					removeItem={(id) => removeJobMutation.mutate(id)}
					produceNext={(batchId) => produceNextMutation.mutate(batchId)}
					produceNow={(batchId) => produceNowMutation.mutate(batchId)}
					setPageNumber={setPageNumber}
					pageSize={pageSize}
					isLoading={isLoading}
					totalResults={data.totalResults}
				/>
			</FlexCard>
		</OrdersContainer>
	);
}

export default Orders;
