import React, { useState } from "react";
import { Form, FormField, FormInput, Grid, GridRow, Button } from "semantic-ui-react";
import LoginSplashFrame from "../login-splash-frame";
import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_LightBG.svg";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { initiatePasswordReset } from "@/api/identity-api";

const useStyles = createUseStyles((theme) => ({
	loginGridContainer: {
		flex: "1",
		width: "40%",
		minWidth: "360px",
		maxWidth: "600px",
	},
	loginBackground: {
		height: "100vh",
		flex: "1",
		padding: "0 10rem",
		display: "flex !important",
		alignItems: "center !important",
		justifyContent: "center !important",
	},
	fullWidth: {
		width: "100%",
	},
	form: {
		width: "100%",
	},
	gridRow: {
		paddingBottom: "2rem",
	},
	resetPasswordButton: {
		textTransform: "none !important",
		width: "100%",
	},
	errorState: {
		color: "#FF3743",
	},
}));

const ForgotPassword = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(true);

	const isValidEmail = () => {
		// Regular expression pattern for basic email validation
		const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return pattern.test(email);
	};

	const handleResetPasswordSubmit = () => {
		let valid = isValidEmail();
		setIsEmailValid(valid);
		if (!valid) {
			return;
		}
		setLoading(true);
		initiatePasswordReset({ email });

		setEmailSent(true);
		setLoading(false);
	};

	return (
		<LoginSplashFrame>
			<div className={classes.loginBackground}>
				<Grid className={classes.loginGridContainer}>
					<div className={classes.fullWidth}>
						<GridRow className={classes.gridRow}>
							<div>
								<img data-cy="packnet" src={CloudPlatformLogo} style={{ height: "30px" }} alt="PackNet" />
							</div>
						</GridRow>
						<Form className={classes.form}>
							<GridRow className={classes.gridRow}>
								{!emailSent
									? intl.formatMessage({ id: "Please Enter Your Email Below" })
									: intl.formatMessage({ id: "An email has been sent" })}
							</GridRow>
							<span className={!isEmailValid ? classes.errorState : ""}>
								<GridRow className={classes.fullWidth}>{intl.formatMessage({ id: "Email" })}</GridRow>
							</span>
							<FormField>
								<FormInput className={classes.gridRow}>
									<GridRow className={classes.fullWidth}>
										<Form.Field className={classes.field} data-cy="form-field-email">
											<Form.Input
												fluid
												placeholder={intl.formatMessage({ id: "Email Address Placeholder" })}
												id="emailInput"
												type="text"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												error={!isEmailValid}
												tabIndex={1}
												data-cy="email"
											/>
										</Form.Field>
									</GridRow>
								</FormInput>
							</FormField>
							<GridRow className={classes.gridRow}>
								<Button
									id="resetPasswordButton"
									primary
									tabIndex={3}
									basic={email.length <= 0}
									onClick={handleResetPasswordSubmit}
									className={classes.resetPasswordButton}
									data-cy="login-button"
									loading={loading}
									disabled={loading}
								>
									{!emailSent
										? intl.formatMessage({ id: "Reset Password" })
										: intl.formatMessage({ id: "Resend email" })}
								</Button>
							</GridRow>
						</Form>
						<GridRow className={classes.fullWidth}>
							<a href="/PackNet/login">{intl.formatMessage({ id: "ReturnToLogin" })}</a>
						</GridRow>
					</div>
				</Grid>
			</div>
		</LoginSplashFrame>
	);
};
export default ForgotPassword;
