import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import DualProgressBarList from "@/views/reporting/dual-progress-bar-list";
import SwimLanes from "@/views/reporting/swim-lanes";
import { getPaginationResult, getSwimLaneMaxiumum } from "@/views/reporting/functions/swimlanes";
import { Popup, Icon } from "semantic-ui-react";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	legend: {
		marginBottom: "32px",
		display: "flex",
		flexDirection: "row",
		fontFamily: "Stolzl",
		fontSize: "13px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
	completedCircle: {
		backgroundColor: "#353430",
	},
	failedCircle: {
		backgroundColor: "#ff005c",
	},
	divider: {
		width: "32px",
	},
	popup: {
		"&:before": {
			borderBottomRightRadius: "2px",
		},
	},
}));

function ProducedByMachines({ reportData, dateRange, limit, sortByMachine, sortByAmount }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [presentationData, setPresentationData] = useState({});
	const [upperBound, setUpperBound] = useState(0);
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});

	function sortData(data) {
		let newData = [];
		if (sortByAmount === "desc") {
			newData = data[""].sort((a, b) => { 
				let bTotal = b.cartonsProduced + b.cartonsFailed;
				let aTotal = a.cartonsProduced + a.cartonsFailed;
				return (aTotal > bTotal) ? -1 : 1;
			});
		} else if (sortByAmount === "asc") {
			newData = data[""].sort((a, b) => { 
				let bTotal = b.cartonsProduced + b.cartonsFailed;
				let aTotal = a.cartonsProduced + a.cartonsFailed;
				return (aTotal > bTotal) ? 1 : -1;
			});
		}

		if (sortByMachine === "desc") {
			newData = data[""].sort((a, b) => {
				return b.machineName.localeCompare(a.machineName, undefined, {numeric: true});
			});
		} else if (sortByMachine === "asc") {
			newData = data[""].sort((a, b) => {
				return a.machineName.localeCompare(b.machineName, undefined, {numeric: true});
			});
		}
		return {"": newData};
	}
	function mapData(data) {
		let aggregation = data.byMachine;

		aggregation = aggregation.sort((a, b) => (b.cartonsProduced > a.cartonsProduced ? 1 : -1));
		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.cartonsProduced + a.cartonsFailed)
		);

		const upper = getSwimLaneMaxiumum(maximumConsumed);
		setUpperBound(upper);

		aggregation = aggregation.map((item) => {
			return {
				...item,
				tenantId: "",
				siteId: "",
				siteName: "",
				cartonProducedPercentage: (item.cartonsProduced / upper) * 100,
				cartonFailedPercentage:
					item.cartonsFailed > 0 ? ((item.cartonsProduced + item.cartonsFailed) / upper) * 100 : 0,
			};
		});

		setPresentationData(
			sortData(aggregation.reduce((dict, el, index) => {
				!dict[el.tenantId] ? (dict[el.tenantId] = [el]) : dict[el.tenantId].push(el);
				return dict;
			}, []))
		);
	}

	function processPresentationData(presentationData) {
		let items = [];
		for (const property in presentationData) {
			const siteInformation = presentationData[property];
			const site = {
				heading: siteInformation[0].siteName,
			};
			const corrugated = siteInformation.map((c) => {
				return {
					cartonsProduced: c.cartonsProduced,
					cartonsFailed: c.cartonsFailed,
					cartonsProducedPercentage: c.cartonProducedPercentage,
					cartonsFailedPercentage: c.cartonFailedPercentage,
					text: c.machineName,
					value: (c.cartonsProduced + c.cartonsFailed).toLocaleString(undefined),
				};
			});
			site.items = corrugated;
			items.push(site);
		}
		return items;
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	useEffect(() => {
		if (Object.keys(presentationData).length === 0) return;
		let newData = sortData(presentationData);
		if (newData[""].length > 0)
			setPresentationData({...newData});
	}, [sortByMachine, sortByAmount]);

	let items = processPresentationData(presentationData);
	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<SwimLanes upperBound={upperBound} numberOfHeadings={1} key="produced-by-machines-swim-lanes">
				{limit === 1000 &&
					items.map((item, i) => {
						return (
							<DualProgressBarList
								heading={item.heading}
								items={item.items}
								limit={1000}
								key={`produced-by-machines-dual-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 && (
					<DualProgressBarList
						heading={paginationResult.heading}
						items={paginationResult.items}
						limit={paginationResult.items.length}
					/>
				)}
			</SwimLanes>,
			<div className={classes.legend} key="produced-by-machines-legend">
				<div>
					<Popup
						className={classes.popup}
						content={intl.formatMessage({ id: "A carton that was produced successfully" })}
						trigger={
							<span>
								<Icon circular className={classes.completedCircle} size="mini" />
								{intl.formatMessage({ id: "Complete" })}
							</span>
						}
						inverted
						position="top center"
					/>
				</div>
				<div className={classes.divider}>&nbsp;</div>
				<div>
					<Popup
						className={classes.popup}
						content={intl.formatMessage({ id: "A carton that began production but was not completed" })}
						trigger={
							<span>
								<Icon circular className={classes.failedCircle} size="mini" />
								{intl.formatMessage({ id: "Broken" })}
							</span>
						}
						inverted
						position="top center"
					/>
				</div>
			</div>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="produced-by-machine-swim-lane-pagination"
			/>,
		]
	) : (
		<div></div>
	);
}

export default ProducedByMachines;
