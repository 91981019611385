import { Button, Divider, Form, Grid, Header, List, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";

import CheckboxButton from "@/components/checkbox-button";
import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import useOnLoadedEffect from "@/components/use-on-loaded-effect";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	loading: {
		//This sucks because there is a bug in Semantic that they lose icon style in a dimmer (modal)
		"& div": {
			"& div": {
				color: "black !important",
				"&:before": {
					borderColor: "rgba(0, 0, 0, 0.1) !important",
				},
			},
		},
	},
}));

const passwordStrengthRules = {
	minLength: 8,
	minLowercase: 1,
	minUppercase: 1,
	minNumbers: 1,
	minSymbols: 1,
};

const NewUser = (props) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { closeModal } = props;
	const [errors, setErrors] = useState({});
	const [roles, setRoles] = useState([]);
	const [settings, setSettings] = useState({});
	const { setList, edit, setEdit } = useContext(ListContext);
	const { add, updateWithId, updateWithUrl, get } = useAxios("/IdentityApi/api/v1/users", token);
	const roleApi = useAxios("/IdentityApi/api/v1/roles", token);
	const settingsApi = useAxios("/identityApi/api/v1/Settings", token);

	const validatePhoneForE164 = (phoneNumber) => {
		const regEx = /^\+[1-9]\d{10,14}$/;
		return regEx.test(phoneNumber);
	};

	var generatePassword = (
		length = 20,
		validChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
	) => 
		Array.from(crypto.getRandomValues(new Uint32Array(length)))
			.map((x) => validChars[x % validChars.length])
			.join("") + "Aa0!";

	const validate = (settings) => {
		const newErrors = {};
		const isEdit = edit.id;

		if (!edit.userName) newErrors.userName = intl.formatMessage({ id: "Username is required" });
		if (!edit.firstName) newErrors.firstName = intl.formatMessage({ id: "First Name is required" });
		if (!edit.lastName) newErrors.lastName = intl.formatMessage({ id: "Last Name is required" });
		if (edit.phoneNumber && !validatePhoneForE164(edit.phoneNumber))
			newErrors.phoneNumber = intl.formatMessage({ id: "Phone Number is Invalid: required format +12125551212" });

		if (!settings.requirePacksizeOkta) {
			if (!isEdit || edit.password || edit.password2) {
				if (!edit.password) newErrors.password = intl.formatMessage({ id: "Password is required" });
				if (!edit.password2) newErrors.password2 = intl.formatMessage({ id: "Password confirmation is required" });
				if (edit.password !== edit.password2) newErrors.password2 = intl.formatMessage({ id: "Passwords must match" });
				if (edit.password && !isStrongPassword(edit.password, passwordStrengthRules))
					newErrors.password = intl.formatMessage({ id: "Password is not strong enough" });
			}
		} else {
			edit.password = generatePassword();
			edit.password2 = edit.password;
		}

		if (edit.userName && edit.requireEmailAsUsername && !isEmail(edit.userName))
			newErrors.userName = intl.formatMessage({ id: "Email must be valid" });

		setErrors(newErrors);
		return newErrors;
	};

	useOnLoadedEffect(() => {
		settingsApi.get().then(setSettings);
		roleApi.get().then(setRoles);
	});

	const close = () => {
		try {
			closeModal();
		} catch (err) {
			console.error(err);
		}
	};

	const saveAndClose = async () => {
		var errors = validate(settings);
		if (Object.keys(errors).length) return;
		try {
			if (edit.id && edit.password) {
				await updateWithId(edit, setList);
			} else if (edit.id) {
				await updateWithUrl(`${edit.id}/withoutPassword`, edit, () => get(setList));
			} else await add(edit, setList);

			close();
		} catch (e) {
			if (e.response && e.response.status === 409)
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage(
						{
							id: "Failed to save User: An item already exists with the Username {name}",
						},
						{ name: edit.userName }
					),
				});
			else
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage({ id: "Failed to save User" }),
				});
		}
	};

	if (!settings.id || !roles.length)
		return (
			<span className={classes.loading}>
				<Loading />
			</span>
		);

	return (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit User" : "New User"} />
				</Header>
				<Header floated="right" className={classes.closeButton}>
					<CloseButton onClick={close} />
				</Header>
				<Header floated="right" className={classes.saveButton} data-cy="save-button">
					<Button primary onClick={saveAndClose}>
						<FormattedMessage id="Save" />
					</Button>
				</Header>
			</Segment>
			<Segment className={classes.form}>
				<Form error>
					<Grid relaxed="very" stackable className={classes.topGrid} divided>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Form.Input data-cy="first-name"
									label={intl.formatMessage({ id: "First Name" })} 
									placeholder={intl.formatMessage({ id: "First Name" })} 
									required
									value={edit.firstName}
									onChange={(e) => setEdit({ ...edit, firstName: e.target.value })}
									error={errors.firstName ? { content: errors.firstName } : null}
								/>
								<Form.Input data-cy="last-name"
									label={intl.formatMessage({ id: "Last Name" })}
									placeholder={intl.formatMessage({ id: "Last Name" })}
									required
									value={edit.lastName}
									onChange={(e) => setEdit({ ...edit, lastName: e.target.value })}
									error={errors.lastName ? { content: errors.lastName } : null}
								/>
								<Form.Input data-cy="phone-number"
									label={intl.formatMessage({ id: "Phone Number" })}
									placeholder={intl.formatMessage({ id: "Phone Number" })}
									value={edit.phoneNumber}
									onChange={(e) => setEdit({ ...edit, phoneNumber: e.target.value })}
									error={errors.phoneNumber ? { content: errors.phoneNumber } : null}
								/>
								<div>
									<FormattedMessage id="Password Rules:" />
									<List bulleted size="mini">
										<List.Item>
											<FormattedMessage id="Minimum length of 8" />
										</List.Item>
										<List.Item>
											<FormattedMessage id="Must contain lowercase characters" />
										</List.Item>
										<List.Item>
											<FormattedMessage id="Must contain uppercase characters" />
										</List.Item>
										<List.Item>
											<FormattedMessage id="Must contain numbers" />
										</List.Item>
										<List.Item>
											<FormattedMessage id="Must contain symbols" />
										</List.Item>
									</List>
								</div>
							</Grid.Column>

							<Grid.Column verticalAlign="middle">
								{settings.requireEmailAsUsername && (
									<Form.Input data-cy="email-address"
										label={intl.formatMessage({ id: "Email Address" })}
										placeholder={intl.formatMessage({ id: "Email Address" })}
										required
										value={edit.userName}
										onChange={(e) => {
											setEdit({ ...edit, userName: e.target.value });
										}}
										error={errors.userName ? { content: errors.userName } : null}
									/>
								)}
								{!settings.requireEmailAsUsername && (
									<Form.Input data-cy="user-name"
										label={intl.formatMessage({ id: "Username" })}
										placeholder={intl.formatMessage({ id: "Username" })}
										required
										value={edit.userName}
										onChange={(e) => {
											setEdit({ ...edit, userName: e.target.value });
										}}
										error={errors.userName ? { content: errors.userName } : null}
									/>
								)}
								{settings.requirePacksizeOkta && <FormattedMessage id="Passwords Set Via SSO" />}
								<Form.Input 
									label={intl.formatMessage({ id: "Password" })}
									placeholder={intl.formatMessage({ id: "Enter User Password" })}
									type="password"
									disabled={settings.requirePacksizeOkta}
									required
									onChange={(e) => setEdit({ ...edit, password: e.target.value })}
									icon
									error={errors.password ? { content: errors.password } : null}
								>
									<input />
									{errors.password && (
										<i class="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
								<Form.Input 
									label={intl.formatMessage({ id: "Confirm Password" })}
									placeholder={intl.formatMessage({ id: "Confirm User Password" })}
									type="password"
									disabled={settings.requirePacksizeOkta}
									required
									onChange={(e) => setEdit({ ...edit, password2: e.target.value })}
									icon
									error={errors.password2 ? { content: errors.password2 } : null}
								>
									<input />
									{errors.password2 && (
										<i class="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Divider />

					<Form.Field label={intl.formatMessage({ id: "Roles" })} />
					<Grid columns={2} relaxed="very" stackable>
						{roles.map((r) => (
							<Grid.Column key={r.id}>
								<CheckboxButton
									value={r.id}
									defaultChecked={edit?.assignedRoles?.includes(r.id)}
									onChange={(e) => {
										const checked = e.target.checked;
										const value = e.target.value;
										let roles = edit.assignedRoles ?? [];
										var index = roles.indexOf(value);
										if (!checked && index !== -1) roles.splice(index, 1);
										else roles.push(value);
										setEdit({ ...edit, assignedRoles: roles });
									}}
								>
									{r.name}
								</CheckboxButton>
							</Grid.Column>
						))}
					</Grid>
					{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
				</Form>
			</Segment>
		</Segment.Group>
	);
};

export default NewUser;
