export const countryOptions = [
    { text: "Czech", value: "cs" },
    { text: "Dansk", value: "da-DK" },
    { text: "Deutsch", value: "de-DE" },
    { text: "English", value: "en" },
    { text: "Español (Americas)", value: "es-419" },
    { text: "Español (Europe)", value: "es-ES" },
    { text: "Flemish", value: "fl" },
    { text: "Français Canadien", value: "fr-CA" },
    { text: "Français", value: "fr-FR" },
    { text: "Magyar", value: "hu-HU" },
    { text: "Italiano", value: "it-IT" },
    { text: "Nederlands", value: "nl-NL" },
    { text: "Polski", value: "pl-PL" },
    { text: "Portuguese", value: "pt" },
    { text: "Română", value: "ro-RO" },
    { text: "Slovensky", value: "sk-SK" },
    { text: "Svenska", value: "sv-SE" }
];

export const translationLoader = {
    "cs": () => import("./cs"),
    "da-DK": () => import("./da_DK"),
    "de-DE": () => import("./de_DE"),
    "en": () => import("./english"),
    "es-419": () => import("./es_419"),
    "es-ES": () => import("./es_ES"),
    "fl": () => import("./fl"),
    "fr-CA": () => import("./fr_CA"),
    "fr-FR": () => import("./fr_FR"),
    "hu-HU": () => import("./hu_HU"),
    "it-IT": () => import("./it_IT"),
    "nl-NL": () => import("./nl_NL"),
    "pl-PL": () => import("./pl_PL"),
    "pt": () => import("./pt"),
    "ro-RO": () => import("./ro_RO"),
    "sk-SK": () => import("./sk_SK"),
    "sv-SE": () => import("./sv_SE")
};

export const getTranslationLoader = (language) => {
    return translationLoader[language] || translationLoader.en;
};
