import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";
import DashboardCard from "@/views/reporting/dashboard-card";
import TotalPercentage from "./cards/total-percentage";
import TotalTrim from "./cards/total-trim";
import TrimByBaleWidth from "./cards/by-bale-width";
import TrimByMachine from "./cards/by-machine";
import TrimByMachineOverTime from "./cards/by-machine-over-time";
import { getBucketTrimByPlannedProductionSchedulesForSite } from "@/views/reporting/services/API/Reports/trim-api";
import { getPlannedProductionDataForSite } from "@/views/reporting/services/API/Reports/planned-production-api";
import { incrementDateRange, midnightDate } from "@/views/reporting/functions/index";
import { convertUomRawValue, getOriginalUOM, getTargetUOM } from "@/views/reporting/functions/unit-of-measure";
import ExperienceContainer from "../layout/experience-container";
import Row from "../layout/row";
import errorBoundary from "@/components/error-boundary";

const useStyles = createUseStyles(() => ({
	topRightTop: {
		display: "flex",
		flexDirection: "row",
		gap: "18px 18px",
		"& > div": {
			flexBasis: "49%",
			height: "408px",
		},
	},
	topRightBottom: {
		flexBasis: "45%",
	},
}));

function Trim({ currentUser }) {
	const intl = useIntl();
	const maxBaleSelection = 10;
	const maxMachineSelection = 10;
	const defaultColor = "#353430";
	const subtitles = {
		baleWidth: ["Trim", "percent Used"],
		byMachine: ["Machines", "percent Used"],
	};
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const selectedSites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [isMetric, setIsMetric] = useState(false);
	const isSiteMetric = currentUser.unitofmeasure === "mm";
	const [sortByMachine, setSortByMachine] = useState("none");
	const [sortByAmount, setSortByAmount] = useState("desc");
	const [machines, setMachines] = useState(() => {
		const machinesSaved = localStorage.getItem("MACHINES");
		return machinesSaved !== null && machinesSaved !== "undefined" ? JSON.parse(machinesSaved) : [];
	});

	const [internalNavigation, setInternalNavigation] = useState("");

	const [dateOptions, setDateOptions] = useState({
		start: midnightDate(new Date()),
		end: midnightDate(new Date()),
	});

	async function getData(sites) {
		setIsLoading(true);
		let plannedProduction = [];
		const newDateRange = incrementDateRange(dateOptions);
		const featureFlagWIP = false; // isFeatureFlagEnabled(currentUser, "pack-net-reporting-wip");

		if (featureFlagWIP) {
			plannedProduction = await getPlannedProductionDataForSite(sites, newDateRange);
		}

		const trim = await getBucketTrimByPlannedProductionSchedulesForSite(
			plannedProduction,
			sites,
			machines.filter((m) => m.checked).map((m) => m.id),
			newDateRange,
		);
		const [known, originalUOM] = getOriginalUOM(trim, isSiteMetric);
		const preferredMeasurement = getTargetUOM(known, originalUOM, isSiteMetric);
		setIsMetric(preferredMeasurement === "squaredMeters");

		const convertItem = (item) => {
			return {
				...item,
				totalZFoldArea: convertUomRawValue(item.totalZFoldArea, originalUOM, preferredMeasurement),
				trimArea: convertUomRawValue(item.trimArea, originalUOM, preferredMeasurement),
			};
		};

		trim.totalZFoldArea = convertUomRawValue(trim.totalZFoldArea, originalUOM, preferredMeasurement);
		trim.totalTrimArea = convertUomRawValue(trim.totalTrimArea, originalUOM, preferredMeasurement);
		trim.byMachine = trim.byMachine.map(convertItem);
		trim.byBaleWidth = trim.byBaleWidth.map(convertItem);
		trim.overTime = trim.overTime.map((item) => {
			item.data = item.data.map(convertItem);
			return item;
		});

		setData(trim);
		setIsLoading(false);
	}

	subtitles.byMachine = [
		{
			id: "Machines",
			onClick: () => {
				if (sortByAmount !== "none") {
					setSortByAmount("none");
				}
				if (sortByMachine === "desc") {
					setSortByMachine("asc");
				} else {
					setSortByMachine("desc");
				}
			},
			icon: () => {
				if (sortByMachine === "asc") {
					return <Icon name="arrow up" />;
				}
				if (sortByMachine === "desc") {
					return <Icon name="arrow down" />;
				}
				return "";
			},
		},
		{
			id: "percent Used",
			onClick: () => {
				if (sortByMachine !== "none") {
					setSortByMachine("none");
				}
				if (sortByAmount === "desc") {
					setSortByAmount("asc");
				} else {
					setSortByAmount("desc");
				}
			},
			icon: () => {
				if (sortByAmount === "asc") {
					return <Icon name="arrow up" />;
				}
				if (sortByAmount === "desc") {
					return <Icon name="arrow down" />;
				}
				return "";
			},
		},
	];

	function showOverview() {
		const totalTrimTitle = `Total Trim ${isMetric ? "sq meters" : "sq ft"}`;
		return [
			<Row size="half" key="left">
				<div className={classes.topRightTop}>
					<DashboardCard key="Trim (%)" title="Trim percentage" dateRange={dateOptions}>
						<TotalPercentage reportData={data} dateRange={dateOptions} color={defaultColor} />
					</DashboardCard>
					<DashboardCard key="Total Trim (sq. ft.)" title={totalTrimTitle} dateRange={dateOptions}>
						<TotalTrim reportData={data} dateRange={dateOptions} isMetric={isMetric} />
					</DashboardCard>
				</div>
				<div className={classes.topRightBottom}>
					<DashboardCard
						key="Trim by Machine (%)"
						title="Trim by Machine in percentage"
						dateRange={dateOptions}
						columns={2}
						limit={maxMachineSelection}
						setInternalNavigation={setInternalNavigation}
						navigationDestination={"byMachine"}
						color={defaultColor}
						subtitles={subtitles.byMachine}
					>
						<TrimByMachine
							reportData={data}
							dateRange={dateOptions}
							limit={maxMachineSelection}
							color={defaultColor}
							isMetric={isMetric}
							sortByAmount={sortByAmount}
							sortByMachine={sortByMachine}
						/>
					</DashboardCard>
				</div>
			</Row>,
			<Row size="half" key="right">
				<DashboardCard
					key="Trim by Bale Width (%)"
					title="Trim by Bale Width in percentage"
					dateRange={dateOptions}
					setInternalNavigation={setInternalNavigation}
					limit={maxBaleSelection}
					navigationDestination={"byBaleWidth"}
					color={defaultColor}
					subtitles={subtitles.baleWidth}
				>
					<TrimByBaleWidth
						reportData={data}
						dateRange={dateOptions}
						limit={maxBaleSelection}
						color={defaultColor}
						isMetric={isMetric}
					/>
				</DashboardCard>
			</Row>,
			<Row bottom key="bottom">
				<DashboardCard
					key="Trim by Machine Over Time (%)"
					title="Trim by Machine in percentage"
					dateRange={dateOptions}
					columns={3}
				>
					<TrimByMachineOverTime reportData={data} dateRange={dateOptions} />
				</DashboardCard>
			</Row>,
		];
	}

	function showBaleWidth() {
		return (
			<Row bottom>
				<DashboardCard
					key="Trim by Bale Width (%)"
					title="Trim by Bale Width in percentage"
					dateRange={dateOptions}
					columns={3}
					subtitles={subtitles.baleWidth}
				>
					<TrimByBaleWidth reportData={data} dateRange={dateOptions} limit={1000} color={defaultColor} />
				</DashboardCard>
			</Row>
		);
	}

	function showMachine() {
		return (
			<Row bottom>
				<DashboardCard
					key="Trim by Machine (%)"
					title="Trim by Machine in percentage"
					dateRange={dateOptions}
					columns={3}
					subtitles={subtitles.byMachine}
				>
					<TrimByMachine
						reportData={data}
						dateRange={dateOptions}
						limit={1000}
						setInternalNavigation={setInternalNavigation}
						color={defaultColor}
						isMetric={isMetric}
						sortByAmount={sortByAmount}
						sortByMachine={sortByMachine}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function showDashboard() {
		switch (internalNavigation) {
			case "":
				return showOverview();
			case "byBaleWidth":
				return showBaleWidth();
			case "byMachine":
				return showMachine();
			default:
				return <div>{intl.formatMessage({ id: "This is not the card you are looking for" })}</div>;
		}
	}
	useEffect(() => {
		if (dateOptions.id) getData(selectedSites, dateOptions);
	}, [dateOptions, selectedSites, machines]);

	return (
		<ExperienceContainer
			currentUser={currentUser}
			header="Trim Overview"
			backMessage="Back to Trim Overview"
			dateOptions={dateOptions}
			setDateOptions={setDateOptions}
			internalNavigation={internalNavigation}
			setInternalNavigation={setInternalNavigation}
			machines={machines}
			setMachines={setMachines}
			isLoading={isLoading}
			noData={(data.byMachine && data.byMachine.length === 0) || machines.every((m) => !m.checked)}
		>
			{showDashboard()}
		</ExperienceContainer>
	);
}
export default errorBoundary(Trim);
