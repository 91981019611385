import { Button } from "semantic-ui-react";
import React from "react";
import { ReactComponent as TrashCan } from "@/assets/images/trash.svg";

const DeleteButton = (props) => (
  <Button basic className={props.buttonstyle} {...props} data-cy="delete-button">
    <TrashCan />
  </Button>
);
export default DeleteButton;
