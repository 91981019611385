import { Button, Header, Message } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import {
	findProduct,
	flattenCustomAttributes,
	postProduct,
	putProduct,
	unflattenCustomAttributes,
} from "@/api/dim-product-api";
import { getCurrentConfiguration, getIdentifiers } from "@/api/dim-config-api";
import { useNavigate, useSearchParams } from "react-router-dom";

import AlphaNumeric from "@/views/dimensioning/capture-forms/alpha-numeric";
import Bool from "@/views/dimensioning/capture-forms/bool";
import Date from "@/views/dimensioning/capture-forms/date";
import Dimensions from "@/views/dimensioning/capture-forms/dimensions";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import Nesting from "@/views/dimensioning/capture-forms/nesting";
import Orientation from "@/views/dimensioning/capture-forms/orientation";
import PacksizeSelect from "@/views/dimensioning/capture-forms/select";
import DimensioningVoidFill from "@/views/dimensioning/capture-forms/void-fill";
import Weight from "@/views/dimensioning/capture-forms/weight";
import { sleep } from "@/utils/sleep";

const useStyles = createUseStyles((theme) => ({
	grid: {
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "space-between",
	},
	leftColumn: {
		backgroundColor: "white",
		width: "calc(25vw - 32px)",
	},
	rightColumn: {
		backgroundColor: theme.light,
		padding: "1.5em !important",
		paddingRight: "2.3em !important",
		height: "calc(100vh - 128px)",
		overflow: "auto",
		overflowY: "scroll !important",
		width: "75vw",
	},
	header: {
		padding: "1em !important",
		paddingTop: "3em !important",
	},
	subHeader: {
		paddingTop: "1em !important",
	},
	formEntry: {
		borderTop: "2px !important",
		marginTop: "0px !important",
		paddingTop: "24px !important",
	},
	footer: {
		position: "fixed",
		bottom: "0",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignItems: "center",
		alignSelf: "auto",
		paddingLeft: "1.5rem",
		paddingBottom: "1.5rem",
	},
}));

function isNumber(value) {
	const num = parseFloat(value);
	return !isNaN(num);
}

function CollectDataPage() {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const defaultProduct = {
		attributes: {},
		customAttributes: {},
		identifiers: {},
		dimensions: { length: 0, width: 0, height: 0 },
		quantity: 1,
		voidFill: { length: 0, width: 0, height: 0 },
	};
	const [product, setProduct] = useState(defaultProduct);
	const [tenantConfiguration, setTenantConfiguration] = useState({});
	const [identifiers, setIdentifiers] = useState([]);
	const [errors, setErrors] = useState({});
	const [messageBox, setMessageBox] = useState({
		show: false,
		message: "",
		isError: true,
	});
	const [isEditing, setIsEditing] = useState(false);
	const [mainIdType, setMainIdType] = useState("");

	function validate(product) {
		let errors = [];
		tenantConfiguration.attributeCaptureSteps.forEach((step) => {
			switch (step.type) {
				case "String":
					if (step.isRequired) {
						const id = identifiers.find((id) => id.key === step.name);
						if (id && (!product.identifiers || !product.identifiers[step.name])) {
							errors = { ...errors, [step.name]: true };
						} else if (step.name === "Name" && !product.name) {
							errors = { ...errors, [step.name]: true };
						} else if (step.name === "Description" && !product.description) {
							errors = { ...errors, [step.name]: true };
						}
					}
					if (step.maxLength && product[step.name]?.length > step.maxLength) {
						errors = { ...errors, [step.name]: true };
					}
					break;
				case "Dimensions":
					if (!isNumber(product?.dimensions?.length)) {
						errors = { ...errors, length: true };
					}
					if (!isNumber(product?.dimensions?.width)) {
						errors = { ...errors, width: true };
					}
					if (!isNumber(product?.dimensions?.height)) {
						errors = { ...errors, height: true };
					}
					break;
				case "VoidFill":
					if (product.canContain) {
						if (product?.voidFill?.length <= 0 || product?.voidFill?.length >= product?.dimensions.length) {
							errors = { ...errors, voidFillLength: true };
						}
						if (product.voidFill?.width <= 0 || product.voidFill?.width >= product.dimensions.width) {
							errors = { ...errors, voidFillWidth: true };
						}
						if (product.voidFill?.height <= 0 || product.voidFill?.height >= product.dimensions.height) {
							errors = { ...errors, voidFillHeight: true };
						}
						break;
					}
					break;

				default:
					break;
			}
		});
		return errors;
	}

	async function saveProduct(product) {
		const newErrors = validate(product);
		if (Object.keys(newErrors).length) {
			setErrors(newErrors);

			setMessageBox({
				show: true,
				message: "Validation Errors",
				isError: true,
			});

			await sleep(5 * 1000);

			setMessageBox({ show: false });
			return;
		}
		setLoading(true);
		try {
			if (isEditing) {
				await putProduct(unflattenCustomAttributes(product, tenantConfiguration), product.identifiers[mainIdType]);
			} else {
				await postProduct(unflattenCustomAttributes(product, tenantConfiguration));
			}

			setProduct(defaultProduct);
			setMessageBox({ show: true, message: "Product Saved", isError: false });

			const returnTo = searchParams.get("returnTo");
			if (returnTo) {
				navigate(`/dimensioning/${returnTo}`);
			}
		} catch (err) {
			if (err?.response?.status === 409) {
				setErrors({ [err.response.data]: true });
				setMessageBox({
					show: true,
					message: `Duplicate ${err.response.data}`,
					isError: true,
				});
			} else {
				setMessageBox({
					show: true,
					message: "Unexpected Error",
					isError: true,
				});
				console.error(err);
			}
		}

		setLoading(false);

		await sleep(5 * 1000);
		setMessageBox({ show: false });
	}

	function cancelHandler() {
		const returnTo = searchParams.get("returnTo");
		if (returnTo) {
			navigate(`/dimensioning/${returnTo}`);
		}
	}

	async function load() {
		setFailed(false);
		setLoading(true);
		const ids = await getIdentifiers();
		setIdentifiers(ids);

		try {
			const config = await getCurrentConfiguration();
			setTenantConfiguration(config);
			setMainIdType(config.mainIdentifierType);

			let newProduct;
			const productId = searchParams.get("productId");
			if (productId) {
				const edit = searchParams.get("edit");
				if (edit) {
					setIsEditing(true);
					const result = await findProduct(productId);
					newProduct = flattenCustomAttributes(result.product, config);
				}

				const finalProduct = {
					...newProduct,
					identifiers: { [config.mainIdentifierType]: productId },
				};
				setProduct(finalProduct);
			}
		} catch (err) {
			console.error(err);
			setFailed(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		load();
	}, []);

	function getFormComponent(step) {
		var set;

		switch (step.type) {
			case "String":
				set = (text) => {
					const id = identifiers.find((id) => id.key === step.name);

					if (id) {
						product.identifiers[step.name] = text;
					} else if (step.name === "Name") {
						product.name = text;
					} else if (step.name === "Description") {
						product.description = text;
					} else if (step.name === "Product Image") {
						product.productImage = text;
					} else {
						product[step.name] = text;
					}

					setProduct({ ...product });
				};
				let value;
				const id = identifiers.find((id) => id.key === step.name);
				if (id) {
					if (product.identifiers) value = product.identifiers[step.name];
				} else if (step.name === "Name") {
					value = product.name;
				} else if (step.name === "Description") {
					value = product.description;
				} else if (step.name === "Product Image") {
					value = product.productImage;
				} else {
					value = product[step.name];
				}
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt ?? step.name}
						defaultPrompt={intl.formatMessage({ id: "Enter Text" })}
						callback={set}
						error={errors[step.name]}
						value={value}
						disabled={step.name === mainIdType && isEditing}
						maxLength={step.maxLength}
					/>
				);
			case "Date":
				return <Date prompt={step.prompt} />;
			case "Dimensions":
				set = (state) => {
					setProduct({
						...product,
						dimensions: {
							length: state.length,
							width: state.width,
							height: state.height,
						},
					});
				};
				return (
					<Dimensions
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Product Dimensions" })}
						callback={set}
						errors={errors}
						value={product.dimensions}
					/>
				);
			case "Nesting":
				return <Nesting product={product} setProduct={setProduct} />;
			case "Number":
				set = (text) => {
					if (step.name === "Quantity") {
						product.quantity = text;
					} else {
						product[step.name] = text;
					}
					setProduct({ ...product });
				};
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt ?? step.name}
						defaultPrompt={intl.formatMessage({ id: "Number" })}
						callback={set}
						value={step.name === "Quantity" ? product.quantity : product[step.name]}
					/>
				);
			case "Orientation":
				return <Orientation product={product} setProduct={setProduct} />;
			case "Select":
				set = (text) => {
					let newProduct = { ...product, [step.name]: text };
					setProduct(newProduct);
				};
				return <PacksizeSelect step={step} callback={set} value={product[step.name]} />;
			case "Bool":
				return <Bool prompt={step.prompt} />;
			case "VoidFill":
				return <DimensioningVoidFill product={product} setProduct={setProduct} errors={errors} />;
			case "Weight":
				return (
					<Weight
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Weight" })}
						product={product}
						setProduct={setProduct}
					/>
				);
			// case "Yes/No":
			//   return <Bool yesno prompt={step.prompt} />;

			default:
				return <span>{step.name}</span>;
		}
	}

	const returnTo = searchParams.get("returnTo");

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed || !tenantConfiguration?.attributeCaptureSteps ? (
				<FailedRetry retry={load} />
			) : (
				<Fragment>
					<div className={classes.grid}>
						<div className={classes.leftColumn}>
							<Header size="large" className={classes.header}>
								{isEditing ? (
									<>
										<FormattedMessage id="Editing Product" />
										<span> {product.identifiers[mainIdType]}</span>
									</>
								) : (
									<FormattedMessage id="Data Capture" />
								)}

								<Header.Subheader className={classes.subHeader}>
									{intl.formatMessage({
										id: "Enter product information in the form fields on the right Click save when finished",
									})}
								</Header.Subheader>
								{messageBox.show &&
									(messageBox.isError ? (
										<Message error content={messageBox.message} />
									) : (
										<Message success content={messageBox.message} />
									))}
							</Header>
							<div className={classes.footer}>
								<Button primary onClick={() => saveProduct(product)}>
									<FormattedMessage id="Save" />
								</Button>
								{returnTo && (
									<Button onClick={cancelHandler}>
										<FormattedMessage id="Cancel" />
									</Button>
								)}
								{/* <Button
                  onClick={() => {
                    navigate("/dimensioning/collect-wizard");
                  }}
                >
                  {intl.formatMessage({ id: "Use Wizard" })}
                </Button> */}
							</div>
						</div>
						<div className={classes.rightColumn}>
							{tenantConfiguration.attributeCaptureSteps.map((step, index) => (
								<div className={classes.formEntry} key={index}>
									{getFormComponent(step)}
								</div>
							))}
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
}

export default CollectDataPage;
