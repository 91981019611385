import { createContext, useEffect, useContext } from "react";
import { ProductionContext, aliasSort } from "@/contexts/production-context";
import useAxios from "@/api/useAxios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useToken from "@/hooks/use-token";
import { UserContext } from "@/components/user-context";
import isEqual from "lodash.isequal";
import useSignalRHub from "@/hooks/use-signalr-hub/use-signalr-hub";

export const ProductionGroupContext = createContext({
	changeProductionGroup: () => {},
	removeMachineGroupFromProductionGroup: () => {},
});

function ProductionGroupProvider({ children }) {
	const token = useToken();
	const queryClient = useQueryClient();
	const ProductionGroupApi = useAxios("/ProductionGroupApi/api/v1/ProductionGroups", token);

	const { currentUser } = useContext(UserContext);

	const { hubConnection, usePolling } = useSignalRHub({
		hubUrl: "/ProductionGroupApi/hubs/productionGroups",
		token,
	});

	const {
		currentMachineGroup,
		currentProductionGroup,
		productionGroups,
		setProductionGroups,
		setCurrentProductionGroup,
	} = useContext(ProductionContext);

	// Wiring up callbacks for when ProductionGroups publishes messages
	useEffect(() => {
		if (!hubConnection || !currentUser) return;

		hubConnection.on("OnProductionGroupsVersion", (version) => {
			console.info(
				`signalr ProductionGroup OnProductionGroupsVersion for connectionid ${hubConnection.connectionId}- ${version}`,
			);
		});

		hubConnection.on("OnProductionGroupUpdated", (tenantId, productionGroup) => {
			if (tenantId !== currentUser.Tenant) return;

			const oldProductionGroups = productionGroups.filter((pg) => pg.id !== productionGroup.id);
			const newProductionGroups = [...oldProductionGroups, productionGroup];
			newProductionGroups.sort(aliasSort);
			setProductionGroups(newProductionGroups);
		});

		hubConnection.on("OnProductionGroupAdded", (tenantId, productionGroup) => {
			if (tenantId !== currentUser.Tenant) return;

			const newProductionGroups = [...productionGroups, productionGroup];
			newProductionGroups.sort(aliasSort);
			setProductionGroups(newProductionGroups);
		});

		hubConnection.on("OnProductionGroupDeleted", (tenantId, productionGroupId) => {
			if (tenantId !== currentUser.Tenant) return;

			const newProductionGroups = productionGroups.filter((pg) => pg.id !== productionGroupId);
			setProductionGroups(newProductionGroups);
		});

		// Cleanup block
		return () => {
			hubConnection.off("OnProductionGroupsVersion");
			hubConnection.off("OnProductionGroupUpdated");
			hubConnection.off("OnProductionGroupAdded");
			hubConnection.off("OnProductionGroupDeleted");
		};
	}, [hubConnection, productionGroups, currentUser]);

	useEffect(() => {
		if (productionGroups.length > 0 && currentMachineGroup) {
			const newCurrentPg = productionGroups.find((pg) => pg.configuredMachineGroups.includes(currentMachineGroup.id));

			if (!isEqual(newCurrentPg, currentProductionGroup)) {
				setCurrentProductionGroup(newCurrentPg);
			}
		}
	}, [productionGroups, currentMachineGroup]);

	const queryFunction = async () => {
		const pgs = await ProductionGroupApi.get();
		const sortedPgs = pgs.sort(aliasSort);
		setProductionGroups(sortedPgs);
		return sortedPgs;
	};

	const queryKey = ["productionGroups", currentUser?.Tenant];

	useQuery({
		queryKey: queryKey,
		queryFn: queryFunction,
		refetchInterval: 3000,
		enabled: usePolling && Boolean(token),
	});

	const changeProductionGroup = async (mg, pg) => {
		if (mg.id && pg.id) {
			await ProductionGroupApi.updateWithUrl(`${pg.id}/machinegroups/${mg.id}`, {}, setCurrentProductionGroup);
			queryClient.invalidateQueries({ queryKey });
		}
	};

	const removeMachineGroupFromProductionGroup = async (mg) => {
		if (mg.id) {
			await ProductionGroupApi.updateWithUrl(`RemoveMachineGroupFromProductionGroup/${mg.id}`, {});
			queryClient.invalidateQueries({ queryKey });
			setCurrentProductionGroup(null);
		}
	};

	return (
		<ProductionGroupContext.Provider value={{ changeProductionGroup, removeMachineGroupFromProductionGroup }}>
			{children}
		</ProductionGroupContext.Provider>
	);
}

export default ProductionGroupProvider;
