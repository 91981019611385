import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage } from "react-intl";
import { Button, Form } from "semantic-ui-react";
import ApimModal from "./apim-modal";
import { removeApimSubscription } from "../../../../api/identity-api";
import { UserContext } from "../../../../components/user-context";
import ListContext from "@/components/list-context";

const useStyles = createUseStyles((theme) => ({
	field: {
		"& .field": {
			marginTop: ".5rem !important",
		},
	},
	boldText: {
		fontFamily: "Stolzl",
	},
}));

const DeleteApiKey = ({ trigger, data }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [input, setInput] = useState("");
	const { currentUser } = useContext(UserContext);
	const { list, setList } = useContext(ListContext);
	const deleteKey = async () => {
		await removeApimSubscription({ TenantId: currentUser.tid, SubscriptionId: data?.identifier });
		let filteredList = list.filter((x) => x.identifier !== data.identifier);

		setList([...filteredList]);
	};
	return (
		<ApimModal
			header={<FormattedMessage id="Delete API Key?"></FormattedMessage>}
			trigger={trigger}
			size={"tiny"}
			submit={
				<Button id="deleteKeyButton" primary disabled={input !== "delete"}>
					<FormattedMessage id="Delete Key" />
				</Button>
			}
			submitFunction={deleteKey}
			disabled={input !== "delete"}
		>
			<div>
				<FormattedMessage id="deleteKey" values={{ keyName: `'${data?.properties?.displayName}'` }} />
			</div>
			<Form>
				<Form.Field className={classes.field}>
					<div className={classes.nameField}>
						<FormattedMessage id="deleteKeyConfirm" />
					</div>
					<Form.Input fluid type="text" tabIndex={1} value={input} onChange={(e) => setInput(e.target.value)} />
				</Form.Field>
			</Form>
		</ApimModal>
	);
};

export default DeleteApiKey;
