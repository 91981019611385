import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	...theme.typography,
	saveButton: {
		textTransform: "uppercase !important",
	},
	centerVertically: {
		display: "flex",
		alignItems: "center",
	},
	dummyButton: {
		backgroundColor: theme.colors.secondary + " !important",
		":active :focus :hover": {
			backgroundColor: theme.colors.secondary + " !important",
		},
	},
}));

const SaveButton = (props) => {
	const intl = useIntl();
	const theme = useTheme();

	const classes = useStyles({ theme });
	return (
		<div>
			<Button
				loading={props.isSaving}
				primary={props.saveButtonEnabled}
				onClick={props.onClick}
				className={props.className + "  " + classes.saveButton + " " + classes.centerVertically + " " + classes.button}
				disabled={props.succesfulSave === false && props.saveButtonEnabled === false}
				style={props.succesfulSave ? { display: "none" } : {}}
				data-cy={props.dataCy ?? ""}
			>
				<span>{intl.formatMessage({ id: "Save" })}</span>
			</Button>
			<Button
				secondary
				className={
					(props.className !== undefined ? props.className : "") +
					"  " +
					classes.saveButton +
					" " +
					classes.centerVertically +
					" " +
					classes.button +
					" " +
					classes.dummyButton
				}
				style={props.succesfulSave === false ? { display: "none" } : {}}
			>
				<span>{intl.formatMessage({ id: "Saved" })}</span>
			</Button>
		</div>
	);
};
export default SaveButton;
