/* eslint-disable no-unused-expressions */

import { Button, Divider, Dropdown, Header, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useEffect, useRef, useState } from "react";
import ScanPageContext, { scanStepNames } from "../contexts/scan-page-context";
import { createUseStyles, useTheme } from "react-jss";
import { readLocalStorage, writeLocalStorage } from "@/api/local-storage";

import ConfirmModal from "@/components/confirm-modal";
import DimDataContext from "../contexts/dimensioning-data-context";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import MultiScan from "./multi-scan";
import { ProductionContext } from "@/contexts/production-context";
import { MachineGroupContext } from "@/contexts/machine-group/machine-group-context";
import ScanOptionsCard from "./scan-options-card";
import SingleScan from "./single-scan";
import { getAllBrands } from "@/api/corrugates-api";
import { getDesigns as getPackagingDesigns } from "@/api/packaging-design-api";
import useAxios from "@/api/useAxios";

const TITLE_HEIGHT = "96px";
const LOCAL_STORAGE_PRODUCTION_OPTIONS = "dimension-production-options";
const LOCAL_STORAGE_XVALUES = "dimension-xvalues-";
const LOCAL_STORAGE_SCANNED = "dimension-scanned-products";

const defaultScanOptions = {
	designId: "",
	brand: "",
	boxesToBeProduced: 1,
	xvalues: [],
};

const keepFocus = {
	scanInput: false,
	lpnInput: false,
	jobTitleInput: false,
	SelectMachineGroup: true,
	SelectBrand: true,
	SelectCartonDesign: true,
	mgPgDropdown: true,
};

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
		height: "calc(100vh - 128px)",
	},
	header: {
		border: "0 !important",
		margin: "37px 32px !important",
		padding: "0 !important",
		height: TITLE_HEIGHT,
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		boxShadow: "0 0 !important",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},

	activeButton: {
		textTransform: "uppercase !important",
		color: `${theme.light} !important`,
		backgroundColor: theme.colors.primary,
	},
	inactiveButton: {
		textTransform: "uppercase !important",
		color: theme.colors.primary,
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: `${theme.light} !important`,
	},
	controlsGroup: {
		position: "absolute !important",
		right: "0px",
		top: "-77px",
		margin: "0px !important",
		padding: "0px !important",
	},
	controls: {
		border: "1px",
		borderRadius: "6px",
		minWidth: "350px",
		margin: "6px !important",
		marginRight: "0px !important",
		maxHeight: "48px",
		boxShadow: "unset !important",
	},
	bigButton: {
		margin: "0px !important",
		padding: "32px !important",
		background: "white !important",
	},
	controlButton: {
		marginTop: "6px !important",
		marginLeft: "16px !important",
		marginRight: "32px !important",
		paddingTop: "8px !important",
		height: "48px",
	},
	controlsRow: {
		paddingTop: "12px !important",
		paddingBottom: "11px !important",
	},
	checkBox: {
		fontSize: "6em !important",
	},
	mgPgDropdown: {
		padding: "unset !important",
		border: "unset !important",
		color: theme.colors.primary,
		marginRight: "16px !important",
		minHeight: "unset !important",
		lineHeight: "unset !important",
		boxShadow: "unset !important",
		"& div.menu": {
			minWidth: "200px !important",
			left: "-16px !important",
			top: "32px !important",
			maxHeight: "unset !important",
		},
	},
	divider: {
		height: "0px !important",
		padding: "0px !important",
		margin: "0px !important",
	},
}));

function readProductionOptions() {
	const pageStateStr = readLocalStorage(LOCAL_STORAGE_PRODUCTION_OPTIONS);
	const newState = JSON.parse(pageStateStr);
	return newState ?? defaultScanOptions;
}

function ScanPage({ currentUser }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [scanOptions, setScanOptions] = useState(() => readProductionOptions());

	const intl = useIntl();
	const [pageLoading, setPageLoading] = useState(true);
	const [loadingError, setLoadingError] = useState(undefined);
	const [designs, setDesigns] = useState([]);
	const [brands, setBrands] = useState([]);
	const scanTimerRef = useRef();
	const scanInputRef = useRef();
	const lpnInputRef = useRef();
	const jobInputRef = useRef();
	const dimCtx = useContext(DimDataContext);
	const scanCtx = useContext(ScanPageContext);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [errors, setErrors] = useState({});
	const { machineGroups, currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);
	const { changeMachineGroup } = useContext(MachineGroupContext);
	const [locationOptions, setLocationOptions] = useState([]);
	const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(true);
	const scanStepsOrder = dimCtx.customerConfiguration.scanSteps;

	const EmServiceApi = useAxios("/emMachineApi/api/v1/machines", token);

	const menuOptions = [
		{
			key: "Divider",
			value: "Divider",
			content: <Divider className={classes.divider} />,
		},
		{
			key: "ChangeMachineGroup",
			text: intl.formatMessage({ id: "Leave Machine Group" }),
			value: "ChangeMachineGroup",
		},
	];
	if (currentUser.role.includes("changeMachineGroupsProductionGroup".toLocaleLowerCase())) {
	}
	menuOptions.push({
		key: "Divider2",
		value: "Divider",
		content: <Divider className={classes.divider} />,
	});

	function setInputFocus() {
		const state = scanStepsOrder[0]?.name;
		let input = null;
		switch (state) {
			case scanStepNames.licensePlateNumber:
				input = lpnInputRef.current;
				break;

			case scanStepNames.jobTitle:
				input = jobInputRef.current;
				break;

			case scanStepNames.uniqueIdentifier:
			default:
				input = scanInputRef.current;
				break;
		}

		if (input) {
			input.focus();
			input.select();
		}
	}

	function setInputFocusToEmptyField(name) {
		switch ("lpn") {
			case scanStepNames.licensePlateNumber:
				lpnInputRef.current?.focus();
				break;

			case "job":
			default:
				jobInputRef.current?.focus();
				break;
		}
	}

	async function pageLoad() {
		setPageLoading(true);
		setLoadingError(undefined);

		const designsData = await getPackagingDesigns();
		if (Array.isArray(designsData)) {
			setDesigns(
				designsData.map((d) => {
					const xvalues = d.designParameters.sort((a, b) => a.alias.localeCompare(b.alias));
					return {
						key: d.DesignId,
						xvalues,
						value: d.DesignId,
						text: d.DesignId ? `${d.name} (${d.DesignId})` : d.name,
					};
				}),
			);
		} else {
			console.error(`Error reading from designs api: ${(designsData && designsData.error) || "unknown error"}`);
		}

		const brandsData = await getAllBrands();
		try {
			setBrands(
				brandsData.map((b) => {
					return { key: b.brand, value: b.brand, text: b.brand };
				}),
			);
			setBrands((brands) => [...brands, { key: "any", value: "any", text: "Any brand" }]);
		} catch (err) {
			console.error(`Error reading from Brands API: ${err.message}`);
		}

		const pageState = readProductionOptions();
		scanOptionsUpdateHandler(pageState);

		setPageLoading(false);
	}

	async function getEmMachineLocations(machineId, machineAlias) {
		// Build up the contents of the location drop-down
		let options = [];
		const { data } = await EmServiceApi.getWithUrl(`${machineId}`);
		if (data.crossConveyorConnected === true) {
			options = [
				{
					text: data.alias,
					packStation: "1",
					machine: machineId,
					machineGroup: currentMachineGroup.alias,
					productionGroup: currentProductionGroup?.alias,
					pickZone: null,
				},
				{
					text: data.alias,
					packStation: "2",
					machine: machineId,
					machineGroup: currentMachineGroup.alias,
					productionGroup: currentProductionGroup?.alias,
					pickZone: null,
				},
			];
		}
		return options;
	}

	async function loadLocationOptions() {
		let options = [];
		if (currentMachineGroup) {
			setIsLoadingLocationOptions(true);

			// prettier-ignore
			if (Object.values(currentMachineGroup?.configuredMachinesWithMachineType).includes("Em")) {
        const pair = Object
          .entries(currentMachineGroup?.configuredMachinesWithMachineType)
          .find(([_, value]) => value === "Em");

        options = await getEmMachineLocations(...pair);
      }

			setIsLoadingLocationOptions(false);
			setLocationOptions(options);
		}

		var location = {
			packStation: null,
			machine: options[1]?.machine,
			machineGroup: currentMachineGroup?.alias || "",
			productionGroup: currentProductionGroup?.alias || "",
			pickZone: null,
		};
		scanOptionsUpdateHandler({ location }, !pageLoading && !dimCtx.isLoading);
	}

	useEffect(() => {
		loadLocationOptions().then((_) => setInputFocus());
	}, [currentMachineGroup?.alias, currentProductionGroup?.alias]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		pageLoad();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!pageLoading && !dimCtx.isLoading) {
			setInputFocus();
		}
	}, [pageLoading, dimCtx.isLoading, scanCtx.isSingleScan]);

	function onScanInputFocus() {
		clearTimeout(scanTimerRef.current);
	}

	function onScanInputBlur() {
		clearTimeout(scanTimerRef.current);

		scanTimerRef.current = setTimeout(scanInputBlurTimerCallback, 5 * 1000);

		function scanInputBlurTimerCallback() {
			let checkAgain = false;
			const id = document.activeElement.id;
			if (id in keepFocus) {
				if (!keepFocus[id]) {
					// Focus is on an input that should ALWAYS keep focus
					checkAgain = true;
				} else {
					// Focus is on one of the dropdowns, so it should only keep focus
					// if it is expanded.
					const isExpanded = document.activeElement["ariaExpanded"];
					if (isExpanded === "true") {
						checkAgain = true;
					}
				}
			}

			if (checkAgain) {
				scanTimerRef.current = setTimeout(scanInputBlurTimerCallback, 5 * 1000);
				return;
			}

			setInputFocus();
		}
	}

	function scanOptionsUpdateHandler(updatedProps, save = true) {
		setScanOptions((oldProps) => {
			const newProps = {
				...oldProps,
				...updatedProps,
			};

			if (save) {
				writeLocalStorage(LOCAL_STORAGE_PRODUCTION_OPTIONS, JSON.stringify(newProps));
			}

			return newProps;
		});

		onScanInputBlur();
	}

	function designChangedHandler(e, { value }) {
		const storage = readLocalStorage(LOCAL_STORAGE_XVALUES) ?? "{}";
		const xValuesCatalog = JSON.parse(storage);

		xValuesCatalog[scanOptions.designId] = scanOptions.xvalues;
		const newXValues = xValuesCatalog[value] ?? [];

		const xvalues = designs.find((x) => x.value === value).xvalues;
		scanOptionsUpdateHandler({
			designId: value,
			xvalues: xvalues.map((x, index) => {
				return {
					...x,
					value: index < newXValues.length ? newXValues[index].value : 0,
				};
			}),
		});

		writeLocalStorage(LOCAL_STORAGE_XVALUES, JSON.stringify(xValuesCatalog));
	}

	function setLocation(location) {
		scanOptionsUpdateHandler({ location });
	}

	function brandChangeHandler(e, { value }) {
		scanOptionsUpdateHandler({ brand: value });
	}

	function xvalueChangeHandler(alias, value) {
		const xvalues = scanOptions.xvalues.map((xvalue) =>
			xvalue.alias === alias ? { ...xvalue, value: value } : xvalue,
		);
		scanOptionsUpdateHandler({ xvalues: xvalues });
	}

	function confirmModalHandler(answerYes) {
		setIsConfirmModalOpen(false);
		if (answerYes) {
			scanCtx.singleScanChanged(true);
		}
	}

	function singleScanClickedHandler() {
		const scanned = JSON.parse(readLocalStorage(LOCAL_STORAGE_SCANNED) || "[]");
		if (!scanned || scanned.length === 0) {
			scanCtx.singleScanChanged(true);
		} else {
			setIsConfirmModalOpen(true);
		}
	}

	function validate() {
		let newErrors = {};

		const isLocationRequired = locationOptions?.length > 1;
		if (isLocationRequired && !scanOptions?.location?.packStation) {
			newErrors = { ...newErrors, location: true };
		}

		if (!scanOptions.designId) {
			newErrors = { ...newErrors, designId: true };
		}

		if (Object.keys(newErrors).length > 0) {
			console.error("PAGE ERRORS", newErrors);
		}

		newErrors = { ...newErrors, count: Object.keys(newErrors).length };
		setErrors(newErrors);

		return newErrors.count === 0;
	}

	return (
		<div className={classes.container}>
			<ConfirmModal
				open={isConfirmModalOpen}
				onClose={confirmModalHandler}
				titleText={intl.formatMessage({ id: "MoveToSingleScan" })}
				detailText="MoveToSingleScanDescription"
				confirmText="Continue"
				cancelText="Cancel"
			/>
			<Segment className={classes.header}>
				<Header className={classes.headerText}>
					{scanCtx.isSingleScan ? <FormattedMessage id="Single Scan" /> : <FormattedMessage id="Multiple Scan" />}
				</Header>
				<Segment basic compact className={classes.controlsGroup} floated="right">
					<Segment.Group horizontal>
						<Segment>
							<FormattedMessage id={"Current Machine Group"} />
						</Segment>
						<Segment>
							<Dropdown
								compact
								fluid
								onChange={(e, { value }) => {
									if (value === "ChangeMachineGroup") {
										changeMachineGroup(undefined);
										return;
									}

									const newGroup = machineGroups.filter((mg) => mg.id === value);
									if (newGroup.length) changeMachineGroup(newGroup[0]);
								}}
								options={menuOptions.concat(
									machineGroups.map((mg) => ({
										key: mg.id,
										text: mg.alias,
										value: mg.id,
									})),
								)}
								selection
								value={currentMachineGroup?.id}
								className={classes.mgPgDropdown}
								id="mgPgDropdown"
								icon={null}
							></Dropdown>
						</Segment>
					</Segment.Group>
				</Segment>
				<Button.Group>
					<Button
						size="large"
						className={scanCtx.isSingleScan ? classes.activeButton : classes.inactiveButton}
						onClick={singleScanClickedHandler}
					>
						<FormattedMessage id="Single" />
					</Button>
					<Button
						size="large"
						className={!scanCtx.isSingleScan ? classes.activeButton : classes.inactiveButton}
						onClick={() => {
							scanCtx.singleScanChanged(false);
						}}
					>
						<FormattedMessage id="Multiple" />
					</Button>
				</Button.Group>
			</Segment>

			{pageLoading && dimCtx.isLoading ? (
				<Loading />
			) : loadingError ? (
				<FailedRetry retry={pageLoad} />
			) : (
				<div>
					{scanCtx.isSingleScan && (
						<SingleScan
							scanOptions={scanOptions}
							onScanInputFocus={onScanInputFocus}
							onScanInputBlur={onScanInputBlur}
							scanInputRef={scanInputRef}
							lpnInputRef={lpnInputRef}
							jobInputRef={jobInputRef}
							onValidate={validate}
							setInputFocus={setInputFocus}
						>
							<ScanOptionsCard
								location={scanOptions.location}
								setLocation={setLocation}
								locationOptions={locationOptions}
								brand={scanOptions.brand}
								setBrand={brandChangeHandler}
								brandOptions={brands}
								designId={scanOptions.designId}
								setDesignId={designChangedHandler}
								designOptions={designs}
								xvaluesList={scanOptions.xvalues}
								setXvalue={xvalueChangeHandler}
								isLoadingLocationOptions={isLoadingLocationOptions}
								errors={errors}
							/>
						</SingleScan>
					)}
					{!scanCtx.isSingleScan && (
						<MultiScan
							scanOptions={scanOptions}
							onScanInputFocus={onScanInputFocus}
							onScanInputBlur={onScanInputBlur}
							scanInputRef={scanInputRef}
							lpnInputRef={lpnInputRef}
							jobInputRef={jobInputRef}
							onValidate={validate}
							setInputFocus={setInputFocus}
							setInputFocusToEmptyField={setInputFocusToEmptyField}
						>
							<ScanOptionsCard
								location={scanOptions.location}
								setLocation={setLocation}
								locationOptions={locationOptions}
								brand={scanOptions.brand}
								setBrand={brandChangeHandler}
								brandOptions={brands}
								designId={scanOptions.designId}
								setDesignId={designChangedHandler}
								designOptions={designs}
								xvaluesList={scanOptions.xvalues}
								setXvalue={xvalueChangeHandler}
								isLoadingLocationOptions={isLoadingLocationOptions}
								errors={errors}
							/>
						</MultiScan>
					)}
				</div>
			)}
		</div>
	);
}

export default ScanPage;
