export const Info = ({ size, color, onMouseOver, onMouseOut, dataTooltipId }) => {
	return (
		<svg
			width={size}
			height={size}
			data-tooltip-id={dataTooltipId}
			viewBox="0 0 100 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="transparent" stroke={color}>
				<circle cx="50" cy="50" r="45" strokeWidth={10} />
				<line y1="80" y2="45" x1="50" x2="50" strokeWidth={10} />
				<circle cx="50" cy="30" r="5" fill={color} />
			</g>
		</svg>
	);
};

export default Info;
