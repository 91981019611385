import axios from "axios";
import { readLocalStorage } from "./local-storage";

const x6Api = "/x6Api/api/v1/machine";

export async function getX6Machines() {
	const bearer = readLocalStorage("BEARER");
	const response = await axios.get(x6Api, {
		headers: {
			Authorization: `${bearer}`,
		},
	});

	return response.data;
}

export async function createX6Machine(data) {
	const bearer = readLocalStorage("BEARER");
	await axios.post(x6Api, data, {
		headers: {
			Authorization: `${bearer}`,
		},
	});
}

export async function updateX6Machine(data) {
	const bearer = readLocalStorage("BEARER");
	await axios.put(`${x6Api}/${data.id}`, data, {
		headers: {
			Authorization: `${bearer}`,
		},
	});
}

export async function deleteX6Machine(id) {
	const bearer = readLocalStorage("BEARER");
	await axios.delete(`${x6Api}/${id}`, {
		headers: {
			Authorization: `${bearer}`,
		},
	});
}

export async function getWithPath(machineId, path, abortController) {
	const bearer = readLocalStorage("BEARER");
	const response = await axios.get(`${x6Api}/${machineId}/${path}`, {
		headers: {
			Authorization: `${bearer}`,
		},
		signal: abortController?.signal,
	});

	return response.data;
}

export async function putWithPath(machineId, path, data, abortController) {
	const bearer = readLocalStorage("BEARER");
	const response = await axios.put(`${x6Api}/${machineId}/${path}`, data, {
		headers: {
			Authorization: `${bearer}`,
		},
		signal: abortController?.signal,
	});

	return response.data;
}
