// eslint-disable
import { Header, Segment, Label, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import DeleteButton from "@/components/delete-button";
import EditButton from "@/components/edit-button";
import IconButton from "@/components/icon-button";
import ProductSearch from "./product-search";
import { deleteProduct } from "@/api/dim-product-api";
import { useIsRoleEnabled } from "@/components/user-context";
import ProductCreate from "./product/product-create";
import ProductDetails from "./product/product-details";
import ProductEdit from "./product/product-edit";
import ConfirmModal from "@/components/confirm-modal";
import GlobalButton from "@/components/global-button";

import { useNavigate, useSearchParams } from "react-router-dom";

import { sleep } from "@/utils/sleep";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: theme.light,
		height: `calc(100vh - ${theme.measurements.menuBarHeight})`,
	},
	header: {
		border: "0 !important",
		margin: "16px 32px !important",
		padding: "0 !important",
		height: theme.measurements.pageHeaderHeight,
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		boxShadow: "0 0 !important",
		display: "flex",
		justifyContent: "space-between",
		wrap: "no-wrap",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontFamily: "Stolzl-Medium !important",
		fontSize: "32px !important",
		marginTop: "26px !important",
	},
	iconButton: {
		marginTop: "0 !important",
	},
	headerTextPadding: {
		paddingLeft: "16px !important",
		paddingTop: "8px !important",
	},
	defaultButton: {
		backgroundColor: `${theme.colors.light} !important`,
		color: "#0068ab !important",
		opacity: "1 !important",
		cursor: "pointer",
	},
	buttonPadding: {
		marginTop: "26px !important",
	},
}));

function ProductsPage({ currentUser }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const [searchParams] = useSearchParams();
	const [isDeleteConfirmModalOpened, setIsDeleteConfirmModalOpened] = useState(false);
	const [isCreateOpen, setIsCreateOpen] = useState(false);
	const [isViewOpen, setIsViewOpen] = useState(false);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [editViewId, setEditViewId] = useState(0);
	const [triggerSaveButton, setTriggerSaveButton] = useState(0);
	const [triggerClearSearch, setTriggerClearSearch] = useState(0);
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [saveComplete, setSaveComplete] = useState(false);
	const hasManageProductsAccess = useIsRoleEnabled("manageDimensioningProducts");
	const navigate = useNavigate();

	function createProductHandler() {
		setIsCreateOpen(false);
		setSearchText("");
		setTriggerClearSearch(triggerClearSearch + 1);
		const returnTo = searchParams.get("returnTo");
		if (returnTo) {
			navigate(`/dimensioning/${returnTo}`);
		}
	}

	async function addNewClickedHandler(id) {
		setEditViewId("0");
		setSearchText(id ?? "");
		setTriggerClearSearch(0);
		await sleep(100);
		setIsCreateOpen(true);
	}

	async function viewProductHandler(id) {
		setEditViewId(id);
		await sleep(100);
		setIsViewOpen(true);
	}
	function returnToProductsList() {
		const returnTo = searchParams.get("returnTo");
		if (returnTo) {
			navigate(`/dimensioning/${returnTo}`);
		}
		setIsCreateOpen(false);
		setIsViewOpen(false);
		setIsEditOpen(false);
		setSearchText("");
		setTriggerSaveButton(0);
		dimCtx.reload();
	}

	async function deleteProductHandler() {
		var success = await deleteProduct(editViewId);

		if (success) {
			dimCtx.reload();
		}
	}
	function confirmDeleteHandler(answerYes) {
		setIsDeleteConfirmModalOpened(false);
		if (!answerYes) {
			dimCtx.reload();
			return;
		}

		deleteProductHandler();
		dimCtx.reload();
		setIsViewOpen(false);
	}

	function showDelete(id) {
		setEditViewId(id);
		setIsDeleteConfirmModalOpened(true);
	}

	function editItemHandler(productId) {
		setEditViewId(productId);
		setIsViewOpen(false);
		setIsCreateOpen(false);
		setIsEditOpen(true);
	}

	function onLoad() {
		const idToCreate = searchParams.get("productId");
		if (idToCreate) {
			addNewClickedHandler(idToCreate);
		}
	}

	useEffect(() => {
		onLoad();
	}, []);

	useEffect(() => {
		if (saveComplete) {
			returnToProductsList();
			setSaveComplete(false);
		}
	}, [saveComplete]);

	return (
		<div className={classes.container}>
			<ProductCreate
				open={isCreateOpen}
				onConfirm={createProductHandler}
				onCancel={returnToProductsList}
				onClose={returnToProductsList}
				config={dimCtx.customerConfiguration}
				productId={searchText}
			/>
			<ConfirmModal
				open={isDeleteConfirmModalOpened}
				onClose={confirmDeleteHandler}
				titleText={intl.formatMessage({ id: "DeleteProduct" }, { productId: editViewId })}
				detailText={intl.formatMessage({ id: "DeleteProductDescription" })}
				confirmText={intl.formatMessage({ id: "Delete" })}
				cancelText={intl.formatMessage({ id: "Cancel" })}
			/>
			{isViewOpen && (
				<Segment className={classes.header}>
					<Header floated="left" className={classes.headerText}>
						<IconButton
							onClick={() => {
								returnToProductsList();
							}}
							className={classes.iconButton}
						>
							<Icon name="angle left" color="blue" size="large" />
						</IconButton>
						<span className={classes.headerTextPadding}>
							<FormattedMessage id="DimProductDetails" />
						</span>
					</Header>
					{hasManageProductsAccess && (
						<div className={classes.buttonPadding}>
							<EditButton
								onClick={() => {
									editItemHandler(editViewId);
								}}
							/>
							<DeleteButton
								onClick={() => {
									setIsDeleteConfirmModalOpened(true);
								}}
							/>
						</div>
					)}
				</Segment>
			)}
			{isEditOpen && (
				<Segment className={classes.header}>
					<Header floated="left" className={classes.headerText}>
						<IconButton
							onClick={() => {
								returnToProductsList();
							}}
						>
							<Icon name="angle left" color="blue" size="large" />
						</IconButton>
						<span className={classes.headerTextPadding}>{editViewId}</span>
					</Header>
					{hasManageProductsAccess && (
						<div className={classes.buttonPadding}>
							<GlobalButton
								type="primary"
								onClick={() => {
									setTriggerSaveButton(triggerSaveButton + 1);
								}}
								disabled={saveDisabled}
								text="Save"
							/>
						</div>
					)}
				</Segment>
			)}
			{!isViewOpen && !isEditOpen && (
				<Segment className={classes.header}>
					<Header className={classes.headerText}>
						<FormattedMessage id="Products" />
						<Label size="large">
							{dimCtx && dimCtx.productsCount ? Intl.NumberFormat("en-us", {}).format(dimCtx.productsCount) : "..."}
						</Label>
					</Header>
					{hasManageProductsAccess && (
						<div className={classes.buttonPadding}>
							<GlobalButton
								type="secondary"
								text="AddNew"
								disabled={dimCtx.isLoading}
								onClick={() => addNewClickedHandler("")}
							/>
						</div>
					)}
				</Segment>
			)}

			{dimCtx.isLoading && <Loading />}
			{dimCtx.hasLoadFailed() && <FailedRetry retry={dimCtx.reload} />}
			{dimCtx.hasLoadSucceeded() && isViewOpen && (
				<ProductDetails config={dimCtx.customerConfiguration} productId={editViewId} />
			)}
			{dimCtx.hasLoadSucceeded() && isEditOpen && (
				<ProductEdit
					config={dimCtx.customerConfiguration}
					productId={editViewId}
					triggerSave={triggerSaveButton}
					setSaveDisabled={setSaveDisabled}
					setSaveComplete={setSaveComplete}
				/>
			)}
			{dimCtx.hasLoadSucceeded() && !dimCtx.isLoading && !isViewOpen && !isEditOpen && (
				<ProductSearch
					config={dimCtx.customerConfiguration}
					addNew={addNewClickedHandler}
					viewProduct={viewProductHandler}
					editItemHandler={editItemHandler}
					showDelete={showDelete}
					triggerClearSearch={triggerClearSearch}
				/>
			)}
		</div>
	);
}

export default ProductsPage;
