import { createContext, useState } from "react";
import MachineGroupProvider, { MACHINE_GROUP_KEY } from "./machine-group/machine-group-context";
import ProductionGroupProvider from "@/contexts/production-group/production-group-context";
import { readLocalStorage } from "@/api/local-storage";

export const aliasSort = (a, b) => {
	if (a.alias > b.alias) return 1;
	else if (a.alias < b.alias) return -1;
	else return 0;
};

const getStoredMachineGroup = () => {
	try {
		const storedMachineGroup = readLocalStorage(MACHINE_GROUP_KEY);
		if (storedMachineGroup) {
			return JSON.parse(storedMachineGroup);
		}
		return null;
	} catch (e) {
		console.error(e);
		return null;
	}
};

export const ProductionContext = createContext({
	machineGroups: [],
	currentMachineGroup: null,
	productionGroups: [],
	currentProductionGroup: null,
	setMachineGroups: () => {},
	setProductionGroups: () => {},
	setCurrentMachineGroup: () => {},
	setCurrentProductionGroup: () => {},
});

function ProductionProvider({ children }) {
	const [machineGroups, setMachineGroups] = useState([]);
	const [currentMachineGroup, setCurrentMachineGroup] = useState(getStoredMachineGroup());
	const [productionGroups, setProductionGroups] = useState([]);
	const [currentProductionGroup, setCurrentProductionGroup] = useState(null);

	return (
		<ProductionContext.Provider
			value={{
				machineGroups,
				currentMachineGroup,
				productionGroups,
				currentProductionGroup,
				setMachineGroups,
				setProductionGroups,
				setCurrentMachineGroup,
				setCurrentProductionGroup,
			}}
		>
			<ProductionGroupProvider>
				<MachineGroupProvider>{children}</MachineGroupProvider>
			</ProductionGroupProvider>
		</ProductionContext.Provider>
	);
}

export default ProductionProvider;
