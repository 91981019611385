import styled from "styled-components";
import { StyledIconBase } from "@styled-icons/styled-icon";

const IconStyleWrapper = styled.div`
	${StyledIconBase} {
		&.defaultIcon {
			height: 1.5em;
			width: 1.5em;
			color: ${({ theme }) => theme.colors.text};
		}

		&.errorColor {
			color: ${({ theme }) => theme.colors.error};
		}

		&.textColor {
			color: ${({ theme }) => theme.colors.text};
		}

		&.darkGreyTextColor {
			color: ${({ theme }) => theme.colors.darkGreyText};
		}

		&.primaryColor {
			color: ${({ theme }) => theme.colors.primary};
		}
	}
`;

export default IconStyleWrapper;
